import styles from "./ToggleButton.module.css";

function ToggleButton({ checked, onChange, disabled = false }) {
  return (
    <div
      className={`${styles.toggleButton} ${checked && styles.checked} ${
        disabled && styles.disabled
      }`}
      onClick={(e) => {
        if (!disabled) {
          onChange();
        }
        e.stopPropagation();
      }}
    >
      <div className={styles.button} />
    </div>
  );
}

export default ToggleButton;
