import React, { useState } from "react";
import styles from "./Tooltip.module.css";

const Tooltip = ({
  text,
  title,
  icon,
  position = "top",
  children,
  arrowVisible = false,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  return (
    <div
      className={styles.tooltipWrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={(e) => e.stopPropagation()}
    >
      <div>{children}</div>
      <div
        className={`${styles.tooltip} ${styles[position]}`}
        style={{
          opacity: isVisible ? 1 : 0,
          visibility: isVisible ? "visible" : "hidden",
          pointerEvents: "none", // Prevent tooltip from blocking interactions
        }}
        data-arrow-visible={arrowVisible}
      >
        <div className={styles.titleHolder}>
          {icon && <span className={styles.icon}>{icon}</span>}
          {title && <div className={styles.title}>{title}</div>}
        </div>
        <div className={icon ? styles.textIcon : styles.textRegular}>
          {text}
        </div>
      </div>
    </div>
  );
};

export default Tooltip;
