import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../features/auth/authSlice"; // Assuming you have this action in Redux
import { useNavigate } from "react-router-dom";
import styles from "./Login.module.css";

function Login() {
  // Redux dispatch and selector
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, isAuthenticated } = useSelector(
    (state) => state.auth
  );

  // Local state for the login form
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  // State to toggle password visibility
  const [showPassword, setShowPassword] = useState(false);

  const { username, password } = formData;

  // Handle input changes
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const onSubmit = async (e) => {
    e.preventDefault();
    await dispatch(loginUser({ username, password }));
  };

  // Redirect if authenticated
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    // <div className="loginlight">
    <div className={styles.loginContainer}>
      {/*Left side of the screen (everything of login)*/}
      <div className={styles.loginContent}>
        <img
          loading="lazy"
          src={`${process.env.PUBLIC_URL}/reform_svg.png`}
          className={styles.reformLogoPng}
          alt="Reform Logo"
        />

        <div className={styles.frameParent}>
          <div className={styles.signInParent}>
            <div className={styles.signIn}>Sign in</div>
            <div className={styles.newToReformContainer}>
              New to Reform?{" "}
              <span
                onClick={() => console.log("Redirect to sign up")}
                className={styles.signUpFor}
              >
                Sign up for free
              </span>
              .
            </div>
          </div>

          {/* Form with username and password */}
          <form onSubmit={onSubmit} className={styles.frameGroup}>
            <div className={styles.usernameWrapper}>
              <input
                type="text"
                placeholder="Username"
                name="username"
                value={username}
                onChange={onChange}
                required
                className={styles.username}
              />
            </div>

            <div className={styles.frameContainer}>
              <div className={styles.usernameWrapper}>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  value={password}
                  onChange={onChange}
                  required
                  className={styles.username}
                />

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                  onClick={togglePasswordVisibility}
                  style={{ cursor: "pointer" }}
                >
                  {showPassword ? (
                    <path
                      stroke="#BDB4FE"
                      strokeLinecap="round"
                      strokeWidth="1.66667"
                      strokeLinejoin="round"
                      d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                    />
                  ) : (
                    <path
                      stroke="#BDB4FE"
                      strokeLinecap="round"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178ZM15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  )}
                </svg>
              </div>

              <div className={styles.forgotPassword}>Forgot password?</div>
            </div>

            {/* Show loading or error */}
            {loading && <p>Loading...</p>}
            {error && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  marginBottom: 10,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="red"
                  fill="none"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
                  />
                </svg>
                <p className={styles.error}>{error}</p>
              </div>
            )}
          </form>

          {/* Sign in button and Google button */}
          <div className={styles.mainButtonParent}>
            <div className={styles.mainButton} onClick={onSubmit}>
              <button type="submit" className={styles.high}>
                Sign in
              </button>
            </div>

            {/* Sign in with Google */}
            {/* 
            <button className={styles.mainButton1}>
              <img
                loading="lazy"
                src={`${process.env.PUBLIC_URL}/google_svg.png`}
                className={styles.image104Icon}
                alt=""
              />
              <span className={styles.high1}>Sign in with Google</span>
            </button>
            */}
          </div>
        </div>

        <div className={styles.englishUnitedKingdom}>
          English (United Kingdom)
        </div>
      </div>

      {/*Right side of the screen*/}
      <img
        loading="lazy"
        src={`${process.env.PUBLIC_URL}/background_svg.svg`}
        className={styles.backgroundImage}
        alt=""
      />

      <img
        loading="lazy"
        src={`${process.env.PUBLIC_URL}/dashboard_svg.png`}
        className={styles.floatingImage}
        alt="Decorative overlay"
      />
    </div>
  );
}

export default Login;
