import styles from "./SortingSelection.module.css";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setSearchQuery,
  setSelectedSortingRule,
  setSelectedPeriod,
  fetchRecommendations,
  clearRecommendations,
} from "../../../features/scenario_overview/scenarioOverviewSlice";
import MonthSelector from "../../../layout/selector/MonthSelector";

import { FilterButton, IconButton } from "../../../layout/buttons/IconButton";
import ModalPopUp from "../../../layout/modal/Modal";
import {
  SecondaryButton,
  PrimaryButton,
} from "../../../layout/buttons/NormalButton";

import { InputSelector } from "../../../layout/selector/Selector";
import { OptionClass } from "../../../layout/selector/Selector";
import SignInput from "../../../layout/input/SignInput";

const inputPairSelectorOptions = [
  //q1 , q3 , median
  new OptionClass("Q1", "Q1", "Q1"),
  new OptionClass("Q3", "Q3", "Q3"),
  new OptionClass("Median", "Median", "Median"),
];

const importIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M10 11.0029V17.6696"
      stroke="#E9D7FE"
      strokeWidth="1.39167"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.3328 12.5855C2.71366 11.9529 2.2466 11.1877 1.96699 10.3479C1.68738 9.50812 1.60255 8.61569 1.71893 7.73824C1.83531 6.86078 2.14984 6.02132 2.63871 5.28343C3.12757 4.54554 3.77795 3.92858 4.54057 3.47928C5.3032 3.02998 6.15807 2.76011 7.04044 2.69014C7.9228 2.62016 8.80952 2.75189 9.63344 3.07537C10.4573 3.39885 11.1968 3.90558 11.7959 4.55718C12.395 5.20879 12.8379 5.98818 13.0911 6.83632H14.5828C15.3874 6.83623 16.1707 7.09492 16.817 7.57419C17.4632 8.05345 17.9382 8.72787 18.1718 9.49783C18.4053 10.2678 18.385 11.0924 18.1139 11.85C17.8428 12.6075 17.3352 13.2577 16.6661 13.7047"
      stroke="#E9D7FE"
      strokeWidth="1.39167"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66669 14.3363L10 11.0029L13.3334 14.3363"
      stroke="#E9D7FE"
      strokeWidth="1.39167"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const trashIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    stroke="#BDB4FE"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M3 6.5H21"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.0004 6.5V20.5C19.0004 21.5 18.0004 22.5 17.0004 22.5H7.0004C6.0004 22.5 5.0004 21.5 5.0004 20.5V6.5"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.0004 6.50195V4.50195C8.0004 3.50195 9.0004 2.50195 10.0004 2.50195H14.0004C15.0004 2.50195 16.0004 3.50195 16.0004 4.50195V6.50195"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const eraserIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#BDB4FE"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="lucide lucide-eraser"
  >
    <path d="m7 21-4.3-4.3c-1-1-1-2.5 0-3.4l9.6-9.6c1-1 2.5-1 3.4 0l5.6 5.6c1 1 1 2.5 0 3.4L13 21" />
    <path d="M22 21H7" />
    <path d="m5 11 9 9" />
  </svg>
);

const filterModalIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    stroke="#BDB4FE"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M11 13V17"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 5V9"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 3V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H21"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 13H8C7.44772 13 7 13.4477 7 14V16C7 16.5523 7.44772 17 8 17H15C15.5523 17 16 16.5523 16 16V14C16 13.4477 15.5523 13 15 13Z"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 5H8C7.44772 5 7 5.44772 7 6V8C7 8.55228 7.44772 9 8 9H18C18.5523 9 19 8.55228 19 8V6C19 5.44772 18.5523 5 18 5Z"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

function InputPair({
  label,
  value,
  setValue,
  selector,
  setSelector,
  onRemove,
  index,
  totalLength,
  disabledOptions,
  selectedId,
}) {
  return (
    <div style={{ display: "flex", gap: 24, marginBottom: "12px" }}>
      <div style={{ display: "flex", flex: 0.5, width: "50%", gap: "12px" }}>
        <InputSelector
          selectedId={selectedId}
          svgIcon={filterModalIcon}
          state={selector}
          options={inputPairSelectorOptions}
          label={label?.[0]}
          setState={setSelector}
          disabledOptions={disabledOptions}
          style={{width: 150}}
        />
      </div>
      <SignInput
        sign="%"
        label={label?.[1]}
        value={value}
        setValue={setValue}
        dataType="int"
        inputWidth="60px"
        maxLength={3}
      />
      <div
        style={{
          alignSelf: "center",
          display: "flex",
          marginTop: label?.[1] ? "20px" : "5px",
        }}
      >
        <IconButton
          icon={index == 0 && totalLength == 1 ? eraserIcon : trashIcon}
          onClick={onRemove}
        />
      </div>
    </div>
  );
}

const SortingSelection = () => {
  //Get the neccesary information from slice
  const {
    searchQuery,
    selectedSortingRule,
    sortingRuleReverted,
    selectedPeriod,
    scenarioGroups,
    paginatedRows,
  } = useSelector((state) => state.scenarioPerformance);

  const [showModal, setShowModal] = useState(false);
  const controllerRef = useRef(null);

  const plusIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M12.5 5V19M5.5 12H19.5"
        stroke="url(#paint0_linear_2326_1588)"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2326_1588"
          x1="5.5"
          y1="11.364"
          x2="19.4571"
          y2="10.9983"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#866BFF" />
          <stop offset="1" stopColor="#948DFF" />
        </linearGradient>
      </defs>
    </svg>
  );

  const [inputPairs, setInputPairs] = useState([
    { selector: "Median", value: "" }, // Default input pair
  ]);

  const dispatch = useDispatch();

  const selectedOptions = inputPairs.map((pair) => pair.selector);

  const inputPairsMaxCount = 3; // Maximum number of input pairs

  // Handle adding a new input pair
  const addInputPair = () => {
    const availableOptions = inputPairSelectorOptions.filter(
      (option) => !selectedOptions.includes(option.key)
    );

    setInputPairs((prevPairs) => [
      ...prevPairs,
      {
        selector: availableOptions.length > 0 ? availableOptions[0].key : "",
        value: "",
      },
    ]);
  };

  // Handle updating selector or input value for a specific input pair
  const updateInputPair = (index, field, newValue) => {
    // For value field, limit to maximum of 100
    if (field === "value" && newValue > 100) {
      return; // Don't update if value exceeds 100
    }

    setInputPairs((prevPairs) =>
      prevPairs.map((pair, i) =>
        i === index ? { ...pair, [field]: newValue } : pair
      )
    );
  };

  const removeInputPair = (index) => {
    setInputPairs((prevPairs) => {
      if (prevPairs.length === 1) {
        // If there's only one pair left, clear its fields instead of removing it
        return prevPairs.map((pair, i) =>
          i === index ? { ...pair, selector: "Median", value: "" } : pair
        );
      }
      return prevPairs.filter((_, i) => i !== index);
    });
  };

  useEffect(() => {
    handleRecommendationFilter(inputPairs, paginatedRows, selectedPeriod);
  }, [dispatch, paginatedRows, selectedPeriod]);

  // Function to handle recommendation filtering and dispatch
  const handleRecommendationFilter = (
    inputPairs,
    paginatedRows,
    selectedPeriod
  ) => {
    // Cancel previous request if it exists
    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    // Create new controller for this request
    controllerRef.current = new AbortController();

    let q1 = null;
    let q3 = null;
    let median = null;

    inputPairs.forEach((pair) => {
      if (pair.value === 0 || pair.value === "");
      else if (pair.selector.toLowerCase() === "q1") {
        q1 = pair.value;
      } else if (pair.selector.toLowerCase() === "q3") {
        q3 = pair.value;
      } else if (pair.selector.toLowerCase() === "median") {
        median = pair.value;
      }
    });

    const list_of_ids = paginatedRows.flatMap((group) =>
      group.childs.map((scen) => scen.child_id)
    );

    dispatch(clearRecommendations());
    dispatch(
      fetchRecommendations({
        q1,
        q3,
        median,
        period: selectedPeriod,
        listOfIds: list_of_ids,
        signal: controllerRef.current.signal,
      })
    );
  };

  // Function to handle applying the filter
  const handleApplyFilter = () => {
    handleRecommendationFilter(inputPairs, paginatedRows, selectedPeriod);
    setShowModal(false);
  };

  // Function to handle input value changes
  const handleInputChange = (index, newValue) => {
    updateInputPair(index, "value", newValue);
  };

  // Function to handle selector state changes
  const handleSelectorChange = (index, newSelector) => {
    updateInputPair(index, "selector", newSelector);
  };

  //Set the sorting rule when a click on an icon happens
  const handleIconClick = (string) => {
    dispatch(setSelectedSortingRule(string));
    handleRecommendationFilter(inputPairs, paginatedRows, selectedPeriod);
  };

  function handlePeriodSelect(period) {
    dispatch(setSelectedPeriod(period));
  }

  const infoIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M10 18.5036C14.6024 18.5036 18.3334 14.7726 18.3334 10.1702C18.3334 5.56787 14.6024 1.83691 10 1.83691C5.39765 1.83691 1.66669 5.56787 1.66669 10.1702C1.66669 14.7726 5.39765 18.5036 10 18.5036Z"
        stroke="#E9D7FE"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 13.5033V10.1699"
        stroke="#E9D7FE"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 6.83691H10.0083"
        stroke="#E9D7FE"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <div className={styles.selectionWrapper}>
      <ModalPopUp
        handleClose={() => setShowModal(false)}
        inlineTitle={true}
        buttonsAlign={"center"}
        scroll={false}
        actionButtons={[
          <SecondaryButton
            action={addInputPair}
            style={{ flex: 1, height: 25 }}
            svgIcon={plusIcon}
            disabled={inputPairs.length == inputPairsMaxCount}
            content={"Add filter"}
          />,

          <PrimaryButton
            action={() => handleApplyFilter()}
            style={{ flex: 1, height: 25, display: "flex" }}
            content={"Apply"}
          />,
        ]}
        content={
          <div>
            {inputPairs.map((pair, index) => (
              <InputPair
                key={index}
                selectedId={pair.selector}
                label={index === 0 ? ["Statistics", "Percentage"] : undefined}
                value={pair.value}
                setValue={(newValue) => handleInputChange(index, newValue)}
                selector={pair.selector}
                setSelector={(newSelector) =>
                  handleSelectorChange(index, newSelector)
                }
                onRemove={() => removeInputPair(index)}
                index={index}
                totalLength={inputPairs.length}
                disabledOptions={selectedOptions.filter(
                  (option) => option !== pair.selector
                )}
              />
            ))}
          </div>
        }
        isOpen={showModal}
        icon={filterModalIcon}
        title={"Statistical Metrics Filter"}
      />

      {/* Searcher */}
      <div className={styles.searcherBox}>
        <div style={{ display: "flex", width: "19.49%" }}>
          <MonthSelector
            setState={handlePeriodSelect}
            label={"Choose a date range"}
            state={selectedPeriod}
          />
        </div>
        <div
          style={{
            display: "flex",
            padding: "12px",
            width: "47.93%",
            height: "10px",
          }}
        ></div>

        <div className={styles.searchInputBox} style={{ width: "23.42%" }}>
          <div style={{ minWidth: "20px" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                stroke="#BDB4FE"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>

          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => dispatch(setSearchQuery(e.target.value))}
            className={styles.searchInput}
          />
        </div>
        <div style={{ display: "flex", gap: "12px", marginLeft: "12px" }}>
          <FilterButton
            onClick={() => {
              setShowModal(true);
            }}
          />
          <IconButton disabled={true} icon={importIcon} onClick={() => {}} />
        </div>
      </div>

      {/* Filters & Sorters */}
      <div className={styles.sortingSelectionWrapper}>
        {/* Name filter */}
        <div
          className={`${styles.textWrapper} ${
            selectedSortingRule === "name" ? styles.selected : ""
          }`}
          style={{ width: "30%" }}
        >
          Detecting Solution Name
          <div style={{ minWidth: "21px" }}>
            {selectedSortingRule === "name" && sortingRuleReverted && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                onClick={() => handleIconClick("name")}
                className={
                  selectedSortingRule === "name" ? styles.selected : ""
                }
              >
                <path
                  d="M2.9082 6.89147L6.35436 3.44531L9.80051 6.89147"
                  strokeWidth="1.72308"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.35547 3.44531V17.2299"
                  strokeWidth="1.72308"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.248 3.44531H17.5557L13.248 8.61454H17.5557"
                  strokeWidth="1.72308"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.248 17.2317V14.2163C13.248 13.6451 13.475 13.0973 13.8789 12.6933C14.2828 12.2894 14.8307 12.0625 15.4019 12.0625C15.9731 12.0625 16.521 12.2894 16.9249 12.6933C17.3288 13.0973 17.5557 13.6451 17.5557 14.2163V17.2317"
                  strokeWidth="1.72308"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.5557 15.5078H13.248"
                  strokeWidth="1.72308"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
            {(!(selectedSortingRule === "name") || !sortingRuleReverted) && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                onClick={() => handleIconClick("name")}
                className={
                  selectedSortingRule === "name" ? styles.selected : ""
                }
              >
                <path
                  d="M2.58398 13.7852L6.03014 17.2313L9.47629 13.7852"
                  strokeWidth="1.72308"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.03125 17.2299V3.44531"
                  strokeWidth="1.72308"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.2315 6.89062H12.9238"
                  strokeWidth="1.72308"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.9238 8.61454V5.59916C12.9238 5.02792 13.1508 4.48008 13.5547 4.07616C13.9586 3.67223 14.5064 3.44531 15.0777 3.44531C15.6489 3.44531 16.1967 3.67223 16.6007 4.07616C17.0046 4.48008 17.2315 5.02792 17.2315 5.59916V8.61454"
                  strokeWidth="1.72308"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.9238 12.0625H17.2315L12.9238 17.2317H17.2315"
                  strokeWidth="1.72308"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
        </div>

        {/* Alert Relevancy filter */}

        <div
          className={`${styles.textWrapper} ${
            selectedSortingRule === "alerts" ? styles.selected : ""
          }`}
          style={{ width: "30%", position: "relative" }} // Ensure position for tooltip
        >
          <div style={{ cursor: "pointer", position: "relative" }}>
            Alert Relevancy
          </div>
          <div className={styles.iconsWrapper}>
            <div className={styles.hoversvg}>
              <div className={styles.tooltipWrapper}>
                <div className={styles.customTooltip}>
                  <div className={styles.tooltipIcon}>{infoIcon}</div>
                  <div className={styles.tooltipContent}>
                    <p>
                      <strong>
                        The following KPIs will be used to optimize detection
                        scenario thresholds :
                      </strong>

                      <ul>
                        <li>Efficiency: TP / generated alerts</li>
                        <li>Noise: FP / generated alerts</li>
                        <li>Noise Reduction: lost FP / initial total FP</li>
                        <li>Risk: lost TP / initial generated alerts</li>
                        <li>Risk - TP Ratio: lost TP / initial TP</li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>

              {infoIcon}
            </div>

            {selectedSortingRule === "alerts" && sortingRuleReverted && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                onClick={() => handleIconClick("alerts")}
              >
                <path
                  d="M2.5 6.66536L5.83333 3.33203L9.16667 6.66536"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.83398 3.33203V16.6654"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.16602 10H12.4993"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.16602 13.332H14.9993"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.16602 16.668H17.4993"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
            {(!(selectedSortingRule === "alerts") || !sortingRuleReverted) && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                onClick={() => handleIconClick("alerts")}
              >
                <path
                  d="M2.5 13.332L5.83333 16.6654L9.16667 13.332"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.83398 16.6654V3.33203"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.16602 3.33203H17.4993"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.16602 6.66797H14.9993"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.16602 10H12.4993"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
        </div>

        {/* Total Alerts filter */}
        <div
          className={`${styles.textWrapper} ${
            selectedSortingRule === "total" ? styles.selected : ""
          }`}
          style={{ width: "30%" }}
        >
          Total Alerts
          <div style={{ minWidth: "21px" }}>
            {selectedSortingRule === "total" && sortingRuleReverted && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                onClick={() => handleIconClick("total")}
              >
                <path
                  d="M2.72266 6.96195L6.20414 3.48047L9.68562 6.96195"
                  strokeWidth="1.74074"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.20312 3.48047V17.4064"
                  strokeWidth="1.74074"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.9068 8.70269V3.48047H13.166"
                  strokeWidth="1.74074"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.166 8.70312H16.6475"
                  strokeWidth="1.74074"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.6475 13.9243C16.6475 12.9629 15.8681 12.1836 14.9068 12.1836C13.9454 12.1836 13.166 12.9629 13.166 13.9243V15.6651C13.166 16.6265 13.9454 17.4058 14.9068 17.4058C15.8681 17.4058 16.6475 16.6265 16.6475 15.6651V13.9243Z"
                  strokeWidth="1.74074"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
            {(!(selectedSortingRule === "total") || !sortingRuleReverted) && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                onClick={() => handleIconClick("total")}
              >
                <path
                  d="M2.61133 13.9258L6.09281 17.4073L9.57429 13.9258"
                  strokeWidth="1.74074"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.0918 17.4064V3.48047"
                  strokeWidth="1.74074"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.5362 5.22121C16.5362 4.25982 15.7568 3.48047 14.7954 3.48047C13.834 3.48047 13.0547 4.25982 13.0547 5.22121V6.96195C13.0547 7.92334 13.834 8.70269 14.7954 8.70269C15.7568 8.70269 16.5362 7.92334 16.5362 6.96195V5.22121Z"
                  strokeWidth="1.74074"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.7954 17.4058V12.1836H13.0547"
                  strokeWidth="1.74074"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.0547 17.4062H16.5362"
                  strokeWidth="1.74074"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SortingSelection;
