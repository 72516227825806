import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import view_style from "../../../layout/Layout.module.css";
import styles from "./AlertDetails.module.css";
import AlertOverview from "./alert_overview/AlertOverview.js";
import ContributingTable from "./contributing_transactions/ContributingTable.js";
import AlertsOverlap from "./alerts_overlap/AlertsOverlap.js";
import SliderButton from "../../../layout/buttons/SliderButton.js";
import { RoundBackButton } from "../../../layout/buttons/RoundIconButton.js";

import {
  fetchOverlapAlerts,
  fetchContributingTransactions,
  fetchAuditLogs,
  fetchAlert,
  setSelectedAlertDetails
} from "../../../features/all_alerts/allAlertsSlice.js";

function AlertDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const alertId = parseInt(id, 10);

  const { selectedAlertDetails } = useSelector((state) => state.allAlerts);
  const [shownContainer, setShownContainer] = useState(1);
  const [alertInfoType, setAlertInfoType] = useState("Alert Details");

  useEffect(() => {
    if (alertId !== null || alertId !== undefined) {
      if(selectedAlertDetails.id !== alertId){
        dispatch(setSelectedAlertDetails({}))
      }
      dispatch(fetchAlert({ alertId: alertId }));
      dispatch(fetchOverlapAlerts({ alertId: alertId }));
      dispatch(
        fetchContributingTransactions({ alertId: alertId, limit: "inf" })
      );
      dispatch(fetchAuditLogs({ alertId: alertId }));
    }
  }, [alertId]);

  const handleArrowClick = () => {
    handleAlertInfoTypeClick("Alert Details");
    setShownContainer(1);
  };

  const handleAlertInfoTypeClick = (type) => {
    setAlertInfoType(type);
  };

  return (
    <div className={view_style.viewWrapper}>
      <div className={view_style.sectionWrapper} style={{ display: "block" }}>
        <div
          className={`${styles.headerSectionBox} ${
            shownContainer === 2 || shownContainer === 3 ? styles.slide : ""
          }`}>
          <div className={styles.header}>
            <div className={styles.headerTitle}>
              <RoundBackButton action={() => navigate(-1)} />
              {selectedAlertDetails ? selectedAlertDetails.alert_code : ""}
            </div>
            <div className={styles.selectionBox}>
              <SliderButton
                options={["Alert Details", "Audit Log"]}
                activeOption={alertInfoType}
                onClick={(origin) => handleAlertInfoTypeClick(origin)}
              />
            </div>
          </div>
          <div className={styles.header}>
            <div className={styles.headerTitle}>
              <RoundBackButton action={handleArrowClick} />
              {shownContainer == 2
                ? `Contributing Transaction of Alert: ${selectedAlertDetails.alert_code}`
                : `Overlapping Alerts of Alert: ${selectedAlertDetails.alert_code}`}
            </div>
            <div>
              {shownContainer == 2 ? "Transaction List" : "Overlapping List"}
            </div>
          </div>
        </div>
        <div
          className={`${styles.containerWrapper} ${
            shownContainer === 2 || shownContainer === 3 ? styles.slide : ""
          }`}>
          <div className={styles.container}>
            <AlertOverview
              alertInfoType={alertInfoType}
              setShownContainer={setShownContainer}
            />
          </div>
          <div className={styles.container}>
            <div className={`${shownContainer !== 2 ? styles.hidden : ""}`}>
              <ContributingTable />
            </div>
            <div className={`${shownContainer !== 3 ? styles.hidden : ""}`}>
              <AlertsOverlap setShownContainer={setShownContainer}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlertDetails;
