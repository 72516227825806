import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  createReport,
  setCurrentReportDB,
} from "../../../features/report_builder/reportBuilderSlice";
import ModalPopUp from "../../../layout/modal/Modal.js";
import {
  SecondaryButton,
  PrimaryButton,
} from "../../../layout/buttons/NormalButton.js";
import InputComponent from "../../../layout/input/InputComponent.js";

function NewReportModal({ setModalOpen, isOpen }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const [newReportName, setNewReportName] = useState("");

  function cancelCreate() {
    setNewReportName("");
    setModalOpen(false);
  }

  function handleCreateReport() {
    const initialReportData = {
      name: newReportName,
      status: "draft",
      user_id: user.id,
      creation_date: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      // analysis_daterange_id: 1,
      report_json: {
        elements: [{ type: "title", content: "Analysis Summary" }],
      },
    };

    const scenarioIds = [0]; // O cualquier otro ID de escenario predeterminado

    dispatch(createReport({ reportData: initialReportData, scenarioIds }))
      .unwrap()
      .then(async (newReport) => {
        cancelCreate();
        await dispatch(setCurrentReportDB({ reportId: newReport.id }));
        navigate(`/reports/${newReport.id}`);
      })
      .catch((error) => {
        console.error("Error creating report:", error);
      })
      .finally(() => {
        // setIsModalOpen(false);
        // setNewReportName('New Report');
      });
  }

  return (
    <ModalPopUp
      handleClose={() => setModalOpen(false)}
      isOpen={isOpen}
      title={"Create New Report"}
      content={
        <InputComponent
          label="Report name"
          value={newReportName}
          onChange={(e) => setNewReportName(e.target.value)}
        />
      }
      actionButtons={[
        <SecondaryButton
          content={"Cancel"}
          style={{ flex: 1 }}
          action={cancelCreate}
        />,
        <PrimaryButton
          content={"Create"}
          style={{ flex: 1 }}
          disabled={newReportName.length == 0}
          action={handleCreateReport}
        />,
      ]}
    />
  );
}

export default NewReportModal;
