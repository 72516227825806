import { Fragment } from "react";
import { useSelector } from "react-redux";

import styles from "./AlertOverview.module.css";
import InputComponent from "../../../../layout/input/InputComponent.js";
import CommentTable from "./CommentTable.js";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../layout/buttons/NormalButton.js";
import RiskBadge from "../../../../layout/labels/risk_badge/RiskBadge.js";
import RelevancyBadge from "../../../../layout/labels/relevancy_badge/RelevancyBadge.js";

function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string;
}

function AlertOverview({ alertInfoType, setShownContainer }) {
  const { selectedAlertDetails } = useSelector((state) => state.allAlerts);

  const getColor = (text) => {
    if (text === null) {
      return "green";
    }
    if (text.toLowerCase() === "non relevant" || text === "low") {
      return "green";
    } else if (text.toLowerCase() === "relevant" || text === "medium") {
      return "yellow";
    } else {
      return "red";
    }
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.popup}>
        <div className={alertInfoType !== "Alert Details" ? styles.hidden : ""}>
          <div className={styles.contentWrapper}>
            <div className={styles.content}>
              {selectedAlertDetails && (
                <Fragment>
                  <InputComponent
                    type="normal"
                    label="Alert ID"
                    value={selectedAlertDetails?.alert_code}
                    onChange={() => {}}
                    isEditable={false}
                  />
                  <InputComponent
                    type="normal"
                    label="Masked Data"
                    value={selectedAlertDetails?.masked_data}
                    onChange={() => {}}
                    isEditable={false}
                  />
                  <InputComponent
                    type="normal"
                    label="Creation Time"
                    value={selectedAlertDetails?.event_date}
                    onChange={() => {}}
                    isEditable={false}
                  />
                  <InputComponent
                    type="normal"
                    label="Customer Segment"
                    value={selectedAlertDetails?.customer_segment}
                    onChange={() => {}}
                    isEditable={false}
                  />
                  <InputComponent
                    type="normal"
                    label="Scenario"
                    value={selectedAlertDetails?.scenario}
                    onChange={() => {}}
                    isEditable={false}
                  />
                  <InputComponent
                    type="normal"
                    label="Alert Type"
                    value={selectedAlertDetails?.alert_type}
                    onChange={() => {}}
                    isEditable={false}
                  />
                  <InputComponent
                    type="status"
                    label="Risk Score"
                    isEditable={false}
                    badges={[
                      <RiskBadge value={selectedAlertDetails?.risk_score} />,
                    ]}
                  />
                  <InputComponent
                    type="status"
                    isEditable={false}
                    label="Relevancy"
                    badges={[
                      <RelevancyBadge
                        value={selectedAlertDetails?.alert_relevancy}
                      />,
                    ]}
                  />
                  <InputComponent
                    type="normal"
                    label="Assigned to"
                    value={selectedAlertDetails?.user || "Not Assigned"}
                    onChange={() => {}}
                    isEditable={false}
                  />
                  <InputComponent
                    type="normal"
                    label="Status"
                    value={selectedAlertDetails?.status}
                    onChange={() => {}}
                    isEditable={false}
                  />
                </Fragment>
              )}
            </div>
            <div className={styles.textAreaBox}>
              {selectedAlertDetails && (
                <InputComponent
                  type="comment"
                  label="Comment"
                  value={selectedAlertDetails?.comment || ""}
                  isEditable={false}
                />
              )}
            </div>
            <div className={styles.buttonsBox}>
              <SecondaryButton
                content={"Contibuting Transactions"}
                action={() => setShownContainer(2)}
              />
              <PrimaryButton
                content={"Overlapping Alerts"}
                action={() => setShownContainer(3)}
              />
            </div>
          </div>
        </div>
        <div className={alertInfoType !== "Audit Log" ? styles.hidden : ""}>
          <CommentTable alertId={selectedAlertDetails.id} />
        </div>
      </div>
    </div>
  );
}

export default AlertOverview;
