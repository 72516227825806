import React, { useState } from "react";
import styles from "./DropDownButton.module.css";
import { PrimaryDropDownButton } from "./NormalButton";

const DropDownButton = ({ title, actions , disabled}) => {
   
    const content = (
      <div style={{ display: "flex", gap: 8, padding: 8, alignItems: "center" }}>
        <div className={styles.plusIcon}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none">
            <path
              d="M3.7998 8.00098H12.1998"
              stroke="#8174FE"
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.99902 3.80078V12.2008"
              stroke="#8174FE"
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className={styles.text}>{title}</div>
      </div>
    );
  
    return (
      <>
        <div style={{ marginLeft: 12 , width:"fit-content"}}>
            <PrimaryDropDownButton
                content={content}
                action={actions}
                disabled={disabled}
            />
        </div>
       
      </>
    );
  };
  

export default DropDownButton;
