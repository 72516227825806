import React from "react";
import PropTypes from "prop-types";
import styles from "./RoundedButton.module.css";

const RoundedButton = ({ radius, onClick, content, color, bgColor, shadow }) => {
  return (
    <button
      className={styles.button}
      onClick={onClick}
      style={{
        borderRadius: radius,
        color: color,
        backgroundColor: bgColor,
        boxShadow: shadow ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "none",
      }}>
      {content}
    </button>
  );
};

RoundedButton.propTypes = {
  radius: PropTypes.string, // Border radius (e.g., "20px" or "50%")
  onClick: PropTypes.func, // Click event handler
  content: PropTypes.node.isRequired, // Button content (text or JSX)
  color: PropTypes.string, // Text color
  bgColor: PropTypes.string, // Background color
  shadow: PropTypes.bool, // Shadow effect
};

RoundedButton.defaultProps = {
  radius: "20px",
  color: "#FFFFFF",
  bgColor: "#6C63FF",
  shadow:  true,
  onClick: () => {},
};

export default RoundedButton;
