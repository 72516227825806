// components/atl_tool/parameter_optimisation/RiskScoreSelection.js

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedRiskScore } from "../../../features/atl_tool/atlToolSlice";
import styles from "./ParameterOptimisation.module.css";
import RiskBadge from "../../../layout/labels/risk_badge/RiskBadge";

const RiskScoreSelection = () => {
  const dispatch = useDispatch();
  const { riskScores, selectedRiskScore } = useSelector(
    (state) => state.atlTool
  );

  const handleRiskScoreClick = (riskScore) => {
    dispatch(setSelectedRiskScore(riskScore));
  };

  return (
    <div className={styles.badgestandardTagParent}>
      {riskScores.map((riskScore, i) => (
        <RiskBadge
          value={riskScore.name}
          selected={selectedRiskScore.id === riskScore.id}
          onClick={() => handleRiskScoreClick(riskScore)}
          disabled={
            !(
              riskScore.non_relevant_alerts > 0 ||
              riskScore.relevant_alerts > 0 ||
              riskScore.sar_alerts > 0 ||
              (riskScore.alert_ongoing_alerts ?? 0) > 0
            )
          }
        />
      ))}
    </div>
  );
};

export default RiskScoreSelection;
