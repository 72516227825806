import { useRef, useState, useEffect, Fragment } from "react";
import * as XLSX from "xlsx";

import Checkbox from "../buttons/Checkbox";
import styles from "./Table.module.css";
import { ExportButton } from "../buttons/IconButton";
import ModalPopUp from "../modal/Modal";
import { SecondaryButton, PrimaryButton } from "../buttons/NormalButton";
import SearchBar from "../searchBar/SearchBar";

export class SelectActionButton {
  /**
   * @param {string} actionName - The name of the action.
   * @param {string} object - The object where the action is performing on.
   * @param {function(string, boolean, function): void} action - A function to handle
   * the action, based on selectAllMode and selectedIds the caller knows which are selected
   *   - `selectedIds`: The id selected.
   *   - `selectAllMode`: Whether all are selected.
   *   - `onSuccess`: A callback function to invoke on success.
   * @param {string} icon - The icon of the button.
   * @param {boolean} useModal - Whether the button should use a modal.
   */
  constructor(actionName, object, onClick, icon, useModal = false) {
    this.actionName = actionName;
    this.object = object;
    this.action = onClick;
    this.icon = icon;
    this.useModal = useModal;
  }
}

function capitalizeFirstLetter(string) {
  if (!string) return ""; // Handle empty or undefined strings
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function exportToExcel(headers, rows, title, sheetName = undefined) {
  const worksheetData = [headers, ...rows];
  const ws = XLSX.utils.aoa_to_sheet(worksheetData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, `${sheetName ? sheetName : title}`);

  XLSX.writeFile(wb, `${title}.xlsx`);
}

function Export({ onExport }) {
  const [isLoading, setIsLoading] = useState(false);

  async function handleExport() {
    setIsLoading(true);
    await onExport();
    setIsLoading(false);
  }

  return <ExportButton onClick={handleExport} disabled={isLoading} />;
}

function SelectAction({
  resetSelected,
  actionButton,
  selectedIds,
  selectAllMode,
  selectableRowIds,
  rowsAmount,
  fetchFunction,
}) {
  const [openModal, setOpenModal] = useState(false);
  const { actionName, object, action, icon, useModal } = actionButton;

  const realSelectedIds = fetchFunction
    ? Array.from(selectedIds)
    : selectAllMode
    ? selectableRowIds.filter((id) => !selectedIds.has(id))
    : Array.from(selectedIds);

  const realAllMode = fetchFunction ? selectAllMode : false;
  const amountSelected = realAllMode
    ? rowsAmount - realSelectedIds.length
    : realSelectedIds.length;

  function handleCloseModal() {
    setOpenModal(false);
    resetSelected();
  }

  function actionButtonFunc() {
    action({
      selectedIds: realSelectedIds,
      selectAllMode: realAllMode,
      onSuccess: handleCloseModal,
    });
  }

  let modal = null;

  if (useModal) {
    const modalButtons = [
      <SecondaryButton
        content={"Cancel"}
        action={handleCloseModal}
        style={{ flex: 1 }}
      />,
      <PrimaryButton
        content={capitalizeFirstLetter(actionName)}
        action={actionButtonFunc}
        style={{ flex: 1 }}
      />,
    ];

    modal = (
      <ModalPopUp
        handleClose={() => setOpenModal(false)}
        isOpen={openModal}
        title={`${capitalizeFirstLetter(actionName)} ${object}`}
        description={`Are you sure you want to ${actionName.toLowerCase()} ${amountSelected} selected ${object}${
          amountSelected > 1 ? "s" : ""
        }?`}
        icon={icon}
        actionButtons={modalButtons}
      />
    );
  }

  function onClickIcon() {
    if (useModal) {
      return setOpenModal(true);
    } else {
      actionButtonFunc();
    }
  }

  return (
    <Fragment>
      <div className={styles.iconButton} onClick={onClickIcon}>
        {icon}
      </div>
      {useModal && modal}
    </Fragment>
  );
}

function SelectActionsContainer({
  resetSelected,
  selectableRowIds,
  rowsAmount,
  selectedIds,
  selectAllMode,
  onSelectActionButtons,
  fetchFunction,
}) {
  const canUseActions =
    (selectedIds.size > 0 && selectAllMode === false) ||
    (selectAllMode === true && selectedIds.size < selectableRowIds.length);
  const [widthContainer, setWidthContainer] = useState(
    canUseActions ? "fit-content" : 0
  );
  const [opacity, setOpacity] = useState(canUseActions ? 1 : 0);
  const [scale, setScale] = useState(canUseActions ? 1 : 0.1);
  const deleteContainerRef = useRef(null);

  useEffect(() => {
    if (canUseActions && deleteContainerRef.current) {
      const innerWidth = deleteContainerRef.current.offsetWidth;
      setWidthContainer(innerWidth);
      setOpacity(1);
      setScale(1);
    } else {
      setWidthContainer(0);
      setOpacity(0);
      setScale(0.1);
    }
  }, [canUseActions, onSelectActionButtons.length]);

  return (
    <div
      className={styles.actionButtonContainer}
      style={{
        width: widthContainer,
        opacity: opacity,
        transform: `scale(${scale})`,
      }}>
      <div ref={deleteContainerRef} className={styles.actionButtons}>
        {onSelectActionButtons.map((action, index) => (
          <Fragment key={index}>
            <SelectAction
              resetSelected={resetSelected}
              actionButton={action}
              selectedIds={selectedIds}
              selectAllMode={selectAllMode}
              selectableRowIds={selectableRowIds}
              rowsAmount={rowsAmount}
              fetchFunction={fetchFunction}
            />
          </Fragment>
        ))}
      </div>
    </div>
  );
}

export function TableTools({
  rowsAmount,
  resetSelected,
  selectableRowIds,
  notSelectableIds,
  canSearch = true,
  searchTerm = "",
  setSearchTerm = () => {},
  canSelect = false,
  allSelected = false,
  selectAllMode = false,
  selectedIds = [],
  toggleSelectAll = () => {},
  onSelectActionButtons = [],
  onExport = undefined,
  actionButtonsLeft = [],
  actionButtonsRight = [],
  fetchFunction = undefined,
  actionButtonsFarRight = [],
}) {
  return (
    <div className={styles.tableTools}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ display: "flex", gap: "12px" }}>
          {canSelect && (
            <Checkbox
              checked={allSelected}
              onChange={toggleSelectAll}
              disabled={rowsAmount - notSelectableIds.length === 0}
            />
          )}
          <SelectActionsContainer
            onSelectActionButtons={onSelectActionButtons}
            selectedIds={selectedIds}
            selectAllMode={selectAllMode}
            selectableRowIds={selectableRowIds}
            fetchFunction={fetchFunction}
            resetSelected={resetSelected}
            rowsAmount={rowsAmount}
          />
          <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
            {actionButtonsLeft.map((button, index) => (
              <Fragment key={index}>{button}</Fragment>
            ))}
          </div>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
          {actionButtonsRight.map((button, index) => (
            <Fragment key={index}>{button}</Fragment>
          ))}
        </div>
        {canSearch && (
          <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        )}
        {onExport && <Export onExport={onExport} />}

        {actionButtonsFarRight.map((button, index) => (
          <Fragment key={index}>{button}</Fragment>
        ))}
      </div>
    </div>
  );
}
