import React from "react";
import PropTypes from "prop-types";
import styles from "./SignInput.module.css"; // Importing the CSS module

const SignInput = ({ sign, label, value, setValue, dataType, inputWidth, maxLength }) => {
  const handleChange = (e) => {
    let newValue = e.target.value;

    // Validate input based on the data type
    if (dataType === "int") {
      newValue = newValue.replace(/[^\d]/g, ""); // Allow only digits
    } else if (dataType === "float") {
      newValue = newValue.replace(/[^\d.]/g, ""); // Allow digits and a single decimal point
      if (newValue.split(".").length > 2) {
        newValue = newValue.slice(0, -1); // Remove extra decimal points
      }
    } // For strings, no additional validation is needed

    // Apply maxLength restriction
    if (maxLength && newValue.length > maxLength) {
      newValue = newValue.slice(0, maxLength);
    }

    setValue(newValue);
  };

  return (
    <div className={styles.signInputContainer}>
      {label && <label className={styles.signInputLabel}>{label}</label>}
      <div className={styles.signInputWrapper} style={{ width: inputWidth }}>
        <span className={styles.signSymbol}>{sign}</span>
        <input
          type="text"
          value={value}
          onChange={handleChange}
          className={styles.signInputField}
          maxLength={maxLength} // Apply maxLength to restrict user input
        />
      </div>
    </div>
  );
};

SignInput.propTypes = {
  sign: PropTypes.string.isRequired, // The sign to display (e.g., "%", "$")
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // The input value
  setValue: PropTypes.func.isRequired, // Callback function to update the value
  dataType: PropTypes.oneOf(["int", "float", "string"]).isRequired, // The data type for validation
  label: PropTypes.string, // The label for the input
  inputWidth: PropTypes.string.isRequired, // The width of the input field
  maxLength: PropTypes.number, // The maximum length of the input
};

export default SignInput;
