import React from "react";
import styles from "./Parameters.module.css";
import Overview from "./overview/Overview";
import Edit from "./edit/Edit";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";

const Parameters = ({ configId , configType}) => {
  Parameters.propTypes = {
    configId: PropTypes.number.isRequired,
  };

  // variable deciding if the overview or edit is shown
  const { showEdit } = useSelector((state) => state.configuration);

  return (
    <div className={`${styles.tabWrapper} ${showEdit ? styles.slide : ""}`}>
      <Overview configId={configId} configType={configType} />
      <Edit />
    </div>
  );
};

export default Parameters;
