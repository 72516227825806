import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ModalPopUp from "../../../layout/modal/Modal";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../layout/buttons/NormalButton";
import NumberInput from "../../../layout/input/ValueInput";
import RadioButtonsContainer from "../../../layout/buttons/RadioButton";
import {
  fetchSampledIds,
  setAlertSelectionType,
} from "../../../features/btl_tool/btlToolSlice";

const OPTIMISATION_AMOUNT_TYPES = {
  ratio: "Percentage",
  abs: "Absolute Number",
};

function BtlIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Property 1=Dark Mode">
        <path
          id="Vector"
          d="M15 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7L15 2Z"
          stroke="#905EE2"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_2"
          d="M14 2V6C14 6.53043 14.2107 7.03914 14.5858 7.41421C14.9609 7.78929 15.4696 8 16 8H20"
          stroke="#905EE2"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_3"
          d="M8 18V16"
          stroke="#905EE2"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_4"
          d="M12 18V14"
          stroke="#905EE2"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_5"
          d="M16 18V12"
          stroke="#905EE2"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}

function SampleModal({ modalOpen, setModalOpen }) {
  const dispatch = useDispatch();
  const { generatedAlerts } = useSelector((state) => state.btlTool);
  const [amountType, setAmountType] = useState("ratio");
  const [amount, setAmount] = useState(0);
  const [validAmount, setValidAmount] = useState(true);

  function resetModal() {
    setAmountType("ratio");
    setAmount(0);
  }

  const content = (
    <div style={{ display: "flex", gap: "12px", alignItems: "end" }}>
      <NumberInput
        value={amount}
        setValue={setAmount}
        label={"Value"}
        setValid={setValidAmount}
      />
      <div style={{ display: "flex", gap: "24px", alignItems: "center" }}>
        <RadioButtonsContainer
          options={OPTIMISATION_AMOUNT_TYPES}
          currentKey={amountType}
          setKey={setAmountType}
        />
      </div>
    </div>
  );

  const alertIds = generatedAlerts.map((alert) => alert.id);
  function sampleAlerts() {
    dispatch(
      fetchSampledIds({
        sampleAmount: amount,
        sampleType: amountType,
        ids: alertIds,
      })
    );
    dispatch(setAlertSelectionType("Sampled Alerts"));
    resetModal();
    setModalOpen(false);
  }

  const actionButtons = [
    <SecondaryButton
      content={"Cancel"}
      action={() => {
        resetModal();
        setModalOpen(false);
      }}
      style={{ flex: 1 }}
    />,
    <PrimaryButton
      content={"Continue"}
      action={sampleAlerts}
      style={{ flex: 1 }}
      disabled={!validAmount}
    />,
  ];
  return (
    <ModalPopUp
      isOpen={modalOpen}
      handleClose={() => setModalOpen(false)}
      title={"Random Sampling"}
      description={"Choose the amount of alerts for this sample."}
      icon={<BtlIcon />}
      actionButtons={actionButtons}
      content={content}
    />
  );
}

export default SampleModal;
