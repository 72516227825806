import { useState, useRef, useEffect, Fragment } from "react";
import styles from "./Selector.module.css";
import Scrollbar from "../scrollbar/Scrollbar";

export class OptionClass {
  constructor(id, name, data, render = null) {
    this.id = id;
    this.name = name;
    this.data = data;
    this.render = render;
  }
}

export function InputSelector({
  selectedId,
  setState,
  options = [],
  label,
  show = true,
  search = false,
  disabled: inactive = false,
  svgIcon,
  disabledOptions = [],
  style = {},
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef();
  const disabled = options.length == 0 || inactive;
  const selectedOption = options.find((option) => option.id === selectedId);

  const [optionsHeight, setOptionsHeight] = useState(0);
  const optionsRef = useRef(null);
  const maxOptionsHeight = 200;

  const handleSearchChange = (e) => {
    if (search) {
    }
    setSearchTerm(e.target.value);
  };

  const filteredOptions = options.filter((option) =>
    option.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (isOpen && optionsRef.current) {
      const contentHeight = optionsRef.current.offsetHeight;
      setOptionsHeight(Math.min(contentHeight, maxOptionsHeight));
    }
  }, [isOpen, options, searchTerm]);

  function close() {
    setIsOpen(false);
    setSearchTerm("");
  }

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        close();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Fragment>
      {show && (
        <div className={styles.inputWithLabel} style={style} ref={dropdownRef}>
          {label && <div className={styles.label}>{label}</div>}
          <div
            className={`${styles.inputContainer} ${
              disabled && styles.disabled
            }`}
            onClick={(e) => {
              setIsOpen(true);
              e.stopPropagation();
            }}>
            <div className={styles.input}>
              {svgIcon}
              {isOpen ? (
                <input
                  type="text"
                  className={styles.content}
                  value={searchTerm}
                  onChange={handleSearchChange}
                  readOnly={!search}
                  placeholder={search ? "Search..." : "Select..."}
                />
              ) : (
                <div className={styles.content}>
                  {selectedOption
                    ? typeof selectedOption.render === "function"
                      ? selectedOption.render(selectedId === selectedOption.id)
                      : selectedOption.name
                    : search
                    ? "Search..."
                    : "Select..."}
                </div>
              )}
            </div>
            <div
              className={styles.dropdownButton}
              onClick={(e) => {
                if (isOpen) {
                  close();
                  e.stopPropagation();
                }
              }}>
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g id="Dropdown Icon" clip-path="url(#clip0_6667_1740993)">
                  <path
                    id="Vector"
                    d="M0.591797 5.53276L2.38955 3.73438L8.22 9.66904L14.0454 3.73438L15.8431 5.53276L8.22 13.2665L0.591797 5.53276Z"
                    fill="#BDB4FE"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6667_1740993">
                    <rect
                      width="15.2513"
                      height="15.2513"
                      fill="white"
                      transform="translate(0.591797 0.875)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div
              className={`${styles.dropdownContent} ${
                isOpen ? styles.show : ""
              }`}>
              {filteredOptions.length === 0 ? (
                <div className={styles.dropdownItem}>Nothing found</div>
              ) : (
                <Scrollbar
                  horizontalScroll={false}
                  style={{
                    height: optionsHeight,
                  }}
                  trackYProps={{
                    style: {
                      zIndex: "100",
                      bottom: "0px",
                      right: "0px",
                      width: "5px",
                      backgroundColor: "#E9D7FE",
                    },
                  }}>
                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    ref={optionsRef}>
                    {filteredOptions.map((option, i) => {
                      const isDisabled = disabledOptions.includes(option.id);
                      return (
                        <button
                          key={i}
                          className={`${styles.dropdownItem} ${
                            selectedId === option.id ? styles.selectedItem : ""
                          } ${isDisabled ? styles.disabledItem : ""}`}
                          onClick={(e) => {
                            if (!isDisabled) {
                              setState(option.data);
                              setIsOpen(false);
                            }
                            e.stopPropagation();
                          }}
                          disabled={isDisabled}
                          style={{
                            width: "100%",
                            overflow: "hidden",
                            textWrap: "nowrap",
                            textOverflow: "ellipsis",
                            color: isDisabled ?? "#ccc",
                            cursor: isDisabled ? "not-allowed" : "pointer",
                          }}>
                          {option.render
                            ? option.render(selectedId === option.id)
                            : option.name}
                        </button>
                      );
                    })}
                  </div>
                </Scrollbar>
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}
