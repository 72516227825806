import React from 'react';
import styles from './Labels.module.css';
import Overview from './overview/Overview';
import Edit from './edit/Edit';
import { useSelector } from 'react-redux';

const Labels = ({configId, configType}) => {

    // variable deciding if the overview or edit is shown
    const {showEdit} = useSelector((state) => state.configuration);

    return (
        <div className={`${styles.tabWrapper} ${showEdit ? styles.slide : ''}`}>

            <Overview  configType={configType}/>
            
            <Edit  configId={configId}/>

        </div>
    );
};

export default Labels;