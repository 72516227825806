import { Modal, Box, Typography } from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { Fragment, useRef, useState, useEffect } from "react";

import styles from "./Modal.module.css";
import PropTypes from "prop-types";
import CustomScrollbar from "../scrollbar/Scrollbar";

function Icon({ icon }) {
  Icon.propTypes = {
    icon: PropTypes.node.isRequired, // Icon to be displayed, required
  };

  return <div className={styles.icon}>{icon}</div>;
}

function ModalPopUp({
  handleClose,
  isOpen,
  title = "",
  description = "",
  content = null,
  actionButtons = [],
  large = false,
  icon = null,
  inlineTitle = false,
  scroll = true,
  buttonsAlign = "stretch", // left, right, center  :default stretch
}) {
  ModalPopUp.propTypes = {
    handleClose: PropTypes.func.isRequired, // Function to handle closing the modal, required
    isOpen: PropTypes.bool.isRequired, // Boolean to control if modal is open, required
    title: PropTypes.string, // Modal title, optional
    description: PropTypes.string, // Modal description, optional
    content: PropTypes.node, // Content to be displayed in modal, optional
    actionButtons: PropTypes.arrayOf(PropTypes.node), // Array of buttons (could be JSX elements), optional
    icon: PropTypes.node, // Icon to be displayed, optional
    inlineTitle: PropTypes.bool, // Boolean for inline title styling, optional
    buttonsAlign: PropTypes.oneOf(["left", "right", "center", "stretch"]), // Alignment option, optional
  };

  let alignSelfValue;
  if (buttonsAlign === "stretch") {
    alignSelfValue = "stretch";
  } else if (buttonsAlign === "left") {
    alignSelfValue = "flex-start";
  } else if (buttonsAlign === "right") {
    alignSelfValue = "flex-end";
  } else {
    alignSelfValue = "center";
  }

  const [contentHeight, setContentHeight] = useState(0);
  const contentHeightRef = useRef(null);

  // Calculate the height of the invisible div
  useEffect(() => {
    if (contentHeightRef.current) {
      setContentHeight(contentHeightRef.current.scrollHeight);
    }
  }, [content]);

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: 5,
          bgcolor: "#fff",
          border: "none",
          boxShadow: 24,
          p: 4,
          outline: "none",
          padding: "24px",
          width: large ? "90%" : 400,
          maxHeight: "80vh",
          display: "flex",
          flexDirection: "column",
        }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "32px",
            position: "relative",
            height: "100%",
            overflowY: scroll ? "auto" : "",
          }}>
          <button
            className={styles.closeIcon}
            onClick={handleClose}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                handleClose();
              }
            }}
            aria-label="Close"
            style={{
              background: "none",
              border: "none",
              padding: 0,
              cursor: "pointer",
            }}>
            <ClearOutlinedIcon sx={{ color: "var(--Primary-6)" }} />
          </button>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              flex: "1 1 auto",
              overflowY: scroll ? "auto" : "",
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: inlineTitle ? "row" : "column",
                gap: "16px",
              }}>
              {icon && <Icon icon={icon} />}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: inlineTitle ? "center" : "flex-start",
                  gap: inlineTitle ? "0px" : "24px",
                }}>
                {title && (
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontFamily: "Inter",
                      fontSize: 16.42,

                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                    }}
                    component="h2"
                    className={styles.title}>
                    {title}
                  </Typography>
                )}
                {description && (
                  <Typography
                    sx={{
                      color: "#717BBC",
                      fontFamily: "Inter",
                      mt: 1,
                      fontSize: 15,
                      lineHeight: 1.5,
                      fontStyle: "normal",
                      fontWeight: "500",
                    }}
                    component="p"
                    className={styles.description}>
                    {description}
                  </Typography>
                )}
              </div>
            </div>
            <div
              className={styles.modalContent}
              style={{
                overflowY: scroll ? "hidden" : "",
                display: "block",
                position: "relative",
              }}>
              <div
                style={{
                  position: "relative",
                  top: 0,
                  left: 0,
                  width: "100%",
                  opacity: scroll ? 0 : 1,
                  zIndex: scroll ? -1 : 1,
                }}>
                {content}
              </div>
              {scroll && (
                <CustomScrollbar
                  style={{ flex: 1, position: "absolute", top: 0 }}>
                  {content}
                </CustomScrollbar>
              )}
            </div>
          </div>
          {actionButtons && (
            <div
              style={{
                alignSelf: alignSelfValue,
                width: "100%",
                justifyContent: "center",
                flexShrink: 0,
              }}
              className={styles.modalActionsContainer}>
              {actionButtons.map((button, index) => (
                <Fragment key={"modalBtn" + index}>{button}</Fragment>
              ))}
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
}

export default ModalPopUp;
