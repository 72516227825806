import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import RiskBadge from "../../../layout/labels/risk_badge/RiskBadge";
import RelevancyBadge from "../../../layout/labels/relevancy_badge/RelevancyBadge";
import view_style from "../../../layout/Layout.module.css";
import HeaderSection from "../../../layout/HeaderSection";
import { HeaderClass } from "../../../layout/table/Header";
import { RowCellClass, CellClass } from "../../../layout/table/Cell";
import TableGrid from "../../../layout/table/Table";
import { fetchOverlapAnalysis } from "../../../features/overlap_analysis/overlapAnalysisSlice";

function AlertsOverlap() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id1, id2 } = useParams();
  const scenarioIds = [id1, id2];
  const { overlapData, selectedYearMonthPairs } = useSelector(
    (state) => state.overlapAnalysis
  );

  useEffect(() => {
    console.log("scenarioIds", scenarioIds);
    dispatch(
      fetchOverlapAnalysis({
        scenario_id_1: scenarioIds[0],
        scenario_id_2: scenarioIds[1],
        year_month_pairs: selectedYearMonthPairs,
        skip: 0,
        limit: "inf",
      })
    );
  }, []);

  const headers = [
    new HeaderClass("Alert ID", "alert_code", true, false, { minWidth: 210 }),
    new HeaderClass("Alert Status Category", "alert_relevancy", true, true, {
      minWidth: 210,
    }),
    new HeaderClass("Event Date", "event_date", true, false, { minWidth: 210 }),
    new HeaderClass("Risk Score", "risk_score", true, true, { minWidth: 210 }),
    new HeaderClass("Overlap Alert ID", "overlap_alert_code", true, false, {
      minWidth: 210,
    }),
    new HeaderClass(
      "Overlap Alert Status Category",
      "overlap_alert_relevancy",
      true,
      true,
      {
        minWidth: 210,
      }
    ),
    new HeaderClass("Overlap Event Date", "overlap_event_date", true, false, {
      minWidth: 210,
    }),
    new HeaderClass("Overlap Risk Score", "overlap_risk_score", true, true, {
      minWidth: 210,
    }),
  ];

  const rows = overlapData.map((rowData) => {
    const alertId = rowData.combinedId;
    const cells = headers.map((header) => {
      const value = rowData[header.field];
      if (
        header.field === "alert_relevancy" ||
        header.field === "overlap_alert_relevancy"
      ) {
        return new CellClass(value, header.field, () => (
          <div style={{ textAlign: "center" }}>
            <RelevancyBadge value={value} />
          </div>
        ));
      }
      if (
        header.field === "risk_score" ||
        header.field === "overlap_risk_score"
      ) {
        return new CellClass(value, header.field, () => (
          <div style={{ textAlign: "center" }}>
            <RiskBadge value={value} />
          </div>
        ));
      }

      return new CellClass(value, header.field);
    });

    return new RowCellClass(alertId, cells, rowData);
  });

  const handleReturnClick = () => {
    navigate(-1);
  };

  function onRowClick(row) {
    navigate(`/alerts/${row.id}`);
  }

  return (
    <div className={view_style.viewWrapper}>
      <div className={view_style.sectionWrapper}>
        <HeaderSection
          SectionName="Alerts Overlap Analysis"
          AdditionalText={
            "Overlapping scenarios: " +
            scenarioIds[0] +
            " and " +
            scenarioIds[1]
          }
          count={overlapData.length}
          returnFunction={() => {
            handleReturnClick();
          }}
        />
        <TableGrid
          title={`Alerts Overlap Analysis`}
          headers={headers}
          rows={rows}
          onRowClick={onRowClick}
          noDataPlaceholder={"There are no overlapping alerts"}
        />
      </div>
    </div>
  );
}

export default AlertsOverlap;
