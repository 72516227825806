import { useState, useEffect, useRef } from "react";
import styles from "./Selector.module.css";
import { PrimaryButton } from "../buttons/NormalButton";

function CalendarIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M5.6758 1.31445V3.85634" stroke="#D9D6FE" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.7596 1.31445V3.85634" stroke="#D9D6FE" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12.666 2.58594H3.76938C3.06746 2.58594 2.49844 3.15496 2.49844 3.85688V12.7535C2.49844 13.4554 3.06746 14.0244 3.76938 14.0244H12.666C13.3679 14.0244 13.9369 13.4554 13.9369 12.7535V3.85688C13.9369 3.15496 13.3679 2.58594 12.666 2.58594Z"
        stroke="#D9D6FE"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2.49844 6.39844H13.9369" stroke="#D9D6FE" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

const DateSelector = ({ label, selectedDate, setSelectedDate, options }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [visibleYear, setVisibleYear] = useState(new Date().getFullYear());
  const [visibleMonth, setVisibleMonth] = useState(new Date().getMonth());
  const dropdownRef = useRef();

  const maxYear = new Date().getFullYear() + 10;

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDateSelection = (day) => {
    setSelectedDate(new Date(visibleYear, visibleMonth, day));
    setIsOpen(false);
  };

  const daysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const renderDays = () => {
    const totalDays = daysInMonth(visibleMonth, visibleYear);
    const today = new Date();

    return Array.from({ length: totalDays }, (_, index) => {
      const day = index + 1;
      const isFuture =
        visibleYear > today.getFullYear() ||
        (visibleYear === today.getFullYear() &&
          visibleMonth > today.getMonth()) ||
        (visibleYear === today.getFullYear() &&
          visibleMonth === today.getMonth() &&
          day >= today.getDate());

      return (
        <button
          key={day}
          onClick={() => isFuture && handleDateSelection(day)}
          className={`${styles.day} ${
            selectedDate &&
            selectedDate.getFullYear() === visibleYear &&
            selectedDate.getMonth() === visibleMonth &&
            selectedDate.getDate() === day
              ? styles.selected
              : ""
          } ${!isFuture ? styles.notValid : ""}`}>
          {day}
        </button>
      );
    });
  };

  return (
    <div
      className={styles.inputWithLabel}
      ref={dropdownRef}
      style={{ position: "relative", width:"270px" }}>
      <div className={styles.label}>{label}</div>
      <div
        className={styles.inputContainer}
        onClick={() => setIsOpen((prev) => !prev)}>
        <div className={styles.input}>
          <div type="text" className={styles.content}>
            {selectedDate
              ? `${selectedDate.getDate()}/${
                  selectedDate.getMonth() + 1
                }/${selectedDate.getFullYear()}`
              : "DD/MM/YYYY"}
          </div>
        </div>
        <div className={styles.dropdownButton}>
          <CalendarIcon />
        </div>
      </div>
      {isOpen && (
        <div className={`${styles.daydropdownContent} ${isOpen ? styles.show : ""}`}>
          <div className={styles.calendarHeader}>
            <button
              onClick={() => {
                if (visibleMonth === 0) {
                  setVisibleMonth(11);
                  setVisibleYear((prev) => prev - 1);
                } else {
                  setVisibleMonth((prev) => prev - 1);
                }
              }}
              className={styles.day}
              disabled={visibleYear === new Date().getFullYear() && visibleMonth === 0}>
              &lt;
            </button>
            <div >
              {MONTHS[visibleMonth]} {visibleYear}
            </div>
            <button
             className={styles.day}
              onClick={() => {
                if (visibleMonth === 11) {
                  setVisibleMonth(0);
                  setVisibleYear((prev) => prev + 1);
                } else {
                  setVisibleMonth((prev) => prev + 1);
                }
              }}
              disabled={visibleYear === maxYear && visibleMonth === 11}>
              &gt;
            </button>
          </div>
          <div className={styles.daysGrid}>{renderDays()}</div>


          <div>
            {options}
          
          </div>
        
        </div>
      )}
    </div>
  );
};

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export default DateSelector;
