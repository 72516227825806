import React, { useState } from "react";
import styles from "./SliderButton.module.css";

function SliderButton({
  options = [],
  onClick,
  activeOption,
  widthSlider = 120,
}) {
  const handleOptionClick = (option) => {
    onClick(option);
  };

  const activeIndex = options.indexOf(activeOption);

  return (
    <div
      className={styles.sliderContainer}
      style={{ width: `${widthSlider * options.length}px` }}>
      <div
        className={styles.slider}
        style={{
          width: `${100 / options.length}%`,
          left: `${(100 / options.length) * activeIndex}%`,
        }}></div>
      {options.map((option, index) => (
        <div
          key={index}
          className={`${styles.option} ${
            option === activeOption ? styles.active : ""
          }`}
          onClick={() => handleOptionClick(option)}>
          <div className={styles.optionText}>{option}</div>
        </div>
      ))}
    </div>
  );
}

export default SliderButton;
