import Checkbox from "../buttons/Checkbox";
import { useState, useEffect, Fragment } from "react";
import InputComponent from "../../layout/input/InputComponent";
import { InputSelector, OptionClass } from "../../layout/selector/Selector";

export class RowCellClass {
  constructor(id, cells, rowData = undefined) {
    this.id = id;
    this.cells = cells;
    this.rowData = rowData;
  }
}

export class CellClass {
  constructor(value, field, render = null, editType = "normal") {
    this.field = field;
    this.value = value;
    this.render = render;
    this.editType = editType;
  }
}

export function Cell({
  cellConf,
  selectable,
  canSelect,
  selected,
  setSelected,
  isEditing,
  onValueChange,
  editType,
}) {
  const [value, setValue] = useState(cellConf.value);

  const handleChange = (e) => {
    setValue(e.target.value);
    if (onValueChange) {
      onValueChange(cellConf.field, e.target.value);
    }
  };

  useEffect(() => {
    if (onValueChange) {
      onValueChange(cellConf.field, value);
    }
  }, [value]);

  function getCorrectInputComponent(editType) {
    switch (editType) {
      case "TEXT":
        return (
          <InputComponent type="text" value={value} onChange={handleChange} />
        );
      case "BOOLEAN":
        return (
          <InputSelector
            state={new OptionClass(value, value, value)}
            setState={setValue}
            options={[
              new OptionClass("True", "True", "True"),
              new OptionClass("False", "False", "False"),
            ]}
          />
        );
      default:
        return (
          <InputComponent type="text" value={value} onChange={handleChange} />
        );
    }
  }

  return cellConf.render ? (
    cellConf.render()
  ) : (
    <div
      style={{
        display: canSelect ? "flex" : "block",
        gap: "8px",
        alignItems: "center",
      }}>
      {isEditing ? (
        getCorrectInputComponent(editType)
      ) : (
        <Fragment>
          {canSelect &&
            (selectable ? (
              <Checkbox checked={selected} onChange={setSelected} />
            ) : (
              <div style={{ width: 20 }}></div>
            ))}
          {cellConf.render ? cellConf.render() : <div>{cellConf.value}</div>}
        </Fragment>
      )}
    </div>
  );
}