import { useRef, useState, useEffect } from "react";

import styles from "./Buttons.module.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PropTypes from "prop-types";

export function PrimaryButton({
  content,
  action,
  disabled = false,
  svgIcon = null,
  style = {},
}) {
  PrimaryButton.propTypes = {
    content: PropTypes.node.isRequired, // Content to be displayed required
    action: PropTypes.func.isRequired, // Function to be executed on button click, required
    disabled: PropTypes.bool, // Boolean to disable the button, optional
    style: PropTypes.object, // Style object to customize button styling, optional
  };

  return (
    <div
      className={`${styles.primaryButton} ${disabled && styles.disabled}`}
      style={style}
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled) {
          action();
        }
      }}>
      {svgIcon}{content}
    </div>
  );
}

const DropDownContent = ({ actions, closeDropDown }) => {
  return (
    <div className={styles.optionsContainer}>
      {actions.map((action) => (
        <div
          className={styles.optionContainer}
          onClick={() => {
            action.action();
            closeDropDown();
          }}>
          {action.icon}
          <div className={styles.dropText} style={{ marginLeft: 8 }}>{action.name}</div>
        </div>
      ))}
    </div>
  );
};

export function PrimaryDropDownButton({
  content,
  action,
  disabled = false,
  style = {},
}) {
  PrimaryDropDownButton.propTypes = {
    content: PropTypes.node.isRequired,
    action: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.arrayOf(
        PropTypes.shape({
          icon: PropTypes.node.isRequired,
          name: PropTypes.string.isRequired,
          action: PropTypes.func.isRequired,
        })
      ),
    ]).isRequired,
    disabled: PropTypes.bool,
    style: PropTypes.object,
  };
  const containerRef = useRef(null);
  const hasDropdown = Array.isArray(action);
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const openDropDown = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const contentButton = (
    <div style={{ display: "flex" }}>
      <div style={{ display: "flex", marginRight: "40px" }}>{content}</div>
      <div className={styles.arrowDownContainer}>
      <KeyboardArrowDownIcon fontSize="small" style={{ fill: "white", color: "white" }} />

      </div>
    </div>
  );
  style["padding"] = 0;

  return (
    <div ref={containerRef} style={{ position: "relative", zIndex: 20 }}>
      <PrimaryButton
        content={contentButton}
        action={hasDropdown ? openDropDown : action}
        disabled={disabled}
        style={style}
      />
      {hasDropdown && isOpen && (
        <DropDownContent
          actions={action}
          closeDropDown={() => setIsOpen(false)}
        />
      )}
    </div>
  );
}

export function SecondaryButton({
  content,
  action,
  disabled = false,
  borderRadius = null,
  svgIcon = null,
  style = {},
}) {
  SecondaryButton.propTypes = {
    content: PropTypes.node.isRequired, // Content to be displayed required
    action: PropTypes.func.isRequired, // Function to be executed on button click, required
    disabled: PropTypes.bool, // Boolean to disable the button, optional
    style: PropTypes.object, // Style object to customize button styling, optional
  };

  return (
      <div
        className={`${styles.secondaryButton} ${disabled && styles.disabled}`}
        style={{ ...style, ...(borderRadius !== null && { borderRadius }) }}
        onClick={(e) => {
          e.stopPropagation();
          if (!disabled) {
            action();
          }
        }}
      >
      {svgIcon}{content}
    </div>
  );
}
