import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine, Label } from 'recharts';
import styles from './../Visualisation.module.css';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.customTooltip}>
        <p>{`x: ${payload[0].value}`}</p>
        <p>{`y: ${payload[1].value}`}</p>
      </div>
    );
  }
  return null;
};

const ScatterPlot = ({ id  }) => {

  const visualisations = useSelector((state) => state.atlTool.visualisations);

  const visualisation = visualisations.find(visualisation => visualisation.key === id);

  const xAxis = visualisation.xAxis;
  const yAxis = visualisation.yAxis;


  const data = useSelector((state) => state.atlTool.scatterPlotData) ; 
  const  [scatterPlotData, setScatterPlotData] = useState([]);


  useEffect(() => {
    const key = `${xAxis}_${yAxis}`;
    console.log("Key:", key, "Data:", data, "X:", xAxis, "Y:", yAxis);
  
  }, [data,yAxis,xAxis]);

  useEffect(() => {
    const key = `${xAxis}_${yAxis}`;
    if (data.length === 0) return;
    
    console.log("Key:", key);
    console.log("Data for key:", data[key]);

    const newData = data[key]?.[0] || [];

    if (newData.length > 0) {
        setScatterPlotData(newData);
    } else {
        console.warn(`Data for key "${key}" is empty.`);
    }

    console.log("New Data:", newData);

}, [data]);

  const calculateTicks = (data, key) => {
    const values = data.map(d => d[key]);
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);
    const range = maxValue - minValue;
  
    const roughTickCount = 5;
  
    let interval = range / roughTickCount;
  
    // Adjust the interval to be a "nice" number (like 10, 20, 50, etc.)
    const magnitude = Math.pow(10, Math.floor(Math.log10(interval)));
    interval = Math.ceil(interval / magnitude) * magnitude;
  
    // Generate the ticks based on the interval
    let ticks = [];
    let tick = Math.floor(minValue / interval) * interval;
    while (tick <= maxValue) {
      ticks.push(tick);
      tick += interval;
    }
  
    return ticks;
  };
  
  const calculateDomain = (data, key) => {
    const values = data.map(d => d[key]);
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);
    return [minValue, maxValue];
  };

  const normalizeLabel = (name) => {
    return name
      .replace(/_/g, ' ')          // Replace underscores with spaces
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Add spaces before uppercase letters in camelCase
      .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize the first letter of each word
  }; 

  const getAxisConfig = (axis) => {
    const axisLeter = axis === xAxis ? 'x' : 'y';
        return {
          key: axisLeter,
          label: normalizeLabel(axis),
          domain:  calculateDomain(scatterPlotData, axisLeter),
          ticks:  calculateTicks(scatterPlotData, axisLeter), 
          formatter: (tick) => tick,
        };
  };

  const xAxisConfig = getAxisConfig(xAxis);
  const yAxisConfig = getAxisConfig(yAxis);

  const minX = Math.min(...scatterPlotData.map(d => d[xAxisConfig.key]));
  const minY = Math.min(...scatterPlotData.map(d => d[yAxisConfig.key]));

  return (
    <ResponsiveContainer width="100%" height={400}>
      <ScatterChart margin={{ top: 20, right: 70, bottom: 20, left: 20 }} className={styles.hideFirstVerticalLine}>
        <defs>
          <linearGradient id="colorGroup1" x1="0" y1="0" x2="1" y2="1">
            <stop offset="0%" stopColor="rgb(165, 242, 163)" />
            <stop offset="100%" stopColor="rgb(77, 200, 66)" />
          </linearGradient>
          <linearGradient id="colorGroup2" x1="0" y1="0" x2="1" y2="1">
            <stop offset="0%" stopColor="rgb(254, 228, 163)" />
            <stop offset="100%" stopColor="rgb(246, 186, 94)" />
          </linearGradient>
          <linearGradient id="colorGroup3" x1="0" y1="0" x2="1" y2="1">
            <stop offset="0%" stopColor="rgb(253, 188, 188)" />
            <stop offset="100%" stopColor="rgb(228, 111, 111)" />
          </linearGradient>
        </defs>
        <CartesianGrid />
        <XAxis
          type="number"
          dataKey={xAxisConfig.key}
          name={xAxisConfig.label}
          tickFormatter={xAxisConfig.formatter}
          domain={xAxisConfig.domain}
          ticks={xAxisConfig.ticks}
          axisLine={{ strokeWidth: 0.1 }}
          tick={{ fill: 'var(--Blue-gray-400, #717BBC)', fontSize: 9.109, fontFamily: 'Poppins'}}
          tickLine={{ strokeWidth: 0.5 }}
          interval="preserveStartEnd"
        >
          <Label className={styles.Scatter_axis_legend} value={xAxisConfig.label} offset={-10} position="insideBottom" />
        </XAxis>
        <YAxis
          type="number"
          axisLine={false}
          dataKey={yAxisConfig.key}
          name={yAxisConfig.label}
          tickFormatter={yAxisConfig.formatter}
          domain={yAxisConfig.domain}
          ticks={yAxisConfig.ticks}
          tick={{ fill: 'var(--Blue-gray-400, #717BBC)', fontSize: 9.109, fontFamily: 'Poppins'}}
          tickLine={{ strokeWidth: 0 }}
        >
          <Label className={styles.Scatter_axis_legend} value={yAxisConfig.label} angle={-90} position="insideLeft" style={{ textAnchor: 'middle' }} />
        </YAxis>
        <ReferenceLine
          x={minX}
          stroke="#2E90FA"
          label={{ position: 'top', value: minX , style: { fontSize: 10, textAnchor: 'center' } }}
          strokeDasharray="5 5"
          strokeWidth="1.5"
          ifOverflow="extendDomain"
        />
        <ReferenceLine
          y={minY}
          // stroke="#9B8AFB"
          stroke="#2E90FA"
          label={{ position: 'right', value: minY, style: { fontSize: 10, textAnchor: 'start' } }}
          strokeDasharray="5 5"
          strokeWidth="1.5"
          ifOverflow="extendDomain"
        />
        <Tooltip content={<CustomTooltip />} cursor={{ stroke: 'rgba(94, 110, 255, 0.8)', strokeWidth: 1, strokeDasharray: '3 3' }} />
        <Scatter name="Non relevant alerts" data={scatterPlotData.filter(d => d.group === "Non Relevant")} fill="url(#colorGroup1)" />
        <Scatter name="Relevant alerts" data={scatterPlotData.filter(d => d.group === "Relevant")} fill="url(#colorGroup2)" />
        <Scatter name="SAR alerts" data={scatterPlotData.filter(d => d.group === "SAR")} fill="url(#colorGroup3)" />
      </ScatterChart>
    </ResponsiveContainer>
  );
};

export default ScatterPlot;
