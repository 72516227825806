import React from "react";
import styles from "./TitleWithCount.module.css";

const TitleWithCount = ({ title, labels, palette, isCircle }) => {
  return (
    <div className={styles.titleWithCount}>
      <span>{title}</span>
      <span
        className={styles.labelCount}
        style={{
          borderColor: palette.borderColor,
          backgroundColor: palette.backgroundColor,
          color: palette.textColor,
          padding: isCircle ? "5px" : "4px 12px",
          borderRadius: isCircle ? "49%" : "20px",
          fontSize: "10.5px",
        }}
      >
        {labels.length}
      </span>
    </div>
  );
};

export default TitleWithCount;
