import { useState, useEffect, useRef } from "react";
import styles from "./Selector.module.css";

function CalenderIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Calendar Icon" clip-path="url(#clip0_990_140063)">
        <g id="Vector">
          <path d="M5.6758 1.31445V3.85634V1.31445Z" fill="#BDB4FE" />
          <path
            d="M5.6758 1.31445V3.85634"
            stroke="#D9D6FE"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <path
          id="Vector_2"
          d="M10.7596 1.31445V3.85634"
          stroke="#D9D6FE"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_3"
          d="M12.666 2.58594H3.76938C3.06746 2.58594 2.49844 3.15496 2.49844 3.85688V12.7535C2.49844 13.4554 3.06746 14.0244 3.76938 14.0244H12.666C13.3679 14.0244 13.9369 13.4554 13.9369 12.7535V3.85688C13.9369 3.15496 13.3679 2.58594 12.666 2.58594Z"
          stroke="#D9D6FE"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_4"
          d="M2.49844 6.39844H13.9369"
          stroke="#D9D6FE"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_990_140063">
          <rect
            width="15.2513"
            height="15.2513"
            fill="white"
            transform="translate(0.592026 0.0439453)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

function getDateFormat(state) {
  const years = Object.keys(state)
    .map((year) => parseInt(year, 10))
    .sort((a, b) => a - b);

  let startMonth, startYear, endMonth, endYear;
  for (const year of years) {
    if (state[year] && state[year].length > 0) {
      startYear = year;
      startMonth = Math.min(...state[year]);
      break;
    }
  }
  for (const year of years.reverse()) {
    if (state[year] && state[year].length > 0) {
      endYear = year;
      endMonth = Math.max(...state[year]);
      break;
    }
  }

  const formatMonthYear = (month, year) => `${MONTHS[month]} ${year}`; // Use MONTHS array for full month names

  if (
    startMonth != null &&
    startYear != null &&
    endMonth != null &&
    endYear != null
  ) {
    return `${formatMonthYear(startMonth, startYear)} - ${formatMonthYear(
      endMonth,
      endYear
    )}`;
  }

  return "No valid date range selected";
}

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function getFirstAndLastSelectedMonths(state) {
  const years = Object.keys(state)
    .map((year) => parseInt(year, 10))
    .sort((a, b) => a - b);

  let firstMonth = null;
  let lastMonth = null;

  for (const year of years) {
    if (state[year] && state[year].length > 0) {
      const startMonth = Math.min(...state[year]);
      firstMonth = { year, month: startMonth };
      break;
    }
  }

  for (const year of years.reverse()) {
    if (state[year] && state[year].length > 0) {
      const endMonth = Math.max(...state[year]);
      lastMonth = { year, month: endMonth };
      break;
    }
  }

  return { firstMonth, lastMonth };
}

const MonthSelector = ({ label, state, setState }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showYear, setShowYear] = useState(new Date().getFullYear());
  const [selectedStart, setSelectedStart] = useState(null); // Start selection
  const dropdownRef = useRef();

  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  const nothingSelected = !state || Object.keys(state).length === 0;
  const isYearSelected =
    showYear === currentYear
      ? state[showYear]?.length === currentMonth + 1 // Check if only up to the current month is selected for the current year
      : state[showYear]?.length === 12;

  useEffect(() => {
    if (nothingSelected) {
      setState({
        [currentYear]: [currentMonth],
      });
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleYearChange = (direction) => {
    setShowYear((prevYear) => {
      const newYear = prevYear + direction;
      return newYear > currentYear ? currentYear : newYear; // Prevent exceeding the current year
    });
  };

  const handleYearSelect = () => {
    const months =
      showYear === currentYear
        ? [...Array(currentMonth + 1).keys()]
        : [...Array(12).keys()];

    setState({ [showYear]: months });
    setSelectedStart(null);
  };

  const handleMonthSelect = (monthIndex) => {
    if (
      showYear > currentYear ||
      (showYear === currentYear && monthIndex > currentMonth)
    ) {
      if (!selectedStart) {
        setSelectedStart({ year: currentYear, month: currentMonth });
      } else {
        const updatedState = buildStateFromRange(selectedStart, {
          year: currentYear,
          month: currentMonth,
        });
        setState(updatedState);
        setSelectedStart(null);
      }
    } else {
      if (!selectedStart) {
        setSelectedStart({ year: showYear, month: monthIndex });
      } else if (selectedStart) {
        if (
          showYear === selectedStart.year &&
          monthIndex === selectedStart.month
        ) {
          setState({ [showYear]: [monthIndex] });
        } else {
          const updatedState = buildStateFromRange(selectedStart, {
            year: showYear,
            month: monthIndex,
          });
          setState(updatedState);
        }
        setSelectedStart(null);
      }
    }
  };

  const buildStateFromRange = (start, end) => {
    const result = {};
    const [startYear, endYear] =
      start.year <= end.year ? [start.year, end.year] : [end.year, start.year];
    const [startMonth, endMonth] =
      start.year === end.year && start.month > end.month
        ? [end.month, start.month]
        : start.year > end.year
        ? [end.month, 11]
        : start.year < end.year
        ? [start.month, end.month]
        : [start.month, end.month];

    for (let year = startYear; year <= endYear; year++) {
      if (!result[year]) result[year] = [];
      if (year === startYear && year === endYear) {
        for (let month = startMonth; month <= endMonth; month++) {
          result[year].push(month);
        }
      } else if (year === startYear) {
        for (let month = startMonth; month < 12; month++) {
          result[year].push(month);
        }
      } else if (year === endYear) {
        for (let month = 0; month <= endMonth; month++) {
          result[year].push(month);
        }
      } else {
        result[year] = [...Array(12).keys()];
      }
    }
    return result;
  };
  const [hoveredMonth, setHoveredMonth] = useState(null);
  const firstSelectedMonth =
    selectedStart || getFirstAndLastSelectedMonths(state).firstMonth;

  const lastSelectedMonth =
    selectedStart === null
      ? getFirstAndLastSelectedMonths(state).lastMonth
      : null;

  return (
    <div
      className={styles.inputWithLabel}
      ref={dropdownRef}
      style={{ position: "relative" }}>
      <div className={styles.label}>{label}</div>
      <div className={styles.inputContainer} onClick={() => setIsOpen(true)}>
        <div className={styles.input}>
          <div type="text" className={styles.content}>
            {nothingSelected ? (
              <div className={styles.noDate}>MM/YY - MM/YY</div>
            ) : (
              getDateFormat(state)
            )}
          </div>
        </div>
        <div className={styles.dropdownButton}>
          <CalenderIcon />
        </div>
      </div>
      <div className={`${styles.dropdownContent} ${isOpen ? styles.show : ""}`}>
        <div className={styles.monthSelectorContainer}>
          <div className={styles.calenderContainer}>
            <div className={styles.yearSelector}>
              <div
                className={styles.monthArrow}
                onClick={() => handleYearChange(-1)}>
                <svg
                  width="13"
                  height="14"
                  viewBox="0 0 13 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g id="Chevron left">
                    <path
                      id="Icon"
                      d="M8.125 10.25L4.875 7L8.125 3.75"
                      stroke="#4E589F"
                      strokeWidth="1.08333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              </div>
              <span
                className={`${styles.year} ${
                  isYearSelected && styles.selected
                }`}
                onClick={handleYearSelect}>
                {showYear}
              </span>
              <div
                className={`${styles.monthArrow} ${
                  showYear == currentYear && styles.disabled
                }`}
                style={{ transform: "rotate(180deg)" }}
                onClick={() => handleYearChange(1)}>
                <svg
                  width="13"
                  height="14"
                  viewBox="0 0 13 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g id="Chevron left">
                    <path
                      id="Icon"
                      d="M8.125 10.25L4.875 7L8.125 3.75"
                      stroke="#4E589F"
                      strokeWidth="1.08333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <div className={styles.calender}>
              {MONTHS.map((month, index) => {
                const validMonth =
                  showYear < currentYear ||
                  (showYear == currentYear && index <= currentMonth);
                const isInRange =
                  firstSelectedMonth &&
                  lastSelectedMonth &&
                  validMonth &&
                  ((showYear > firstSelectedMonth.year &&
                    showYear < lastSelectedMonth.year) ||
                    (showYear === firstSelectedMonth.year &&
                      showYear === lastSelectedMonth.year &&
                      index > firstSelectedMonth.month &&
                      index < lastSelectedMonth.month) ||
                    (showYear === firstSelectedMonth.year &&
                      index > firstSelectedMonth.month &&
                      showYear < lastSelectedMonth.year) ||
                    (showYear === lastSelectedMonth.year &&
                      index < lastSelectedMonth.month &&
                      showYear > firstSelectedMonth.year));
                const isHoverInRange =
                  selectedStart &&
                  hoveredMonth &&
                  validMonth &&
                  ((showYear >
                    Math.min(selectedStart.year, hoveredMonth.year) &&
                    showYear <
                      Math.max(selectedStart.year, hoveredMonth.year)) ||
                    (showYear === selectedStart.year &&
                      showYear === hoveredMonth.year &&
                      index >=
                        Math.min(selectedStart.month, hoveredMonth.month) &&
                      index <=
                        Math.max(selectedStart.month, hoveredMonth.month)) ||
                    (showYear === selectedStart.year &&
                      index >= selectedStart.month &&
                      showYear < hoveredMonth.year) ||
                    (showYear === hoveredMonth.year &&
                      index <= hoveredMonth.month &&
                      showYear > selectedStart.year) ||
                    (selectedStart.year === hoveredMonth.year &&
                      index >=
                        Math.min(selectedStart.month, hoveredMonth.month) &&
                      index <=
                        Math.max(selectedStart.month, hoveredMonth.month)));
                return (
                  <button
                    key={index}
                    onMouseEnter={() =>
                      setHoveredMonth({ year: showYear, month: index })
                    }
                    onMouseLeave={() => setHoveredMonth(null)}
                    onClick={() => handleMonthSelect(index)}
                    className={`${styles.month} ${
                      firstSelectedMonth &&
                      firstSelectedMonth.year === showYear &&
                      firstSelectedMonth.month === index
                        ? styles.selected
                        : ""
                    } ${
                      lastSelectedMonth &&
                      lastSelectedMonth.year === showYear &&
                      lastSelectedMonth.month === index
                        ? styles.selected
                        : ""
                    } ${isHoverInRange ? styles.hoverRange : ""} ${
                      isInRange ? styles.inRange : ""
                    } ${!validMonth ? styles.notValid : ""} ${
                      index === currentMonth &&
                      showYear === currentYear &&
                      styles.currentMonth
                    }`}>
                    <span>{month}</span>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthSelector;
