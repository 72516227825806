import React from 'react';
import styles from './SearchBar.module.css';

const SearchBar = ({ searchTerm, setSearchTerm }) => {
  return (
    <div className={styles.searchContainer}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 21"
        fill="none"
      >
        <path
          d="M17.5 18.3906L13.875 14.7656M15.8333 10.0573C15.8333 13.7392 12.8486 16.724 9.16667 16.724C5.48477 16.724 2.5 13.7392 2.5 10.0573C2.5 6.37539 5.48477 3.39062 9.16667 3.39062C12.8486 3.39062 15.8333 6.37539 15.8333 10.0573Z"
          stroke="#667085"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <input
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className={styles.searchInput}
      />
    </div>
  );
};

export default SearchBar;
