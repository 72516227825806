import React, { useEffect , useState, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import VisualisationHeader from './VisualisationHeader';
import Selector from './selector/Selector';
import ScatterPlot from './charts/ScatterPlot';
import BoxPlotChart from './charts/BoxPlotChart';
import styles from './Visualisation.module.css';
import AlertsLegend from './AlertsLegend';
import BoxplotDataGrid from './BoxplotDataGrid';
import Checkbox from '../../../layout/buttons/Checkbox';
import {fetchThresholds, setRiskScoreSelectorOption, setVisualisationYSelectorOption, setVisualisationXSelectorOption, setVisualisationChartType, fetchScatterPlotData, fetchBoxPlotData } from '../../../features/atl_tool/atlToolSlice';

const Visualisation = ({ id }) => {
  const dispatch = useDispatch();
  const { selectedSegment, selectedScenario, selectedRiskScore, selectedSubScenario, visualisationsThresholds , selectedPeriod} = useSelector(state => state.atlTool);

  const visualisationRef = useRef(null);

  const visualisations = useSelector((state) => state.atlTool.visualisations);
  const visualisation = visualisations.find(visualisation => visualisation.key === id);

  const isExpanded = visualisation.isExpanded;
  const chartType = visualisation.chartType;
  const xSelector = visualisation.xSelector;
  const ySelector = visualisation.ySelector;
  const boxPlotData = visualisation.boxPlotData;

  
  // New state for toggling outliers visibility
  const [showOutliers, setShowOutliers] = useState(true);

  // Reset outliers when x-axis changes
  useEffect(() => {
    setShowOutliers(false);


  }, [visualisation.xSelector.xOption])


  useEffect(() => {
    if (selectedScenario) {
      dispatch(fetchThresholds({ selectedScenario, selectedSubScenario }));
    }
  }, [selectedScenario, selectedSubScenario]);

  const handleYOptionSelect = (scenario) => {
    setShowOutliers(true);
    const currentXOption = xSelector.xOption;

    let x_option = null; 
    let y_option = null;

    if (scenario === currentXOption) {
      // Swap X and Y if they are the same
      x_option = ySelector.yOption;
      y_option = currentXOption;
    } else {
      y_option = scenario;
      x_option = currentXOption;
    }

    dispatch(setVisualisationYSelectorOption({ id, scenario: y_option }));
    dispatch(setVisualisationXSelectorOption({ id, scenario: x_option }));

    if (chartType === 'Scatter Plot') {
      // Trigger data fetch with correct X and Y values after state update
      setTimeout(() => {

      
        sendFetchScatterPlotData(x_option, y_option)
      }, 500);
    } else if (chartType === 'Box Plot') {
      // Fetch Box Plot data
      setTimeout(() => {
        sendFetchBoxPlotData(x_option);
      }, 500);
    }
  };


  const sendFetchScatterPlotData = (x_option, y_option) => {
    dispatch(fetchScatterPlotData({
      scenarioId: selectedScenario?.id,
      segmentId: selectedSegment.id,
      subScenarioId: selectedSubScenario?.id || 0,
      riskScore: selectedRiskScore,
      x_threshold_name: x_option,
      y_threshold_name: y_option, 
      x_threshold_id: getThresholdIdFromName(x_option),
      y_threshold_id: getThresholdIdFromName(y_option),
      period: selectedPeriod,
    }));
  };




  const handleXOptionSelect = (scenario) => {
    const currentYOption = ySelector.yOption;
   
    setShowOutliers(true);
    let x_option = null; 
    let y_option = null;

    if (scenario === currentYOption) {
      // Swap X and Y if they are the same
      x_option = currentYOption;
      y_option = xSelector.xOption;
    } else {
      y_option = currentYOption;
      x_option = scenario;
    }

    dispatch(setVisualisationYSelectorOption({ id, scenario: y_option }));
    dispatch(setVisualisationXSelectorOption({ id, scenario: x_option }));
    
    if (chartType === 'Scatter Plot') {
      // Trigger data fetch with correct X and Y values after state update
      setTimeout(() => {
        sendFetchScatterPlotData(x_option, y_option)
      }, 500);
    } else if (chartType === 'Box Plot') {
      // Fetch Box Plot data
      setTimeout(() => {
        sendFetchBoxPlotData(x_option);
      }, 500);
    }
  };

  const handleRiskScoreSelection = (riskScore) => {
    dispatch(setRiskScoreSelectorOption(riskScore));
  };

  const handleChartTypeClick = (chartType) => {
    dispatch(setVisualisationChartType({ key: id, chartType }));
    if (chartType === 'Scatter Plot') {
      // Trigger data fetch with correct X and Y values after state update
      setTimeout(() => {
        dispatch(fetchScatterPlotData({
          scenarioId: selectedScenario?.id,
          segmentId: selectedSegment.id,
          subScenarioId: selectedSubScenario?.id || 0,
          riskScore: selectedRiskScore,
          x_threshold_id: getThresholdIdFromName(xSelector.xOption),
          y_threshold_id: getThresholdIdFromName(ySelector.yOption), 
          x_threshold_name: xSelector.xOption,
          y_threshold_name: ySelector.yOption, 
          period: selectedPeriod,
        }));
      }, 1);
    } else if (chartType === 'Box Plot') {
      // Fetch Box Plot data
      setTimeout(() => {
        sendFetchBoxPlotData(xSelector.xOption);
      }, 1);
    }
  };


  const sendFetchBoxPlotData = () => {
    dispatch(fetchBoxPlotData({
      scenarioId: selectedScenario?.id,
      segmentId: selectedSegment.id,
      subScenarioId: selectedSubScenario?.id || 0,
      riskScore: selectedRiskScore,
      period: selectedPeriod,
    }));
  };

  const getThresholdIdFromName = (name) => {
    return visualisationsThresholds.find(threshold => threshold.threshold_name === name)?.threshold_id;
  };

  const calcHeightSelector = () => {
    return (57 + 40 * xSelector.xOptions.length);
  };

  return (
    <div ref={visualisationRef} className={`${styles.visualisation} ${!isExpanded ? styles.isCollapsed : ''}`}>
      <VisualisationHeader id={id} CaptureSectionName="Visualisation" CaptureRef={visualisationRef} />
      <div className={`${styles.contentHolder} ${!isExpanded ? styles.isCollapsed : ''}`}>
        <div className="controls">
          <div className={styles.chartTypeSelectionHolder}>
            <div className={`${styles.chartTypeSelectionButton} ${chartType === 'Box Plot' ? styles.chartTypeSelectionButtonActive : ''}`} onClick={() => handleChartTypeClick('Box Plot')}>
              Box Plot
            </div>
            <div className={`${styles.chartTypeSelectionButton} ${chartType === 'Scatter Plot' ? styles.chartTypeSelectionButtonActive : ''}`} onClick={() => handleChartTypeClick('Scatter Plot')}>
              Scatter Plot
            </div>
          </div>
          <div className={styles.graphHolder}>
            <AlertsLegend />
            {chartType === 'Scatter Plot' ? (
              <ScatterPlot id={id} xAxisOption={xSelector.xOption} yAxisOption={ySelector.yOption} />
            ) : (
              <BoxPlotChart id={id} data={boxPlotData} showOutliers={showOutliers} xAxis={xSelector.xOption} yAxisLegend={selectedSegment.name +"," +selectedRiskScore.name} />
            )}
          </div>
          <div className={styles.visualisationSelectorsHolder} style={{ height: `${(!(chartType === "Box Plot")) ? `${calcHeightSelector()}px` : 'auto'}` }}>
            {chartType === "Scatter Plot" && (
              <div>
                <Selector options={ySelector.yOptions} selectedOption={ySelector.yOption} onOptionSelect={handleYOptionSelect} label="Y Axis" />
              </div>
            )}
            <Selector options={xSelector.xOptions} selectedOption={xSelector.xOption} onOptionSelect={handleXOptionSelect} label="X Axis" />

            {chartType === 'Box Plot' && (
              <div style={{ alignContent:"end" }}>
                <Checkbox
                  label={"Show Outliers"}
                  checked={showOutliers}
                  onChange={() => setShowOutliers(!showOutliers)}
                />
              </div>
            )}
          </div>
          {chartType === 'Box Plot' && (
            <div className={styles.boxplotTable}>
              <BoxplotDataGrid id={id} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Visualisation;