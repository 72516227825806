import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedSegmentOverview, setSelectedRiskScore, setReloadOptimisationTable } from'../../../features/atl_tool/atlToolSlice';
import { VictoryPie } from 'victory';
import styles from './SegmentOverview.module.css';
import RiskBadge from '../../../layout/labels/risk_badge/RiskBadge';


import fonts from '../../../layout/Fonts.module.css';

const PieChart = ({ risk_score_id, risk_level }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([
    { x: 'Non Relevant Alerts', y: 0 },
    { x: 'Non Relevant Alerts Diference', y: 0 },
    { x: 'Relevant Alerts', y: 0 },
    { x: 'Relevant Alerts Diference', y: 0 },
    { x: 'SAR Alerts', y: 0 },
    { x: 'SAR Alerts Diference', y: 0 },
    { x: 'Empty', y: 0 }
  ]);
  const [totalAlerts, setTotalAlerts] = useState(0);
  const pieChartRef = useRef(null);
  const risk_score = useSelector((state) => state.atlTool.riskScores.find(score => score.id === risk_score_id));
  const selectedSegmentOverview = useSelector((state) => state.atlTool.selectedSegmentOverview);
  const navBarIsCollapsed = useSelector((state) => state.atlTool.navBarIsCollapsed);
  const selectedSegmentOverviewUpdates = useSelector((state) => state.atlTool.selectedSegmentOverviewUpdates);
  const isUpdated = (selectedSegmentOverviewUpdates !== null && selectedSegmentOverviewUpdates.id === risk_score_id);

  const { reloadOptimisationTable } = useSelector((state) => state.atlTool);

  const [alertsLabels, setAlertsLabels] = useState({
    non_relevant_alerts_label: 0,
    relevant_alerts_label: 0,
    sar_alerts_label: 0,
    nr_ratio_label: "0%",
    r_ratio_label: "0%",
    sar_ratio_label: "0%", 
    alert_label_color: '#BFC5FF', 
    secondary_alert_label_color: '#E8EBFE',
    pieChartTextColor: '#4E589F'
  });


  const makeAlertsLabels = (isHovered) => {
    const dataLabelsRatio = isUpdated ? (isHovered ? getDataWhenNotUpdated() : getDataWhenUpdated()) : getDataWhenNotUpdated();
    const dataLabels = isUpdated ? (isHovered ? risk_score : selectedSegmentOverviewUpdates) : risk_score;

    const non_relevant_alerts_label = dataLabels.non_relevant_alerts || 0;
    const relevant_alerts_label = dataLabels.relevant_alerts || 0;
    const sar_alerts_label = dataLabels.sar_alerts || 0;
    const nr_ratio_label = Math.round(dataLabelsRatio.nr_ratio * 100) + '%';
    const r_ratio_label = Math.round(dataLabelsRatio.r_ratio * 100) + '%';
    const sar_ratio_label = Math.round(dataLabelsRatio.sar_ratio * 100) + '%';

    const alert_label_color = (non_relevant_alerts_label === 0 && relevant_alerts_label === 0 && sar_alerts_label === 0) ? '#BFC5FF' : '';
    const secondary_alert_label_color = (non_relevant_alerts_label === 0 && relevant_alerts_label === 0 && sar_alerts_label === 0) ? '#E8EBFE' : '';
   
    setAlertsLabels({
      non_relevant_alerts_label,
      relevant_alerts_label,
      sar_alerts_label,
      nr_ratio_label,
      r_ratio_label,
      sar_ratio_label,
      alert_label_color, 
      secondary_alert_label_color
    });
  };

  const getDataWhenUpdated = () => {
    const non_relevant_alerts = Number(selectedSegmentOverviewUpdates.non_relevant_alerts);
    const relevant_alerts = Number(selectedSegmentOverviewUpdates.relevant_alerts);
    const sar_alerts = Number(selectedSegmentOverviewUpdates.sar_alerts);
  
    const total_alerts = non_relevant_alerts + relevant_alerts + sar_alerts;
  
    if (total_alerts > 0) {
      const nr_ratio = non_relevant_alerts / total_alerts;
      const r_ratio = relevant_alerts / total_alerts;
      const sar_ratio = sar_alerts / total_alerts;
  
      const risk_non_relevant_alerts = Number(risk_score.non_relevant_alerts);
      const risk_relevant_alerts = Number(risk_score.relevant_alerts);
      const risk_sar_alerts = Number(risk_score.sar_alerts);
  
      const nr_ratio_difference = (risk_non_relevant_alerts - non_relevant_alerts) / total_alerts;

      const r_ratio_difference = (risk_relevant_alerts - relevant_alerts) / total_alerts;
      const sar_ratio_difference = (risk_sar_alerts - sar_alerts) / total_alerts;
  
      return { nr_ratio, r_ratio, sar_ratio, nr_ratio_difference, r_ratio_difference, sar_ratio_difference, total_alerts };
    } else {
      // Handle the case where total_alerts is 0
      const nr_ratio = 0;
      const r_ratio = 0;
      const sar_ratio = 0;
  
      const nr_ratio_difference = 0;
      const r_ratio_difference = 0;
      const sar_ratio_difference = 0;
  
      return { nr_ratio, r_ratio, sar_ratio, nr_ratio_difference, r_ratio_difference, sar_ratio_difference, total_alerts };
    }
  };
  
  
  const getDataWhenNotUpdated = () => {
    const total_alerts = risk_score.non_relevant_alerts + risk_score.relevant_alerts + risk_score.sar_alerts;
    
    // Calculate ratios and handle NaN cases
    const nr_ratio = isNaN(risk_score.non_relevant_alerts / total_alerts) ? 0 : risk_score.non_relevant_alerts / total_alerts;
    const r_ratio = isNaN(risk_score.relevant_alerts / total_alerts) ? 0 : risk_score.relevant_alerts / total_alerts;
    const sar_ratio = isNaN(risk_score.sar_alerts / total_alerts) ? 0 : risk_score.sar_alerts / total_alerts;
    
    return { 
        nr_ratio, 
        r_ratio, 
        sar_ratio, 
        nr_ratio_difference: 0, 
        r_ratio_difference: 0, 
        sar_ratio_difference: 0, 
        total_alerts 
    };
};

  const handleUpdate = () => {
    makeAlertsLabels(false);
    if (isUpdated) {
      updatePie(getDataWhenUpdated());
    } else {
      updatePie(getDataWhenNotUpdated());
    }
  };

  const updatePie = ({ nr_ratio, r_ratio, sar_ratio, nr_ratio_difference, r_ratio_difference, sar_ratio_difference, total_alerts }) => {
    const isEmpty = total_alerts === 0;

    setTimeout(() => {
      setData([
        { x: 'Non Relevant Alerts', y: isEmpty ? 0 : (nr_ratio * 100) },
        { x: 'Non Relevant Alerts Diference', y: isEmpty ? 0 : (nr_ratio_difference * 100) },
        { x: 'Relevant Alerts', y: isEmpty ? 0 : (r_ratio * 100) },
        { x: 'Relevant Alerts Diference', y: isEmpty ? 0 : (r_ratio_difference * 100) },
        { x: 'SAR Alerts', y: isEmpty ? 0 : (sar_ratio * 100) },
        { x: 'SAR Alerts Diference', y: isEmpty ? 0 : (sar_ratio_difference * 100) },
        { x: 'Empty', y: isEmpty ? 100 : 0 },
      ]);

      /*
      console.log(nr_ratio, r_ratio, sar_ratio, nr_ratio_difference, r_ratio_difference, sar_ratio_difference, total_alerts); 
      console.dir([
        { x: 'Non Relevant Alerts', y: isEmpty ? 0 : (nr_ratio * 100) },
        { x: 'Non Relevant Alerts Diference', y: isEmpty ? 0 : (nr_ratio_difference * 100) },
        { x: 'Relevant Alerts', y: isEmpty ? 0 : (r_ratio * 100) },
        { x: 'Relevant Alerts Diference', y: isEmpty ? 0 : (r_ratio_difference * 100) },
        { x: 'SAR Alerts', y: isEmpty ? 0 : (sar_ratio * 100) },
        { x: 'SAR Alerts Diference', y: isEmpty ? 0 : (sar_ratio_difference * 100) },
        { x: 'Empty', y: isEmpty ? 100 : 0 },
      ]); 
      */
      setTotalAlerts(total_alerts);
    }, 50);

  };

  useEffect(() => {
    handleUpdate();
  }, [risk_score, selectedSegmentOverviewUpdates]);

  const handleSegmentOverviewClick = () => {
    console.log("handle segment overview click")
    dispatch(setSelectedSegmentOverview(risk_score_id));
    dispatch(setSelectedRiskScore(risk_score));
    dispatch(setReloadOptimisationTable(!reloadOptimisationTable))
  };

  const radius = ({ datum }) => {
    if (datum.x === 'Non Relevant Alerts') return 200;
    if (datum.x === 'Non Relevant Alerts Diference') return 200;
    if (datum.x === 'Relevant Alerts') return 185;
    if (datum.x === 'Relevant Alerts Diference') return 185;
    if (datum.x === 'SAR Alerts') return 170;
    if (datum.x === 'SAR Alerts Diference') return 170;
    if (datum.x === 'Empty') return 170;
    return 130;
  };

  const handleMouseEnter = () => {
    if (isUpdated) {
      const data2 = getDataWhenNotUpdated();
      makeAlertsLabels(true);
      updatePie(data2);
    }
  };

  const handleMouseLeave = () => {
    if (isUpdated) {
      const data2 = getDataWhenUpdated();
      makeAlertsLabels(false);
      updatePie(data2);
    }
  };

  const isActive = selectedSegmentOverview === risk_score_id;
  const colorScale = ['url(#greenGradient)', 'url(#greenGradientLight)', 'url(#yellowGradient)', 'url(#yellowGradientLight)', 'url(#redGradient)', 'url(#redGradientLight)', 'url(#EmptyGradient)'];

  if (!risk_score) {
    return null;
  }

  return (
    <div
      ref={pieChartRef}
      className={`${isActive ? styles.pieChartBox_active : totalAlerts > 0 ? styles.pieChartBox : styles.pieChartBoxNoAction}`}
      
      onClick={totalAlerts > 0 ? handleSegmentOverviewClick : undefined}
      onMouseEnter={ handleMouseEnter }
      onMouseLeave={ handleMouseLeave }
    >
      <div className={styles.riskScoreTagText} style={{ backgroundColor: alertsLabels.alert_label_color === '' ? `rgba(181, 120, 255, ${0.1 + 0.4 * risk_level})` : '#E8EBFE' }} >
        <div className={styles.riskScoreTagTextBox} style={{color: alertsLabels.alert_label_color === '' ? ' rgba(138, 66, 255, 0.6)' : '#BFC5FF' }}>{risk_score.name}</div>
      </div>
      <div className={styles.pieChartSubBox}>
        <div className={styles.alertsLegend}>
          <div className={styles.frameParent}>
            <div className={`${styles.totalBox} ${isActive ? styles.piechartBoxTextActive : ""}`}>
              {totalAlerts} Alerts
            </div>
            <div className={styles.frameGroup}>
              <img className={styles.color_circle} alt="" src={`${process.env.PUBLIC_URL}/${alertsLabels.alert_label_color === '' ? "Color_red.svg" : "Color_gray.svg"}`}  />
              <div className={styles.labelGroup}>
                <div className={`${styles.alerts_label} ${isActive ? styles.piechartBoxTextActive : styles.pieChartBoxText}`}>SAR alerts</div>
                <div className={styles.labelContainer}>
                  <div className={`${styles.n_alerts_abs} ${isActive ? styles.piechartBoxTextActive : styles.pieChartBoxText}`}>{alertsLabels.sar_alerts_label}</div>
                  <div className={`${styles.n_alerts_rel} ${isActive ? styles.piechartBoxTextActive : styles.pieChartBoxText}`}>
                    ({alertsLabels.sar_ratio_label})
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameGroup}>
              <img className={styles.color_circle} alt="" 
               src={`${process.env.PUBLIC_URL}/${alertsLabels.alert_label_color === '' ? "Color_yellow.svg" : "Color_gray.svg"}`} 
               />
              <div className={styles.labelGroup}>
                <div className={`${styles.alerts_label} ${isActive ? styles.piechartBoxTextActive : styles.pieChartBoxText}`}>Relevant alerts</div>
                <div className={styles.labelContainer}>
                  <div className={`${styles.n_alerts_abs} ${isActive ? styles.piechartBoxTextActive : styles.pieChartBoxText}`}>{alertsLabels.relevant_alerts_label}</div>
                  <div className={`${styles.n_alerts_rel} ${isActive ? styles.piechartBoxTextActive : styles.pieChartBoxText}`}>
                    ({alertsLabels.r_ratio_label})
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameGroup}>
              <img className={styles.color_circle} alt="" src={`${process.env.PUBLIC_URL}/${alertsLabels.alert_label_color === '' ? "Color_green.svg" : "Color_gray.svg"}`}  />
              <div className={styles.labelGroup}>
                <div className={`${styles.alerts_label} ${isActive ? styles.piechartBoxTextActive : styles.pieChartBoxText}`}>Non relevant alerts</div>
                <div className={styles.labelContainer}>
                  <div className={`${styles.n_alerts_abs} ${isActive ? styles.piechartBoxTextActive : styles.pieChartBoxText}`}>{alertsLabels.non_relevant_alerts_label}</div>
                  <div className={`${styles.n_alerts_rel} ${isActive ? styles.piechartBoxTextActive : styles.pieChartBoxText}`}>
                    ({alertsLabels.nr_ratio_label})
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameGroup}>
              <img className={styles.color_circle} alt="" src={`${process.env.PUBLIC_URL}/${alertsLabels.alert_label_color === '' ? "Color_gray.svg" : "Color_gray.svg"}`}  />
              <div className={styles.labelGroup}>
                <div className={`${styles.alerts_label} ${isActive ? styles.piechartBoxTextActive : styles.pieChartBoxText}`}>Supressed Alerts</div>
                <div className={styles.labelContainer}>
                  <div className={`${styles.n_alerts_abs} ${isActive ? styles.piechartBoxTextActive : styles.pieChartBoxText}`}>{data.filter(item => item.x.toLowerCase().includes('diference')).reduce((sum, item) => sum + item.y / 100 * totalAlerts, 0)}</div>
                  <div className={`${styles.n_alerts_rel} ${isActive ? styles.piechartBoxTextActive : styles.pieChartBoxText}`}>
                    ({Math.round(data.filter(item => item.x.toLowerCase().includes('diference')).reduce((sum, item) => sum + item.y / 100 * totalAlerts, 0) / (risk_score.non_relevant_alerts + risk_score.relevant_alerts + risk_score.sar_alerts) * 100)}%)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${navBarIsCollapsed ? styles.mediumPieChart : styles.mediumPieChartNavBarCollapsed}`}>
          <svg style={{ height: 0 }}>
            <defs>
              <linearGradient id="greenGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" style={{ stopColor: '#A5F2A3', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: '#4DC842', stopOpacity: 1 }} />
              </linearGradient>
              <linearGradient id="greenGradientLight" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style={{ stopColor: '#C9F3C8', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: '#C8E0C6', stopOpacity: 1 }} />
              </linearGradient>
              <linearGradient id="yellowGradient" x1="0%" y1="0%" x2="100%" y2="36%">
                <stop offset="0%" style={{ stopColor: '#FEE4A3', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: '#F6BA5E', stopOpacity: 1 }} />
              </linearGradient>
              <linearGradient id="yellowGradientLight" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style={{ stopColor: '#FFF2C8', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: '#FCE9A6', stopOpacity: 1 }} />
              </linearGradient>
              <linearGradient id="redGradient" x1="0%" y1="0%" x2="100%" y2="70%">
                <stop offset="0%" style={{ stopColor: '#FDBCBC', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: '#E46F6F', stopOpacity: 1 }} />
              </linearGradient>
              <linearGradient id="redGradientLight" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style={{ stopColor: '#FDDCDC', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: '#F7A8A8', stopOpacity: 1 }} />
              </linearGradient>
              <linearGradient id="EmptyGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style={{ stopColor: '#E8EBFE', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: '#E8EBFE', stopOpacity: 1 }} />
              </linearGradient>
            </defs>
          </svg>
          <VictoryPie
            data={data}
            key={risk_score_id}
            colorScale={colorScale}
            style={{
              data: {
                fillOpacity: 0.9,
                stroke: '#fff',
                strokeWidth: 0,
                filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1))',
              },
              labels: {
                display: 'none',
              },
            }}
            innerRadius={50}
            cornerRadius={4}
            radius={radius}
            animate={{
              duration: 200,
              before: () => ({ _y: 0, _x: 0 }),
              onLoad: { duration: 200, before: () => ({ _y: 0, _x: 0 }) },
              easing: "linear",
            }}
          />
          <div className={styles.frameParent2}>
            <div className={styles.pie_medal}></div>
            <div className={`${styles.div} ${styles.pieChartText}`}>{totalAlerts}</div>
            <div className={`${styles.alerts_label} ${styles.pieChartText}`}>alerts</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PieChart;
