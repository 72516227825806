import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../axiosConfig";
import { convertData } from "../Functions";
import { keyMappingAlerts } from "../all_alerts/allAlertsSlice";

const convertDsMatrixData = (data) => {
  var result = [];

  data.map((category) => {
    // Create an array for the 'data' field with all elements from category.ds
    const dsData = category.ds.map((dsItem) => ({
      id: dsItem.ds_id,
      name: dsItem.ds_name,
      data: dsItem.data,
    }));

    // Push the category with all ds elements to the result array
    result.push({
      category_id: category.category_id,
      category_name: category.category,
      data: dsData,
    });
  });
  return result;
};

const convertScenariosMatrixData = (data) => {
  var result = [];

  data.scenarios.map((scenario) => {
    result.push({
      id: scenario.scenario_id,
      name: scenario.scenario_name,
      data: scenario.overlap_data,
    });
  });

  return {
    row_ds_id: data.row_ds_id,
    row_ds_name: data.row_ds_name,
    col_ds_id: data.col_ds_id,
    col_ds_name: data.col_ds_name,
    scenarios: result,
  };
};

export const keyMappingOverlapAlerts = {
  id: "overlap_id",
  alert_code: "overlap_alert_code",
  risk_score: "overlap_risk_score",
  event_date: "overlap_event_date",
  alert_relevancy: "overlap_alert_relevancy",
  alert_status: "overlap_alert_status",
};

const convertOverlappingAlertsData = (data) => {
  const formattedData = [];
  if (data.total_overlaps === 0) return formattedData;
  data.overlap_alerts.forEach((overlap) => {
    const alert1 = convertData(overlap.alert_1[0], keyMappingAlerts);
    const alert2 = convertData(overlap.alert_2[0], keyMappingOverlapAlerts);
    formattedData.push({
      ...alert1,
      ...alert2,
      combinedId: `${alert1.id}_${alert2.overlap_id}`
    });
  });
  return { formattedData, total_count: data.total_count };
};

const parseYearMonthPairs = (inputArray) => {
  // Check if the input is a non-empty array
  if (!Array.isArray(inputArray) || inputArray.length === 0) {
    return "";
  }

  // Join the array elements with the desired format
  const parsedString = inputArray
    .map((pair) => `year_month_pairs=${pair}`)
    .join("&");

  // Prepend the "?" to the resulting string
  return parsedString;
};

// Thunks

export const fetchOverlapAnalysisMatrix = createAsyncThunk(
  "overlapAnalysis/fetchOverlapAnalysisMatrix",
  async ({}, { getState }) => {
    const state = getState().overlapAnalysis;
    const year_month_pairs_string = parseYearMonthPairs(
      state.selectedYearMonthPairs
    );
    const response = await apiClient.get(
      `/ds-overlap-analysis-view?${year_month_pairs_string}`
    );
    const result = convertDsMatrixData(response.data);
    return result;
  }
);

export const fetchOverlapAnalysisScenariosMatrix = createAsyncThunk(
  "overlapAnalysis/fetchOverlapAnalysisScenariosMatrix",
  async ({ ds_id_1, ds_id_2, year_month_pairs }) => {
    const year_month_pairs_string = parseYearMonthPairs(year_month_pairs);
    const response = await apiClient.get(
      `/scenario-overlap-analysis-view?ds_id_1=${ds_id_1}&ds_id_2=${ds_id_2}&${year_month_pairs_string}`
    );
    const result = convertScenariosMatrixData(response.data);
    return result;
  }
);

export const fetchOverlapAnalysis = createAsyncThunk(
  "overlapAnalysis/fetchOverlapAnalysis",
  async ({
    scenario_id_1,
    scenario_id_2,
    year_month_pairs,
    skip = 0,
    limit = 10,
  }) => {
    const year_month_pairs_string = parseYearMonthPairs(year_month_pairs);
    const response = await apiClient.get(
      `/overlap-analysis-alerts?scenario_id_1=${scenario_id_1}&scenario_id_2=${scenario_id_2}&${year_month_pairs_string}&skip=${skip}&limit=${limit}`
    );
    const result = convertOverlappingAlertsData(response.data);
    return result;
  }
);

const overlapAnalysisSlice = createSlice({
  name: "overlapAnalysis",
  initialState: {
    overlapData: [],
    matrixData: [],
    status: "idle",
    error: null,
    overlapScenariosData: [],

    // used for scenarios overlap
    ds_id_1: -1,
    ds_id_2: -1,
    ds_name_1: -1,
    ds_name_2: -1,
    scenarioIds: [],
    selectedYearMonthPairs: ["2022-01", "2022-02", "2022-03"],

    tableStatus: "idle",
  },
  reducers: {
    setDsId(state, action) {
      if (action.payload.number === 1) {
        state.ds_id_1 = action.payload.id;
      } else if (action.payload.number === 2) {
        state.ds_id_2 = action.payload.id;
      }
    },
    setScenarioIds(state, action) {
      console.log("action apyload", action.payload);
      state.scenarioIds = action.payload;
      console.log("Scenario ids please load:", state.scenarioIds);
    },

    setSelectedYearMonthPairs(state, action) {
      state.selectedYearMonthPairs = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOverlapAnalysisMatrix.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOverlapAnalysisMatrix.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.matrixData = action.payload;
      })
      .addCase(fetchOverlapAnalysisMatrix.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchOverlapAnalysis.pending, (state) => {
        state.tableStatus = "loading";
      })
      .addCase(fetchOverlapAnalysis.fulfilled, (state, action) => {
        state.tableStatus = "succeeded";
        state.overlapData = action.payload.formattedData;
        state.totalAlerts = action.payload.total_count;
      })
      .addCase(fetchOverlapAnalysis.rejected, (state, action) => {
        state.tableStatus = "failed";

        state.error = action.error.message;
      })
      .addCase(fetchOverlapAnalysisScenariosMatrix.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchOverlapAnalysisScenariosMatrix.fulfilled,
        (state, action) => {
          state.status = "succeeded";
          state.overlapScenariosData = action.payload.scenarios;
          console.log(
            "Scenario overlap data loaded: ",
            state.overlapScenariosData
          );
          state.ds_name_1 = action.payload.row_ds_name;
          state.ds_name_2 = action.payload.col_ds_name;
        }
      )
      .addCase(
        fetchOverlapAnalysisScenariosMatrix.rejected,
        (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        }
      );
  },
});

export const {
  setDsId,
  setScenarioIds,
  setSelectedYearMonthPairs,
} = overlapAnalysisSlice.actions;

export default overlapAnalysisSlice.reducer;
