import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Badge from './../badges/Badge';
import styles from './CustomEditor.module.css';

const CustomEditor = ({ isEdit, onChange , badgesSelection})=> {

    const {dataLabels,  infoLists, scenarioDetails, dataSources, profiles , scenarioActiveDataLabels, scenarioActiveInfoLists, scenarioActiveProfiles,  scenarioActiveDataSources } = useSelector((state) => state.configuration);

  
    const [savedRange, setSavedRange] = useState(null);
    const [suggestionsPosition, setSuggestionsPosition] = useState({ top: 0, left: 0 });



    const [badges, setBadges] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [currentSuggestions, setCurrentSuggestions] = useState([]);
    const [highlightedIndex, setHighlightedIndex] = useState(0);
    const [isDotContext, setIsDotContext] = useState(false);
    const [isSlashContext, setIsSlashContext] = useState(false);
    const editorRef = useRef(null);
    const suggestionsBoxRef = useRef(null);
    const [filterText, setFilterText] = useState("");

    const [validInfoList, setValidInfoList] = useState([]);
    const [validDataLabels, setValidDataLabels] = useState([]);
    const [validDataSources, setValidDataSources] = useState([]);
    const [validCurrentProfiles, setValidCurrentProfiles] = useState([]);

    const placeCaretAfterNode = (node) => {
        const range = document.createRange();
        const selection = window.getSelection();
        range.setStartAfter(node);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
        editorRef.current.focus();
      };

      useEffect(() => {
        const editorElement = editorRef.current;
      
        const handleBadgeClick = (event) => {
          const target = event.target;
          if (target.classList.contains(styles.badge)) {
            event.preventDefault();
            placeCaretAfterNode(target);
          } else if (target.closest(`.${styles.badge}`)) {
            // If the click is on a child of the badge (e.g., the bullet span)
            const badgeNode = target.closest(`.${styles.badge}`);
            event.preventDefault();
            placeCaretAfterNode(badgeNode);
          }
        };
      
        if (editorElement) {
          editorElement.addEventListener('click', handleBadgeClick);
        }
      
        return () => {
          if (editorElement) {
            editorElement.removeEventListener('click', handleBadgeClick);
          }
        };
      }, []);
      
      


    const makeActiveInfoLists = () => {
        let activeInfoLists = [];
        if (scenarioActiveInfoLists) {
            activeInfoLists = scenarioActiveInfoLists.map(list => {
                const matchedList = infoLists.find(infoList => infoList.id === list.id);
                if (matchedList) {
                    return matchedList;
                }
            });
            return activeInfoLists;
        }
    };

    const makeActiveDataLabels = () => {
        let activeDataLabels = [];
        if (scenarioActiveDataLabels) {
            activeDataLabels = scenarioActiveDataLabels.map(label => {
                const matchedLabel = dataLabels.find(dataLabel => dataLabel.id === label.id);
                if (matchedLabel) {
                    return matchedLabel;
                }
            });
            return activeDataLabels;
        }
    };

    const makeActiveProfiles = () => {
        return scenarioActiveProfiles?.map(profile2 => 
            scenarioDetails?.scenario_profiles?.find(profile => profile.id === profile2.id)
        ).filter(Boolean) ?? [];
    };
    

    const makeActiveDataSources = () => {
        let activeDataSources = [];
        if (scenarioActiveDataSources[0] && dataSources) {
            console.log("data sources:22 ", dataSources, scenarioActiveDataSources);
            activeDataSources = scenarioActiveDataSources.map(source => {
                const matchedSource = dataSources.find(data => data.id === source.id);
                if (matchedSource) {
                    return matchedSource;
                }        
            });
            return activeDataSources;
        }
    }
  
    
    useEffect(() => {
        setValidInfoList(makeActiveInfoLists());
        setValidDataLabels(makeActiveDataLabels());
        setValidCurrentProfiles(makeActiveProfiles());
        setValidDataSources(makeActiveDataSources());
    }, [scenarioActiveProfiles, scenarioActiveInfoLists, scenarioActiveDataLabels,scenarioActiveDataSources] );


    
    
    const getTextContent = () => {
        if (editorRef.current) {
        
            return editorRef.current.textContent;
        }
        return '';
    };
      
    const focusEditor = () => {
        if (editorRef.current) {
            editorRef.current.focus();
        }
    };

    const normalizeFieldName = (name) => name.replace(/_/g, '').toLowerCase();

    const handleInputChange = () => {
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        const textBeforeCursor = range.startContainer.textContent.slice(0, range.startOffset).trim();
        if (textBeforeCursor.endsWith('/')) {
            setIsDotContext(false);
            setIsSlashContext(true);
            showSuggestionsForSlash("");
        }
        else if(isSlashContext){
            const lastPart = textBeforeCursor.split('/').pop();
            setFilterText(lastPart);
            showSuggestionsForSlash(lastPart);
        }
         else if (textBeforeCursor.endsWith('.')) {
            setIsDotContext(true);
            setIsSlashContext(false);
            setFilterText("");
            showSuggestionsForDot("");
        }
        else if (isDotContext) {
            const lastPart = textBeforeCursor.split('.').pop();
            setFilterText(lastPart);
            showSuggestionsForDot(lastPart);
        }
        else {
            setShowSuggestions(false);
        }
            // Llamar a onChange con el contenido actual
    if (onChange) {
        const currentContent = getTextContent(); // O el método que prefieras para obtener el contenido
        onChange(currentContent);
    }
    };



    useEffect(() => {
        const handleClickOutside = (event) => {
          if (
            suggestionsBoxRef.current &&
            !suggestionsBoxRef.current.contains(event.target) &&
            editorRef.current &&
            !editorRef.current.contains(event.target)
          ) {
            setShowSuggestions(false);
          }
        };
      
        document.addEventListener('mousedown', handleClickOutside);
      
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [suggestionsBoxRef, editorRef]);


      

    const parseDefinitionToBadges = () => {
        if (!scenarioDetails || !scenarioDetails.definition) return;
        const definition = scenarioDetails.definition;
        const parts = definition.split(/(\s+|\=|\[|\]|\(|\)|\.|\,)/);
    
        // Si no es editable, genera el contenido HTML directamente.
        if (!isEdit) {
            let htmlContent = ''; // Almacena el HTML generado
    
          
            parts.forEach((part, index) => {
                if (part.trim() && !["=", " "].includes(part)) {
                    let badgeDetails = null;
                    if (scenarioDetails.data_source && 
                        (part === scenarioDetails.data_source.name || 
                        normalizeFieldName(part) === normalizeFieldName(scenarioDetails.data_source.name))) {
                        badgeDetails = { 
                            name: scenarioDetails.data_source.name, 
                            color: "#027A48", 
                            bgColor: "#ECFDF3", 
                            hasBullet: true ,
                            bulletColor: "#4FC795"
                        };
                    } else if (scenarioDetails.list && 
                        scenarioDetails.list.some(list => part.includes(list.name))) {
                        const matchedList = scenarioDetails.list.find(list => part.includes(list.name));
                        badgeDetails = { 
                            name: matchedList.name, 
                            color: "#B42318", 
                            bgColor: "#FEF3F2", 
                            hasBullet: true,
                            bulletColor: "#FF7065"
                        };
                    }

                 

                     else {
                        const matchingDataField = dataSources
                            .flatMap(ds => ds.datafields)
                            .find(field => normalizeFieldName(field.name) === normalizeFieldName(part));
    
                        const matchingListColumn = infoLists
                            .flatMap(list => list.list_columns)
                            .find(column => normalizeFieldName(column.name) === normalizeFieldName(part));
    
                        if (matchingDataField) {
                            badgeDetails = {
                                name: matchingDataField.name, 
                                color: "#027A48", 
                                bgColor: "#ECFDF3", 
                                hasBullet: false,
                                bulletColor: null
                            };
                        } else if (matchingListColumn) {
                            badgeDetails = { 
                                name: matchingListColumn.name, 
                                color: "#B42318", 
                                bgColor: "#FEF3F2", 
                                hasBullet: false,
                                bulletColor: null
                             };
                        }
                    }
    
                    if (badgeDetails) {
                        htmlContent += `
                            <span class="${styles.badge}" style="background-color: ${badgeDetails.bgColor}; color: ${badgeDetails.color}; padding: 6px 16px; border-radius: 15px; margin-right: 4px; display: inline-block; white-space: nowrap; vertical-align: middle;">
                                ${badgeDetails.hasBullet ? `<span style="display: inline-block; vertical-align: middle; margin-right: 4px; width: 8px; height: 8px; border-radius: 50%; background-color: ${badgeDetails.bulletColor};"></span>` : ''}
                                ${badgeDetails.name}
                            </span>`;
                    } else {
                        htmlContent += `<span>${part}</span>`;
                    }
                } else {
                    htmlContent += `<span>${part}</span>`;
                }
            });
    
            editorRef.current.innerHTML = htmlContent; // Asigna el contenido HTML al editor
        } else {
            // Modo de edición: inserta badges y texto en el editor usando DOM API.
            parts.forEach((part, index) => {
                if (part.trim() && !["=", " "].includes(part)) {
                    let badgeDetails = null;
                    if (scenarioDetails.data_source && 
                        (part === scenarioDetails.data_source.name || 
                        normalizeFieldName(part) === normalizeFieldName(scenarioDetails.data_source.name))) {
                        badgeDetails = { 
                            name: scenarioDetails.data_source.name, 
                            color: "#027A48", 
                            bgColor: "#ECFDF3", 
                            hasBullet: true ,
                            bulletColor: "#4FC795"
                        };
                    } else if (scenarioDetails.list && 
                        scenarioDetails.list.some(list => part.includes(list.name))) {
                        const matchedList = scenarioDetails.list.find(list => part.includes(list.name));
                        badgeDetails = { 
                            name: matchedList.name, 
                            color: "#B42318", 
                            bgColor: "#FEF3F2", 
                            hasBullet: true,
                            bulletColor: "#FF7065"
                        };
                    }
                    else if (
                        profiles.some(profile => part.includes(profile.name))) {
                            console.log("profiles: ", profiles, part);
                    
                        const matchedList = profiles.find(profile2 => part.includes(profile2.name));
                        badgeDetails = { 
                            name: matchedList.name, 
                            color: "#026aa2", 
                            bgColor: "rgba(2, 106, 162, 0.2)", 
                            hasBullet: false,
                            bulletColor: "#FF7065"
                        };
                    }
                    else if (
                        infoLists.some(info => part.includes(info.name))) {
                            console.log("info: ", infoLists, part);
                    
                        const matchedList = infoLists.find(info2 => part.includes(info2.name));
                        badgeDetails = { 
                            name: matchedList.name, 
                            color: "#B42318", 
                            bgColor: "#FEF3F2", 
                            hasBullet: true,
                            bulletColor: "#FF7065"
                        };
                    }
                    
                    else {
                        const matchingDataField = dataSources
                            .flatMap(ds => ds.datafields)
                            .find(field => normalizeFieldName(field.name) === normalizeFieldName(part));
    
                        const matchingListColumn = infoLists
                            .flatMap(list => list.list_columns)
                            .find(column => normalizeFieldName(column.name) === normalizeFieldName(part));
    
                        if (matchingDataField) {
                            badgeDetails = {
                                name: matchingDataField.name, 
                                color: "#027A48", 
                                bgColor: "#ECFDF3", 
                                hasBullet: false,
                                bulletColor: null
                            };
                        } else if (matchingListColumn) {
                            badgeDetails = { 
                                name: matchingListColumn.name, 
                                color: "#B42318", 
                                bgColor: "#FEF3F2", 
                                hasBullet: false,
                                bulletColor: null
                             };
                        }
                    }
    
                    if (badgeDetails) {
                        insertBadge(badgeDetails);
                    } else {
                        insertText(part);
                    }
                } else {
                    insertText(part);
                }
            });
        }
    };
    

    const insertText = (text) => {
        const range = document.createRange();
        const selection = window.getSelection();

        range.selectNodeContents(editorRef.current);
        range.collapse(false);
        const textNode = document.createTextNode(text);
        range.insertNode(textNode);

        range.setStartAfter(textNode);
        range.setEndAfter(textNode);
        selection.removeAllRanges();
        selection.addRange(range);
    };

    const insertBadge = (badge) => {
        focusEditor();

        const selection = window.getSelection();
        if (!selection || selection.rangeCount === 0) {

            console.error("No valid selection found for inserting the badge.");
            return;
        }
    
        const range = selection.getRangeAt(0);
        if (!range || !editorRef.current.contains(range.startContainer)) {
            console.error("Range is invalid or not contained within the editor.");
            return;
        }


        const badgeElement = document.createElement('span');
        badgeElement.className = styles.badge;
        badgeElement.style.backgroundColor = badge.bgColor;
        badgeElement.style.color = badge.color;
        badgeElement.style.padding = '6px 16px';
        badgeElement.style.borderRadius = '15px';
        badgeElement.style.marginRight = '4px';
        badgeElement.style.display = 'inline-block';
        badgeElement.style.whiteSpace = 'nowrap';
        badgeElement.style.verticalAlign = 'middle';

        badgeElement.style.userSelect = 'none'; // Prevent text selection

        
        badgeElement.innerHTML = badge.hasBullet
            ? `<span style="display: inline-block; vertical-align: middle; margin-right: 4px; width: 8px; height: 8px; border-radius: 50%; background-color: ${badge.bulletColor};"></span>${badge.name}`
            : `${badge.name}`;
        badgeElement.setAttribute('contenteditable', 'false');

        const spaceAfter = document.createTextNode('\u00A0');

        if (range.startContainer.nodeType === Node.TEXT_NODE) {
            const textNode = range.startContainer;
            const offset = range.startOffset;
            const textBefore = textNode.nodeValue.slice(0, offset);
            const textAfter = textNode.nodeValue.slice(offset);

            const beforeNode = document.createTextNode(textBefore);
            const afterNode = document.createTextNode(textAfter);

            const parentNode = textNode.parentNode;
            parentNode.insertBefore(beforeNode, textNode);
            parentNode.insertBefore(badgeElement, textNode);
            parentNode.insertBefore(spaceAfter, textNode);
            parentNode.insertBefore(afterNode, textNode);

            parentNode.removeChild(textNode);

            range.setStartAfter(spaceAfter);
            range.setEndAfter(spaceAfter);
        } else {
            range.deleteContents();
            range.insertNode(spaceAfter);
            range.insertNode(badgeElement);

            range.setStartAfter(spaceAfter);
            range.setEndAfter(spaceAfter);
        }

        setBadges([...badges, badge]);

        selection.removeAllRanges();
        selection.addRange(range);

        editorRef.current.focus();
    };

    const saveSelection = () => {
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          setSavedRange(range);
        }
      };

      const getCaretCoordinates = () => {
        const selection = window.getSelection();
        if (!selection.rangeCount) return null;
      
        const range = selection.getRangeAt(0).cloneRange();
      
        // Collapse the range to the caret's position
        range.collapse(false);
      
        // Get the client rects of the range
        const rects = range.getClientRects();
        if (rects.length === 0) {
          return null;
        }
      
        // Get the last rectangle, which represents the caret position
        const rect = rects[rects.length - 1];
      
        // Get the position of the editor container
        const editorRect = editorRef.current.getBoundingClientRect();
      
        // Get the computed style of the editor container
        const editorStyles = window.getComputedStyle(editorRef.current);
        const paddingLeft = parseFloat(editorStyles.paddingLeft);
        const paddingTop = parseFloat(editorStyles.paddingTop);
      
        // Calculate position relative to the editor container, accounting for padding
        const relativeTop =
          rect.top - editorRect.top - paddingTop + editorRef.current.scrollTop;
        const relativeLeft =
          rect.left - editorRect.left - paddingLeft + editorRef.current.scrollLeft;
      
        // Return the coordinates
        return { top: relativeTop +30, left: relativeLeft };
      };
      
      
      const adjustSuggestionsPosition = (position) => {
        const { top, left } = position;
        const suggestionsBoxWidth = suggestionsBoxRef.current?.offsetWidth || 200; // Assume a default width
        const editorRect = editorRef.current.getBoundingClientRect();
        const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    
        // Calculate the absolute left position of the suggestions box
        const absoluteLeft = editorRect.left + left;
    
        // Check if the suggestions box would go off-screen to the right
        if (absoluteLeft + suggestionsBoxWidth > viewportWidth) {
          // Adjust the left position to align the suggestions box's right edge with the caret
          const adjustedLeft = left - suggestionsBoxWidth;
    
          // Ensure the suggestions box doesn't go off-screen to the left
          const finalLeft = Math.max(adjustedLeft, 0);
    
          return { top, left: finalLeft };
        }
    
        // If no adjustment is needed, return the original position
        return position;
      };
    


    const showSuggestionsForSlash = (filterText = "") => {

        saveSelection(); // Save the selection before showing suggestions
        let filteredSuggestions = [];
                
            const selectedDataSource = Array.isArray(validDataSources) ? validDataSources.flatMap(dataLabel =>
                dataLabel ? [{ id: dataLabel.id, name: dataLabel.name }] : []
            ) : [];


            const selectedInfoLists = Array.isArray(scenarioDetails.scenario_lists) ? scenarioDetails.scenario_lists.flatMap(scenarioList =>
                Array.isArray(scenarioList) ? scenarioList.flatMap(listItem =>
                    listItem?.list ? [{ id: listItem.list.id, name: listItem.list.name }] : []
                ) : []
            ) : [];

            const selectedProfiles = Array.isArray(scenarioDetails.scenario_profiles) ? scenarioDetails.scenario_profiles.flatMap(profileList =>
                Array.isArray(profileList) ? profileList.flatMap(profile =>
                    profile?.profile_field ? [{ id: profile.profile_field.id, name: profile.profile_field.name }] : []
                ) : []
            ) : [];


            const selectedDataLabels = Array.isArray(validDataLabels) ? validDataLabels.flatMap(dataLabel =>
                dataLabel ? [{ id: dataLabel.id, name: dataLabel.name }] : []
            ) : [];

       
            

            if (filteredSuggestions.length === 0) {
            // Actualizar dynamicSuggestions usando la nueva lógica
             filteredSuggestions = [
                ...selectedDataSource.map(source => ({
                    name: source.name,
                    type: 'data_source',
                    color: "#027A48",
                    bgColor: "#ECFDF3",
                    hasBullet: true,
                    bulletColor: "#4FC795"
                })),
              
                ...selectedDataLabels.map(source => ({
                    name: source.name,
                    type: 'list',
                    color: "#027A48",
                    bgColor: "#ECFDF3",
                    hasBullet: false,
                   
                })),
                ...selectedInfoLists.map(list => ({
                    name: list.name,
                    type: 'list',
                    color: "#B42318",
                    bgColor: "#FEF3F2",
                    hasBullet: true,
                    bulletColor: "#FF7065"
                })),

             
                ...selectedProfiles.map(profile => ({
                    name: profile.name,
                    type: 'profile',
                    color: "#026aa2",  // Ejemplo de color para perfiles
                    bgColor: "#026aa233",  // Ejemplo de fondo para perfiles
                    hasBullet: false,
                    bulletColor: "#63B3ED"  // Ejemplo de color de bullet para perfiles
                }))
            ];
            }


            console.log("badgesSelection: ", badgesSelection);  // datta not coming !!!!


            filteredSuggestions = [ ...filteredSuggestions, ...badgesSelection.map(badge => ({ name: badge.text, type: 'badge', color: badge.color, bgColor: badge.bgColor, hasBullet: badge.hasBullet, bulletColor: badge.color === "#027A48" ? "#4FC795" : badge.color === "#B42318" ?  "#FF7065"  : "#000" }))];
       
            //filtering out "not selectted" badge  and empty badges
            filteredSuggestions = filteredSuggestions.filter(suggestion => suggestion.name !== "Not Selected" && suggestion.name.trim() !== "" && suggestion.name.trim() !== " ");
    
            // removing duplicates 
            filteredSuggestions = filteredSuggestions.filter((v,i,a)=>a.findIndex(t=>(t.name === v.name))===i); 


            if (filterText) {
                filteredSuggestions = filteredSuggestions.filter(suggestion =>
                    suggestion.name.toLowerCase().includes(filterText.toLowerCase())
                );
            }

        setCurrentSuggestions(filteredSuggestions);
        setShowSuggestions(true);
        setHighlightedIndex(0); // Resetear el índice destacado al mostrar nuevas sugerencias

         // Get the caret position
              // Get the caret position
        const caretRect = getCaretCoordinates();
        if (caretRect) {
            const adjustedPosition = adjustSuggestionsPosition({
            top: caretRect.top,
            left: caretRect.left,
            });
            setSuggestionsPosition(adjustedPosition);
        }
  

           
    };

    const showSuggestionsForDot = (filterText = "") => {
        saveSelection(); // Save the selection before showing suggestions
        const lastBadge = badges[badges.length - 1];
        let filteredSuggestions = [];

        if (lastBadge) {
            filteredSuggestions = getDynamicDatafieldsSuggestions(lastBadge.name);
        }

        if (filteredSuggestions.length === 0) {
            // Si no hay sugerencias específicas para el badge seleccionado, mostrar todas las sugerencias posibles
            filteredSuggestions = [
                ...(scenarioDetails?.data_source ? scenarioDetails.data_source.datafields.map(field => ({
                    name: field.name,
                    color: "#027A48",
                    bgColor: "#ECFDF3",
                    hasBullet: false
                })) : []),
                ...(scenarioDetails?.list ? scenarioDetails.list.flatMap(list => list.list_columns.map(column => ({
                    name: column.name,
                    color: "#B42318",
                    bgColor: "#FEF3F2",
                    hasBullet: false
                }))) : [])
            ];
        }

        // Filtrar las sugerencias basadas en el texto ingresado después del punto
        if (filterText) {
            filteredSuggestions = filteredSuggestions.filter(suggestion =>
                suggestion.name.toLowerCase().includes(filterText.toLowerCase())
            );
        }

        setCurrentSuggestions(filteredSuggestions);
        console.log("FILTERED SUGGESTIONS FOR DOT: ", filteredSuggestions);
        setShowSuggestions(filteredSuggestions.length > 0); // Mostrar solo si hay sugerencias
        setHighlightedIndex(0); // Resetear el índice destacado al mostrar nuevas sugerencias

         // Get the caret position
        const caretRect = getCaretCoordinates();
        if (caretRect) {
            const adjustedPosition = adjustSuggestionsPosition({
              top: caretRect.top,
              left: caretRect.left,
            });
            setSuggestionsPosition(adjustedPosition);
          }
    };

    // Define getDynamicDatafieldsSuggestions function
    const getDynamicDatafieldsSuggestions = (selectedName) => {

        console.log("dynamic data fields suggestions: ", selectedName, dataSources, infoLists);
        const suggestions = [];
        const selectedSource = dataSources.find(ds => ds.name === selectedName);
        if (selectedSource && selectedSource.datafields) {
            suggestions.push(...selectedSource.datafields.map(field => ({
                name: field.name,
                color: "#027A48",
                bgColor: "#ECFDF3",
                hasBullet: false
            })));
        }

        const selectedList = infoLists.find(list => list.name === selectedName);
        if (selectedList && selectedList.list_columns) {
            suggestions.push(...selectedList.list_columns.map(column => ({
                name: column.name,
                color: "#B42318",
                bgColor: "#FEF3F2",
                hasBullet: false
            })));
        }
        return suggestions;
    };

    
    const handleSuggestionClick = (suggestion) => {
        const selection = window.getSelection();
      
        // Restore the saved selection if necessary
        if (
          !selection ||
          selection.rangeCount === 0 ||
          !editorRef.current.contains(selection.anchorNode)
        ) {
          if (savedRange) {
            selection.removeAllRanges();
            selection.addRange(savedRange);
          } else {
            console.error(
              "No valid selection found, and no saved selection to restore."
            );
            return;
          }
        }
      
        const range = selection.getRangeAt(0);
      
        if (range.startContainer.nodeType === Node.TEXT_NODE) {
          const textNode = range.startContainer;
          const offset = range.startOffset;
          const textBeforeCursor = textNode.nodeValue.slice(0, offset);
          const textAfterCursor = textNode.nodeValue.slice(offset);
      
          const slashIndex = textBeforeCursor.lastIndexOf('/');
          const dotIndex = textBeforeCursor.lastIndexOf('.');
      
          let delimiterIndex = -1;
      
          if (slashIndex > dotIndex) {
            delimiterIndex = slashIndex;
          } else {
            delimiterIndex = dotIndex + 1;
          }
      
          if (delimiterIndex >= 0) {
            const beforeDelimiter = textNode.nodeValue.slice(0, delimiterIndex);
            const afterCursor = textAfterCursor;
      
            // Update the text node content
            textNode.nodeValue = beforeDelimiter + afterCursor;
      
            // Adjust the cursor position
            const newOffset = beforeDelimiter.length;
            range.setStart(textNode, newOffset);
            range.setEnd(textNode, newOffset);
          }
        }
      
        insertBadge(suggestion);
        setShowSuggestions(false);
        setFilterText("");
        setIsDotContext(false);
        setIsSlashContext(false);
      };
      

    const handleBackspace = (e) => {
        const selection = window.getSelection();
        if (selection.rangeCount === 0) return;
    
        const range = selection.getRangeAt(0);
    
        let nodeBeforeCursor = null;
    
        if (range.startContainer.nodeType === Node.TEXT_NODE) {
            const textNode = range.startContainer;
            if (range.startOffset > 0) {
                // There is text before the cursor in the same text node
                nodeBeforeCursor = textNode;
            } else {
                // At the start of the text node
                nodeBeforeCursor = textNode.previousSibling;
            }
        } else if (range.startContainer.nodeType === Node.ELEMENT_NODE) {
            if (range.startOffset > 0) {
                // There are child nodes before the cursor
                nodeBeforeCursor = range.startContainer.childNodes[range.startOffset - 1];
            } else {
                // At the start of the element
                nodeBeforeCursor = range.startContainer.previousSibling;
            }
        }
    
        if (!nodeBeforeCursor) {
            // Cursor is at the very start, nothing to delete
            e.preventDefault();
            return;
        }
    
        // Now check if nodeBeforeCursor is a badge
        if (nodeBeforeCursor.nodeType === Node.ELEMENT_NODE && nodeBeforeCursor.classList.contains(styles.badge)) {
            e.preventDefault();
            // Remove the badge
            nodeBeforeCursor.remove();
    
            // Also remove any following non-breaking space
            const nextSibling = nodeBeforeCursor.nextSibling;
            if (nextSibling && nextSibling.nodeType === Node.TEXT_NODE && nextSibling.nodeValue === '\u00A0') {
                nextSibling.remove();
            }
    
            setBadges(badges.filter(b => b.name !== nodeBeforeCursor.textContent.trim()));
    
            // Move cursor to the correct position
            const newRange = document.createRange();
            newRange.setStart(range.startContainer, range.startOffset);
            newRange.collapse(true);
    
            selection.removeAllRanges();
            selection.addRange(newRange);
    
            editorRef.current.focus();
        } else if (nodeBeforeCursor.nodeType === Node.TEXT_NODE) {
            // If the text node is empty or contains only a non-breaking space, check previous node
            if (nodeBeforeCursor.nodeValue === '\u00A0' || nodeBeforeCursor.nodeValue === '') {
                const badgeNode = nodeBeforeCursor.previousSibling;
                if (badgeNode && badgeNode.nodeType === Node.ELEMENT_NODE && badgeNode.classList.contains(styles.badge)) {
                    e.preventDefault();
                    // Remove badge and text node
                    badgeNode.remove();
                    nodeBeforeCursor.remove();
    
                    setBadges(badges.filter(b => b.name !== badgeNode.textContent.trim()));
    
                    // Move cursor to the correct position
                     // Get the caret position
  const caretRect = getCaretCoordinates();
  if (caretRect) {
    setSuggestionsPosition({
      top: caretRect.top,
      left: caretRect.left,
    });
  }

                    editorRef.current.focus();
                }
            }
        }
    };
    
    

    const scrollToHighlighted = (index) => {
        if (suggestionsBoxRef.current) {
            const highlightedElement = suggestionsBoxRef.current.children[index];
            const suggestionsBox = suggestionsBoxRef.current;

            const boxHeight = suggestionsBox.clientHeight;
            const itemTop = highlightedElement.offsetTop;
            const itemBottom = itemTop + highlightedElement.clientHeight;

            if (itemTop < suggestionsBox.scrollTop) {
                suggestionsBox.scrollTop = itemTop;
            } else if (itemBottom > suggestionsBox.scrollTop + boxHeight) {
                suggestionsBox.scrollTop = itemBottom - boxHeight;
            }
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Backspace') {
            handleBackspace(e);
        } else if (e.key === 'Enter' && showSuggestions) {
            e.preventDefault();
            handleSuggestionClick(currentSuggestions[highlightedIndex]);
        } else if (e.key === 'ArrowDown' && showSuggestions) {
            e.preventDefault();
            setHighlightedIndex((prevIndex) => {
                const newIndex = (prevIndex + 1) % currentSuggestions.length;
                scrollToHighlighted(newIndex);
                return newIndex;
            });
        } else if (e.key === 'ArrowUp' && showSuggestions) {
            e.preventDefault();
            setHighlightedIndex((prevIndex) => {
                const newIndex = prevIndex === 0 ? currentSuggestions.length - 1 : prevIndex - 1;
                scrollToHighlighted(newIndex);
                return newIndex;
            });
        } else if (isDotContext && e.key !== '.' && e.key.length === 1) {
            setFilterText(prev => prev + e.key);
            showSuggestionsForDot(filterText + e.key);
        } else {
            handleInputChange();
        }
    };

    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.textContent = '';  // Limpia el contenido antes de cada parseo.
            parseDefinitionToBadges();
        }
    }, [isEdit, scenarioDetails]);

   

    return (
        <div className={styles.editorWrapper} style={{ position: 'relative' }}>
        <div
            ref={editorRef}
            className={styles.editorContainer}
            contentEditable={isEdit}  // Condiciona la edición según isEdit
            onInput={isEdit ? handleInputChange : null}  // Solo permite onInput si es editable
            onKeyDown={isEdit ? handleKeyDown : null}  // Solo permite onKeyDown si es editable
            placeholder={isEdit ? "Type your formula..." : ""}  // Placeholder solo cuando es editable
            suppressContentEditableWarning={true}
            style={{
                minHeight: '40px',
                padding: '8px',
                borderRadius: '8px',
                outline: 'none',
                whiteSpace: 'pre-line',
                
            }}
        ></div>
        {showSuggestions && isEdit && (
            <div className={styles.suggestionsBox} ref={suggestionsBoxRef}  style={{ position: 'absolute',top: suggestionsPosition.top,left: suggestionsPosition.left, zIndex: 1000,               }}>
                {currentSuggestions.map((suggestion, index) => (
                    <div
                        key={index}
                        className={styles.suggestionItem}
                        onClick={() => handleSuggestionClick(suggestion)}
                        style={{
                            cursor: 'pointer',
                            padding: '6px 16px',
                            backgroundColor: highlightedIndex === index ? '#fcfaff' : 'transparent',

                        }}
                    >
                        <Badge
                            text={suggestion.name}
                            color={suggestion.color || '#027A48'}
                            bgColor={suggestion.bgColor || '#ECFDF3'}
                            hasBullet={suggestion.hasBullet || false}
                        />
                    </div>
                ))}
            </div>
        )}
    </div>
    
    );
};

export default CustomEditor;
