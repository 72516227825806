// src/store/store.js
import {
  configureStore,
  combineReducers,
  createListenerMiddleware,
} from "@reduxjs/toolkit";
import atlToolReducer from "../features/atl_tool/atlToolSlice";
import scenarioOverviewReducer from "../features/scenario_overview/scenarioOverviewSlice";
import overlapAnalysisReducer from "../features/overlap_analysis/overlapAnalysisSlice";
import miblReportReducer from "../features/mibl_report/miblReportSlice";
import allAlertsReducer from "../features/all_alerts/allAlertsSlice";
import usersSliceReducer from "../features/users/usersSlice";
import authReducer, { loginUser, fetchUser } from "../features/auth/authSlice";
import configurationReducer from "../features/configuration/configurationSlice";
import reportBuilderReducer from "../features/report_builder/reportBuilderSlice";
import sharedListReducer from "../features/shared_list/sharedListSlice";
import btlReducer from "../features/btl_tool/btlToolSlice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web
import apiClient from "../axiosConfig"; // Importa tu configuración de axios

// Configure persist settings
const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "auth",
    "atlTool",
    "overlapAnalysis",
    "btlTool",
    "reportBuilder",
  ], // List of reducers you want to persist
};

// Combine your reducers
const rootReducer = combineReducers({
  auth: authReducer,
  atlTool: atlToolReducer,
  scenarioPerformance: scenarioOverviewReducer,
  overlapAnalysis: overlapAnalysisReducer,
  miblReport: miblReportReducer,
  allAlerts: allAlertsReducer,
  users: usersSliceReducer,
  configuration: configurationReducer,
  reportBuilder: reportBuilderReducer,
  sharedList: sharedListReducer,
  btlTool: btlReducer,
});

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create Listener Middleware
const listenerMiddleware = createListenerMiddleware();

// Define the listener for loginUser.fulfilled
listenerMiddleware.startListening({
  actionCreator: loginUser.fulfilled,
  effect: async (action, listenerApi) => {
    const { access_token } = action.payload;
    // Set the authorization header for future requests
    apiClient.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${access_token}`;
    // Dispatch fetchUser to load the user details
    await listenerApi.dispatch(fetchUser());
  },
});

// Configure the store with the persisted reducer and middleware
export const store = configureStore({
  reducer: persistedReducer, // Use persistedReducer here
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).prepend(listenerMiddleware.middleware), // Add the listener middleware
});

export const persistor = persistStore(store);
