import React, { useEffect } from "react";
import styles from "./NavBar.module.css"; // Asegúrate de importar tu CSS module
import { useSelector, useDispatch } from "react-redux";
import { setNavBarIsCollapsed } from "../features/atl_tool/atlToolSlice";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { logoutUser, fetchUser } from "../features/auth/authSlice";
import { fetchCurrentReport } from "../features/report_builder/reportBuilderSlice";
import { env } from "../env";

const NavBar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const isCollapsed = useSelector((state) => state.atlTool.navBarIsCollapsed);
  const addToReportCount = useSelector(
    (state) => state.reportBuilder.addToReportCount
  );
  const currentReportId = useSelector(
    (state) => state.reportBuilder.currentReportId
  );
  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated && !user) {
      dispatch(fetchUser());
    }
  }, [isAuthenticated, user, dispatch]);

  useEffect(() => {
    if (user && user.id) {
      dispatch(fetchCurrentReport(user.id));
    }
  }, [user, dispatch]);

  const handleLogoClick = () => {
    dispatch(setNavBarIsCollapsed(!isCollapsed));
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/login");
  };

  const isReportsActive =
    location.pathname === "/reports" ||
    (location.pathname.startsWith("/reports/") &&
      location.pathname !== `/reports/${currentReportId}`);

  const isReportBuilderActive =
    currentReportId && location.pathname === `/reports/${currentReportId}`;

  return (
    <div
      className={`${styles.navbarV2} ${isCollapsed ? styles.collapsed : ""}`}>
      <div className={styles.reformLogoParent}>
        <div
          className={`${styles.reformLogo} ${
            isCollapsed ? styles.collapsed : ""
          }`}
          onClick={handleLogoClick}>
          <img
            className={styles.reformIcon}
            alt=""
            src={`${process.env.PUBLIC_URL}/logo.svg`}
          />
          <div
            className={`${styles.reform} ${
              isCollapsed ? styles.collapsed : ""
            }`}>
            reform
          </div>
        </div>
        <div className={styles.navbarBoxParent}>
          <NavLink
            exact="true"
            to="/scenario-overview"
            className={({ isActive }) =>
              isActive ? styles.activeNavLink : styles.navLink
            }>
            <div className={styles.navbarBox}>
              <svg
                stroke="white"
                className={styles.homeIcon}
                width="30"
                height="31"
                viewBox="0 0 30 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.75 3.75V23.75C3.75 24.413 4.01339 25.0489 4.48223 25.5178C4.95107 25.9866 5.58696 26.25 6.25 26.25H26.25"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.75 16.25H10C9.30964 16.25 8.75 16.8096 8.75 17.5V20C8.75 20.6904 9.30964 21.25 10 21.25H18.75C19.4404 21.25 20 20.6904 20 20V17.5C20 16.8096 19.4404 16.25 18.75 16.25Z"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22.5 6.25H10C9.30964 6.25 8.75 6.80964 8.75 7.5V10C8.75 10.6904 9.30964 11.25 10 11.25H22.5C23.1904 11.25 23.75 10.6904 23.75 10V7.5C23.75 6.80964 23.1904 6.25 22.5 6.25Z"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div
                className={`${styles.homepage} ${
                  isCollapsed ? styles.collapsed : ""
                }`}>
                Scenario Performance
              </div>
            </div>
          </NavLink>

          <NavLink
            exact="true"
            to="/atl"
            className={({ isActive }) =>
              isActive ? styles.activeNavLink : styles.navLink
            }>
            <div className={styles.navbarBox}>
              <svg
                stroke="white"
                className={styles.homeIcon}
                width="30"
                height="31"
                viewBox="0 0 30 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.375 10C9.72018 10 10 9.72018 10 9.375C10 9.02982 9.72018 8.75 9.375 8.75C9.02982 8.75 8.75 9.02982 8.75 9.375C8.75 9.72018 9.02982 10 9.375 10Z"
                  fill="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M23.125 7.5C23.4702 7.5 23.75 7.22018 23.75 6.875C23.75 6.52982 23.4702 6.25 23.125 6.25C22.7798 6.25 22.5 6.52982 22.5 6.875C22.5 7.22018 22.7798 7.5 23.125 7.5Z"
                  fill="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.375 15C14.7202 15 15 14.7202 15 14.375C15 14.0298 14.7202 13.75 14.375 13.75C14.0298 13.75 13.75 14.0298 13.75 14.375C13.75 14.7202 14.0298 15 14.375 15Z"
                  fill="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.375 21.25C9.72018 21.25 10 20.9702 10 20.625C10 20.2798 9.72018 20 9.375 20C9.02982 20 8.75 20.2798 8.75 20.625C8.75 20.9702 9.02982 21.25 9.375 21.25Z"
                  fill="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.875 18.75C22.2202 18.75 22.5 18.4702 22.5 18.125C22.5 17.7798 22.2202 17.5 21.875 17.5C21.5298 17.5 21.25 17.7798 21.25 18.125C21.25 18.4702 21.5298 18.75 21.875 18.75Z"
                  fill="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.75 3.75V23.75C3.75 24.413 4.01339 25.0489 4.48223 25.5178C4.95107 25.9866 5.58696 26.25 6.25 26.25H26.25"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div
                className={`${styles.homepage} ${
                  isCollapsed ? styles.collapsed : ""
                }`}>
                ATL
              </div>
            </div>
          </NavLink>

          <NavLink
            exact="true"
            to="/btl"
            className={({ isActive }) =>
              isActive ? styles.activeNavLink : styles.navLink
            }>
            <div className={styles.navbarBox}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={styles.homeIcon}
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none">
                <g id="BTL Icon">
                  <path
                    id="Vector"
                    d="M15.4199 2H6.41992C5.88949 2 5.38078 2.21071 5.00571 2.58579C4.63064 2.96086 4.41992 3.46957 4.41992 4V20C4.41992 20.5304 4.63064 21.0391 5.00571 21.4142C5.38078 21.7893 5.88949 22 6.41992 22H18.4199C18.9504 22 19.4591 21.7893 19.8341 21.4142C20.2092 21.0391 20.4199 20.5304 20.4199 20V7L15.4199 2Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_2"
                    d="M14.4199 2V6C14.4199 6.53043 14.6306 7.03914 15.0057 7.41421C15.3808 7.78929 15.8895 8 16.4199 8H20.4199"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_3"
                    d="M8.41992 18V16"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_4"
                    d="M12.4199 18V14"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_5"
                    d="M16.4199 18V12"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </svg>

              <div
                className={`${styles.homepage} ${
                  isCollapsed ? styles.collapsed : ""
                }`}>
                BTL
              </div>
            </div>
          </NavLink>
          <NavLink
            to="/overlap-analysis"
            className={({ isActive }) =>
              isActive ? styles.activeNavLink : styles.navLink
            }>
            <div className={styles.navbarBox}>
              <svg
                stroke="white"
                className={styles.homeIcon}
                width="30"
                height="31"
                viewBox="0 0 30 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.25 20C16.0825 20 20 16.0825 20 11.25C20 6.41751 16.0825 2.5 11.25 2.5C6.41751 2.5 2.5 6.41751 2.5 11.25C2.5 16.0825 6.41751 20 11.25 20Z"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.75 27.5C23.5825 27.5 27.5 23.5825 27.5 18.75C27.5 13.9175 23.5825 10 18.75 10C13.9175 10 10 13.9175 10 18.75C10 23.5825 13.9175 27.5 18.75 27.5Z"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div
                className={`${styles.homepage} ${
                  isCollapsed ? styles.collapsed : ""
                }`}>
                Overlap Analysis
              </div>
            </div>
          </NavLink>

          <NavLink
            // to="/configuration"
            to="/configurationTable"
            className={({ isActive }) =>
              isActive ? styles.activeNavLink : styles.navLink
            }>
            <div className={styles.navbarBox}>
              <svg
                stroke="white"
                className={styles.homeIcon}
                width="30"
                height="31"
                viewBox="0 0 30 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M27.05 4.54853L25.45 2.94853C25.3094 2.80643 25.1419 2.69362 24.9574 2.61663C24.7729 2.53964 24.5749 2.5 24.375 2.5C24.1751 2.5 23.9771 2.53964 23.7926 2.61663C23.6081 2.69362 23.4406 2.80643 23.3 2.94853L2.95 23.2985C2.80789 23.4392 2.69508 23.6066 2.61809 23.7911C2.54111 23.9756 2.50146 24.1736 2.50146 24.3735C2.50146 24.5735 2.54111 24.7714 2.61809 24.9559C2.69508 25.1405 2.80789 25.3079 2.95 25.4485L4.55 27.0485C4.68977 27.1922 4.85691 27.3063 5.04155 27.3843C5.22619 27.4622 5.42458 27.5024 5.625 27.5024C5.82541 27.5024 6.0238 27.4622 6.20844 27.3843C6.39308 27.3063 6.56022 27.1922 6.7 27.0485L27.05 6.69853C27.1936 6.55875 27.3078 6.39161 27.3858 6.20697C27.4637 6.02234 27.5039 5.82395 27.5039 5.62353C27.5039 5.42311 27.4637 5.22472 27.3858 5.04009C27.3078 4.85545 27.1936 4.68831 27.05 4.54853Z"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.5 8.75L21.25 12.5"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.25 7.5V12.5"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M23.75 17.5V22.5"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.5 2.5V5"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.75 10H3.75"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M26.25 20H21.25"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.75 3.75H11.25"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div
                className={`${styles.homepage} ${
                  isCollapsed ? styles.collapsed : ""
                }`}>
                Configuration
              </div>
            </div>
          </NavLink>

          <NavLink
            to="/alerts"
            className={({ isActive }) =>
              isActive ? styles.activeNavLink : styles.navLink
            }>
            <div className={styles.navbarBox}>
              <svg
                stroke="white"
                className={styles.homeIcon}
                width="30"
                height="31"
                viewBox="0 0 30 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M26.25 7.5H3.75"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.75 15H3.75"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.25 22.5H3.75"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div
                className={`${styles.homepage} ${
                  isCollapsed ? styles.collapsed : ""
                }`}>
                Alerts List
              </div>
            </div>
          </NavLink>

          <NavLink
            to="/reports"
            className={() =>
              isReportsActive ? styles.activeNavLink : styles.navLink
            }>
            <div className={styles.navbarBox}>
              <svg
                stroke="white"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-files">
                <path d="M20 7h-3a2 2 0 0 1-2-2V2" />
                <path d="M9 18a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h7l4 4v10a2 2 0 0 1-2 2Z" />
                <path d="M3 7.6v12.8A1.6 1.6 0 0 0 4.6 22h9.8" />
              </svg>
              <div
                className={`${styles.homepage} ${
                  isCollapsed ? styles.collapsed : ""
                }`}>
                Reports
              </div>
            </div>
          </NavLink>

          {currentReportId && (
            <NavLink
              to={`/reports/${currentReportId}`}
              className={() =>
                isReportBuilderActive ? styles.activeNavLink : styles.navLink
              }>
              <div className={styles.navbarBox}>
                {addToReportCount > 0 && (
                  <div className={styles.bullet}>
                    <span className={styles.bulletText}>
                      {addToReportCount}
                    </span>
                  </div>
                )}
                <svg
                  stroke="white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none">
                  <path
                    d="M4 22.1699H18C18.5304 22.1699 19.0391 21.9592 19.4142 21.5841C19.7893 21.2091 20 20.7004 20 20.1699V7.16992L15 2.16992H6C5.46957 2.16992 4.96086 2.38064 4.58579 2.75571C4.21071 3.13078 4 3.63949 4 4.16992V8.16992"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14 2.16992V6.16992C14 6.70035 14.2107 7.20906 14.5858 7.58414C14.9609 7.95921 15.4696 8.16992 16 8.16992H20"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3 15.1699H9"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6 12.1699V18.1699"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div
                  className={`${styles.homepage} ${
                    isCollapsed ? styles.collapsed : ""
                  }`}>
                  Report Builder
                </div>
              </div>
            </NavLink>
          )}

          <NavLink
            to="/users"
            className={({ isActive }) =>
              isActive ? styles.activeNavLink : styles.navLink
            }>
            <div className={styles.navbarBox}>
              <svg
                width="24"
                stroke="white"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g id="ProfileListOverviewCollapsedComponent/ProfileIcon">
                  <path
                    id="Vector"
                    d="M19 20V18C19 16.9391 18.5786 15.9217 17.8284 15.1716C17.0783 14.4214 16.0609 14 15 14H9C7.93913 14 6.92172 14.4214 6.17157 15.1716C5.42143 15.9217 5 16.9391 5 18V20"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_2"
                    d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
              <div
                className={`${styles.homepage} ${
                  isCollapsed ? styles.collapsed : ""
                }`}>
                Users
              </div>
            </div>
          </NavLink>
          {/* 
          <NavLink to="/shared-lists"  className={({ isActive }) => (isActive ? styles.activeNavLink : styles.navLink)}>
            <div className={styles.navbarBox}>
          

              <svg width="24" stroke="white" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="ProfileListOverviewCollapsedComponent/ProfileIcon">
                  <path
                    id="Vector"
                    d="M19 20V18C19 16.9391 18.5786 15.9217 17.8284 15.1716C17.0783 14.4214 16.0609 14 15 14H9C7.93913 14 6.92172 14.4214 6.17157 15.1716C5.42143 15.9217 5 16.9391 5 18V20"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_2"
                    d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>

              <div className={`${styles.homepage} ${isCollapsed ? styles.collapsed : ''}`}>Shared lists</div>
            </div>
          </NavLink> */}

          {/* <NavLink
              to="/dashboard-analytics"
              className={({ isActive }) => (isActive ? styles.activeNavLink : styles.navLink)}
            >
            <div className={styles.navbarBox}>
               <svg stroke="white"    className={styles.homeIcon} width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Dashboard Icon">
                  <path id="Vector" d="M11.25 4.58008H5C4.30964 4.58008 3.75 5.13972 3.75 5.83008V14.5801C3.75 15.2704 4.30964 15.8301 5 15.8301H11.25C11.9404 15.8301 12.5 15.2704 12.5 14.5801V5.83008C12.5 5.13972 11.9404 4.58008 11.25 4.58008Z" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path id="Vector_2" d="M25 4.58008H18.75C18.0596 4.58008 17.5 5.13972 17.5 5.83008V9.58008C17.5 10.2704 18.0596 10.8301 18.75 10.8301H25C25.6904 10.8301 26.25 10.2704 26.25 9.58008V5.83008C26.25 5.13972 25.6904 4.58008 25 4.58008Z" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path id="Vector_3" d="M25 15.8301H18.75C18.0596 15.8301 17.5 16.3897 17.5 17.0801V25.8301C17.5 26.5204 18.0596 27.0801 18.75 27.0801H25C25.6904 27.0801 26.25 26.5204 26.25 25.8301V17.0801C26.25 16.3897 25.6904 15.8301 25 15.8301Z" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path id="Vector_4" d="M11.25 20.8301H5C4.30964 20.8301 3.75 21.3897 3.75 22.0801V25.8301C3.75 26.5204 4.30964 27.0801 5 27.0801H11.25C11.9404 27.0801 12.5 26.5204 12.5 25.8301V22.0801C12.5 21.3897 11.9404 20.8301 11.25 20.8301Z" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                </g>
              </svg>
              <div className={`${styles.homepage} ${isCollapsed ? styles.collapsed : ''}`}>Dashboard Analytics</div>
            </div>
            </NavLink> */}

          <div className={styles.navbarFooter} onClick={handleLogout}>
            <svg
              stroke="white"
              className={styles.homeIcon}
              width="30"
              height="31"
              viewBox="0 0 30 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g id="Log out">
                <path
                  id="Vector"
                  d="M11.25 26.25H6.25C5.58696 26.25 4.95107 25.9866 4.48223 25.5178C4.01339 25.0489 3.75 24.413 3.75 23.75V6.25C3.75 5.58696 4.01339 4.95107 4.48223 4.48223C4.95107 4.01339 5.58696 3.75 6.25 3.75H11.25"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_2"
                  d="M20 21.25L26.25 15L20 8.75"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_3"
                  d="M26.25 15H11.25"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
            <div
              className={`${styles.homepage} ${
                isCollapsed ? styles.collapsed : ""
              }`}>
              Log out
            </div>
          </div>
          <p style={{ fontSize: "12px" }}> {env.REACT_APP_IMAGE_VERSION} </p>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
