// HeaderSection.js
import React from 'react';

import {addImageToReport ,addToReport, fetchTableForReport, fetchRiskScoresForReport , fetchOptimisationTableForReport} from   "../../features/report_builder/reportBuilderSlice";
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import domtoimage from 'dom-to-image-more';
import { useSelector } from 'react-redux';
import {
  notifySuccess,
} from "../../layout/ToastNotifications.jsx";


const OptionButton = ({CanAddToReport ,Type , CaptureRef,CaptureSectionName}) => {

  const { selectedScenario , selectedPeriod,  selectedSubScenario,  selectedSegment,selectedRiskScore} = useSelector((state) => state.atlTool);

  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [IsActive, setIsActive] = useState(false);
  const [isOptionHovered, setIsOptionHovered] = useState(false);
  const dropdownRef = useRef(null); // Creamos una referencia al dropdown
  const buttonRef = useRef(null); // Creamos referencia al botón
  const handleButtonClick = () => {
    setIsDropdownOpen(!isDropdownOpen); // Alternar el menú desplegable
    setIsActive(true);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setIsDropdownOpen(false); // Cierra el menú si se hace clic fuera
    }
  };

  useEffect(() => {
    // Agregar listener cuando se abre el menú
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    // Limpiar el listener cuando el componente se desmonta
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  const handleSaveToReport = () => {
    setIsDropdownOpen(false);


    console.log("SAVE TO REPORT");
    console.log("Type", Type);
    if (CaptureRef?.current) {
      const element = CaptureRef.current;

      // Find all horizontally and vertically scrollable inner elements
      const scrollableElements = element.querySelectorAll('*[style*="overflow: scroll"], *[style*="overflow: *"]');

      // Store original styles for later restoration
      const originalStyles = [];

      // Expand scrollable elements to show all content (both horizontal and vertical)
      scrollableElements.forEach((scrollableElement, index) => {
        originalStyles[index] = {
          // width: scrollableElement.style.width,
          // height: scrollableElement.style.height,
          overflowX: scrollableElement.style.overflowX,
          overflowY: scrollableElement.style.overflowY,
        };
        // scrollableElement.style.width = `${scrollableElement.scrollWidth}px`; // Expand width to full content width
        // scrollableElement.style.height = `${scrollableElement.scrollHeight}px`; // Expand height to full content height
        scrollableElement.style.overflowX = 'visible'; // Ensure no horizontal clipping
        scrollableElement.style.overflowY = 'visible'; // Ensure no vertical clipping
      });
      // Capture the expanded content
      domtoimage
      .toPng(element, {
        quality: 1.0,
        bgcolor: 'white',
        copyDefaultStyles: true,
        width: element.scrollWidth,   // Explicit width
        height: element.scrollHeight, // Explicit height
        style: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          height: 'auto',
          // transform: `scale(${scaleFactor})`,
          // transformOrigin: 'top left',
        },
        useCORS: true,
      })
         .then((dataUrl) => {
          // Reset scrollable elements to their original styles
          scrollableElements.forEach((scrollableElement, index) => {
            // scrollableElement.style.width = originalStyles[index].width;
            // scrollableElement.style.height = originalStyles[index].height;
            scrollableElement.style.overflowX = originalStyles[index].overflowX;
            scrollableElement.style.overflowY = originalStyles[index].overflowY;
          });
          console.log('Captured image data:', dataUrl);
          dispatch(addImageToReport({ title: CaptureSectionName, imageUrl: dataUrl }));
          dispatch(addToReport());
          notifySuccess("Added and saved to current report successfully");

          // dispatch(addToReport({ name: CaptureSectionName, data: dataUrl }));
        })
        .catch((error) => {
          // Reset scrollable elements in case of error
          scrollableElements.forEach((scrollableElement, index) => {
            // scrollableElement.style.width = originalStyles[index].width;
            // scrollableElement.style.height = originalStyles[index].height;
            scrollableElement.style.overflowX = originalStyles[index].overflowX;
            scrollableElement.style.overflowY = originalStyles[index].overflowY;
          });
          console.error('Error capturing the component:', error);
        });
    }else{
      if(Type === "performance_table"){

        dispatch(fetchTableForReport({
          title: "Alert Statistics Table",
          selectedScenarioId: selectedScenario.id,
          selectedSubScenario: selectedSubScenario ? selectedSubScenario.id : 0,
          period: selectedPeriod,
        }));
        dispatch(addToReport());
        notifySuccess("Added and saved to current report successfully");
      }else if(Type === "optimisation_table"){
        dispatch(fetchOptimisationTableForReport({
          title: "Parameters Optimisation Table",
          selectedScenarioId: selectedScenario.id,
          selectedSubScenario: selectedSubScenario ? selectedSubScenario.id : 0,
          period: selectedPeriod,
          selectedRiskScore: selectedRiskScore.name,
          selectedSegmentId: selectedSegment.id,
          selectedSegmentName: selectedSegment.name,

        }));
        dispatch(addToReport());
        notifySuccess("Added and saved to current report successfully");
      }else if(Type === "piechart"){
        console.log("SAVE TO REPORT", selectedSegment);

        if(selectedSegment){
          dispatch(fetchRiskScoresForReport({
            title: "KYC Segment Risk Score",
            selectedScenarioId: selectedScenario.id,
            selectedSubScenario: selectedSubScenario ? selectedSubScenario.id : 0,
            perdiod: selectedPeriod,
            selectedSegmentId: selectedSegment?.id,
          }));
          dispatch(addToReport());
          notifySuccess("Added and saved to current report successfully");
        }
      }else{
        console.log("type" ,Type);
      }
    }
  };


  return (
    <div style={{position:'relative' }}>
       <div style={{marginLeft:30, cursor: 'pointer'}}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={(e) => {
              e.stopPropagation();
              handleButtonClick();
            }}
            ref={buttonRef} // Referencia al botón
            >

            <div style={{
                            width: 30,
                            height: 30,
                            padding: 2,
                            borderRadius: 6,
                            border: isHovered ? '2px #D9D6FE solid' : '2px #D0D5DD solid',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: 10,
                            display: 'inline-flex'
                        }}>

              <div style={{width: 28, paddingLeft: 12, paddingRight: 12, paddingTop: 5, paddingBottom: 5, background: 'white', borderRadius: 5, overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 4, display: 'inline-flex'}}>
                     {[...Array(3)].map((_, index) => (
                <svg
                  key={index}
                  xmlns="http://www.w3.org/2000/svg"
                  width="5"
                  height="5"
                  viewBox="0 0 4 4"
                  fill="none"
                >
                  <path
                    d="M2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3Z"
                    stroke={isHovered ? '#717BBC' : '#D0D5DD'}
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ))}

              </div>
            </div>
          </div>

    {/* Menú desplegable */}
    {isDropdownOpen && (
        <div
        ref={dropdownRef}
        className="DropdownOverlayComponent"
          style={{
            width: 190,
            background: 'white',
            boxShadow: '0px 0px 5px rgba(106, 106, 106, 0.05)',
            borderRadius: 10,
            border: '1px #EFEFEF solid',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            display: 'inline-flex',
            position: 'absolute',
            zIndex: 1000, // Añade esta línea
            right: 0,  // Alinea la parte derecha del dropdown con el botón
            marginTop: 10, // Ajustar la posición del dropdown
            cursor: 'pointer',
          }}
        >
        <div

              onClick={(e) => {
                e.stopPropagation();
                handleSaveToReport();
              }}
              className="DropdownItems"
              style={{
                alignSelf: 'stretch',
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 16,
                paddingBottom: 16,
                borderRadius: 5,
                background: isOptionHovered ? '#F4F3FF' : 'white',
                justifyContent: 'flex-start',
                alignItems: 'center', // Alinea verticalmente
                gap: 10,
                display: 'flex', // Cambié 'inline-flex' a 'flex' para asegurar un mejor comportamiento
              }}
              onMouseEnter={() => setIsOptionHovered(true)}
              onMouseLeave={() => setIsOptionHovered(false)}
            >
              <div
                className="DropdownIcons"
                style={{
                  width: 20,
                  height: 20,
                  display: 'flex', // Asegura que el icono esté alineado con su contenedor
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M4 22.1699H18C18.5304 22.1699 19.0391 21.9592 19.4142 21.5841C19.7893 21.2091 20 20.7004 20 20.1699V7.16992L15 2.16992H6C5.46957 2.16992 4.96086 2.38064 4.58579 2.75571C4.21071 3.13078 4 3.63949 4 4.16992V8.16992"
                    stroke={isOptionHovered ? '#717BBC': "#B0AFD9"}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14 2.16992V6.16992C14 6.70035 14.2107 7.20906 14.5858 7.58414C14.9609 7.95921 15.4696 8.16992 16 8.16992H20"
                    stroke={isOptionHovered ? '#717BBC': "#B0AFD9"}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3 15.1699H9"
                    stroke={isOptionHovered ? '#717BBC': "#B0AFD9"}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6 12.1699V18.1699"
                    stroke={isOptionHovered ? '#717BBC': "#B0AFD9"}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div
                className="EditMode"
                style={{
                  textAlign: 'center',
                  color: isOptionHovered ? '#717BBC':'#B0AFD9',
                  fontSize: 14,
                  fontFamily: 'DM Sans',
                  fontWeight: '500',
                  wordWrap: 'break-word',
                }}
              >
                Add to Report
              </div>
            </div>

          {/* Opción 3 */}
          <div
            className="DropdownItems"
            style={{
              alignSelf: 'stretch',
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 16,
              paddingBottom: 16,
              borderRadius: 5,
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: 10,
              display: 'inline-flex',
            }}
          >
            <div
              className="DropdownIcons"
              style={{
                width: 20,
                height: 20,
                display: 'flex', // Asegura que el icono esté alineado con su contenedor
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <g id="Dropdown Icons" clipPath="url(#clip0_3071_2101)">
              <path id="Vector" d="M2.5 5H17.5"
              stroke='#F97066' strokeWidth="1.39167" strokeLinecap="round" strokeLinejoin="round"/>
              <path id="Vector_2" d="M15.8337 5V16.6667C15.8337 17.5 15.0003 18.3333 14.167 18.3333H5.83366C5.00033 18.3333 4.16699 17.5 4.16699 16.6667V5" stroke='#F97066' strokeWidth="1.39167" strokeLinecap="round" strokeLinejoin="round"/>
              <path id="Vector_3" d="M6.66699 4.99984V3.33317C6.66699 2.49984 7.50033 1.6665 8.33366 1.6665H11.667C12.5003 1.6665 13.3337 2.49984 13.3337 3.33317V4.99984" stroke='#F97066' strokeWidth="1.39167" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
              <defs>
              <clipPath id="clip0_3071_2101">
              <rect width="20" height="20" fill="white"/>
              </clipPath>
              </defs>
              </svg>
            </div>
            <div
              className="EditMode"
              style={{
                textAlign: 'center',
                color: '#F97066',
                fontSize: 14,
                fontFamily: 'DM Sans',
                fontWeight: '500',
                wordWrap: 'break-word',
              }}
            >
              Remove
            </div>
            </div>
            </div>
          )}





    </div>
  );
};


export default OptionButton;
