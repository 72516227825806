import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../axiosConfig";
import { transformPeriodToDateRange } from "../Functions";

const transformData = (data) => {
  return data.map((item, index) => ({
    id: item.id,
    Title: `${item.name} ${item.name.includes("DS") ? "" : ""}`,
    isUsingSegment: true,
    alerts: [
      item.alert_relevancies["SAR"],
      item.alert_relevancies["Relevant"],
      item.alert_relevancies["Non Relevant"],
    ],
    total_alerts: item.total_count,
    category_id: item.category_id,
    childs: item.scenarios.map((scenario, childIndex) => ({
      child_id: scenario.scenario_id,
      id: scenario.scenario_id,
      Title: scenario.name,
      isUsingSegment: true,
      alerts: [
        scenario.alert_relevancies["SAR"],
        scenario.alert_relevancies["Relevant"],
        scenario.alert_relevancies["Non Relevant"],
      ],
      total_alerts: scenario.total_count,
      customer_segments: scenario.customer_segments.map(
        (segment, segmentIndex) => ({
          id: segment.customer_segment_id,
          Title: segment.name,
          risk_scores: [
            segment.risk_scores.find((score) => score.risk_score === "low")
              ? {
                  name: "low",
                  alerts: [
                    segment.risk_scores.find(
                      (score) => score.risk_score === "low"
                    ).alert_relevancies["SAR"],
                    segment.risk_scores.find(
                      (score) => score.risk_score === "low"
                    ).alert_relevancies["Relevant"],
                    segment.risk_scores.find(
                      (score) => score.risk_score === "low"
                    ).alert_relevancies["Non Relevant"],
                  ],
                  total_alerts:
                    segment.risk_scores.find(
                      (score) => score.risk_score === "low"
                    ).alert_relevancies["SAR"] +
                    segment.risk_scores.find(
                      (score) => score.risk_score === "low"
                    ).alert_relevancies["Relevant"] +
                    segment.risk_scores.find(
                      (score) => score.risk_score === "low"
                    ).alert_relevancies["Non Relevant"],
                }
              : { alerts: [0, 0, 0], total_alerts: 0, name: "low" },

            segment.risk_scores.find((score) => score.risk_score === "medium")
              ? {
                  name: "medium",
                  alerts: [
                    segment.risk_scores.find(
                      (score) => score.risk_score === "medium"
                    ).alert_relevancies["SAR"],
                    segment.risk_scores.find(
                      (score) => score.risk_score === "medium"
                    ).alert_relevancies["Relevant"],
                    segment.risk_scores.find(
                      (score) => score.risk_score === "medium"
                    ).alert_relevancies["Non Relevant"],
                  ],
                  total_alerts:
                    segment.risk_scores.find(
                      (score) => score.risk_score === "medium"
                    ).alert_relevancies["SAR"] +
                    segment.risk_scores.find(
                      (score) => score.risk_score === "medium"
                    ).alert_relevancies["Relevant"] +
                    segment.risk_scores.find(
                      (score) => score.risk_score === "medium"
                    ).alert_relevancies["Non Relevant"],
                }
              : { alerts: [0, 0, 0], total_alerts: 0, name: "medium" },

            segment.risk_scores.find((score) => score.risk_score === "high")
              ? {
                  name: "high",
                  alerts: [
                    segment.risk_scores.find(
                      (score) => score.risk_score === "high"
                    ).alert_relevancies["SAR"],
                    segment.risk_scores.find(
                      (score) => score.risk_score === "high"
                    ).alert_relevancies["Relevant"],
                    segment.risk_scores.find(
                      (score) => score.risk_score === "high"
                    ).alert_relevancies["Non Relevant"],
                  ],
                  total_alerts:
                    segment.risk_scores.find(
                      (score) => score.risk_score === "high"
                    ).alert_relevancies["SAR"] +
                    segment.risk_scores.find(
                      (score) => score.risk_score === "high"
                    ).alert_relevancies["Relevant"] +
                    segment.risk_scores.find(
                      (score) => score.risk_score === "high"
                    ).alert_relevancies["Non Relevant"],
                }
              : { alerts: [0, 0, 0], total_alerts: 0, name: "high" },
          ],
        })
      ),
    })),
  }));
};

export const fetchScenarioOverview = createAsyncThunk(
  "atlTool/fetchScenarioOverview",
  async ({ period }) => {
    const periodString = new URLSearchParams(
      transformPeriodToDateRange(period)
    ).toString().replace(/^&/, '');
    const response = await apiClient.get(`ds-relevancy-counts?${periodString}`);
    return transformData(response.data);
  }
);

export const fetchRecommendations = createAsyncThunk(
  "atlTool/fetchRecommendations",
  async ({ listOfIds, period, q1, q3, median, signal }) => {
    // Build scenario IDs part
    const scenarioParams = listOfIds
      .map((id) => `scenario_ids=${id}`)
      .join("&");

    // Add statistical parameters if they exist
    const statsParams = [
      q1 != null && `q1=${q1}`,
      q3 != null && `q3=${q3}`,
      median != null && `median=${median}`,
    ]
      .filter(Boolean)
      .join("&");

    // Get period parameters
    const periodParams = new URLSearchParams(
      transformPeriodToDateRange(period)
    ).toString().replace(/^&/, '');

    // Combine all parameters with proper syntax
    const response = await apiClient.get(
      `/scenario-performance-suggestion?${scenarioParams}&${
        statsParams ? statsParams + "&" : ""
      }${periodParams}`,
      {
        signal,
      }
    );

    return response.data;
  }
);

const scenarioOverviewSlice = createSlice({
  name: "scenarioOverview",
  initialState: {
    scenarioGroups: [],
    paginatedRows: [],
    openedGroups: Array(),
    openedScenarios: Array(),
    selectedPeriod: {
      [new Date().getFullYear()]: Array.from(
        { length: new Date().getMonth() + 1 }, // Generate months up to the current month (inclusive)
        (_, index) => index
      ),
    },
    selectedScenario: null,
    status: "idle",
    error: null,
    searchQuery: "",
    selectedSortingRule: "none",
    sortingRuleReverted: false,
    totalScenarioGroups: 0,
    totalScenarios: 0,
    currentPage: 0,

    requestedAtl: 0,
    requestedBtl: 0,
    requestedLogic: 0,
    recommendationMessage: "",

    selectedYearMonthPairs: ["2022-01", "2022-02", "2022-03"],

    goingAtl: false,

    recommendations: [],
  },
  reducers: {
    setPaginatedRows(state, action) {
      state.paginatedRows = action.payload;
    },
    setRequestedAtl(state, action) {
      state.requestedAtl = action.payload; // should be {scenario: scenario_id, customer_segment: customer_segment_id}
    },
    setRequestedBtl(state, action) {
      state.requestedBtl = action.payload;
    },
    setRequestedLogic(state, action) {
      state.requestedLogic = action.payload;
    },
    setRecommendationMessage(state, action) {
      state.recommendationMessage = action.payload;
    },
    setSelectedScenario(state, action) {
      state.selectedScenario = action.payload;
    },
    switchOpenedStatus(state, action) {
      if (!state.openedGroups.includes(action.payload)) {
        state.openedGroups.push(action.payload);
      } else {
        state.openedGroups.splice(
          state.openedGroups.indexOf(action.payload),
          1
        );
      }
    },
    switchOpenedScenarioStatus(state, action) {
      if (!state.openedScenarios.includes(action.payload)) {
        state.openedScenarios.push(action.payload);
      } else {
        state.openedScenarios.splice(
          state.openedScenarios.indexOf(action.payload),
          1
        );
      }
    },
    setSelectedPeriod(state, action) {
      state.selectedPeriod = action.payload;
    },
    setOpenedGroups(state, action) {
      state.openedGroups = action.payload;
    },
    setOpenedScenarios(state, action) {
      state.openedScenarios = action.payload;
    },
    setSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },
    setSelectedSortingRule(state, action) {
      if (
        state.selectedSortingRule === action.payload &&
        state.sortingRuleReverted
      ) {
        state.selectedSortingRule = "none";
        state.sortingRuleReverted = false;
      } else if (state.selectedSortingRule === action.payload) {
        state.selectedSortingRule = action.payload;
        state.sortingRuleReverted = true;
      } else {
        state.selectedSortingRule = action.payload;
        state.sortingRuleReverted = false;
      }
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setTotalScenariosGroups(state, action) {
      state.totalScenarioGroups = action.payload;
    },
    setTotalScenarios(state, action) {
      state.totalScenarios = action.payload;
    },
    clearRecommendations(state) {
      state.recommendations = [];
    },
    setSelectedYearMonthPairs(state, action) {
      state.selectedYearMonthPairs = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchScenarioOverview.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchScenarioOverview.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.scenarioGroups = action.payload;
      })
      .addCase(fetchScenarioOverview.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchRecommendations.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRecommendations.fulfilled, (state, action) => {
        state.recommendations = state.recommendations.concat([action.payload]);
      })
      .addCase(fetchRecommendations.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  setSelectedScenario,
  setSelectedPeriod,
  switchOpenedStatus,
  switchOpenedScenarioStatus,
  setSearchQuery,
  setRequestedAtl,
  setRequestedBtl,
  setRequestedScenario,
  setRequestedLogic,
  setSelectedSortingRule,
  setCurrentPage,
  setTotalScenariosGroups,
  setTotalScenarios,
  setOpenedGroups,
  clearRecommendations,
  setRecommendationMessage,
  setOpenedScenarios,
  setPaginatedRows,
  setSelectedYearMonthPairs,
} = scenarioOverviewSlice.actions;

export default scenarioOverviewSlice.reducer;
