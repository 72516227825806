export function transformPeriodToDateRange(period) {
  if (!period || Object.keys(period).length === 0) {
    return null;
  }

  const years = Object.keys(period)
    .map(Number)
    .sort((a, b) => a - b);

  const startYear = years[0];
  const startMonth = Math.min(...period[startYear]);

  const endYear = years[years.length - 1];
  const endMonth = Math.max(...period[endYear]);

  const startDate = `${startYear}-${String(startMonth + 1).padStart(
    2,
    "0"
  )}-01`;

  const endDay = new Date(endYear, endMonth + 1, 0).getDate();
  const endDate = `${endYear}-${String(endMonth + 1).padStart(2, "0")}-${String(
    endDay
  ).padStart(2, "0")}`;

  return { start_date: startDate, end_date: endDate };
}

export function convertData(data, keyMapping) {
  const transformedDict = {};
  Object.entries(keyMapping).forEach(([sourceKey, targetKey]) => {
    const keys = sourceKey.split(".");
    let value = data;
    for (const key of keys) {
      if (value && key in value) {
        value = value[key];
      } else {
        value = null;
        break;
      }
    }
    if (value !== null) {
      transformedDict[targetKey] = value;
    }
  });
  return transformedDict;
}
