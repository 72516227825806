import { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  fetchBtlTable,
  setSelectedOptimisation,
  resetSampledAlerts
} from "../../../features/btl_tool/btlToolSlice";
import { deleteRows } from "../../../features/atl_tool/atlToolSlice";
import useDebouncedAction from "../../../features/DebounceHook";
import { HeaderClass } from "../../../layout/table/Header";
import TableGrid from "../../../layout/table/Table";
import { PrimaryButton } from "../../../layout/buttons/NormalButton";
import { SelectActionButton } from "../../../layout/table/TableTools";
import { CellClass, RowCellClass } from "../../../layout/table/Cell";
import BtlModal from "./BtlModal";
import { notifyError, notifySuccess } from "../../../layout/ToastNotifications";

function TrashIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"></path>
    </svg>
  );
}

function BtlTable() {
  const dispatch = useDispatch();
  const {
    selectedScenario,
    selectedSubScenario,
    selectedPeriod,
    selectedSegment,
    loadingSegments,
    selectedRiskScore,
    loadingRiskScores,
    thresholds,
    selectedThreshold,
    btlTable,
    selectedOptimisation,
  } = useSelector((state) => state.btlTool);
  const noSelectedPeriod =
    !selectedPeriod || Object.keys(selectedPeriod).length === 0;
  const showTable =
    selectedScenario &&
    selectedSegment &&
    selectedRiskScore &&
    !noSelectedPeriod &&
    selectedThreshold;

  const [modalOpen, setModalOpen] = useState(false);

  function fetchTable() {
    dispatch(
      fetchBtlTable({
        scenarioId: selectedScenario?.id,
        segmentId: selectedSegment.id,
        subScenarioId: selectedSubScenario?.id || 0,
        riskScore: selectedRiskScore,
        period: selectedPeriod,
      })
    );
  }

  useDebouncedAction({
    action: () => fetchTable(),
    dependencies: [
      selectedScenario,
      selectedSubScenario,
      selectedRiskScore,
      selectedSegment,
      selectedPeriod,
      loadingSegments,
      loadingRiskScores,
    ],
    condition: showTable && !loadingRiskScores && !loadingSegments,
  });

  if (!showTable || btlTable.length === 0) {
    return <Fragment></Fragment>;
  }

  const generateThresholdHeaders = (thresholds) => {
    return thresholds.map((threshold) => {
      return new HeaderClass(
        threshold.threshold_name,
        threshold.threshold_name,
        true,
        false,
        { minWidth: 150 }
      );
    });
  };
  const headers = [
    new HeaderClass("Optimisation Type", "name", true, false, {
      minWidth: 210,
    }),
  ];
  if (thresholds) {
    const dynamicHeaders = generateThresholdHeaders(thresholds);
    headers.push(...dynamicHeaders);
  }
  headers.push(
    new HeaderClass("Efficiency", "efficiency", true, false, {
      minWidth: 250,
      maxWidth: 250,
      width: 250,
    })
  );

  const rows = btlTable.map((rowData) => {
    const optimisationId = rowData.id;
    const cells = headers.map((header) => {
      const value = rowData[header.field];
      return new CellClass(value, header.field);
    });

    return new RowCellClass(optimisationId, cells, rowData);
  });

  async function deleteRowsTable(selectedIds, onSuccess) {
    const result = await dispatch(
      deleteRows({ row_ids: selectedIds })
    ).unwrap();
    onSuccess();
    fetchTable();
    if (result.deleted_count == 0) {
      notifyError("Could not delete optimisations");
    } else {
      notifySuccess(`Deleted ${result.deleted_count} optimisations`);
    }
  }

  function onRowClick(row) {
    dispatch(setSelectedOptimisation(row));
    dispatch(resetSampledAlerts())
  }

  return (
    <Fragment>
      <TableGrid
        title={`Optimisation BTL`}
        headers={headers}
        rows={rows}
        canExport={true}
        noDataPlaceholder={"No optimisations found"}
        canSearch={false}
        onRowClick={onRowClick}
        pinnedId={0}
        notClickableIds={[0]}
        notSelectableIds={[0]}
        clickedIds={[selectedOptimisation?.id]}
        pageSize={4}
        actionButtonsLeft={[
          <PrimaryButton
            content={"Perform BTL"}
            action={() => setModalOpen(true)}
          />,
        ]}
        onSelectActionButtons={[
          new SelectActionButton(
            "delete",
            "optimisation",
            ({ selectedIds, selectAllMode, onSuccess }) => {
              deleteRowsTable(selectedIds, onSuccess);
            },
            <TrashIcon />,
            true
          ),
        ]}
      />
      <BtlModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </Fragment>
  );
}

export default BtlTable;
