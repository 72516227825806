import { useSelector, useDispatch } from "react-redux";
import { useState, Fragment } from "react";
import stylesheet from "../../atl_tool/AtlTool.module.css";
import { useNavigate } from "react-router-dom";

import useDebouncedAction from "../../../features/DebounceHook";
import {
  fetchGeneratedAlerts,
  setAlertSelectionType,
} from "../../../features/btl_tool/btlToolSlice";
import HeaderSection from "../../../layout/HeaderSection";
import TableGrid from "../../../layout/table/Table";
import { HeaderClass } from "../../../layout/table/Header";
import { CellClass, RowCellClass } from "../../../layout/table/Cell";
import { PrimaryButton } from "../../../layout/buttons/NormalButton";
import SliderButton from "../../../layout/buttons/SliderButton";
import SampleModal from "./SampleModal";

function AlertsTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    selectedScenario,
    selectedSubScenario,
    selectedPeriod,
    selectedSegment,
    loadingSegments,
    selectedRiskScore,
    loadingRiskScores,
    selectedOptimisation,
    loadingBtlTable,
    generatedAlerts,
    alertSelectionType,
    sampledAlerts,
  } = useSelector((state) => state.btlTool);

  const noSelectedPeriod =
    !selectedPeriod || Object.keys(selectedPeriod).length === 0;
  const showTable =
    selectedScenario &&
    selectedSegment &&
    selectedRiskScore &&
    !noSelectedPeriod &&
    selectedOptimisation;

  const [modalOpen, setModalOpen] = useState(false);

  useDebouncedAction({
    action: () =>
      dispatch(
        fetchGeneratedAlerts({
          scenarioId: selectedScenario?.id,
          segmentId: selectedSegment.id,
          subScenarioId: selectedSubScenario?.id || 0,
          riskScore: selectedRiskScore,
          period: selectedPeriod,
          rowId: selectedOptimisation.id,
        })
      ),
    dependencies: [
      selectedScenario,
      selectedSubScenario,
      selectedRiskScore,
      selectedSegment,
      selectedPeriod,
      selectedOptimisation,
      loadingSegments,
      loadingRiskScores,
      loadingBtlTable,
    ],
    condition:
      showTable && !loadingRiskScores && !loadingSegments && !loadingBtlTable,
  });

  if (!showTable) {
    return <Fragment></Fragment>;
  }

  const headers = [
    new HeaderClass("BTL Alert ID", "id", true, false, {
      minWidth: 210,
    }),
    new HeaderClass("Creation Time", "event_date", true, false, {
      minWidth: 210,
    }),
    new HeaderClass("Origin", "alert_origin", true, false, {
      minWidth: 210,
    }),
  ];

  const rows = generatedAlerts.map((rowData) => {
    const alertId = rowData.id;
    const cells = headers.map((header) => {
      const value = rowData[header.field];
      return new CellClass(value, header.field);
    });
    return new RowCellClass(alertId, cells, rowData);
  });

  const sampledRows = sampledAlerts.map((rowData) => {
    const alertId = rowData.id;
    const cells = headers.map((header) => {
      const value = rowData[header.field];
      return new CellClass(value, header.field);
    });
    return new RowCellClass(alertId, cells, rowData);
  });

  const rowsToShow = alertSelectionType == "All Alerts" ? rows : sampledRows;

  function onRowClick(row) {
    navigate(`/alerts/${row.id}`);
  }

  return (
    <div className={stylesheet.sectionWrapper}>
      <div className={stylesheet.section}>
        <HeaderSection
          SectionName="BTL Generated Alerts"
          Count={rowsToShow.length}
          AdditionalText={`${
            selectedScenario?.name ? `${selectedScenario.name}` : ""
          }${
            selectedSubScenario?.name ? ` - ${selectedSubScenario.name}` : ""
          }${selectedSegment?.name ? ` - ${selectedSegment.name}` : ""}`}
        />
        <TableGrid
          headers={headers}
          title={"BTL Generated Alerts"}
          rows={rowsToShow}
          canExport={true}
          onRowClick={onRowClick}
          actionButtonsLeft={[
            <SliderButton
              options={["Sampled Alerts", "All Alerts"]}
              activeOption={alertSelectionType}
              onClick={(type) => dispatch(setAlertSelectionType(type))}
              widthSlider={150}
            />,
            <PrimaryButton
              content={"Random Sampling"}
              action={() => setModalOpen(true)}
            />,
          ]}
        />
        <SampleModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      </div>
    </div>
  );
}

export default AlertsTable;
