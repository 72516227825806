import React, { useRef } from "react";
import { useSelector } from "react-redux";
import HeaderSection from "../../../layout/HeaderSection";
import c_styles from "../AtlTool.module.css";
import styles from "./SegmentOverview.module.css";
import PieChart from "./PieChart.js";
import Scrollbar from "../../../layout/scrollbar/Scrollbar.js";
import ParameterOptimisation from "../parameter_optimisation/ParameterOptimisation.js";

const SegmentOverview = () => {
  const risk_scores = useSelector((state) =>
    state.atlTool.riskScores.filter(
      (risk_score) =>
        risk_score.name !== "Unknown" ||
        risk_score.non_relevant_alerts > 0 ||
        risk_score.relevant_alerts > 0 ||
        risk_score.sar_alerts > 0 ||
        (risk_score.alert_ongoing_alerts ?? 0) > 0
    )
  );

  const { selectedSegment, selectedSegmentOverview, selectedRiskScore } =
    useSelector((state) => state.atlTool);
  const pieChartContainerRef = useRef(null); // Referencia para el área con scroll
  const getTotalAlerts = () => {
    let totalAlerts = 0;
    if (selectedRiskScore) {
      totalAlerts =
        selectedRiskScore.non_relevant_alerts +
        selectedRiskScore.relevant_alerts +
        selectedRiskScore.sar_alerts;
    }

    return totalAlerts;
  };
  return (
    <div className={c_styles.section}>
      <div ref={pieChartContainerRef} style={{ marginBottom: "20px" }}>
        <HeaderSection
          SectionName="KYC Segment Risk Score"
          Type="piechart"
          CaptureRef={pieChartContainerRef} // Pasamos solo el área de gráficos con scroll
          AdditionalText={selectedSegment.name}
          CanAddToReport={true}
          CaptureSectionName="KYC Segment Risk Score"
        />
        <Scrollbar
          height={"360px"}
          verticalScroll={false}>
          <div className={styles.subsegments}>
            {risk_scores.map((risk_score, i) => (
              <div key={risk_score.id} className={styles.pieChartContainer}>
                <PieChart
                  risk_score_id={risk_score.id}
                  risk_level={i / (risk_scores.length - 1)}
                />
              </div>
            ))}
          </div>
        </Scrollbar>
      </div>
      {selectedSegmentOverview > -1 &&
        selectedRiskScore !== null &&
        getTotalAlerts() > 0 && (
          <div className={c_styles.sectionWrapperBordered}>
            <ParameterOptimisation />
          </div>
        )}
    </div>
  );
};

export default SegmentOverview;
