import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  setSelectedScenario,
  setSelectedSubScenario,
  setSelectedPeriod,
  setSelectedSegment,
  setSelectedRiskScore,
} from "../../../../features/atl_tool/atlToolSlice";
import styles from "./ScenarioSelector.module.css";
import {
  InputSelector,
  OptionClass,
} from "../../../../layout/selector/Selector";
import MonthSelector from "../../../../layout/selector/MonthSelector";

const ScenarioSelector = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { scenarios, selectedScenario, selectedPeriod, selectedSubScenario } =
    useSelector((state) => state.atlTool);
  const preState = location.state?.preState;

  useEffect(() => {
    if (preState) {
      if (preState?.scenario) {
        const foundScenario = scenarios.find(
          (scenario) => scenario.id === preState.scenario.id
        );
        if (foundScenario && selectedScenario?.id !== foundScenario.id) {
          dispatch(setSelectedScenario(foundScenario));
        }
      }
      if (preState?.subscenario) {
        dispatch(setSelectedSubScenario(preState.subscenario));
      } else {
        dispatch(setSelectedSubScenario(null));
      }
      if (preState?.period) {
        dispatch(setSelectedPeriod(preState.period));
      }
      if (preState?.segment) {
        dispatch(setSelectedSegment(preState.segment));
      }
    }
  }, [preState, scenarios]);

  const scenarioOptions = scenarios.map(
    (scenario) => new OptionClass(scenario.id, scenario.name, scenario)
  );

  let subscenarioOptions = [];
  if (selectedScenario?.subscenarios?.length > 0) {
    subscenarioOptions = selectedScenario.subscenarios;
  }
  subscenarioOptions = subscenarioOptions.map(
    (scenario) => new OptionClass(scenario.id, scenario.name, scenario)
  );

  function handleScenarioSelect(scenario) {
    dispatch(setSelectedScenario(scenario));
  }
  function handleSubScenarioSelect(scenario) {
    dispatch(setSelectedSubScenario(scenario));
  }
  function handlePeriodSelect(period) {
    dispatch(setSelectedPeriod(period));
  }

  useEffect(() => {
    if (preState?.subscenario?.id !== 0) {
      if (
        !selectedScenario?.subscenarios.find(
          (subscenario) => subscenario.id === selectedSubScenario?.id
        )
      ) {
        dispatch(setSelectedSubScenario(selectedScenario?.subscenarios[0]));
      }
    }
  }, [selectedScenario]);

  return (
    <div className={styles.selectorsContainer}>
      <InputSelector
        selectedId={selectedScenario?.id}
        setState={handleScenarioSelect}
        label={"Choose a scenario"}
        search={true}
        options={scenarioOptions}
      />
      <InputSelector
        selectedId={selectedSubScenario?.id}
        setState={handleSubScenarioSelect}
        label={"Choose a subscenario"}
        options={subscenarioOptions}
        show={subscenarioOptions.length > 0 && selectedScenario}
      />
      {!(subscenarioOptions.length > 0 && !selectedSubScenario) &&
        selectedScenario && (
          <MonthSelector
            setState={handlePeriodSelect}
            label={"Choose a date range"}
            state={selectedPeriod}
          />
        )}
    </div>
  );
};

export default ScenarioSelector;
