import React from "react";
import styles from "./HeaderSection.module.css"; 
import OptionButton from "./buttons/OptionButton";

import { RoundBackButton } from "../layout/buttons/RoundIconButton.js";

const HeaderSection = ({
  SectionName,
  Count,
  returnFunction,
  Type,
  AdditionalText,
  CanAddToReport,
  CaptureRef,
  CaptureSectionName,
}) => {
  return (
    <div className={styles.headerSection}>
      <div className={styles.titleCount}>
        {returnFunction && (
          <div style={{ marginRight: "24px" }}>
            <RoundBackButton action={returnFunction} />
          </div>
        )}
        <div className={styles.kycSegmentRisk}>{SectionName}</div>
        {Count > 0 && <div className={styles.count}>{Count} results</div>}
      </div>
      {AdditionalText && (
        <div className={styles.additionalText}>
          <span
            style={{
              position: "relative",
              alignSelf: "center",
              justifySelf: "center",
            }}>
            {AdditionalText}
          </span>
          {CanAddToReport && (
            <OptionButton
              CanAddToReport={CanAddToReport}
              Type={Type}
              CaptureRef={CaptureRef}
              CaptureSectionName={CaptureSectionName}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default HeaderSection;
