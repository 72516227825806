import React, { useState, useEffect, useRef, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import {
  setParameterOptimisationIsWaiting,
  setSelectedSegmentOverviewUpdates,
  setJustAddedNewOptimisationRow,
  fetchSupressedAlerts,
  setSelectedRowId,
  setManualThresholds,
  setCanManualOpt,
} from "../../../../features/atl_tool/atlToolSlice";
import styles from "./OptimisationTable.module.css";
import Skeleton from "@mui/material/Skeleton";
import Scrollbar from "../../../../layout/scrollbar/Scrollbar";
import Checkbox from "../../../../layout/buttons/Checkbox";
import Pagination from "../../../../layout/table/Pagination";

const tableRef = React.createRef();
//To display the percentage
const PercentageDisplay = (r) => {
  if (isNaN(r)) {
    return "";
  }
  const percentage = (r * 100).toFixed(1);
  return `${formatNumber(percentage)}%`;
};

const formatNumber = (r) => {
  if (isNaN(r)) {
    return "";
  }
  return new Intl.NumberFormat("de-DE").format(r);
};

const ManualRow = ({
  scenarioParameters,
  initValues,
  dispatch,
  thresholdIdMap,
}) => {
  const [inputStates, setInputStates] = useState(
    scenarioParameters.reduce((acc, param) => {
      acc[param] = { value: initValues[param], error: false };
      return acc;
    }, {})
  );

  useEffect(() => {
    const thresholdsWithValues = scenarioParameters.map((param) => {
      const thresholdId = thresholdIdMap[param];
      const newValue = inputStates[param].value;
      return [thresholdId, newValue];
    });

    dispatch(setManualThresholds(thresholdsWithValues));
    const hasErrors = scenarioParameters.some(
      (param) => inputStates[param].error
    );

    if (hasErrors) {
      dispatch(setCanManualOpt(false));
    } else {
      dispatch(setCanManualOpt(true));
    }
  }, [inputStates]);

  const handleChange = (param, event) => {
    const newValue = event.target.value;
    setInputStates((prev) => ({
      ...prev,
      [param]: { ...prev[param], value: newValue, error: !newValue },
    }));
  };

  // Handle the cell click to trigger input focus
  const handleCellClick = (e) => {
    // Find the input element inside the clicked td and focus it
    const inputElement = e.currentTarget.querySelector("input");
    if (inputElement) {
      inputElement.focus();
    }
  };

  return (
    <CSSTransition
      key={"manual"}
      timeout={300}
      classNames={{
        enter: styles.rowEnter,
        enterActive: styles.rowEnterActive,
        exit: styles.rowExit,
        exitActive: styles.rowExitActive,
      }}>
      <tr className={`${styles.row} ${styles.manualRow}`}>
        <td
          className={styles.stickyColumnNoColor}
          style={{ width: "230px", padding: "0" }}>
          <div
            className={`${styles.cell} `}
            style={{ borderRight: "1px solid #ebe9fe" }}>
            <div
              style={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                justifyContent: "center",
              }}>
              -
            </div>
          </div>
        </td>
        {scenarioParameters.map((parameter) => (
          <td
            key={parameter}
            className={`${styles.cellInput} ${
              inputStates[parameter].error ? styles.error : ""
            }`}
            onClick={handleCellClick}>
            <input
              type="number"
              value={inputStates[parameter].value}
              onChange={(e) => handleChange(parameter, e)}
              className={`${styles.input}`}
            />
          </td>
        ))}
        <td className={styles.cell}>-</td>
        <td className={styles.cell}>-</td>
        <td className={styles.cell}>-</td>
        <td className={styles.cell}>-</td>
      </tr>
    </CSSTransition>
  );
};

const OptimisationTable = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const { selectedRows, toggleRowSelection, isAddingManual } = props;

  const [scrollHeight, setScrollHeight] = useState();
  //Needed variables from slice
  const {
    parameterOptimisationIsWaiting,
    optimisationTable,
    selectedScenario,
    selectedSegment,
    selectedRiskScore,
    selectedRowId,
    selectedSubScenario,
    selectedPeriod,
    justAddedNewOptimisationRow,
  } = useSelector((state) => state.atlTool);

  //Needed state variables
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  //Used for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  useEffect(() => {
    dispatch(setParameterOptimisationIsWaiting(false));
  }, [optimisationTable.table.length]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedRiskScore]);

  //Handle a click on a row
  const handleRowClick = (index, row) => {
    if (index === "skeleton") {
      return;
    } // Prevent click actions on skeleton row
    let segmentOverviewUpdates = null;
    if (index === 0 && currentPage === 1) {
      // First item from table
      dispatch(setSelectedRowId(null));
      segmentOverviewUpdates = null;
    } else {
      dispatch(setSelectedRowId(row.id));
      const row_id = row.id;
      if (row.efficiency.replace(/\s/g, "") !== "") {
        segmentOverviewUpdates = {
          id: selectedRiskScore.id,
          name: selectedRiskScore.name,
          non_relevant_alerts: row.non_relevant_alerts,
          relevant_alerts: row.relevant_alerts,
          sar_alerts: row.sar_alerts,
        };
        dispatch(
          fetchSupressedAlerts({
            scenarioId: selectedScenario?.id,
            segment: selectedSegment,
            subScenarioId: selectedSubScenario?.id || 0,
            riskScore: selectedRiskScore,
            row_id,
            period: selectedPeriod,
          })
        );
      }
    }
    dispatch(setSelectedSegmentOverviewUpdates(segmentOverviewUpdates));
  };
  const handleSort = (column) => {
    const newSortOrder =
      sortedColumn === column && sortOrder === "asc" ? "desc" : "asc";
    setSortedColumn(column);
    setSortOrder(newSortOrder);
  };

  const sortRows = (rows, column, order) => {
    const sortedRows = [...rows].sort((a, b) => {
      if (a[column] < b[column]) return order === "asc" ? -1 : 1;
      if (a[column] > b[column]) return order === "asc" ? 1 : -1;
      return 0;
    });
    return sortedRows;
  };

  let rows = optimisationTable.table.map((row, index) => {
    const parameters = optimisationTable.scenario_parameters.reduce(
      (acc, parameter) => ({
        ...acc,
        [parameter]: formatNumber(row.parameters[parameter]),
      }),
      {}
    );

    return {
      id: row.id,
      optimisation_type: row.optimisation_type,
      ...parameters,
      non_relevant_alerts: formatNumber(row.non_relevant_alerts),
      relevant_alerts: formatNumber(row.relevant_alerts),
      sar_alerts: formatNumber(row.sar_alerts),
      efficiency: PercentageDisplay(
        (row.relevant_alerts + row.sar_alerts) /
          (row.non_relevant_alerts + row.relevant_alerts + row.sar_alerts)
      ),
      no_sar_loss: row.no_sar_loss,
    };
  });

  if (sortedColumn) {
    rows = sortRows(rows, sortedColumn, sortOrder);
  }

  if (parameterOptimisationIsWaiting) {
    rows.push({
      id: "skeleton",
      optimisation_type: "",
      ...optimisationTable.scenario_parameters.reduce(
        (acc, parameter) => ({
          ...acc,
          [parameter]: "",
        }),
        {}
      ),
      non_relevant_alerts: "",
      relevant_alerts: "",
      sar_alerts: "",
      efficiency: "",
    });
  }

  var totalPagesVar = null;
  var currentRowsVar = null;

  if (rows[rows.length - 1].id === "skeleton") {
    totalPagesVar = Math.ceil((rows.length - 1) / rowsPerPage);
    if (currentPage === totalPagesVar) {
      currentRowsVar = rows.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage + 1
      );
    } else {
      currentRowsVar = rows.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
      );
    }
  } else {
    totalPagesVar = Math.ceil(rows.length / rowsPerPage);
    currentRowsVar = rows.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );
  }

  const totalPages = totalPagesVar;
  const currentRows = currentRowsVar;

  useEffect(() => {
    if (tableRef.current) {
      setScrollHeight(tableRef.current.clientHeight);
    }
  }, [tableRef, currentRows]);

  useEffect(() => {
    if (parameterOptimisationIsWaiting) {
      setCurrentPage(totalPages);
      dispatch(setJustAddedNewOptimisationRow(true));
    } else if (justAddedNewOptimisationRow) {
      let segmentOverviewUpdates = null;
      const row = currentRows[currentRows.length - 1];
      segmentOverviewUpdates = {
        id: selectedRiskScore.id,
        name: selectedRiskScore.name,
        non_relevant_alerts: row.non_relevant_alerts,
        relevant_alerts: row.relevant_alerts,
        sar_alerts: row.sar_alerts,
      };
      dispatch(setSelectedRowId(row.id));

      const row_id = row.id;

      dispatch(
        fetchSupressedAlerts({
          scenarioId: selectedScenario?.id,
          segment: selectedSegment,
          subScenarioId: selectedSubScenario?.id || 0,
          riskScore: selectedRiskScore,
          row_id,
          period: selectedPeriod,
        })
      );
      dispatch(setSelectedSegmentOverviewUpdates(segmentOverviewUpdates));
    }
  }, [parameterOptimisationIsWaiting]);


  function getCurrentParams() {
    return optimisationTable.table.find((item) => item.id === 0).parameters;
  }

  return (
    <div ref={ref} className={styles.optimisationTable}>
      <Scrollbar verticalScroll={false} height={scrollHeight}>
        <div style={{ overflowX: "visible", overflowY: "none" }}>
          <table className={styles.table} ref={tableRef}>
            <thead>
              <tr className={styles.row}>
                <th
                  className={` ${styles.stickyColumnHeader}`}
                  style={{ padding: "0" }}
                  onClick={() => handleSort("optimisation_type")}>
                  <div className={styles.borderedCell}>
                    Optimisation Type{" "}
                    {sortedColumn === "optimisation_type" &&
                      (sortOrder === "asc" ? "↑" : "↓")}
                  </div>
                </th>
                {optimisationTable.scenario_parameters.map((parameter) => (
                  <th
                    key={parameter}
                    className={styles.headerCell}
                    style={{}}
                    onClick={() => handleSort(parameter)}>
                    <div title={parameter} className={styles.elipse}>
                      {parameter}{" "}
                    </div>

                    {sortedColumn === parameter &&
                      (sortOrder === "asc" ? "↑" : "↓")}
                  </th>
                ))}
                <th
                  className={styles.headerCell}
                  onClick={() => handleSort("non_relevant_alerts")}>
                  Non Relevant{" "}
                  {sortedColumn === "non_relevant_alerts" &&
                    (sortOrder === "asc" ? "↑" : "↓")}
                </th>
                <th
                  className={styles.headerCell}
                  onClick={() => handleSort("relevant_alerts")}>
                  Relevant{" "}
                  {sortedColumn === "relevant_alerts" &&
                    (sortOrder === "asc" ? "↑" : "↓")}
                </th>
                <th
                  className={styles.headerCell}
                  onClick={() => handleSort("sar_alerts")}>
                  SAR{" "}
                  {sortedColumn === "sar_alerts" &&
                    (sortOrder === "asc" ? "↑" : "↓")}
                </th>
                <th
                  className={styles.headerCell}
                  onClick={() => handleSort("efficiency")}>
                  Efficiency{" "}
                  {sortedColumn === "efficiency" &&
                    (sortOrder === "asc" ? "↑" : "↓")}
                </th>
              </tr>
            </thead>
            <TransitionGroup component={null}>
              <tbody style={{ display: "grid" }}>
                {isAddingManual && (
                  <ManualRow
                    scenarioParameters={optimisationTable?.scenario_parameters}
                    initValues={getCurrentParams()}
                    thresholdIdMap={optimisationTable?.threshold_id_map || {}}
                    dispatch={dispatch}
                  />
                )}
                {currentRows.map((row, index) => (
                  <CSSTransition
                    key={row.id}
                    timeout={300}
                    classNames={{
                      enter: styles.rowEnter,
                      enterActive: styles.rowEnterActive,
                      exit: styles.rowExit,
                      exitActive: styles.rowExitActive,
                    }}>
                    <tr
                      className={`
            ${styles.row}
            ${index === "skeleton" ? styles.skeletonRow : ""}
            ${row.id === selectedRowId ? styles.selectedRow : styles.normalRow}
            ${!row.efficiency ? styles.uneficientRow : ""}
          `}
                      onClick={() => {
                        handleRowClick(index, row);
                      }}>
                      <td
                        className={` ${
                          row.id === selectedRowId
                            ? styles.stickyColumnSelectedRow
                            : styles.stickyColumn
                        }`}
                        style={{ width: "230px", padding: "0" }}>
                        <div
                          className={`${styles.cell} `}
                          style={{ borderRight: "1px solid #ebe9fe" }}>
                          {row.id === "skeleton" ? (
                            <Skeleton animation="wave" />
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                gap: "8px",
                                alignItems: "center",
                              }}>
                              {row.id !== 0 && !isAddingManual ? (
                                <>
                                  {row.no_sar_loss && (
                                    <div
                                      className={styles.sarBulletPoint}></div>
                                  )}

                                  <Checkbox
                                    checked={selectedRows.includes(row.id)}
                                    onChange={() => toggleRowSelection(row.id)}
                                  />
                                </>
                              ) : (
                                <div style={{ width: 30 }}></div>
                              )}
                              <div style={{ whiteSpace: "nowrap" }}>
                                {row.optimisation_type}
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      {optimisationTable.scenario_parameters.map(
                        (parameter) => (
                          <td key={parameter} className={styles.cell}>
                            {row.id === "skeleton" ? (
                              <Skeleton animation="wave" />
                            ) : (
                              row[parameter]
                            )}
                          </td>
                        )
                      )}
                      <td className={styles.cell}>
                        {row.id === "skeleton" ? (
                          <Skeleton animation="wave" />
                        ) : (
                          row.non_relevant_alerts
                        )}
                      </td>
                      <td className={styles.cell}>
                        {row.id === "skeleton" ? (
                          <Skeleton animation="wave" />
                        ) : (
                          row.relevant_alerts
                        )}
                      </td>
                      <td className={styles.cell}>
                        {row.id === "skeleton" ? (
                          <Skeleton animation="wave" />
                        ) : (
                          row.sar_alerts
                        )}
                      </td>
                      <td className={styles.cell}>
                        {row.id === "skeleton" ? (
                          <Skeleton animation="wave" />
                        ) : (
                          row.efficiency
                        )}
                      </td>
                    </tr>
                  </CSSTransition>
                ))}
              </tbody>
            </TransitionGroup>
          </table>
        </div>
      </Scrollbar>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pages={totalPages}
      />
    </div>
  );
});

export default OptimisationTable;
