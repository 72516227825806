import React, { useEffect, useState } from 'react';
import styles from './Profiles.module.css'
import Overview from './overview/Overview';
import Edit from './edit/Edit';
import { useSelector } from 'react-redux';

const Profiles = ({configId, configType}) => {

  // variable deciding if the overview or edit is shown
  const showEdit = useSelector((state) => state.configuration.showEdit);

  return (
      <div className={`${styles.tabWrapper} ${showEdit ? styles.slide : ''}`}>

          <Overview configType={configType} />

          <Edit  configId={configId}/>

      </div>
  );
};

export default Profiles;
