import { useState, useEffect, useRef, Fragment } from "react";

import Pagination from "./Pagination";
import { TableTools, exportToExcel } from "./TableTools";
import { HeaderCell } from "./Header";
import { Cell } from "./Cell";
import styles from "./Table.module.css";
import FilterSelector from "./Filter";
import Scrollbar from "../scrollbar/Scrollbar";
import { PrimaryButton, SecondaryButton } from "../buttons/NormalButton";

function filterRows({ currentFilters, sortOn, searchTerm, rows }) {
  let filteredRows = rows;
  if (Object.keys(currentFilters).length > 0) {
    filteredRows = filteredRows.filter((row) => {
      return Object.entries(currentFilters).every(([field, filterValues]) => {
        const cell = row.cells.find((cell) => cell.field === field);
        if (cell) {
          return filterValues.includes(cell.value);
        }
        return true;
      });
    });
  }

  if (searchTerm) {
    const lowerSearchTerm = searchTerm.toLowerCase();
    filteredRows = filteredRows.filter((row) => {
      return row.cells.some((cell) => {
        return (
          cell.value &&
          cell.value.toString().toLowerCase().includes(lowerSearchTerm)
        );
      });
    });
  }

  if (sortOn.length === 2) {
    const [sortField, sortDirection] = sortOn;
    filteredRows.sort((a, b) => {
      const aCell = a.cells.find((cell) => cell.field === sortField);
      const bCell = b.cells.find((cell) => cell.field === sortField);
      const aValue = aCell ? aCell.value : "";
      const bValue = bCell ? bCell.value : "";

      if (aValue < bValue) return sortDirection === "ASC" ? -1 : 1;
      if (aValue > bValue) return sortDirection === "ASC" ? 1 : -1;
      return 0;
    });
  }

  return filteredRows;
}

function generateFilterOptions(headers, rows) {
  const filterOptions = {};

  headers.forEach((header) => {
    if (header.filterable) {
      const field = header.field;
      const valuesSet = new Set();

      rows.forEach((row) => {
        const cell = row.cells.find((cell) => cell.field === field);
        if (cell && cell.value != null) {
          valuesSet.add(cell.value);
        }
      });

      filterOptions[field] = Array.from(valuesSet);
    }
  });

  return filterOptions;
}

function TableGrid({
  title,
  headers,
  rows,
  pageSize = 10,
  isLoading = false,
  noDataPlaceholder = undefined,
  rowsAmount = rows.length,
  canExport = false,
  onExport = undefined,
  canSearch = true,
  filterOptions = generateFilterOptions(headers, rows),
  fetchData = {},
  onSelectActionButtons = [],
  notSelectableIds = [],
  initFilters = {},
  cellStyle = {},
  initSort = [],
  initSearchTerm = "",
  resetTable = 1,
  resetPagination = 1,
  extraContent = undefined,
  newIds = [],
  actionButtonsRight = [],
  actionButtonsLeft = [],
  actionButtonsFarRight = [],
  pinnedId = null,
  onRowClick = undefined,
  clickedIds = [],
  notClickableIds = [],
  firstRowWidth = null,
  fixedHeight = null,
  onAddRow = undefined,
  isAddingRow = false,
  setIsAddingRow = () => {},
}) {
  // Gathering pre information
  const {
    fetchFunction = undefined,
    retriggers = [], // Default to empty array if not provided
  } = fetchData;
  const canSelect = onSelectActionButtons.length > 0;

  // State for current rows and editing row
  const [currentRows, setCurrentRows] = useState(rows);
  const [editingRow, setEditingRow] = useState(null);

  useEffect(() => {
    setCurrentRows(rows);
  }, [rows]);

  // Function to add a new row
  const addRow = () => {
    if (isAddingRow) return; // Prevent adding another row if already in Add Row mode

    setIsAddingRow(true);

    const newRow = {
      id: `temp-${Date.now()}`, // Unique ID for the temporary row
      cells: headers.map((header) => ({
        field: header.field,
        value: "", // Initialize with empty values
        editType: header.type,
      })),
    };

    setEditingRow(newRow);
    setCurrentPage(1);
    setCurrentRows((prevRows) => [newRow, ...prevRows]);
  };

  // Function to confirm row creation
  const confirmRow = () => {
    if (onAddRow && editingRow) {
      onAddRow(editingRow); // Call the parent callback with the new row
    }
    setIsAddingRow(false);
    setEditingRow(null); // Reset editing mode
  };

  // Function to cancel row creation
  const cancelRow = () => {
    setCurrentRows((prevRows) =>
      prevRows.filter((row) => row.id !== editingRow.id)
    );
    setIsAddingRow(false);
    setEditingRow(null);
  };

  // Expose addRow function to parent component
  useEffect(() => {
    if (onAddRow) {
      onAddRow(addRow); // Expose the addRow function to the parent
    }
  }, [onAddRow]);

  // Loading
  const tbodyRef = useRef(null);
  const [showLoading, setShowLoading] = useState(false);
  useEffect(() => {
    let timer;
    if (isLoading) {
      timer = setTimeout(() => setShowLoading(true), 300); // Delay showing the loader by 500ms
    } else {
      clearTimeout(timer);
      setShowLoading(false); // Hide immediately if isLoading is false
    }

    return () => clearTimeout(timer);
  }, [isLoading]);

  // Filters, Pagination, Sorting, Searching
  const [currentPage, setCurrentPage] = useState(1);
  const [currentFilters, setCurrentFilters] = useState(initFilters);

  const [sortOn, setSortOn] = useState(initSort);
  const [searchTerm, setSearchTerm] = useState(initSearchTerm);

  const pinnedRow = currentRows.find((row) => row.id === pinnedId) ?? null;

  let filteredRows = currentRows.filter((row) => row.id !== pinnedId);
  let sortedRows = filteredRows;
  if (!fetchFunction) {
    filteredRows = filterRows({
      currentFilters,
      sortOn,
      searchTerm,
      rows: filteredRows,
    });
    sortedRows = filteredRows.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );
  }

  useEffect(() => {
    if (fetchFunction) {
      fetchFunction(pageSize, currentPage, currentFilters, sortOn, searchTerm);
    }
  }, [currentPage, currentFilters, sortOn, searchTerm, ...retriggers]);

  useEffect(() => {
    resetSelected();
  }, [currentFilters, sortOn, searchTerm, ...retriggers]);

  useEffect(() => {
    if (fetchFunction) {
      setSelectedIds(new Set());
      setSelectAllMode(false);
    }
  }, [currentFilters, sortOn, searchTerm]);

  // Filter popups
  const [filterPosition, setFilterPosition] = useState({ top: 0, left: 0 });
  const [openFilterField, setOpenFilterField] = useState(null);
  const outsideTableRef = useRef(null);

  const setSelectedOptions = (selectedOptions) => {
    setCurrentFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (selectedOptions.length === 0) {
        delete updatedFilters[openFilterField];
      } else {
        updatedFilters[openFilterField] = selectedOptions;
      }

      return updatedFilters;
    });
  };

  const handleOpenFilter = (field, buttonRef) => {
    const buttonRect = buttonRef.current.getBoundingClientRect();
    const tableRect = outsideTableRef.current.getBoundingClientRect();

    setFilterPosition({
      top: buttonRect.bottom - tableRect.top + 60, // Position relative to the table
      left: buttonRect.left - tableRect.left,
    });
    setOpenFilterField(field);
  };

  const handleCloseFilter = () => setOpenFilterField(null);

  // Selecting
  const rowIds = [...filteredRows, pinnedRow]
    .filter((row) => row !== null && row !== undefined)
    .map((row) => row.id);
  const [selectAllMode, setSelectAllMode] = useState(false);
  const [selectedIds, setSelectedIds] = useState(() => new Set());

  const selectableRowIds = rowIds.filter(
    (id) => !notSelectableIds.includes(id)
  );
  const actualAmountRowsSelectable = fetchFunction
    ? rowsAmount - notSelectableIds.length
    : selectableRowIds.length;

  const allSelected =
    selectableRowIds.length > 0 &&
    ((actualAmountRowsSelectable === selectedIds.size &&
      selectAllMode === false) ||
      (selectAllMode === true && selectedIds.size === 0));

  const toggleSelection = (id) => {
    console.log("selectedConfigurations toggle", id);
    setSelectedIds((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  const toggleSelectAll = () => {
    setSelectedIds(new Set());
    setSelectAllMode(!allSelected);
  };

  const resetSelected = () => {
    setSelectedIds(new Set());
    setSelectAllMode(false);
  };

  // Height Animation
  const headerHeight = 80;
  const tableRef = useRef(null);
  const [tableHeight, setTableHeight] = useState(headerHeight);

  useEffect(() => {
    if (tableRef.current) {
      if (fixedHeight) {
        setTableHeight(fixedHeight);
        return;
      }
      const newHeight = tableRef.current.scrollHeight;
      setTableHeight(newHeight);
    }
  }, [sortedRows.length]);

  // Resetting Table back to initial
  useEffect(() => {
    if (resetTable > 1) {
      setCurrentPage(1);
      setCurrentFilters(initFilters);
      setSortOn(initSort);
      setSearchTerm(initSearchTerm);
    }
  }, [resetTable]);

  useEffect(() => {
    if (resetPagination > 1) {
      setCurrentPage(1);
    }
  }, [resetPagination]);

  // Exporting
  function exportTable() {
    const headerLabels = headers.map((header) => header.label);

    const pinnedRows = pinnedRow
      ? [pinnedRow.cells.map((cell) => cell.value)]
      : [];

    const allRows = filteredRows.map((row) => {
      return row.cells.map((cell) => cell.value);
    });

    exportToExcel(headerLabels, [...pinnedRows, ...allRows], title);
  }

  // Render Row
  const renderRow = (row) => {
    if (row.id === editingRow?.id) {
      // Render the editable row and confirm/cancel buttons
      return (
        <Fragment key={row.id}>
          {/* Editable Row */}
          <tr className={styles.editableRow}>
            {row.cells.map((cell, cellIndex) => (
              <td
                key={cellIndex}
                className={`${styles.cell}`}
                style={{
                  border: "0px solid transparent",
                  overflowY: "visible",
                  ...(cellIndex === 0
                    ? {
                        minWidth: firstRowWidth,
                        maxWidth: firstRowWidth,
                        width: firstRowWidth,
                      }
                    : {}),
                }}
              >
                <div
                  className={styles.bodyLabel}
                  style={{
                    alignItems: "baseline",
                    ...cellStyle,
                  }}
                >
                  <Cell
                    key={cellIndex}
                    cellConf={cell}
                    isEditing={true}
                    onValueChange={(field, value) => {
                      setEditingRow((prevRow) => ({
                        ...prevRow,
                        cells: prevRow.cells.map((c) =>
                          c.field === field ? { ...c, value } : c
                        ),
                      }));
                    }}
                    editType={cell.editType}
                  />
                </div>
              </td>
            ))}
          </tr>

          {/* Confirm/Cancel Row */}
          <tr className={styles.confirmRow}>
            <td
              colSpan={headers.length}
              className={styles.addRow}
              style={{
                textAlign: "end",
                gap: "6px",
              }}
            >
              <SecondaryButton
                content="Cancel"
                action={cancelRow}
                style={{ marginRight: "6px" }}
              />
              <PrimaryButton
                content="Confirm"
                action={confirmRow}
                style={{ display: "inline-flex" }}
              />
            </td>
          </tr>
        </Fragment>
      );
    }

    // Regular row rendering
    return (
      <tr
        key={row.id}
        className={
          onRowClick && !notClickableIds.includes(row.id) && styles.clickable
        }
        onClick={(e) => {
          if (onRowClick && !notClickableIds.includes(row.id)) {
            onRowClick(row?.rowData ? row.rowData : row, e);
          }
        }}
      >
        {row.cells.map((cell, cellIndex) => (
          <td
            key={cellIndex}
            className={`${newIds.includes(row.id) && styles.newRow} ${
              clickedIds.includes(row.id) && styles.clicked
            }`}
            style={
              cellIndex === 0
                ? {
                    minWidth: firstRowWidth,
                    maxWidth: firstRowWidth,
                    width: firstRowWidth,
                  }
                : {}
            }
          >
            <div className={styles.bodyLabel} style={cellStyle}>
              <Cell
                key={cellIndex}
                cellConf={cell}
                canSelect={cellIndex === 0 && canSelect}
                selectable={!notSelectableIds.includes(row.id)}
                selected={
                  selectAllMode
                    ? !selectedIds.has(row.id)
                    : selectedIds.has(row.id)
                }
                setSelected={() => toggleSelection(row.id)}
              />
            </div>
          </td>
        ))}
      </tr>
    );
  };

  return (
    <div className={styles.tableContainer}>
      <TableTools
        canSearch={canSearch}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        canSelect={canSelect}
        toggleSelectAll={toggleSelectAll}
        allSelected={allSelected}
        selectAllMode={selectAllMode}
        selectedIds={selectedIds}
        onSelectActionButtons={onSelectActionButtons}
        onExport={canExport ? (onExport ? onExport : exportTable) : undefined}
        rowsAmount={rowsAmount}
        fetchFunction={fetchFunction}
        actionButtonsRight={actionButtonsRight}
        actionButtonsLeft={actionButtonsLeft}
        resetSelected={resetSelected}
        actionButtonsFarRight={actionButtonsFarRight}
        selectableRowIds={selectableRowIds}
        notSelectableIds={notSelectableIds}
        pinnedRow={pinnedRow}
      />
      <div
        className={styles.tableContentContainer}
        style={{ height: tableHeight }}
        ref={outsideTableRef}
      >
        <Scrollbar noScrollY={true} verticalScroll={false}>
          <table className={styles.table} ref={tableRef}>
            <thead style={{ height: headerHeight }}>
              <tr style={{ position: "relative", zIndex: 4 }}>
                {headers.map((header, index) => (
                  <th
                    key={index}
                    style={{
                      ...header.style,
                      ...(index === 0 && firstRowWidth
                        ? {
                            width: firstRowWidth,
                            minWidth: firstRowWidth,
                            maxWidth: firstRowWidth,
                          }
                        : {}),
                    }}
                  >
                    <div className={styles.headLabel}>
                      <HeaderCell
                        setSortOn={setSortOn}
                        sortOn={sortOn}
                        headerConf={header}
                        openFilterField={openFilterField}
                        handleOpenFilter={handleOpenFilter}
                        handleCloseFilter={handleCloseFilter}
                      />
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody ref={tbodyRef}>
              {currentRows.length > 0 ? (
                <>
                  {editingRow && renderRow(editingRow)}
                  {pinnedRow && renderRow(pinnedRow)}
                  {sortedRows.length !== 0 ? (
                    sortedRows.map((row) => {
                      if (row.id !== editingRow?.id) {
                        return renderRow(row);
                      } else {
                        return null; // already rendered editingRow
                      }
                    })
                  ) : (
                    <>
                      {!pinnedRow && !editingRow && (
                        <tr>
                          <td
                            style={{ height: 90 }}
                            colSpan={headers.length}
                            className={styles.placeholder}
                          >
                            No results found
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {noDataPlaceholder && (
                    <tr>
                      <td
                        style={{ height: 90 }}
                        colSpan={headers.length}
                        className={styles.placeholder}
                      >
                        {noDataPlaceholder}
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
          {showLoading && (
            <div
              className={styles.loadingOverlay}
              style={{
                top: tbodyRef.current?.offsetTop,
                height: tbodyRef.current?.offsetHeight,
              }}
            >
              <div className={styles.spinner}></div>
            </div>
          )}
        </Scrollbar>
        {openFilterField && (
          <div
            style={{
              position: "absolute",
              top: filterPosition.top,
              left: filterPosition.left,
              zIndex: 1000,
            }}
          >
            <FilterSelector
              options={filterOptions[openFilterField]}
              selectedOptions={currentFilters[openFilterField]}
              isFilterOpen={Boolean(openFilterField)}
              closeFilter={handleCloseFilter}
              title={openFilterField}
              setSelectedOptions={setSelectedOptions}
              outlineRight={openFilterField === headers[0].field}
            />
          </div>
        )}
      </div>
      {extraContent && extraContent}
      <Pagination
        pages={Math.ceil(
          (rowsAmount -
            (pinnedId !== null && pinnedId !== undefined ? 1 : 0) +
            (editingRow ? 1 : 0)) /
            pageSize
        )}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

export default TableGrid;
