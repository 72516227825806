import styles from "./RelevancyBadge.module.css";

const RelevancyBadge = ({ value = null }) => {
  let relevancy = "unknown";
  let typeclass = undefined;

  if (value) {
    if (value.toLowerCase().includes("non relevant")) {
      relevancy = "Non Relevant";
      typeclass = styles.nonRelevantBadge;
    } else if (value.toLowerCase() === "relevant") {
      relevancy = "Relevant";
      typeclass = styles.relevantBadge;
    } else if (value.toLowerCase() === "sar") {
      relevancy = "SAR";
      typeclass = styles.sarBadge;
    } else if (value.toLowerCase() === "alert ongoing") {
      relevancy = "Alert Ongoing";
      typeclass = styles.relevantBadge;
    }
  }

  return (
    <div className={`${styles.badgesContainer} ${typeclass}`}>{relevancy}</div>
  );
};

export default RelevancyBadge;
