import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./InputComponent.module.css";

const InputComponent = ({
  type = "normal",
  value = "",
  onChange = () => {},
  statusValue = "",
  currencyOptions = ["USD", "EUR"],
  infoText = "",
  badges = [],
  isEditable = true, // Default to true, meaning inputs are editable
  label,
}) => {
  // Local state for value in case it's not controlled
  const [inputValue, setInputValue] = useState(value);

  // Sync local state with the value prop if it changes externally
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue); // Update local state
    if (onChange) {
      onChange(e); // Trigger the parent's onChange handler
    }
  };

  const renderInput = () => {
    switch (type) {
      case "normal":
        return (
          <div className={styles.inputWrapper}>
            <input
              type="text"
              value={inputValue}
              onChange={handleChange}
              className={styles.input}
              readOnly={!isEditable} // Changed to readOnly
            />
          </div>
        );
      case "password":
        return (
          <div className={styles.inputWrapper}>
            <input
              type="password"
              value={inputValue}
              onChange={handleChange}
              className={styles.input}
              readOnly={!isEditable} // Changed to readOnly
            />
          </div>
        );
      case "number":
        return (
          <div className={styles.inputWrapper}>
            <input
              type="number"
              value={inputValue}
              onChange={handleChange}
              className={styles.input}
              readOnly={!isEditable} // Changed to readOnly
            />
          </div>
        );
      case "comment":
        return (
          <textarea
            style={{ resize: "none" }}
            value={inputValue}
            onChange={handleChange}
            className={styles.textarea}
            readOnly={!isEditable} // Changed to readOnly
          />
        );
      case "info":
        return (
          <div className={styles.inputWrapper}>
            <input
              type="text"
              value={inputValue}
              onChange={handleChange}
              className={styles.input}
              readOnly={!isEditable} // Changed to readOnly
            />
            <svg
              className={styles.infoIcon}
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none">
              <g clipPath="url(#clip0_3437_21913)">
                <path
                  d="M6.06065 6.00065C6.21739 5.5551 6.52675 5.17939 6.93395 4.94007C7.34116 4.70076 7.81991 4.61328 8.28544 4.69313C8.75096 4.77297 9.1732 5.015 9.47737 5.37634C9.78154 5.73767 9.94802 6.195 9.94732 6.66732C9.94732 8.00065 7.94732 8.66732 7.94732 8.66732M8.00065 11.334H8.00732M14.6673 8.00065C14.6673 11.6826 11.6826 14.6673 8.00065 14.6673C4.31875 14.6673 1.33398 11.6826 1.33398 8.00065C1.33398 4.31875 4.31875 1.33398 8.00065 1.33398C11.6826 1.33398 14.6673 4.31875 14.6673 8.00065Z"
                  stroke="#717BBC"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_3437_21913">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        );
      case "money":
        return (
          <div className={styles.inputWrapper}>
            <div className={styles.moneyIcon}>$</div>
            <input
              type="text"
              value={inputValue}
              onChange={handleChange}
              className={styles.input}
              readOnly={!isEditable} // Changed to readOnly
            />
            <select
              className={styles.currencyPicker}
              onChange={onChange}
              disabled={!isEditable}>
              {currencyOptions.map((currency, index) => (
                <option key={index} value={currency}>
                  {currency}
                </option>
              ))}
            </select>
          </div>
        );
      case "link":
        return (
          <div className={styles.inputWrapper}>
            <span className={styles.linkPrefix}>http://</span>
            <input
              type="text"
              value={inputValue}
              onChange={handleChange}
              className={styles.input}
              style={{ backgroundColor: "white" }}
              readOnly={!isEditable} // Changed to readOnly
            />
          </div>
        );
      case "status":
        return (
          <div className={styles.badgesWrapper}>
            {badges.map((badge, index) => (
              <>{badge}</>
            ))}
          </div>
        );
      default:
        return (
          <div className={styles.inputWrapper}>
            <input
              type="text"
              value={inputValue}
              onChange={handleChange}
              className={styles.input}
              readOnly={!isEditable} // Changed to readOnly
            />
          </div>
        );
    }
  };

  return (
    <div className={styles.inputContainer}>
      <label className={styles.label}>{label}</label>
      {renderInput()}
    </div>
  );
};

InputComponent.propTypes = {
  type: PropTypes.oneOf([
    "normal",
    "number",
    "comment",
    "info",
    "money",
    "link",
    "status",
    "password",
  ]),
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  statusValue: PropTypes.string,
  currencyOptions: PropTypes.arrayOf(PropTypes.string),
  infoText: PropTypes.string,
  isEditable: PropTypes.bool, // New Prop to control editability
};

export default InputComponent;
