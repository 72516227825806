import React, { useState, useEffect, useRef, forwardRef } from "react";
import styles from "./Selector.module.css";
import Scrollbar from "../../../../layout/scrollbar/Scrollbar";

const Selector = forwardRef(
  ({ options, selectedOption, onOptionSelect, label }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const combinedRef = ref || dropdownRef;

    const [scrollHeight, setScrollHeight] = useState("auto");

    useEffect(() => {
      const elementHeight = 51;
      setScrollHeight(`${elementHeight * options.length}px`);
    }, [options]);

    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

    const selectOption = (option) => {
      onOptionSelect(option);
      setIsOpen(false);
    };

    const handleClickOutside = (event) => {
      if (combinedRef.current && !combinedRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const formatOption = (option) => {
      return option.replace(/_/g, " ");
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    return (
      <div className={styles.inputWithLabel} ref={combinedRef}>
        <div className={styles.label}>{label}</div>
        <div className={styles.input} onClick={toggleDropdown}>
          <div className={styles.title}>
            <div className={styles.content}>
              {selectedOption && (
                <div className={styles.label}>
                  {formatOption(selectedOption)}
                </div>
              )}
            </div>
          </div>
          <img
            className={styles.dropdownIcon}
            alt=""
            src={`${process.env.PUBLIC_URL}/Dropdown Icon.svg`}
          />
        </div>

        {options && (
          <div
            className={`${styles.dropdownContent} ${
              isOpen ? styles.show : ""
            }`}>
            <Scrollbar
              height={scrollHeight}
              style={{
                transition: "min-height 0.3s ease",
                height: scrollHeight,
              }}>
              {options.map((option) => (
                <button
                  key={option}
                  className={styles.dropdownItem}
                  title={option}
                  onClick={() => selectOption(option)}>
                  {formatOption(option)}
                </button>
              ))}
            </Scrollbar>
          </div>
        )}
      </div>
    );
  }
);

export default Selector;
