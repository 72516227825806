import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Visualisation.module.css";
import {
  addNewVisualisation,
  fetchBoxPlotData,
} from "../../../features/atl_tool/atlToolSlice";

const AddNewVisualisationButton = () => {
  const dispatch = useDispatch();

  const {
    selectedScenario,
    selectedSegment,
    selectedRiskScore,
    visualisationsThresholds,
    selectedSubScenario,
    selectedPeriod,
  } = useSelector((state) => state.atlTool);
  const boxPlotData = useSelector((state) => state.atlTool.boxPlotData);

  useEffect(() => {
    dispatch(
      fetchBoxPlotData({
        scenarioId: selectedScenario?.id,
        segmentId: selectedSegment.id,
        subScenarioId: selectedSubScenario?.id || 0,
        riskScore: selectedRiskScore,
        period: selectedPeriod,
      })
    );
  }, [
    selectedScenario,
    selectedSegment,
    selectedRiskScore,
    selectedSubScenario,
  ]);

  const handleNewVisualisation = () => {
    const xOption = boxPlotData[0].threshold_name; // treshold_name
    const yOption = boxPlotData[1].threshold_name; // treshold_name

    let xSelectorVar = [];
    let ySelectorVar = [];

    visualisationsThresholds.map((data) => {
      xSelectorVar.push(data.threshold_name);
      ySelectorVar.push(data.threshold_name);
    });
    console.log("xSelector: ", xSelectorVar);
    console.log("ySelector: ", ySelectorVar);

    dispatch(
      addNewVisualisation({
        chartType: "Box Plot",
        xAxis: xOption,
        yAxis: yOption,
        xSelector: {
          xOption: xOption,
          xOptions: xSelectorVar,
        },
        ySelector: {
          yOption: yOption,
          yOptions: ySelectorVar,
        },
      })
    );
  };

  return (
    <div
      className={styles.addNewVisualisationButton}
      onClick={handleNewVisualisation}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="white"
        width={28}
        height={28}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>

      <span>Add New Visualisation</span>
    </div>
  );
};

export default AddNewVisualisationButton;
