import React, { useEffect, useState } from 'react';
import styles from '../Labels.module.css';
import RegularInput from '../../inputs/RegularInput';
import Selector from '../../selector/Selector';
import { useDispatch, useSelector } from 'react-redux';
import { SecondaryButton , PrimaryButton} from '../../../../layout/buttons/NormalButton';
import {
  setSelectedDataLabelId,
  setSelectedDataLabelsDetails,
  setShowEdit,
  fetchDataSources,
  fetchDataLabels,
  createDataLabel,
  linkDataLabel,
  fetchConfigurationDataLabels,
  updateDataLabel
  
} from '../../../../features/configuration/configurationSlice';

const Edit = ({configId}) => {
  const dispatch = useDispatch();
//YYY
  // Get the needed data from the Redux store
  const {
    dataLabels,
    infoLists,
    selectedDataLabelDetails,
    dataSources,
    selectedDataLabelId,
    dataLabelToLink
  } = useSelector((state) => state.configuration);

  // Local state for the form fields
  const [currentName, setCurrentName] = useState('');
  const [currentDescription, setCurrentDescription] = useState('');
  const [currentDefinition, setCurrentDefinition] = useState('');
  const [currentDataSource, setCurrentDataSource] = useState(null);
  const [currentInfoList, setCurrentInfoList] = useState([]);

  // Initialize local state when selectedDataLabelDetails changes
  useEffect(() => {
    if (selectedDataLabelDetails) {
      setCurrentName(selectedDataLabelDetails.name || '');
      setCurrentDescription(selectedDataLabelDetails.description || '');
      setCurrentDefinition(selectedDataLabelDetails.definition || '');
      setCurrentDataSource(selectedDataLabelDetails.data_source || null);
      setCurrentInfoList(selectedDataLabelDetails.list || []);
    } 
    else 
    {
      // Clear all fields
      resetFields();
    }
  }, [selectedDataLabelDetails]);


  // Function to reset all fields
  const resetFields = () => 
  {
    setCurrentName('');
    setCurrentDescription('');
    setCurrentDefinition('');
    setCurrentDataSource(null);
    setCurrentInfoList([]);
  };



  const resetView = () =>
  {
        if(configId != 0)
        {
        
            dispatch(fetchDataLabels());
        }
  }

  useEffect(() => {
    if (dataLabelToLink) {
        resetView();

        if(configId > 0)
          {
            console.log("dataLabelToLink", dataLabelToLink);
              dispatch(linkDataLabel({dataLabel:dataLabelToLink, config:configId}));
              dispatch(fetchConfigurationDataLabels(configId));
          }

        dispatch(setShowEdit(false));
        if (dataLabels.length > 0) {
          dispatch(setSelectedDataLabelId(dataLabels[0].id));
        }
    }
    }, [dataLabelToLink]);



  // Functions to handle changes in the editable fields
  const handleNameChange = (newValue) => {
    setCurrentName(newValue);
  };

  const handleDescriptionChange = (newValue) => {
    setCurrentDescription(newValue);
  };

  const handleDefinitionChange = (newValue) => {
    console.log("newValueDEFDEF", newValue);
    setCurrentDefinition(newValue);
  };

  const handleCancelClick = () => {
    dispatch(setShowEdit(false));
  };

  // Properties for the name badge
  const nameBadgeProps = [
    {
      type: 'text',
      text: currentName,
      color: '#5925DC',
      bgColor: 'rgba(181, 120, 255, 0.20)',
      hasBullet: false,
      onChange: handleNameChange,
    },
  ];

  // Properties for the data source badge
  const dataSourceProps = currentDataSource
    ? [
        {
          type: 'badge',
          text: currentDataSource.name,
          color: '#027A48',
          bgColor: '#ECFDF3',
          hasBullet: true,
        },
      ]
    : [
        {
          type: 'text',
          text: 'Not Selected',
        },
      ];

  // Parse the info lists to useful data
  const generateInfoListProps = (list) => {
    if (!list || list.length === 0) {
      return [
        {
          type: 'text',
          text: '',
        },
      ];
    } else {
     
      return list.map((item) => ({
        type: 'badge',
        text: item.name,
        color: '#B42318',
        bgColor: '#FEF3F2',
        hasBullet: true,
      }));
    }
  };

  

  // Properties of the info lists
  const infoListBadgeProps = generateInfoListProps(currentInfoList);

  useEffect(() => {
    if (infoListBadgeProps) {
      console.log('infoListBadgeProps', infoListBadgeProps);
    }
  }, [infoListBadgeProps]);




  // Properties for the description badge
  const descriptionBadgeProps = [
    {
      type: 'text',
      text: currentDescription,
      placeholder: 'Enter description',
      onChange: handleDescriptionChange,
    },
  ];

  // Properties for the definition badge
  const definitionBadgeProps = [
    {
      type: 'text',
      text: currentDefinition,
      color: '#B42318',
      bgColor: '#FEF3F2',
      hasBullet: false,
      onChange: handleDefinitionChange,
    },
  ];

  // Options for the data source selector
  const dataSourceOptions = dataSources.map((source) => ({
    id: source.id,
    name: source.name,
  }));

  // Fetch data sources when the component mounts
  useEffect(() => {
    dispatch(fetchDataSources());
  }, []);

  // Handle selection changes
  const handleOptionSelect = (selector, option) => {


    console.log("handleOptionSelect", selector, option);


    if (selector === 'Data Source') {
      setCurrentDataSource(option);
    } else if (selector === 'Info List') {
      toggleSelection(currentInfoList, setCurrentInfoList, option);
    }
  };

  const toggleSelection = (list, setList, option) => {


    console.log("toggleSelection", list, option);
    
    const exists = list.some((item) => item.id === option.id);
    const updatedList = exists
      ? list.filter((item) => item.id !== option.id)
      : [...list, option];

      console.log("toggleSelection update", updatedList);
    setList(updatedList);
  };


  // Save the changes made to the fields
  const handleSaveClick = () => {
     const list_columns = findSelectedColumnInDefinition();

    const updatedDataLabelDetails = {
      ...selectedDataLabelDetails,
      name: currentName,
      description: currentDescription,
      definition: currentDefinition,
      data_source: currentDataSource,
      data_source_id: currentDataSource?.id,
      list: currentInfoList,
    };


    if (!selectedDataLabelDetails) {
        const data_source_ids = currentDataSource ? currentDataSource.id : 0;
        console.log("lñlñlñlñlñlñlñlñl", currentDefinition, updatedDataLabelDetails);
        const list_ids = list_columns ?  list_columns.map((item) =>{console.log("yiorioyurioyiory" , item);  return  item.id}) : [];
        const data = {datalabel:{name:currentName,description:currentDescription,definition:currentDefinition,data_source_id:data_source_ids},list_column_ids:list_ids}
        
        dispatch(createDataLabel({dataLabel:data}));
    }
    else
    {
      console.log("lñlñlñlñlñlñlñlñl", selectedDataLabelDetails.id, updatedDataLabelDetails);

      const data_source_ids = currentDataSource ? currentDataSource.id : 0;
      console.log("lñlñlñlñlñlñlñlñl", currentDefinition, updatedDataLabelDetails);
      const list_ids = list_columns ?  list_columns.map((item) =>{console.log("yiorioyurioyiory" , item);  return  item.id}) : [];
      const data = {name:currentName,description:currentDescription,definition:currentDefinition,data_source_id:data_source_ids, list_column_ids:list_ids}; 
      

        dispatch(updateDataLabel({dataLabel:data, id:selectedDataLabelDetails.id}));
        dispatch(setShowEdit(false));
        resetView();

    } 
  };


  const findSelectedColumnInDefinition = () => {

    const definition = currentDefinition ;

    console.log("definition column", definition);

    //remove empty and puctuation characters
    const columns = definition.split(/(\s+|=|\[|\]|\(|\)|\.|,)/).map((column) => column.replace(/[^a-zA-Z0-9]/g, ''))?.filter((column) => column.trim() !== "");

    console.log("currentList column", currentInfoList);

    const infoListAvailableColumns = currentInfoList.map((infoList) => infoList.list_columns).flat();
    console.log("infoListAvailableColumns", infoListAvailableColumns);

    const selectedColumn = infoListAvailableColumns.filter((column) => columns.includes(column.name.replace(/[^a-zA-Z0-9]/g, '')));

    console.log("selectedColumn", selectedColumn);
    console.log("infoListAvailableColumns", infoListAvailableColumns);
    console.log("columns", columns);
    

    return selectedColumn;
    // const selectedInfoListColumns = columns.filter((column) => column.includes(id));

  };


  const isDisabled = !currentDataSource || !currentName;

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.leftContainer}>
          <div className={styles.text}>Create new label</div>
          <Selector
            color="#027A48"
            bgColor="#ECFDF3"
            options={dataSourceOptions}
            selectedOption={dataSourceProps}
            hasBullet={true}
            onOptionSelect={(option) => handleOptionSelect('Data Source', option)}
            label="Data Source"
          />
          <Selector
            color="#B42318"
            bgColor="#FEF3F2"
            options={infoLists}
            hasBullet={true}
            selectedOption={infoListBadgeProps}
            onOptionSelect={(option) => handleOptionSelect('Info List', option)}
            label="Info List"
          />
        </div>
        <div className={styles.rightContainer}>
          <RegularInput
            title="Name"
            withBackground={true}
            isEditable={true}
            isBadgeEditor={false}
            content={nameBadgeProps}
          />
          {currentInfoList && currentInfoList.length > 0 && (
            <RegularInput
              title="Info List"
              withBackground={true}
              content={infoListBadgeProps}
            />
          )}
          <RegularInput
            title="Description"
            withBackground={true}
            isEditable={true}
            content={descriptionBadgeProps}
          />
          <RegularInput
            title="Definition"
            withBackground={true}
            isEditable={true}
            badgesSelection={[...(infoListBadgeProps || []), ...(dataSourceProps || [])]}
            isBadgeEditor={true}
            content={definitionBadgeProps}
            definitionCallback={handleDefinitionChange}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <SecondaryButton action={handleCancelClick} content="Cancel"  style={{ flex: 1}} />
        <PrimaryButton action={handleSaveClick} content={selectedDataLabelDetails ? "Edit" : "Create"}   style={{ flex: 1}} disabled={isDisabled} />
      </div>
    </div>
  );
};

export default Edit;
