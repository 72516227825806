// components/atl_tool/AtlTool.js

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  fetchOptimisationTable,
  resetSegments,
  fetchScenarios,
  fetchThresholds,
  fetchSegments,
  fetchRiskScores,
  setSelectedSegmentOverview,
} from "../../features/atl_tool/atlToolSlice";
import styles from "./AtlTool.module.css";
import ScenarioSelection from "./scenario_selection/ScenarioSelection";
import SegmentOverview from "./segment_overview/SegmentOverview";
import Visualisation from "./visualisation/Visualisation";
import AddNewVisualisationButton from "./visualisation/AddNewVisualisationButton";
import AlertsTable from "./alerts_table/AlertsTable";
import useDebouncedAction from "../../features/DebounceHook";

const AtlTool = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const stateBack = location.state?.backState;

  const {
    selectedPeriod,
    selectedScenario,
    selectedSegment,
    visualisations,
    selectedRiskScore,
    selectedSegmentOverview,
    optimisationTable,
    suppressedAlerts,
    selectedSubScenario,
    selectedRowId,
  } = useSelector((state) => state.atlTool);

  const noSelectedPeriod =
    !selectedPeriod || Object.keys(selectedPeriod).length === 0;
  const showSegments = selectedScenario && !noSelectedPeriod;
  // this fixes a bug where reloading the page after a risk score was selected would break the page
  if (selectedSegmentOverview > -1 && !selectedRiskScore) {
    dispatch(setSelectedSegmentOverview(-1));
  }

  const getTotalAlerts = () => {
    let totalAlerts = 0;
    if (selectedRiskScore) {
      totalAlerts =
        selectedRiskScore.non_relevant_alerts +
        selectedRiskScore.relevant_alerts +
        selectedRiskScore.sar_alerts;
    }
    return totalAlerts;
  };

  useEffect(() => {
    dispatch(fetchScenarios());
  }, []);

  useDebouncedAction({
    action: () =>
      dispatch(fetchThresholds({ selectedScenario, selectedSubScenario })),
    dependencies: [selectedSubScenario, selectedScenario],
  });

  useDebouncedAction({
    action: () =>
      dispatch(
        fetchSegments({
          scenarioId: selectedScenario.id,
          subScenarioId: selectedSubScenario?.id || 0,
          period: selectedPeriod,
        })
      ),
    dependencies: [
      showSegments,
      selectedScenario,
      selectedSubScenario,
      selectedPeriod,
    ],
    condition: showSegments,
    elseAction: () => dispatch(resetSegments()),
  });

  useDebouncedAction({
    action: () =>
      dispatch(
        fetchRiskScores({
          scenarioId: selectedScenario?.id,
          segmentId: selectedSegment.id,
          subScenarioId: selectedSubScenario?.id || 0,
          period: selectedPeriod,
        })
      ),
    dependencies: [
      selectedSegment,
      selectedScenario,
      selectedSubScenario,
      selectedPeriod,
    ],
    condition: selectedSegment && showSegments,
  });

  useDebouncedAction({
    action: () =>
      dispatch(
        fetchOptimisationTable({
          scenarioId: selectedScenario?.id,
          segmentId: selectedSegment.id,
          subScenarioId: selectedSubScenario?.id || 0,
          riskScore: selectedRiskScore,
          period: selectedPeriod,
        })
      ),
    dependencies: [
      selectedSegment,
      selectedSubScenario,
      selectedPeriod,
      selectedRiskScore,
    ],
    condition: selectedRiskScore && selectedSegment && showSegments,
  });

  return (
    <div className={styles.atlTool}>
      <div className={styles.sectionWrapper}>
        <ScenarioSelection navBack={stateBack} showSegments={showSegments} />
      </div>
      {selectedScenario && selectedSegment && (
        <>
          <div
            className={styles.sectionWrapper}
            style={{ paddingBottom: "0px", borderRadius: "12px" }}>
            <SegmentOverview />
          </div>
          {selectedSegmentOverview > -1 && selectedRiskScore !== null && (
            <>
              {visualisations.map((visualisation) => (
                <div className={styles.visualisationSectionWrapper}>
                  <Visualisation id={visualisation.key} />
                </div>
              ))}

              {getTotalAlerts() > 0 && optimisationTable && (
                <AddNewVisualisationButton />
              )}

              {suppressedAlerts && selectedRowId && (
                <>
                  <div className={styles.sectionWrapper}>
                    <AlertsTable />
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AtlTool;
