import React, { useState, useRef } from "react";
import { useEffect } from "react";
import styles from "./FileUploadModal.module.css";
import { useDispatch } from "react-redux";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import Scrollbar from "../../../layout/scrollbar/Scrollbar";

export default function FileUploadModal({
  hideModalFunction,
  dispatchUploadFunction,
  uploadStatus,
  dispatchUploadStatus,
  uploadDetails,
}) {
  const [files, setFiles] = useState([]); // Store all files
  const [progress, setProgress] = useState({}); // Store progress for each file
  const uploadIntervals = useRef({}); // Store intervals to cancel uploads
  const inputFileRef = useRef(null); // Ref to reset input

  const [processingProgress, setProcessingProgress] = useState(0);
  const modalRef = useRef(null);

  const [scrollHeight, setScrollHeight] = useState();
  const tableRef = React.createRef();

  const [continueAllowed, setContinueAllowed] = useState(false);
  const [dropErrorMsg, setDropErrorMsg] = useState(null);
  const [blobExists, setBlobExists] = useState(false);

  const successPathRef = useRef(null);
  const errorPathRef = useRef(null);
  const errorPathRef2 = useRef(null);

  const dispatch = useDispatch();

  // Unique file counter
  const fileCounter = useRef(0);

  // Generate a unique identifier for each file
  const generateFileId = () => {
    fileCounter.current += 1;
    return `file-${fileCounter.current}`;
  };

  // State to manage modal content
  const [modalState, setModalState] = useState("initial");

  // Handle file drop or select
  const handleFileSelect = (e) => {
    const selectedFiles = e.target.files || e.dataTransfer.files;
    handleUpload(selectedFiles);
    if (inputFileRef.current) {
      inputFileRef.current.value = ""; // Reset input field after each selection
    }
  };

  useEffect(() => {
    console.log("uploadDetails ", uploadDetails);
  }, [uploadDetails]);

  React.useEffect(() => {
    if (tableRef.current) {
      setScrollHeight(tableRef.current.clientHeight);
    }
  }, [tableRef]);

  const handleUpload = (selectedFiles) => {
    const fileList = Array.from(selectedFiles).map((file) => ({
      id: generateFileId(), // Assign a unique ID to each file
      file, // Store the actual file for download
      name: file.name,
      size: file.size,
      progress: 0,
    }));

    setFiles((prevFiles) => [...prevFiles, ...fileList]);
    simulateProgress(fileList);
    // loadFilesIntoMemory(fileList);
  };

  const handleHideModal = () => {
    hideModalFunction();
    dispatch(dispatchUploadStatus({ status: "idle" }));
    setModalState("initial");
  };

  // Simulate upload progress for each file
  const simulateProgress = (fileList) => {
    console.log("filelist ", fileList);
    fileList.forEach((file) => {
      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress[file.id] || 0;
          if (newProgress >= 100) {
            clearInterval(uploadIntervals.current[file.id]);
          }
          return {
            ...prevProgress,
            [file.id]: Math.min(newProgress + 30, 100),
          };
        });
      }, 250);
      uploadIntervals.current[file.id] = interval;
    });
  };

  useEffect(() => {
    if (uploadStatus === "success") {
      const blobIsPresent = uploadDetails.details.some((file) =>
        file.uploadMsg.includes("blob already exists")
      );
      setBlobExists(blobIsPresent);
      // check if a filr has blob existing in the uploadDetails

      if (blobIsPresent) {
        setModalState("error");
        setProcessingProgress(100);
      } else {
        setModalState("success");
        setTimeout(() => {
          setModalState("summary");
        }, 2000);
      }

      setProcessingProgress(100);
    } else if (uploadStatus === "error") {
      setModalState("error");

      setProcessingProgress(100);
    }
  }, [uploadStatus]);

  const loadFilesIntoMemory = (fileList) => {
    // load happen too fast back to simulated ones
    fileList.forEach((fileWrapper) => {
      const file = fileWrapper.file; // Extract the actual File object

      if (file instanceof Blob) {
        const reader = new FileReader();

        // Event listener for reading progress
        reader.onprogress = (event) => {
          if (event.lengthComputable) {
            const percentCompleted = Math.round(
              (event.loaded / event.total) * 100
            );
            // Update the progress in state
            setProgress((prevProgress) => ({
              ...prevProgress,
              [fileWrapper.id]: percentCompleted, // Use fileWrapper.id to track progress
            }));
          }
        };

        // Event listener when reading is completed
        reader.onloadend = () => {
          // File has been loaded into memory
          console.log("File loaded into memory:", file.name);

          // Mark the file as fully loaded (100% progress)
          setProgress((prevProgress) => ({
            ...prevProgress,
            [fileWrapper.id]: 100, // Use fileWrapper.id to update progress
          }));

          // Handle the loaded file (e.g., store it in state for later upload)
          // Example: setLoadedFiles(prevFiles => [...prevFiles, reader.result]);
        };

        // Handle errors during the read process
        reader.onerror = () => {
          console.error("Error reading file:", file.name);
        };

        // Start reading the file into memory
        reader.readAsArrayBuffer(file); // Or readAsDataURL, readAsText, etc.
      } else {
        console.error("Provided file is not a Blob or File type:", file);
      }
    });
  };

  // Remove a file
  const handleRemoveFile = (fileId) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
    setProgress((prevProgress) => {
      const newProgress = { ...prevProgress };
      delete newProgress[fileId];
      return newProgress;
    });
    clearInterval(uploadIntervals.current[fileId]);
    delete uploadIntervals.current[fileId];
  };

  const cancelUpload = (fileId) => {
    clearInterval(uploadIntervals.current[fileId]);

    const resetProgressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        const currentProgress = prevProgress[fileId] || 0;
        const newProgress = Math.max(currentProgress - 10, 0);
        if (newProgress <= 0) {
          clearInterval(resetProgressInterval);
        }
        return { ...prevProgress, [fileId]: newProgress };
      });
    }, 50);
    setTimeout(() => {
      delete uploadIntervals.current[fileId];
      setFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
    }, 1000);
  };

  const downloadFile = (file) => {
    const fileURL = URL.createObjectURL(file.file);
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = file.name;
    link.click();
    URL.revokeObjectURL(fileURL); // Free up memory
  };

  const handleContinue = () => {
    setModalState("processing");

    setProcessingProgress(0);

    // Simulate processing progress
    const progressInterval = setInterval(() => {
      setProcessingProgress((prev) => {
        // If the upload status is success, set progress to 100 and clear interval

        // Simulate progress increment until 100
        if (prev >= 100) {
          clearInterval(progressInterval);
          return 100;
        }

        return prev + 10; // Increment progress by 10
      });
    }, 200);

    console.log("files ", files);

    dispatch(dispatchUploadFunction({ files: files }));
  };

  useEffect(() => {
    // if (files.length === 1) {
    //   // If only one file, check if it is a zip file
    //   const file = files[0];
    //   if (file.type !== 'application/zip' && !file.name.endsWith('.zip')) {
    //     setContinueAllowed(false);
    //     setDropErrorMsg("If you are uploading only one file, it must be a zip file.");
    //     return;
    //   }
    // } else
    if (files.length > 1) {
      // If more than one file, none of them should be a zip file
      const hasZipFile = files.some(
        (file) => file.type === "application/zip" || file.name.endsWith(".zip")
      );
      if (hasZipFile) {
        setDropErrorMsg(
          "None of the files should be a zip file when uploading more than one file."
        );
        setContinueAllowed(false);
        return;
      }
    }
    setContinueAllowed(true);
    setDropErrorMsg(null);
  }, [files]);

  const handleClickOutSide = (e) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(e.target) &&
      modalState !== "processing"
    ) {
      handleHideModal();
    }
  };

  useEffect(() => {
    if (modalState === "success" && successPathRef.current) {
      if (successPathRef.current) {
        setTimeout(() => {
          const path = successPathRef.current;
          const length = path.getTotalLength();

          // Reset any previous styling
          path.style.strokeDasharray = length;
          path.style.strokeDashoffset = -length; // Start fully hidden (right to left)
          path.style.transition = "none";

          // Trigger a reflow to apply the initial dash offset
          path.getBoundingClientRect();

          // Apply the animation
          path.style.transition = "stroke-dashoffset .5s ease-in-out";
          path.style.strokeDashoffset = 0;
        }, 100);
      }
    }

    if (modalState === "error" && errorPathRef.current) {
      if (errorPathRef.current) {
        setTimeout(() => {
          const path = errorPathRef2.current;
          const length = path.getTotalLength();

          // Reset any previous styling
          path.style.strokeDasharray = length;
          path.style.strokeDashoffset = length; // Start fully hidden (right to left)
          path.style.transition = "none";

          const path2 = errorPathRef.current;
          const length2 = path2.getTotalLength();

          // Reset any previous styling
          path2.style.strokeDasharray = length2;
          path2.style.strokeDashoffset = -length2; // Start fully hidden (right to left)
          path2.style.transition = "none";

          // Trigger a reflow to apply the initial dash offset
          path.getBoundingClientRect();

          // Apply the animation
          path.style.transition = "stroke-dashoffset .5s ease-in-out";
          path.style.strokeDashoffset = 0;

          setTimeout(() => {
            // Trigger a reflow to apply the initial dash offset
            path2.getBoundingClientRect();

            // Apply the animation
            path2.style.transition = "stroke-dashoffset .5s ease-in-out";
            path2.style.strokeDashoffset = 0;
          }, 500);
        }, 100);
      }
    }
  }, [successPathRef, modalState]);

  // Helper function to normalize the filename to match with uploaded one
  function normalizeFileName(fileName) {
    return fileName.replace(/[() ]/g, "_"); // Replace parentheses and spaces with underscores
  }

  const findFileUploadDetails = (targetFileName) => {
    console.log("targetFileName ", targetFileName, uploadDetails);
    return uploadDetails.details.find(
      (detail) =>
        normalizeFileName(detail.fileName) === normalizeFileName(targetFileName)
    );
  };

  const renderFiles = () => (
    <div ref={tableRef} style={{ maxHeight: "300px" }}>
      {files
        .slice()
        .reverse()
        .map(
          (
            file // ensure last added on top of the list
          ) => (
            <>
              <div
                key={file.id}
                className={
                  modalState === "initial"
                    ? styles.fileItem
                    : modalState === "summary" &&
                      findFileUploadDetails(file.name).uploadStatus === "error"
                    ? styles.fileItemError
                    : modalState === "summary" &&
                      findFileUploadDetails(file.name).uploadStatus ===
                        "success"
                    ? styles.fileItemSuccess
                    : ""
                }
              >
                <div className={styles.fileItemData}>
                  {file.name.endsWith(".xls") || file.name.endsWith(".xlsx") ? (
                    <img
                      className={styles.fileIcon}
                      alt=""
                      src={`${process.env.PUBLIC_URL}/xls.png`}
                    />
                  ) : file.name.endsWith(".zip") ? (
                    <img
                      className={styles.fileIcon}
                      alt=""
                      src={`${process.env.PUBLIC_URL}/zip.png`}
                    />
                  ) : (
                    <img
                      className={styles.fileIcon}
                      alt=""
                      src={`${process.env.PUBLIC_URL}/txt.png`}
                    />
                  )}

                  <div className={styles.fileInfo}>
                    <span className={styles.fileName}>{file.name}</span>
                    <span className={styles.fileSize}>
                      {(file.size / 1024 / 1024).toFixed(2)} MB
                    </span>
                  </div>

                  {progress[file.id] < 100 ? (
                    <div
                      id="cancelUpload"
                      className={styles.cancelIcon}
                      onClick={() => cancelUpload(file.id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M12 4L4 12"
                          stroke="#B0AFD9"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4 4L12 12"
                          stroke="#B0AFD9"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  ) : (
                    <div className={styles.fileActions}>
                      <button
                        id="downloadFile"
                        className={styles.actionButton}
                        onClick={() => downloadFile(file)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                        >
                          <path
                            d="M17.5 13V16.3333C17.5 16.7754 17.3244 17.1993 17.0118 17.5118C16.6993 17.8244 16.2754 18 15.8333 18H4.16667C3.72464 18 3.30072 17.8244 2.98816 17.5118C2.67559 17.1993 2.5 16.7754 2.5 16.3333V13"
                            stroke="#BDB4FE"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5.83301 8.83301L9.99967 12.9997L14.1663 8.83301"
                            stroke="#BDB4FE"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10 13V3"
                            stroke="#BDB4FE"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>

                      {modalState === "initial" && (
                        <button
                          className={styles.actionButton}
                          onClick={() => handleRemoveFile(file.id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                          >
                            <path
                              d="M2.5 5.5H17.5"
                              stroke="#BDB4FE"
                              strokeWidth="1.39167"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15.8337 5.5V17.1667C15.8337 18 15.0003 18.8333 14.167 18.8333H5.83366C5.00033 18.8333 4.16699 18 4.16699 17.1667V5.5"
                              stroke="#BDB4FE"
                              strokeWidth="1.39167"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6.66699 5.5013V3.83464C6.66699 3.0013 7.50033 2.16797 8.33366 2.16797H11.667C12.5003 2.16797 13.3337 3.0013 13.3337 3.83464V5.5013"
                              stroke="#BDB4FE"
                              strokeWidth="1.39167"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                  )}
                </div>

                {progress[file.id] < 100 && (
                  <div className={styles.progressBar}>
                    <div
                      className={styles.progress}
                      style={{ width: `${progress[file.id]}%` }}
                    ></div>
                  </div>
                )}
              </div>
              {modalState === "summary" && (
                <>
                  {findFileUploadDetails(file.name).uploadStatus === "error" ? (
                    <div className={styles.statusMsgError}>
                      {" "}
                      {findFileUploadDetails(file.name).uploadStatus +
                        " : " +
                        findFileUploadDetails(file.name).uploadMsg}
                    </div>
                  ) : (
                    <div className={styles.statusMsgSuccess}>
                      {" "}
                      {findFileUploadDetails(file.name).uploadStatus +
                        " : " +
                        findFileUploadDetails(file.name).uploadMsg}
                    </div>
                  )}
                </>
              )}
            </>
          )
        )}
    </div>
  );

  return (
    <div className={styles.modal} onClick={(e) => handleClickOutSide(e)}>
      <div
        ref={modalRef}
        className={styles.modalContent}
        style={{ width: modalState === "initial" ? "450px" : "350px" }}
      >
        <CSSTransition
          in={modalState === "initial" || modalState === "summary"}
          timeout={100}
          classNames={{
            enter: styles.fadeEnter,
            enterActive: styles.fadeEnterActive,
            exit: styles.fadeExit,
            exitActive: styles.fadeExitActive,
          }}
          unmountOnExit
        >
          <div className={styles.modalHeader}>
            <h2 className={styles.titleHeader}>
              {modalState === "initial"
                ? "Import Configuration"
                : "Import Results"}{" "}
            </h2>
            <button
              className={styles.closeButton}
              onClick={() => handleHideModal()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
              >
                <path
                  d="M18 6L6 18"
                  stroke="#BDB4FE"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6 6L18 18"
                  stroke="#BDB4FE"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </CSSTransition>

        <div className={styles.modalBody}>
          {/* Transition for Initial Content */}
          <CSSTransition
            in={modalState === "initial" || modalState === "summary"}
            timeout={1000}
            classNames={{
              enter: styles.fadeEnter,
              enterActive: styles.fadeEnterActive,
              exit: styles.fadeExit,
              exitActive: styles.fadeExitActive,
            }}
            unmountOnExit
          >
            <div>
              <label htmlFor="config-name" className={styles.inputLabel}>
                Name
              </label>
              <input
                type="text"
                id="config-name"
                placeholder="Configuration 1203"
                className={styles.input}
              />

              {modalState === "initial" && (
                <>
                  <div
                    className={styles.fileDropZone}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={handleFileSelect}
                  >
                    <div className={styles.dropIcon}>
                      <svg
                        style={{ display: "flex", alignSelf: "center" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="35"
                        viewBox="0 0 36 35"
                        fill="none"
                      >
                        <path
                          d="M18 18.958V30.6247"
                          stroke="#905EE2"
                          strokeWidth="2.91667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.33281 21.7277C5.24933 20.6207 4.43197 19.2817 3.94265 17.812C3.45333 16.3423 3.30488 14.7806 3.50854 13.245C3.71221 11.7095 4.26264 10.2404 5.11816 8.94913C5.97367 7.65783 7.11183 6.57815 8.44642 5.79186C9.78101 5.00558 11.277 4.53333 12.8212 4.41086C14.3653 4.2884 15.9171 4.51894 17.3589 5.08502C18.8008 5.65111 20.0949 6.53789 21.1433 7.6782C22.1916 8.81851 22.9667 10.1824 23.4099 11.6667H26.0203C27.4283 11.6665 28.7991 12.1192 29.9301 12.958C31.0611 13.7967 31.8923 14.9769 32.301 16.3243C32.7097 17.6717 32.6742 19.1149 32.1997 20.4406C31.7253 21.7663 30.837 22.9042 29.6661 23.6863"
                          stroke="#905EE2"
                          strokeWidth="2.91667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.167 24.7913L18.0003 18.958L23.8337 24.7913"
                          stroke="#905EE2"
                          strokeWidth="2.91667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p className={styles.dropLabel}>
                        Drag & drop or click to choose files
                      </p>
                    </div>
                    <input
                      type="file"
                      id="file-upload"
                      ref={inputFileRef}
                      onChange={handleFileSelect}
                      multiple // Allow multiple file selection
                      accept=".zip, .xls, .xlsx, .txt"
                      className={styles.fileInput}
                    />
                  </div>

                  <div className={styles.dropLegendHolder}>
                    <small className={styles.dropLegend}>
                      Supported formats: zip, xls, xlsx, txt
                    </small>
                    <small className={styles.dropLegend}>Max: 25MB</small>
                  </div>
                </>
              )}

              {dropErrorMsg && (
                <div className={styles.dropLegendHolder}>
                  <small className={styles.dropLegendError}>
                    {dropErrorMsg}
                  </small>
                </div>
              )}

              {files.length > 2 ? (
                <Scrollbar style={{ height: scrollHeight }}>
                  {renderFiles()}
                </Scrollbar>
              ) : (
                renderFiles()
              )}
            </div>
          </CSSTransition>

          {modalState !== "initial" && (
            <>
              <CSSTransition
                in={modalState === "processing"}
                timeout={250}
                classNames={{
                  enter: styles.fadeEnter,
                  enterActive: styles.fadeEnterActive,
                  exit: styles.fadeExit,
                  exitActive: styles.fadeExitActive,
                }}
                unmountOnExit
              >
                <div className={styles.processingContent}>
                  <div className={styles.spinner}></div>
                  <p className={styles.processingLabel}>Processing ...</p>
                  <div className={styles.progressBar2}>
                    <div
                      className={styles.progress2}
                      style={{
                        width: `${processingProgress}%`,
                        backgroundColor: "#905ee2", // Purple
                      }}
                    ></div>
                  </div>
                </div>
              </CSSTransition>

              <CSSTransition
                in={modalState === "success"}
                timeout={250}
                classNames={{
                  enter: styles.fadeEnter,
                  enterActive: styles.fadeEnterActive,
                  exit: styles.fadeExit,
                  exitActive: styles.fadeExitActive,
                }}
                unmountOnExit
              >
                <div className={styles.processingContentFinal}>
                  {/* Success Icon */}

                  <svg
                    className={styles.confirmIcon}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#027A48"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path ref={successPathRef} d="M20 6 9 17l-5-5" />
                  </svg>

                  <div className={styles.finalModalHolder}>
                    <p className={styles.successLabel}>Success</p>
                    <p className={styles.successLegend}>
                      Your changes have been saved successfully
                    </p>
                  </div>
                  <div className={styles.progressBar2}>
                    <div
                      className={styles.progress2}
                      style={{
                        width: "100%",
                        backgroundColor: "#027A48", // Green
                      }}
                    ></div>
                  </div>
                </div>
              </CSSTransition>
              <CSSTransition
                in={modalState === "error"}
                timeout={250}
                classNames={{
                  enter: styles.fadeEnter,
                  enterActive: styles.fadeEnterActive,
                  exit: styles.fadeExit,
                  exitActive: styles.fadeExitActive,
                }}
                unmountOnExit
              >
                <div className={styles.processingContentFinal}>
                  {/* Error Icon */}
                  <svg
                    className={styles.failedIcon}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#B42318"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path ref={errorPathRef} d="M18 6 6 18" />
                    <path ref={errorPathRef2} d="m6 6 12 12" />
                  </svg>
                  <div className={styles.finalModalHolder}>
                    <p className={styles.successLabel}>Error</p>
                    <p className={styles.successLegend}>
                      {blobExists
                        ? "Please wait for the previous job to be completed before creating a new configuration"
                        : "The file could not be uploaded"}
                    </p>
                  </div>
                  <div className={styles.progressBar2}>
                    <div
                      className={styles.progress2}
                      style={{
                        width: "100%",
                        backgroundColor: "#B42318", // Red
                      }}
                    ></div>
                  </div>
                </div>
              </CSSTransition>
            </>
          )}

          {modalState !== "initial" && modalState !== "summary" && (
            <div className={styles.progressBar2}>
              <div
                className={styles.progress2}
                style={{
                  width: `${processingProgress}%`,
                  backgroundColor:
                    modalState === "processing"
                      ? "#905ee2" // Purple
                      : modalState === "success"
                      ? "#027A48" // Green
                      : "#B42318", // Red,
                }}
              ></div>
            </div>
          )}
        </div>

        {/* Footer */}
        {modalState === "initial" && (
          <div className={styles.modalFooter}>
            <button
              className={styles.cancelButton}
              onClick={() => handleHideModal()}
            >
              Cancel
            </button>
            <button
              className={styles.continueButton}
              disabled={
                continueAllowed == false || files.length == 0 ? true : false
              }
              onClick={handleContinue}
            >
              Continue
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
