import styles from "./RiskBadge.module.css";

const RiskBadge = ({
  value = null,
  selected = false,
  onClick = undefined,
  disabled = false,
  style = {},
}) => {
  let risk = "Unknown";
  let typeclass = styles.unknownRisk;

  if (value) {
    if (value.toLowerCase().includes("high")) {
      risk = "High";
      typeclass = styles.highRisk;
    } else if (value.toLowerCase() === "medium") {
      risk = "Medium";
      typeclass = styles.mediumRisk;
    } else if (value.toLowerCase() === "low") {
      risk = "Low";
      typeclass = styles.standardRisk;
    } else if (value.toLowerCase() === "standard") {
      risk = "Standard";
      typeclass = styles.standardRisk;
    }
  }

  return (
    <div
      style={style}
      className={`${typeclass} ${styles.cellContent} ${
        onClick && styles.clickable
      } ${selected && styles.selected} ${disabled && styles.disabled}`}
      onClick={!disabled ? onClick : undefined}>
      {risk}
    </div>
  );
};

export default RiskBadge;
