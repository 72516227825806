import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Configuration.module.css";
import view_style from "../../layout/Layout.module.css";
import HeaderSection from "../../layout/HeaderSection";
import Profiles from "./profiles/Profiles";
import Labels from "./labels/Labels";
import {
  fetchDataLabels,
  fetchProfiles,
  fetchTimeBaseOptions,
  fetchScenarios,
  setShowEdit,
  fetchConfigurationDataLabels,
  fetchConfigurationProfiles,
  fetchConfigurationParametersList,
  fetchConfigurationScenarios,
  fetchInfoLists,
  fetchViewOptions,
  fetchRiskLevelOptions,
  fetchCustomerSegments,
  setActiveTab,
  fetchConfigurationDetails,
} from "../../features/configuration/configurationSlice";
import Parameters from "./parameters/Parameters";

import Scenarios from "./scenarios/Scenarios";
import { useParams } from "react-router-dom";

import { useLocation } from "react-router-dom";

// the tabs in the configuration view
const TABS = ["Label", "Profile", "Parameters", "Scenario"];

const Configuration = () => {
  const dispatch = useDispatch();

  const location = useLocation();

  // id is the catorgory id
  // logic is a flag to decide if we want to go to the scenario tab + edit
  const { id, logic } = useParams();

  const { activeTab, showEdit, selectedConfigurationDetails } = useSelector(
    (state) => state.configuration
  );

  // fetch data needed from database
  useEffect(() => {
    if (id > 0) {
      dispatch(fetchConfigurationDataLabels(id));
      dispatch(fetchConfigurationProfiles(id));
      dispatch(fetchConfigurationParametersList(id));
      dispatch(fetchConfigurationScenarios(id));
      dispatch(fetchTimeBaseOptions());
      if (Object.keys(selectedConfigurationDetails).length === 0) {
        dispatch(fetchConfigurationDetails(id));
      }
    } else {
      dispatch(fetchInfoLists());
      dispatch(fetchDataLabels());
      dispatch(fetchProfiles());
      dispatch(fetchTimeBaseOptions());
      dispatch(fetchScenarios());
    }

    dispatch(fetchViewOptions());
    dispatch(fetchRiskLevelOptions());
    dispatch(fetchCustomerSegments());

    // TODO: link with real endpoint

    if (logic && logic === "1") {
      dispatch(setActiveTab("Scenario"));
      dispatch(setShowEdit(true));
    } else {
      dispatch(setActiveTab("Label"));
      dispatch(setShowEdit(false));
    }
  }, [id]);

  const profiles = useSelector((state) => state.configuration.profiles);

  // keep track of the active tab from the configuration
  const [underlineStyle, setUnderlineStyle] = useState({});
  const tabsRef = useRef([]);

  useEffect(() => {
    updateUnderlinePosition(TABS.indexOf(activeTab));

    // Add a resize event listener
    window.addEventListener("resize", handleResize);

    return () => {
      // Cleanup the event listener on component unmount
      window.removeEventListener("resize", handleResize);
    };
  }, [activeTab]);

  // update the position of the underline
  const handleResize = () => {
    updateUnderlinePosition(TABS.indexOf(activeTab));
  };

  // when clicking on a tab
  const handleTabClick = (tabName, index) => {
    dispatch(setActiveTab(tabName));
    dispatch(setShowEdit(false));
    updateUnderlinePosition(index);
  };

  // update the position of the underline
  const updateUnderlinePosition = (index) => {
    const currentTab = tabsRef.current[index];
    if (currentTab) {
      setUnderlineStyle({
        left: currentTab.offsetLeft,
        width: currentTab.clientWidth,
      });
    }
  };
  return (
    <div className={styles.atlTool}>
      <div className={view_style.sectionWrapper}>
        <HeaderSection
          SectionName="Configuration"
          AdditionalText={
            selectedConfigurationDetails?.name &&
            selectedConfigurationDetails?.name
          }
        />
        <div className={styles.tabWrapper}>
          <div className={styles.tabContainer}>
            {TABS.map((tab, index) => (
              <button
                key={tab}
                ref={(el) => (tabsRef.current[index] = el)}
                className={`${styles.tabButton} ${
                  activeTab === tab ? styles.active : ""
                }`}
                onClick={() => handleTabClick(tab, index)}
              >
                {tab}
              </button>
            ))}
            <div className={styles.underline} style={underlineStyle}></div>
          </div>
        </div>

        <div className={styles.tabContent}>
          {activeTab === "Label" && (
            <Labels configId={id} configType={location.state?.config} />
          )}

          {activeTab === "Profile" && (
            <Profiles configId={id} configType={location.state?.config} />
          )}

          {activeTab === "Parameters" && (
            <Parameters configId={id} configType={location.state?.config} />
          )}

          {activeTab === "Scenario" && (
            <Scenarios configId={id} configType={location.state?.config} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Configuration;
