import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  notifySuccess,
  notifyError,
} from "../../../layout/ToastNotifications.jsx";

import {
  setParameterOptimisationIsWaiting,
  fetchOptimisationTable,
  startOptimisation,
  startManualOptimisation,
  setOptimisationAmount,
  setOptimisationType,
  setOptimisationAmountType,
  setNoSarLoss,
  checkOptimisationStatus,
  setSelectedRowId,
  setJustAddedNewOptimisationRow,
  setInitOptimisationParams,
  setSelectedSegmentOverviewUpdates,
  deleteRows,
} from "../../../features/atl_tool/atlToolSlice.js";
import common_styles from "../AtlTool.module.css";
import styles from "./ParameterOptimisation.module.css";

import HeaderSection from "../../../layout/HeaderSection.js";
import RiskScoreSelection from "./RiskScoreSelection.js";
import OptimisationTable from "./optimisation_table/OptimisationTable.js";
import FakeProgressBar from "./FakeProgressBar.js";
import ModalPopUp from "../../../layout/modal/Modal.js";

import Checkbox from "../../../layout/buttons/Checkbox.js";
import RadioButtonsContainer from "../../../layout/buttons/RadioButton.js";
import ExportButton from "../../../layout/buttons/ExportButton.js";
import ToggleButton from "../../../layout/buttons/ToggleButton.js";
import {
  PrimaryButton,
  SecondaryButton,
  PrimaryDropDownButton,
} from "../../../layout/buttons/NormalButton.js";
import NumberInput from "../../../layout/input/ValueInput.js";
import {
  InputSelector,
  OptionClass,
} from "../../../layout/selector/Selector.js";

const OPTIMISATION_TYPES = [
  new OptionClass("sar_loss", "SAR Loss", "sar_loss"),
  new OptionClass("relevant_loss", "Relevant Loss", "relevant_loss"),
  new OptionClass(
    "non_relevant_loss",
    "Non Relevant Loss",
    "non_relevant_loss"
  ),
];

const OPTIMISATION_AMOUNT_TYPES = {
  ratio: "Percentage",
  abs: "Absolute Number",
};

const SearchBox = () => {
  return (
    <div className={styles.searchInputBox}>
      <div style={{ minWidth: "20px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none">
          <path
            d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
            stroke="#BDB4FE"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      <input
        type="text"
        placeholder="Search"
        value={""}
        onChange={() => {}}
        className={styles.searchInput}
      />
    </div>
  );
};

function AutomateIcon() {
  return (
    <img
      className={styles.optionIcon}
      alt=""
      src={`${process.env.PUBLIC_URL}/automate-option.svg`}
    />
  );
}

function PencilIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Frame">
        <path
          id="Vector"
          d="M21.1739 6.81189C21.7026 6.28332 21.9997 5.56636 21.9998 4.81875C21.9999 4.07113 21.703 3.3541 21.1744 2.82539C20.6459 2.29668 19.9289 1.99961 19.1813 1.99951C18.4337 1.99942 17.7166 2.29632 17.1879 2.82489L3.84193 16.1739C3.60975 16.4054 3.43805 16.6904 3.34193 17.0039L2.02093 21.3559C1.99509 21.4424 1.99314 21.5342 2.01529 21.6217C2.03743 21.7092 2.08285 21.7891 2.14673 21.8529C2.21061 21.9167 2.29055 21.962 2.37809 21.984C2.46563 22.006 2.55749 22.0039 2.64393 21.9779L6.99693 20.6579C7.3101 20.5626 7.59511 20.392 7.82693 20.1609L21.1739 6.81189Z"
          stroke="#D9D6FE"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_2"
          d="M15 5L19 9"
          stroke="#D9D6FE"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}

const AutomateModalParams = ({ params, setCanOpt }) => {
  const {
    optimisationType,
    optimisationAmount,
    optimisationAmountType,
    dispatch,
    noSarLoss,
  } = params;


  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      <InputSelector
        label={"Loss Type"}
        options={OPTIMISATION_TYPES}
        selectedId={optimisationType}
        setState={(typeDate) => dispatch(setOptimisationType(typeDate))}
      />
      <div>
        <div style={{ display: "flex", gap: "12px", alignItems: "end" }}>
          <NumberInput
            value={optimisationAmount}
            setValue={(value) => dispatch(setOptimisationAmount(value))}
            setValid={setCanOpt}
            label={"Value"}
          />
          <RadioButtonsContainer
            options={OPTIMISATION_AMOUNT_TYPES}
            currentKey={optimisationAmountType}
            setKey={(value) => dispatch(setOptimisationAmountType(value))}
          />
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <ToggleButton
          checked={noSarLoss}
          onChange={() => dispatch(setNoSarLoss(!noSarLoss))}
        />
        <div className={styles.smallText}>No SAR Loss</div>
      </div>
    </div>
  );
};

const AutomateModal = ({ open, handleClose, params }) => {
  const { handleStartOptimisation, dispatch } = params;
  const [canOpt, setCanOpt] = useState(true);

  return (
    <ModalPopUp
      handleClose={handleClose}
      isOpen={open}
      title={"Optimisation Settings"}
      description={
        "Set your preferences and click 'Continue' to optimize, or 'Cancel' to exit."
      }
      content={
        <AutomateModalParams
          params={params}
          handleClose={() => handleClose()}
          setCanOpt={setCanOpt}
        />
      }
      actionButtons={[
        <SecondaryButton
          content={"Cancel"}
          action={() => {
            dispatch(setInitOptimisationParams());
            handleClose();
          }}
          style={{ flex: 1 }}
        />,
        <PrimaryButton
          content={"Continue"}
          disabled={!canOpt}
          action={() => {
            handleStartOptimisation();
            dispatch(setInitOptimisationParams());
            handleClose();
          }}
          style={{ flex: 1 }}
        />,
      ]}
      icon={
        <img
          style={{ width: 25, height: 25 }}
          alt=""
          src={`${process.env.PUBLIC_URL}/automate.svg`}
        />
      }
    />
  );
};

const OptimisationButton = ({ params, active, setIsAddingManual }) => {
  const [isAutomateOpen, setIsAutomateOpen] = useState(false);

  function openAutomateModal() {
    setIsAutomateOpen(true);
  }

  const content = (
    <div style={{ display: "flex", gap: 8, padding: 8, alignItems: "center" }}>
      <div className={styles.plusIcon}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none">
          <path
            d="M3.7998 8.00098H12.1998"
            stroke="#8174FE"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.99902 3.80078V12.2008"
            stroke="#8174FE"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className={styles.text}>New Optimisation</div>
    </div>
  );

  return (
    <div style={{ marginLeft: 12 }}>
      <PrimaryDropDownButton
        content={content}
        action={[
          {
            name: "Automate",
            action: openAutomateModal,
            icon: <AutomateIcon />,
          },
          {
            name: "Manual",
            action: () => setIsAddingManual(true),
            icon: <PencilIcon />,
          },
        ]}
        disabled={!active}
      />
      <AutomateModal
        open={isAutomateOpen}
        handleClose={() => setIsAutomateOpen(false)}
        params={params}
      />
    </div>
  );
};

const TableTools = ({
  toggleAllRowsSelection,
  areAllRowsSelected,
  optimisationTable,
  allOptimisationSelectedRows,
  setIsModalOpen,
  canUseOptimisationButton,
  canClickDeleteAll,
  isAddingManual,
  setIsAddingManual,
  handleManualOptimisation,
  canManualOpt,
  params,
}) => {
  const canDelete = allOptimisationSelectedRows.length > 0;
  const openWidth = 35;
  const [widthDeletion, setWidthDeletion] = useState(canDelete ? openWidth : 0);
  const [opacity, setOpacity] = useState(canDelete ? 1 : 0);

  useEffect(() => {
    if (canDelete) {
      setWidthDeletion(openWidth);
      setOpacity(1);
    } else {
      setWidthDeletion(0);
      setOpacity(0);
    }
  }, [allOptimisationSelectedRows]);

  return (
    <div className={styles.tableTools}>
      {isAddingManual ? (
        <div style={{ width: "30px" }}></div>
      ) : (
        <Checkbox
          checked={areAllRowsSelected}
          onChange={toggleAllRowsSelection}
          disabled={!canClickDeleteAll}
        />
      )}

      <div
        className={styles.deleteButtonContainer}
        style={{ width: widthDeletion, opacity: opacity }}>
        <div
          style={{
            width: 24,
            height: "100%",
            display: "flex",
            cursor: "pointer",
          }}
          onClick={() => setIsModalOpen(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className={styles.eraseIcon}>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
            />
          </svg>
        </div>
      </div>
      {isAddingManual ? (
        <div className={styles.modalActionsContainer}>
          <PrimaryButton
            action={handleManualOptimisation}
            disabled={!canManualOpt}
            content={"Run"}
            style={{ padding: 8, minWidth: 134 }}
          />
          <SecondaryButton
            action={() => setIsAddingManual(false)}
            content={"Cancel"}
            style={{ padding: 8, minWidth: 134 }}
            noPadding={true}
          />
        </div>
      ) : (
        <OptimisationButton
          params={params}
          active={canUseOptimisationButton}
          setIsAddingManual={setIsAddingManual}
        />
      )}
      <div className={styles.tableToolRight}>
        <SearchBox />
        <div style={{ marginLeft: "1rem" }}>
          <ExportButton
            data={optimisationTable}
            componentName={"ParameterOptimisation"}
          />
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <g clipPath="url(#clip0_4655_651594)">
                        <path d="M6.66649 14.1686L9.99982 17.5019M9.99982 17.5019L13.3332 14.1686M9.99982 17.5019V10.0019M17.3998 15.0769C18.1243 14.5674 18.6676 13.8404 18.9509 13.0012C19.2342 12.162 19.2427 11.2544 18.9752 10.4101C18.7076 9.56576 18.178 8.82864 17.4632 8.3057C16.7484 7.78276 15.8855 7.5012 14.9998 7.5019H13.9498C13.6992 6.52512 13.2302 5.61794 12.5783 4.84863C11.9263 4.07932 11.1083 3.46795 10.1858 3.06053C9.26338 2.6531 8.26053 2.46025 7.25276 2.49649C6.245 2.53274 5.25858 2.79712 4.36778 3.26975C3.47698 3.74238 2.70501 4.41094 2.10998 5.22509C1.51495 6.03924 1.11237 6.97777 0.93254 7.97002C0.752714 8.96228 0.800331 9.98239 1.07181 10.9536C1.34328 11.9248 1.83154 12.8217 2.49982 13.5769" stroke="#7A5AF8" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_4655_651594">
                            <rect width="20" height="20" fill="white" />
                        </clipPath>
                    </defs>
                </svg> */}
        </div>
        <div style={{ marginLeft: "1rem" }}>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none">
              <path
                d="M5 10.3398H15M2.5 5.33984H17.5M7.5 15.3398H12.5"
                stroke="#6941C6"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

const ParameterOptimisation = () => {
  const dispatch = useDispatch();
  const {
    parameterOptimisationIsWaiting,
    selectedScenario,
    selectedSubScenario,
    selectedSegment,
    selectedRiskScore,
    optimisationType,
    noSarLoss,
    optimisationAmountType,
    optimisationAmount,
    optimisationTable,
    optimisationStatus,
    loading,
    selectedPeriod,
    manualThresholds,
    canManualOpt,
  } = useSelector((state) => state.atlTool);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allOptimisationSelectedRows, setAllOptimisationSelectedRows] =
    useState([]);

  const [isAddingManual, setIsAddingManual] = useState(false);
  const canUseOptimisationButton =
    !isAddingManual &&
    !(allOptimisationSelectedRows.length > 0) &&
    !parameterOptimisationIsWaiting;
  const canClickDeleteAll = optimisationTable?.table?.length > 1 || false;

  const confirmDeleteRows = async () => {
    try {
      const result = await dispatch(
        deleteRows({ row_ids: allOptimisationSelectedRows })
      ).unwrap();

      notifySuccess(
        `Successfully deleted ${result.deleted_count} ${
          result.deleted_count > 1 ? "rows" : "row"
        }.`
      );

      await dispatch(
        fetchOptimisationTable({
          scenarioId: selectedScenario?.id,
          segmentId: selectedSegment.id,
          subScenarioId: selectedSubScenario?.id || 0,
          riskScore: selectedRiskScore,
          period: selectedPeriod,
        })
      );

      setIsModalOpen(false);
      setAllOptimisationSelectedRows([]);

      dispatch(setSelectedSegmentOverviewUpdates(null));
    } catch (error) {
      notifyError(`Failed to delete rows: ${error.message || "Unknown error"}`);
    }
  };

  function toggleRowSelection(id) {
    setAllOptimisationSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(id)) {
        return prevSelectedRows.filter((rowId) => rowId !== id);
      } else {
        return [...prevSelectedRows, id];
      }
    });
  }

  const areAllRowsSelected =
    allOptimisationSelectedRows.length + 1 === optimisationTable?.rows &&
    allOptimisationSelectedRows.length !== 0;

  const toggleAllRowsSelection = () => {
    if (areAllRowsSelected) {
      setAllOptimisationSelectedRows([]);
    } else {
      if (optimisationTable) {
        const allIds = optimisationTable.table
          .filter((item) => item.id !== 0)
          .map((item) => item.id);
        setAllOptimisationSelectedRows(allIds);
      }
    }
  };

  const optimisationTableRef = useRef(null);

  const statusIntervalRef = useRef(null);
  const FakeProgressBarRef = useRef(null);

  useEffect(() => {
    return () => {
      if (statusIntervalRef.current) {
        clearInterval(statusIntervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    setInitOptimisationParams();
    setIsAddingManual(false);
    setAllOptimisationSelectedRows([]);
    dispatch(setJustAddedNewOptimisationRow(false));
  }, [
    selectedScenario,
    selectedSubScenario,
    selectedSegment,
    selectedRiskScore,
  ]);

  useEffect(() => {
    return () => {
      if (parameterOptimisationIsWaiting) {
        if (FakeProgressBarRef.current) {
          FakeProgressBarRef.current.completeProgress();
        }
      } else {
        if (FakeProgressBarRef.current) {
          FakeProgressBarRef.current.startProgress();
        }
      }
    };
  }, [parameterOptimisationIsWaiting]);

  async function fetchTableAgain() {
    const fetchTableAction = await dispatch(
      fetchOptimisationTable({
        scenarioId: selectedScenario?.id,
        segmentId: selectedSegment.id,
        subScenarioId: selectedSubScenario?.id || 0,
        riskScore: selectedRiskScore,
        period: selectedPeriod,
      })
    );
    const optimisationTable = fetchTableAction.payload;
    // Ensure the table has rows
    if (
      optimisationTable &&
      optimisationTable.table &&
      optimisationTable.table.length > 0
    ) {
      // Find the row with the largest ID
      const rowWithMaxId = optimisationTable.table.reduce(
        (maxRow, currentRow) =>
          currentRow.id > maxRow.id ? currentRow : maxRow,
        optimisationTable.table[0]
      );

      // Dispatch to select the row with the largest ID
      dispatch(setSelectedRowId(rowWithMaxId.id));
      console.log("Row with the largest ID selected: ", rowWithMaxId);
    }
  }

  const performOptimisation = async ({
    startAction,
    optimisationParams,
    onSuccessMessage,
  }) => {
    if (loading) return; // Avoid starting an optimisation when one is already running

    try {
      const actionResult = await dispatch(startAction(optimisationParams));

      if (startAction.fulfilled.match(actionResult)) {
        const payload = actionResult.payload;
        console.log("response startOptimisation", payload);

        if (payload.job_id) {
          if (FakeProgressBarRef.current) {
            FakeProgressBarRef.current.startProgress();
          }

          dispatch(setParameterOptimisationIsWaiting(true));
          const intervalId = setInterval(async () => {
            const statusActionResult = await dispatch(
              checkOptimisationStatus(payload.job_id)
            );

            const statusPayload = statusActionResult.payload;

            if (
              statusPayload.status === "completed" ||
              statusPayload.status === "failed"
            ) {
              clearInterval(statusIntervalRef.current);
              statusIntervalRef.current = null;
              notifySuccess(onSuccessMessage);
              setIsAddingManual(false);
              await fetchTableAgain();
            }
          }, 5000);
          statusIntervalRef.current = intervalId;
        } else if (payload) {
          notifySuccess(onSuccessMessage);
          setIsAddingManual(false);
          await fetchTableAgain();
        }
      }

      if (startAction.rejected.match(actionResult)) {
        const error = actionResult.payload;
        throw error;
      }
    } catch (error) {
      notifyError(`Error creating optimisation: ${error.message}`);
      console.error("Error creating optimisation: ", error);
    }
  };

  const handleManualOptimisation = async () => {
    const params = {
      scenarioId: selectedScenario?.id,
      segmentId: selectedSegment.id,
      subScenarioId: selectedSubScenario?.id || 0,
      riskScore: selectedRiskScore,
      period: selectedPeriod,
      manualThresholds,
    };

    await performOptimisation({
      startAction: startManualOptimisation,
      optimisationParams: params,
      onSuccessMessage: "Added new manual optimisation",
    });
  };

  // Function for standard optimisation
  const handleStartOptimisation = async () => {
    const params = {
      scenarioId: selectedScenario?.id,
      segmentId: selectedSegment.id,
      subScenarioId: selectedSubScenario?.id || 0,
      riskScore: selectedRiskScore,
      optimisationType,
      optimisationAmountType,
      optimisationAmount,
      noSarLoss,
      period: selectedPeriod,
    };

    await performOptimisation({
      startAction: startOptimisation,
      optimisationParams: params,
      onSuccessMessage: "Added new optimisation",
    });
  };

  // const handleSaveToReport = () => {
  //   if (optimisationTableRef.current) {
  //     const optimisationTableHtml = optimisationTableRef.current.outerHTML;
  //     // Save the HTML to use later (e.g., store in state, send to server)
  //     console.log("Captured HTML:", optimisationTableHtml);
  //   }
  // };

  return (
    <div className={common_styles.section}>
      <HeaderSection
        SectionName="Parameter Optimisation"
        AdditionalText={selectedSegment.name}
        Count={
          optimisationTable
            ? optimisationTable.table.length > 1
              ? optimisationTable.table.length - 1
              : null
            : null
        }
        CanAddToReport={true}
        CaptureRef={optimisationTableRef}
        CaptureSectionName="Parameter Optimisation"
      />

      <RiskScoreSelection />

      <TableTools
        toggleAllRowsSelection={toggleAllRowsSelection}
        areAllRowsSelected={areAllRowsSelected}
        optimisationTable={optimisationTable}
        allOptimisationSelectedRows={allOptimisationSelectedRows}
        setIsModalOpen={setIsModalOpen}
        isAddingManual={isAddingManual}
        canUseOptimisationButton={canUseOptimisationButton}
        canClickDeleteAll={canClickDeleteAll}
        setIsAddingManual={setIsAddingManual}
        handleManualOptimisation={handleManualOptimisation}
        canManualOpt={canManualOpt}
        params={{
          optimisationType,
          dispatch,
          optimisationAmount,
          optimisationAmountType,
          noSarLoss,
          handleStartOptimisation,
        }}
      />

      <div style={{ width: "100%" }}>
        {parameterOptimisationIsWaiting === true && (
          <div className={styles.optimisationLoadingWrapper}>
            Optimisation loading...
          </div>
        )}

        {optimisationStatus === "failed job" && (
          <div className={styles.optimisationLoadingWrapper}>
            Error making the optimisation
          </div>
        )}

        <FakeProgressBar ref={FakeProgressBarRef} />
        
        {(optimisationStatus === "failed" ||
          optimisationStatus === "failed job") && (
          <div className={styles.optimisationLoadingWrapper}>
            Error getting the optimisation
          </div>
        )}

        <div>
          {optimisationTable && (
            <OptimisationTable
              selectedRows={allOptimisationSelectedRows}
              toggleRowSelection={toggleRowSelection}
              isAddingManual={isAddingManual}
              ref={optimisationTableRef}
            />
          )}
        </div>
      </div>

      <ModalPopUp
        handleClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        title={"Confirm Deletion"}
        description={`Are you sure you want to delete ${
          allOptimisationSelectedRows.length
        } 
        row${allOptimisationSelectedRows.length > 1 ? "s" : ""}?`}
        actionButtons={[
          <SecondaryButton
            style={{ flex: 1 }}
            action={() => setIsModalOpen(false)}
            content={"Cancel"}
          />,
          <PrimaryButton
            style={{ flex: 1 }}
            action={() => confirmDeleteRows()}
            content={"Continue"}
          />,
        ]}
      />
    </div>
  );
};

export default ParameterOptimisation;
