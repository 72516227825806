import { Scrollbar } from "react-scrollbars-custom";
import styles from "./Scrollbar.module.css";

const CustomScrollbar = ({
  children,
  height = "100%",
  width = "100%",
  horizontalScroll = true,
  verticalScroll = true,
  ...rest
}) => {
  return (
    <Scrollbar
      style={{
        position: "relative",
        height,
        width,
        overflowX: horizontalScroll ? "auto" : "hidden",
        overflowY: verticalScroll ? "auto" : "hidden",
      }}
      trackXProps={
        horizontalScroll && {
          style: {
            zIndex: "100",
            height: "5px",
            backgroundColor: "var(--Primary-4)",
          },
        }
      }
      thumbXProps={
        horizontalScroll && { style: { backgroundColor: "var(--Primary-5)" } }
      }
      trackYProps={
        verticalScroll && {
          style: {
            zIndex: "100",
            right: "5px",
            width: "5px",
            backgroundColor: "var(--Primary-4)",
          },
        }
      }
      thumbYProps={
        verticalScroll && { style: { backgroundColor: "var(--Primary-5)" } }
      }
      wrapperProps={{
        renderer: (props) => {
          const { elementRef, key, ...restProps } = props;
          return (
            <span
              {...restProps}
              ref={elementRef}
              key={key}
              style={{
                marginBottom: "-5px",
                position: "absolute",
                inset: "0px 0px 5px 0px",
                overflow: "hidden",
                maxHeight: "100%",
              }}
            />
          );
        },
      }}
      {...rest}>
      {children}
    </Scrollbar>
  );
};

export default CustomScrollbar;
