import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { store } from "./store/store"; // Adjust the import path as needed
import NavBar from "./layout/NavBar";
import AtlTool from "./components/atl_tool/AtlTool";
import ScenarioOverview from "./components/scenario_overview/ScenarioOverview";
import MatrixOverlap from "./components/overlap_analysis/matrix_overlap/MatrixOverlap";
import MiblReport from "./components/mibl_report/miblReport";
import Configuration from "./components/configuration/Configuration";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/auth/PrivateRoute";
import MatrixScenarios from "./components/overlap_analysis/matrix_scenarios/MatrixScenarios";
import ConfigurationTable from "./components/configuration/configuration_table/ConfigurationTable";
import ReportBuilder from "./components/report_builder/report_builder/ReportBuilder";
import Reports from "./components/report_builder/reports/Reports";
import Scrollbar from "./layout/scrollbar/Scrollbar";
import AlertDetails from "./components/all_alerts/alert/AlertDetails";
import AlertsTable from "./components/all_alerts/alerts_table/AlertsTable";
import UserOverview from "./components/user_overview/User";
import SharedLists from "./components/shared_lists/SharedLists";
import DataManagement from "./components/shared_lists/data_management/DataManagement";
import BtlTool from "./components/btl_tool/BtlTool";
import AlertsOverlap from "./components/overlap_analysis/alerts_table/AlertsTable";

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <Router>
      <div className="platform">
        {isAuthenticated && <NavBar />}
        <Scrollbar horizontalScroll={false}>
          <Routes>
            {/* Ruta de Inicio de Sesión */}
            <Route path="/login" element={<Login />} />

            {/* Rutas Protegidas */}
            <Route
              path="/"
              exact
              element={<Navigate to="/scenario-overview" replace />}
            />
            <Route
              path="/atl"
              element={
                <PrivateRoute>
                  <AtlTool />
                </PrivateRoute>
              }
            />
            <Route
              path="/btl"
              element={
                <PrivateRoute>
                  <BtlTool />
                </PrivateRoute>
              }
            />
            <Route
              path="/scenario-overview"
              element={
                <PrivateRoute>
                  <ScenarioOverview />
                </PrivateRoute>
              }
            />

            <Route
              path="/reports/:id"
              element={
                <PrivateRoute>
                  <ReportBuilder />
                </PrivateRoute>
              }
            />

            <Route
              path="/reports"
              element={
                <PrivateRoute>
                  <Reports />
                </PrivateRoute>
              }
            />

            <Route
              path="/configuration/:id?"
              element={
                <PrivateRoute>
                  <Configuration />
                </PrivateRoute>
              }
            />
            <Route
              path="/configuration/:id/:logic"
              element={
                <PrivateRoute>
                  <Configuration />
                </PrivateRoute>
              }
            />

            <Route
              path="/configuration/:id?"
              element={
                <PrivateRoute>
                  <Configuration />
                </PrivateRoute>
              }
            />
            <Route
              path="/configurationTable"
              element={
                <PrivateRoute>
                  <ConfigurationTable />
                </PrivateRoute>
              }
            />

            <Route
              path="/overlap-analysis/"
              element={
                <PrivateRoute>
                  <MatrixOverlap />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard-analytics"
              element={
                <PrivateRoute>
                  <MiblReport />
                </PrivateRoute>
              }
            />
            <Route
              path="/alerts-overlap/:id1/:id2"
              element={
                <PrivateRoute>
                  <AlertsOverlap />
                </PrivateRoute>
              }
            />
            <Route
              path="/scenarios-overlap/:id1/:id2"
              element={
                <PrivateRoute>
                  <MatrixScenarios />
                </PrivateRoute>
              }
            />
            <Route
              path="/alerts"
              element={
                <PrivateRoute>
                  <AlertsTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/alerts/:id"
              element={
                <PrivateRoute>
                  <AlertDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/users"
              element={
                <PrivateRoute>
                  <UserOverview />
                </PrivateRoute>
              }
            />

            <Route
              path="/shared-lists"
              element={
                <PrivateRoute>
                  <SharedLists />
                </PrivateRoute>
              }
            />
            <Route
              path="/data-management"
              element={
                <PrivateRoute>
                  <DataManagement />
                </PrivateRoute>
              }
            />
          </Routes>
        </Scrollbar>
      </div>

      <ToastContainer />
    </Router>
  );
}

export default App;
