import styles from "./StatusBadge.module.css";

const StatusBadge = ({ status = null, style }) => {
  let label = "UNDEFINED";
  let className = styles.closed;
  let typeclass = styles.label;

  if (status) {
    if (status.toLowerCase().includes("reported")) {
      label = "REPORTED";
      className = styles.reported;
      typeclass = styles.label;
    } else if (status.toLowerCase() === "closed") {
      label = "CLOSED";
      className = styles.closed;
      typeclass = styles.label;
    }
  }

  return (
    <div className={`${typeclass} ${className}`} style={style}>
      {label}
    </div>
  );
};

export default StatusBadge;
