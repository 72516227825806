import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Badge from './../badges/Badge';
import styles from './CustomEditor.module.css';

import { useDispatch } from 'react-redux';
import { all } from 'axios';
import { Edit } from '@mui/icons-material';

const CustomEditor = ({ isEdit, onChange, mode, definitionCallback , badgesSelection})=> {
    const { infoLists, selectedDataLabelDetails, dataSources, selectedProfileData , dataLabels} = useSelector((state) => state.configuration);
    const [badges, setBadges] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [currentSuggestions, setCurrentSuggestions] = useState([]);
    const [highlightedIndex, setHighlightedIndex] = useState(0);
    const [isDotContext, setIsDotContext] = useState(false);
    const  [isSlashContext, setIsSlashContext] = useState(false);
    const editorRef = useRef(null);
    const suggestionsBoxRef = useRef(null);
    const [filterText, setFilterText] = useState("");

    const dispatch = useDispatch();

    let selectedLabels = selectedDataLabelDetails;
    let dataSourcesData = dataSources;

    console.log("dataSourcesData",dataSourcesData);
    console.log("dataSourcesData",selectedLabels);
    console.log("selectedLabels",selectedLabels);
    console.log("infoLists",infoLists);
    console.log("badgeSelection",badgesSelection);
    


    const [savedRange, setSavedRange] = useState(null);
    const [suggestionsPosition, setSuggestionsPosition] = useState({ top: 0, left: 0 });


    const [isContentInitialized, setIsContentInitialized] = useState(false); // New state to track initial content load


    const [selectedInfoListColumns, setSelectedInfoListColumns] = useState([]);



    const placeCaretAfterNode = (node) => {
        const range = document.createRange();
        const selection = window.getSelection();
        range.setStartAfter(node);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
        editorRef.current.focus();
    };

     
      useEffect(() => {
        const editorElement = editorRef.current;
      
        const handleBadgeClick = (event) => {
          const target = event.target;
          if (target.classList.contains(styles.badge)) 
          {
            event.preventDefault();
            placeCaretAfterNode(target);
          } 
          else if (target.closest(`.${styles.badge}`)) 
          {
            // If the click is on a child of the badge (e.g., the bullet span)
            const badgeNode = target.closest(`.${styles.badge}`);
            event.preventDefault();
            placeCaretAfterNode(badgeNode);
          }
        };
      
        if (editorElement) {
          editorElement.addEventListener('click', handleBadgeClick);
        }
      
        return () => {
          if (editorElement) {
            editorElement.removeEventListener('click', handleBadgeClick);
          }
        };
      }, []);
      

    
    const getTextContent = () => {
        if (editorRef.current) {

         console.log("editorRef.current.textContent",editorRef.current?.textContent);
            return editorRef.current.textContent;
        }
        return '';
    };
      
    const focusEditor = () => {
        if (editorRef.current) {
            editorRef.current.focus();
        }
    };
    const normalizeFieldName = (name) => name.replace(/_/g, '').toLowerCase();

    const handleInputChange = () => {
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        const textBeforeCursor = range.startContainer.textContent.slice(0, range.startOffset).trim();
        if (textBeforeCursor.endsWith('/')) {
            setIsDotContext(false);
            setIsSlashContext(true);
            showSuggestionsForSlash("");
        } 
        else if (isSlashContext) {
            const lastPart = textBeforeCursor.split('/').pop();
            setFilterText(lastPart);
            showSuggestionsForSlash(lastPart);
        }
        
        
        else if (textBeforeCursor.endsWith('.')) {
            setIsDotContext(true);
            setIsSlashContext(false);
            setFilterText("");
            showSuggestionsForDot("");
        } else if (isDotContext) {
            const lastPart = textBeforeCursor.split('.').pop();
            setFilterText(lastPart);
            showSuggestionsForDot(lastPart);
        } else {
            setShowSuggestions(false);
        }

       
        const currentContent = getTextContent();
        definitionCallback(currentContent);
            // Llamar a onChange con el contenido actual
    if (onChange) {
        const currentContent = getTextContent(); // O el método que prefieras para obtener el contenido
        onChange(currentContent);

       
      
        
    }
    };


    

    const parseDefinitionToBadges = () => {
        if (!selectedLabels?.definition) return;
        const definition = selectedLabels.definition;
        const parts = definition.split(/(\s+|=|\[|\]|\(|\)|\.|,)/);
    
        let previousBadge = null; // Track the previous badge
    
        const getNextBadgeAfterRow = (part) => {
            if (previousBadge && part === "row") {
                return {
                    name: `${previousBadge.name}.nextField`, // Adjust based on logic for the next field after "row."
                    color: "#027A48",
                    bgColor: "#ECFDF3",
                    hasBullet: false,
                    bulletColor: null,
                };
            }
            return null;
        }; 
    
        // Generate HTML if not editable
        if (!isEdit) {
            let htmlContent = ''; // Stores generated HTML
    
            parts.forEach((part, index) => {
                if (part.trim() && !["=", " "].includes(part)) {
                    let badgeDetails = null;
    
                    if (part === "row") {
                        badgeDetails = getNextBadgeAfterRow(part);
                    } else if (selectedLabels.data_source && 
                        (part === selectedLabels.data_source.name || 
                        normalizeFieldName(part) === normalizeFieldName(selectedLabels.data_source.name))) {
                        badgeDetails = {
                            name: selectedLabels.data_source.name,
                            color: "#027A48",
                            bgColor: "#ECFDF3",
                            hasBullet: true,
                            bulletColor: "#4FC795"
                        };
                    } else if (selectedLabels.list?.some(list => part.includes(list.name))) {
                        const matchedList = selectedLabels.list.find(list => part.includes(list.name));
                        badgeDetails = {
                            name: matchedList.name,
                            color: "#B42318",
                            bgColor: "#FEF3F2",
                            hasBullet: true,
                            bulletColor: "#FF7065"
                        };
                    } else {
                        const matchingDataField = dataSourcesData
                            .flatMap(ds => ds.datafields)
                            .find(field => normalizeFieldName(field.name) === normalizeFieldName(part));
    
                        const matchingListColumn = infoLists
                            .flatMap(list => list.list_columns)
                            .find(column => normalizeFieldName(column.name) === normalizeFieldName(part));
    
                        if (matchingDataField) {
                            badgeDetails = {
                                name: matchingDataField.name,
                                color: "#027A48",
                                bgColor: "#ECFDF3",
                                hasBullet: false,
                                bulletColor: null
                            };
                        } else if (matchingListColumn) {
                            badgeDetails = {
                                name: matchingListColumn.name,
                                color: "#B42318",
                                bgColor: "#FEF3F2",
                                hasBullet: false,
                                bulletColor: null
                            };
                        }
                    }
    
                    if (badgeDetails) {
                        htmlContent += `
                            <span class="${styles.badge}" style="background-color: ${badgeDetails.bgColor}; color: ${badgeDetails.color}; padding: 6px 16px; border-radius: 15px; margin-right: 4px; display: inline-block; white-space: nowrap; vertical-align: middle;">
                                ${badgeDetails.hasBullet ? `<span style="display: inline-block; vertical-align: middle; margin-right: 4px; width: 8px; height: 8px; border-radius: 50%; background-color: ${badgeDetails.bulletColor};"></span>` : ''}
                                ${badgeDetails.name}
                            </span>`;
                        previousBadge = badgeDetails; // Update previous badge
                    } else {
                        htmlContent += `<span>${part}</span>`;
                        previousBadge = null; // Reset previous badge if not a badge part
                    }
                } else {
                    htmlContent += `<span>${part}</span>`;
                }
            });
    
            editorRef.current.innerHTML = htmlContent; // Assign the HTML content to the editor
        } else {
            // Editable mode: insert badges and text in the editor using DOM API
            parts.forEach((part) => {
                let badgeDetails = null;
    
                if (part.trim() && !["=", " "].includes(part)) {
                    if (part === "row") {
                        badgeDetails = getNextBadgeAfterRow(part);
                    } else if (selectedLabels.data_source &&
                        (part === selectedLabels.data_source.name ||
                        normalizeFieldName(part) === normalizeFieldName(selectedLabels.data_source.name))) {
                        badgeDetails = {
                            name: selectedLabels.data_source.name,
                            color: "#027A48",
                            bgColor: "#ECFDF3",
                            hasBullet: true,
                            bulletColor: "#4FC795"
                        };
                    } else if (selectedLabels?.list.some(list => part.includes(list.name))) {
                        const matchedList = selectedLabels.list.find(list => part.includes(list.name));
                        badgeDetails = {
                            name: matchedList.name,
                            color: "#B42318",
                            bgColor: "#FEF3F2",
                            hasBullet: true,
                            bulletColor: "#FF7065"
                        };
                    } else {
                        const matchingDataField = dataSourcesData
                            .flatMap(ds => ds.datafields)
                            .find(field => normalizeFieldName(field.name) === normalizeFieldName(part));
    
                        const matchingListColumn = infoLists
                            .flatMap(list => list.list_columns)
                            .find(column => normalizeFieldName(column.name) === normalizeFieldName(part));
    
                        if (matchingDataField) {
                            badgeDetails = {
                                name: matchingDataField.name,
                                color: "#027A48",
                                bgColor: "#ECFDF3",
                                hasBullet: false,
                                bulletColor: null
                            };
                        } else if (matchingListColumn) {
                            badgeDetails = {
                                name: matchingListColumn.name,
                                color: "#B42318",
                                bgColor: "#FEF3F2",
                                hasBullet: false,
                                bulletColor: null
                            };
                        }
                    }
    
                    if (badgeDetails) {
                        insertBadge(badgeDetails);
                        previousBadge = badgeDetails; // Update previous badge
                    } else {
                        insertText(part);
                        previousBadge = null; // Reset previous badge if not a badge part
                    }
                } else {
                    insertText(part);
                }
            });
        }
    };
    
    useEffect(() => {
        if (
            
            dataSourcesData &&
            dataSourcesData.length > 0 &&
            infoLists &&
            infoLists.length > 0
        ) {
            editorRef.current.textContent = ""; // Clear the content
            parseDefinitionToBadges(); // Parse and insert badges
        }
    }, [selectedLabels, dataSourcesData, infoLists, isEdit]);



    useEffect(() => {
        const handleClickOutside = (event) => {
          if (
            suggestionsBoxRef.current &&
            !suggestionsBoxRef.current.contains(event.target) &&
            editorRef.current &&
            !editorRef.current.contains(event.target)
          ) {
            setShowSuggestions(false);
          }
        };
      
        document.addEventListener('mousedown', handleClickOutside);
      
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [suggestionsBoxRef, editorRef]);

    

    


    const ensureSpaceAtStart = () => {
        const editor = editorRef.current;
        const firstChild = editor.firstChild;
    
        // Check if the first child is a text node and whether it starts with a non-breaking space
        if (!firstChild || (firstChild.nodeType === Node.TEXT_NODE && firstChild.nodeValue.charAt(0) !== '\u00A0')) {
            // Insert a non-breaking space at the start
            const spaceNode = document.createTextNode('\u00A0');
            editor.insertBefore(spaceNode, firstChild);
        }
    };

    
    const insertText = (text) => {
        ensureSpaceAtStart(); // Ensure the space is at the start
    
        let selection = window.getSelection();
        let range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
    
        if (!range || !editorRef.current.contains(range.startContainer)) {
            // If no valid range or if the range isn't within the editor, set the cursor at the start
            let newRange = document.createRange();
            newRange.selectNodeContents(editorRef.current);
            newRange.collapse(true); // Collapse at the very beginning (position 0)
            selection.removeAllRanges();
            selection.addRange(newRange);
            range = newRange; // Update range to use for text insertion
        }
    
        const textNode = document.createTextNode(text);
    
        if (range.startContainer.nodeType === Node.TEXT_NODE) {
            // If the range is inside a text node, split it and insert the new text
            let textContent = range.startContainer.nodeValue;
            let beforeText = textContent.slice(0, range.startOffset);
            let afterText = textContent.slice(range.startOffset);
    
            let newTextNode = document.createTextNode(beforeText + text + afterText);
            range.startContainer.nodeValue = newTextNode.nodeValue; // Replace the original node content
    
            // Set the range after the inserted text
            range.setStart(newTextNode, beforeText.length + text.length);
            range.setEnd(newTextNode, beforeText.length + text.length);
        } else {
            // If the range is at the element level, just insert the text node
            range.insertNode(textNode);
            range.setStartAfter(textNode);
            range.setEndAfter(textNode);
        }
    
        // Update the selection and focus the editor
        selection.removeAllRanges();
        selection.addRange(range);
        editorRef.current.focus();
    };
    
    const insertBadge = (badge) => {
        focusEditor();
        ensureSpaceAtStart(); // Ensure the space is at the start
    
        const selection = window.getSelection();
        if (!selection || selection.rangeCount === 0) {
            console.error("No valid selection found for inserting the badge.");
            return;
        }
    
        const range = selection.getRangeAt(0);
        if (!range || !editorRef.current.contains(range.startContainer)) {
            console.error("Range is invalid or not contained within the editor.");
            return;
        }
    
        const badgeElement = document.createElement('span');
        badgeElement.className = styles.badge;
        badgeElement.style.backgroundColor = badge.bgColor;
        badgeElement.style.color = badge.color;
        badgeElement.style.padding = '6px 16px';
        badgeElement.style.borderRadius = '15px';
        badgeElement.style.marginRight = '4px';
        badgeElement.style.display = 'inline-block';
        badgeElement.style.whiteSpace = 'nowrap';
        badgeElement.style.verticalAlign = 'middle';
        badgeElement.innerHTML = badge.hasBullet
            ? `<span style="display: inline-block; vertical-align: middle; margin-right: 4px; width: 8px; height: 8px; border-radius: 50%; background-color: ${badge.bulletColor};"></span>${badge.name}`
            : `${badge.name}`;
        badgeElement.setAttribute('contenteditable', 'false');
    
        const spaceAfter = document.createTextNode('\u00A0');
    
        if (range.startContainer.nodeType === Node.TEXT_NODE) {
            const textNode = range.startContainer;
            const offset = range.startOffset;
            const textBefore = textNode.nodeValue.slice(0, offset);
            const textAfter = textNode.nodeValue.slice(offset);
            
            




            const beforeNode = document.createTextNode(textBefore);
            const afterNode = document.createTextNode(textAfter);
    
            const parentNode = textNode.parentNode;
            parentNode.insertBefore(beforeNode, textNode);
            parentNode.insertBefore(badgeElement, textNode);
            parentNode.insertBefore(spaceAfter, textNode);
            parentNode.insertBefore(afterNode, textNode);

    
            parentNode.removeChild(textNode);
    
             range.setStartAfter(spaceAfter);
            range.setEndAfter(spaceAfter);
        } else {
            range.deleteContents();
            range.insertNode(spaceAfter);
            range.insertNode(badgeElement);
    
             range.setStartAfter(spaceAfter);
            range.setEndAfter(spaceAfter);
        }
    
        setBadges([...badges, badge]);
    
        selection.removeAllRanges();
        selection.addRange(range);
    
        editorRef.current.focus();
    };
    



    const saveSelection = () => {
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          setSavedRange(range);
        }
      };

      const getCaretCoordinates = () => {
        const selection = window.getSelection();
        if (!selection.rangeCount) return null;
      
        const range = selection.getRangeAt(0).cloneRange();
      
        // Collapse the range to the caret's position
        range.collapse(false);
      
        // Get the client rects of the range
        const rects = range.getClientRects();
        if (rects.length === 0) {
          return null;
        }
      
        // Get the last rectangle, which represents the caret position
        const rect = rects[rects.length - 1];
      
        // Get the position of the editor container
        const editorRect = editorRef.current.getBoundingClientRect();
      
        // Get the computed style of the editor container
        const editorStyles = window.getComputedStyle(editorRef.current);
        const paddingLeft = parseFloat(editorStyles.paddingLeft);
        const paddingTop = parseFloat(editorStyles.paddingTop);
      
        // Calculate position relative to the editor container, accounting for padding
        const relativeTop =
          rect.top - editorRect.top - paddingTop + editorRef.current.scrollTop;
        const relativeLeft =
          rect.left - editorRect.left - paddingLeft + editorRef.current.scrollLeft;
      
        // Return the coordinates
        return { top: relativeTop +30, left: relativeLeft };
      };
      

      
      const adjustSuggestionsPosition = (position) => {
        const { top, left } = position;
        const suggestionsBoxWidth = suggestionsBoxRef.current?.offsetWidth || 200; // Assume a default width
        const editorRect = editorRef.current.getBoundingClientRect();
        const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    
        // Calculate the absolute left position of the suggestions box
        const absoluteLeft = editorRect.left + left;
    
        // Check if the suggestions box would go off-screen to the right
        if (absoluteLeft + suggestionsBoxWidth > viewportWidth) {
          // Adjust the left position to align the suggestions box's right edge with the caret
          const adjustedLeft = left - suggestionsBoxWidth;
    
          // Ensure the suggestions box doesn't go off-screen to the left
          const finalLeft = Math.max(adjustedLeft, 0);
    
          return { top, left: finalLeft };
        }
    
        // If no adjustment is needed, return the original position
        return position;
      };
    



    const showSuggestionsForSlash = (filterText = "") => {
        saveSelection(); 
        let filteredSuggestions = [
            ...(selectedLabels?.data_source ? [{ name: selectedLabels.data_source.name, type: 'data_source', color: "#027A48", bgColor: "#ECFDF3", hasBullet: true, bulletColor: "#4FC795" }] : []),
            ...(selectedLabels?.list ? selectedLabels.list.map(list => ({ name: list.name, type: 'list', color: "#B42318", bgColor: "#FEF3F2", hasBullet: true, bulletColor: "#FF7065" })) : [])
        ];


        //get bullet color based on color 



        // addbadge selection to filtered suggerstion filtering out not selectted badge and  set bullet color based on color->bullet color pairs :   #027A48->#4FC795  #B42318->#FF7065
     

        filteredSuggestions = [ ...filteredSuggestions, ...badgesSelection.map(badge => ({ name: badge.text, type: 'badge', color: badge.color, bgColor: badge.bgColor, hasBullet: badge.hasBullet, bulletColor: badge.color === "#027A48" ? "#4FC795" : badge.color === "#B42318" ?  "#FF7065"  : "#000" }))];
       
        //filtering out "not selectted" badge  and empty badges
        filteredSuggestions = filteredSuggestions.filter(suggestion => suggestion.name !== "Not Selected" && suggestion.name.trim() !== "" && suggestion.name.trim() !== " ");

        // removing duplicates 
        filteredSuggestions = filteredSuggestions.filter((v,i,a)=>a.findIndex(t=>(t.name === v.name))===i); 

        if (filterText) {
            filteredSuggestions = filteredSuggestions.filter(suggestion =>
                suggestion.name.toLowerCase().includes(filterText.toLowerCase())
            );
        }
        setCurrentSuggestions(filteredSuggestions);
        setShowSuggestions(true);
        setHighlightedIndex(0); // Resetear el índice destacado al mostrar nuevas sugerencias
        
              // Get the caret position
              const caretRect = getCaretCoordinates();
              if (caretRect) {
                  const adjustedPosition = adjustSuggestionsPosition({
                  top: caretRect.top,
                  left: caretRect.left,
                  });
                  setSuggestionsPosition(adjustedPosition);
              }
              editorRef.current.focus();
    };

    const showSuggestionsForDot = (filterText = "") => {

        saveSelection(); 
        const lastBadge = badges[badges.length - 1];
        let filteredSuggestions = [];

        if (lastBadge) {
            filteredSuggestions = getDynamicDatafieldsSuggestions(lastBadge.name);
        }

        if (filteredSuggestions.length === 0) {
            // Si no hay sugerencias específicas para el badge seleccionado, mostrar todas las sugerencias posibles
            filteredSuggestions = [
                ...(selectedLabels?.data_source ? selectedLabels.data_source.datafields.map(field => ({
                    name: field.name,
                    color: "#027A48",
                    bgColor: "#ECFDF3",
                    hasBullet: false
                })) : []),
                ...(selectedLabels?.list ? selectedLabels.list.flatMap(list => list.list_columns.map(column => ({
                    name: column.name,
                    color: "#B42318",
                    bgColor: "#FEF3F2",
                    hasBullet: false
                }))) : [])
            ];
        }

        // Filtrar las sugerencias basadas en el texto ingresado después del punto
        if (filterText) {
            filteredSuggestions = filteredSuggestions.filter(suggestion =>
                suggestion.name.toLowerCase().includes(filterText.toLowerCase())
            );
        }

        setCurrentSuggestions(filteredSuggestions);
        setShowSuggestions(filteredSuggestions.length > 0); // Mostrar solo si hay sugerencias
        setHighlightedIndex(0); // Resetear el índice destacado al mostrar nuevas sugerencias

        
              // Get the caret position
              const caretRect = getCaretCoordinates();
              if (caretRect) {
                  const adjustedPosition = adjustSuggestionsPosition({
                  top: caretRect.top,
                  left: caretRect.left,
                  });
                  setSuggestionsPosition(adjustedPosition);
              }
        
    };

    // Define getDynamicDatafieldsSuggestions function
    const getDynamicDatafieldsSuggestions = (selectedName) => {
        const suggestions = [];
        const selectedSource = dataSourcesData.find(ds => ds.name === selectedName);
        if (selectedSource?.datafields) {
            suggestions.push(...selectedSource.datafields.map(field => ({
                name: field.name,
                color: "#027A48",
                bgColor: "#ECFDF3",
                hasBullet: false
            })));
        }

        const selectedList = infoLists.find(list => list.name === selectedName);
        if (selectedList?.list_columns) {
            suggestions.push(...selectedList.list_columns.map(column => ({
                name: column.name,
                color: "#B42318",
                bgColor: "#FEF3F2",
                hasBullet: false
            })));
        }
        return suggestions;
    };


    
    const handleSuggestionClick = (suggestion) => {
        const selection = window.getSelection();

        // Restore the saved selection if necessary
        if (
          !selection ||
          selection.rangeCount === 0 ||
          !editorRef.current.contains(selection.anchorNode)
        ) {
          if (savedRange) {
            selection.removeAllRanges();
            selection.addRange(savedRange);
           
          } else {
            console.error(
              "No valid selection found, and no saved selection to restore."
            );
            return;
          }
        }
      
        const range = selection.getRangeAt(0);
      
        if (range.startContainer.nodeType === Node.TEXT_NODE) {
          const textNode = range.startContainer;
          const offset = range.startOffset;
          const textBeforeCursor = textNode.nodeValue.slice(0, offset);
          const textAfterCursor = textNode.nodeValue.slice(offset);
      
          const slashIndex = textBeforeCursor.lastIndexOf('/');
          const dotIndex = textBeforeCursor.lastIndexOf('.');
      
          let delimiterIndex = -1;
      
          if (slashIndex > dotIndex) {
            delimiterIndex = slashIndex;
          } else {
            delimiterIndex = dotIndex + 1;
          }
      
          if (delimiterIndex >= 0) {
            const beforeDelimiter = textNode.nodeValue.slice(0, delimiterIndex);
            const afterCursor = textAfterCursor;
      
            // Update the text node content
            textNode.nodeValue = beforeDelimiter + afterCursor;
      
            // Adjust the cursor position
            const newOffset = beforeDelimiter.length;
            range.setStart(textNode, newOffset);
            range.setEnd(textNode, newOffset);
          }
        }
      
        insertBadge(suggestion);
        setShowSuggestions(false);
        setFilterText("");
        setIsDotContext(false);
        setIsSlashContext(false);

        const currentContent = getTextContent();
        definitionCallback(currentContent);
      };
      


    const handleBackspace = (e) => {
        const selection = window.getSelection();
        if (selection.rangeCount === 0) return;
    
        const range = selection.getRangeAt(0);
        const nodeBeforeCursor = getNodeBeforeCursor(range);

        if (!nodeBeforeCursor) {
            e.preventDefault();
            return;
        }

        if (isBadgeNode(nodeBeforeCursor)) {
            e.preventDefault();
            removeBadgeNode(nodeBeforeCursor, range);
        } else if (isEmptyTextNode(nodeBeforeCursor)) {
            e.preventDefault();
            removeEmptyTextNode(nodeBeforeCursor, range);
        }
    };

    const getNodeBeforeCursor = (range) => {
        if (range.startContainer.nodeType === Node.TEXT_NODE) {
            const textNode = range.startContainer;
            return range.startOffset > 0 ? textNode : textNode.previousSibling;
        } else if (range.startContainer.nodeType === Node.ELEMENT_NODE) {
            return range.startOffset > 0 ? range.startContainer.childNodes[range.startOffset - 1] : range.startContainer.previousSibling;
            }
        return null;
    };

    const isBadgeNode = (node) => {
        return node.nodeType === Node.ELEMENT_NODE && node.classList.contains(styles.badge);
    };

    const isEmptyTextNode = (node) => {
        return node.nodeType === Node.TEXT_NODE && (node.nodeValue === '\u00A0' || node.nodeValue === '');
    };

    const removeBadgeNode = (node, range) => {
        node.remove();
        const nextSibling = node.nextSibling;
            if (nextSibling && nextSibling.nodeType === Node.TEXT_NODE && nextSibling.nodeValue === '\u00A0') {
                nextSibling.remove();
            }
        setBadges(badges.filter(b => b.name !== node.textContent.trim()));
        moveCursorToPosition(range);
    };

    const removeEmptyTextNode = (node, range) => {
        const badgeNode = node.previousSibling;
        if (badgeNode && isBadgeNode(badgeNode)) {
                    badgeNode.remove();
            node.remove();
                    setBadges(badges.filter(b => b.name !== badgeNode.textContent.trim()));
            moveCursorToPosition(range);
        }
    };
    
    const moveCursorToPosition = (range) => {
        const selection = window.getSelection();
                    const newRange = document.createRange();
                    newRange.setStart(range.startContainer, range.startOffset);
                    newRange.collapse(true);
                    selection.removeAllRanges();
                    selection.addRange(newRange);
                    editorRef.current.focus();
    };




    const scrollToHighlighted = (index) => {
        if (suggestionsBoxRef.current) {
            const highlightedElement = suggestionsBoxRef.current.children[index];
            const suggestionsBox = suggestionsBoxRef.current;

            const boxHeight = suggestionsBox.clientHeight;
            const itemTop = highlightedElement.offsetTop;
            const itemBottom = itemTop + highlightedElement.clientHeight;

            if (itemTop < suggestionsBox.scrollTop) {
                suggestionsBox.scrollTop = itemTop;
            } else if (itemBottom > suggestionsBox.scrollTop + boxHeight) {
                suggestionsBox.scrollTop = itemBottom - boxHeight;
            }
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Backspace') {
            handleBackspace(e);
           
        } else if (e.key === 'Enter' && showSuggestions) {
            e.preventDefault();
            handleSuggestionClick(currentSuggestions[highlightedIndex]);
        } else if (e.key === 'ArrowDown' && showSuggestions) {
            e.preventDefault();
            setHighlightedIndex((prevIndex) => {
                const newIndex = (prevIndex + 1) % currentSuggestions.length;
                scrollToHighlighted(newIndex);
                return newIndex;
            });
        } else if (e.key === 'ArrowUp' && showSuggestions) {
            e.preventDefault();
            setHighlightedIndex((prevIndex) => {
                const newIndex = prevIndex === 0 ? currentSuggestions.length - 1 : prevIndex - 1;
                scrollToHighlighted(newIndex);
                return newIndex;
            });
        } else if (isDotContext && e.key !== '.' && e.key.length === 1) {
            setFilterText(prev => prev + e.key);
            showSuggestionsForDot(filterText + e.key);
        } else {
            handleInputChange();
        }
    };

    // useEffect(() => {
    //     if (editorRef.current) {
    //         editorRef.current.textContent = '';  // Limpia el contenido antes de cada parseo.
    //         parseDefinitionToBadges();
    //     }
    // }, [isEdit, selectedLabels,]);



    return (
                <div className={styles.editorWrapper}>
            
            <div
                    ref={editorRef}
                    className={styles.editorContainer}
                    contentEditable={isEdit}  // Condiciona la edición según isEdit
                    onInput={isEdit ? handleInputChange : null}  // Solo permite onInput si es editable
                    onKeyDown={isEdit ? handleKeyDown : null}  // Solo permite onKeyDown si es editable
                    placeholder={isEdit ? "Type your formula..." : ""}  // Placeholder solo cuando es editable
                    suppressContentEditableWarning={true}
                    style={{
                        minHeight: '40px',
                        padding: '8px',
                        borderRadius: '8px',
                        outline: 'none',
                     
                        
                    }}
                ></div>





                {showSuggestions && isEdit && (
                    <div className={styles.suggestionsBox} ref={suggestionsBoxRef} style={{ position: 'absolute',top: suggestionsPosition.top, left: suggestionsPosition.left, zIndex: 1000,}}>
                        {currentSuggestions.map((suggestion, index) => (
                            <button
                            key={"suggestion" + index}
                            className={styles.suggestionItem}
                            onClick={(e) => {
                                e.preventDefault();
                                handleSuggestionClick(suggestion);
                            }}
                            style={{
                                padding: '6px 16px',
                                backgroundColor: highlightedIndex === index ? '#fcfaff' : 'transparent',
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer',
                            }}
                        >
                            <Badge
                                text={suggestion.name}
                                color={suggestion.color || '#027A48'}
                                bgColor={suggestion.bgColor || '#ECFDF3'}
                                hasBullet={suggestion.hasBullet || false}
                            />
                        </button>
                        ))}
                    </div>
                )}
            </div>
    
    );
}



CustomEditor.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    mode: PropTypes.string,
    definitionCallback: PropTypes.func,
    badgesSelection: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            color: PropTypes.string.isRequired,
            bgColor: PropTypes.string.isRequired,
            hasBullet: PropTypes.bool.isRequired
        })
    ).isRequired
};

export default CustomEditor;
