import { useEffect, useRef } from "react";

/**
 * Hook for handling debounced actions with conditional execution.
 *
 * @param {Function} action - The action to execute after the delay.
 * @param {Function} elseAction - The action to do if condition not met.
 * @param {Array} dependencies - The dependencies for the useEffect hook.
 * @param {number} delay - The debounce delay in milliseconds.
 * @param {Function} condition - The condition on executing the action.
 */
function useDebouncedAction({
  action,
  dependencies,
  delay = 10,
  condition = true,
  elseAction = () => {},
}) {
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      if (condition) {
        action();
        timeoutRef.current = null;
      } else {
        elseAction();
      }
    }, delay);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [...dependencies]);
}

export default useDebouncedAction;
