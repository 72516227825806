import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './VisualisationHeader.module.css';
import domtoimage from 'dom-to-image-more';
import OptionButton from '../../../layout/buttons/OptionButton';
import { switchVisualisationOpenedStatus,addToReport
 } from '../../../features/atl_tool/atlToolSlice';


const VisualisationHeader = ({ id , CaptureRef,CaptureSectionName}) => {

  const dispatch = useDispatch();

  const visualisations = useSelector((state) => state.atlTool.visualisations);
  const visualisation = visualisations.find(visualisation => visualisation.key === id);

  const visualisationType = visualisation.chartType;

  const xAxis = visualisation.xAxis;
  const yAxis = visualisation.yAxis;

  const isExpanded = visualisation.isExpanded;

  const riskScore = useSelector((state) => state.atlTool.selectedRiskScore)
  
  const handleArrowClick = () => {

    dispatch(switchVisualisationOpenedStatus(id));
    
  }




  return (
    <div className={`${styles.headerSection} ${!isExpanded ? styles.noBorder : ''}`} onClick={handleArrowClick}>
        <div className={styles.visualisationBox}>
            <svg className={`${styles.svg} ${!isExpanded ? styles.svgExpanded : ''}`} xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path d="M6 9.12109L12 15.1211L18 9.12109" stroke="#5E6EFF" strokeOpacity="0.8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <div className={styles.chartType}>{visualisationType}</div>
            Visualisation
        </div>
        <div className={styles.legendBox}>

 

           
            <div>(X) {xAxis}</div>
            <div>(Y) {yAxis}</div>
            <div className={`${styles.riskScore} ${riskScore.name.toLowerCase() === "low" ? styles.riskLow : riskScore.name.toLowerCase() === "medium" ? styles.riskMedium : riskScore.name.toLowerCase() === "high" ? styles.riskHigh : styles.riskUnknown}`}>
              <div className={styles.textBlock}>
                {riskScore.name}
              </div>
            </div>
            <OptionButton   CaptureRef={CaptureRef} CaptureSectionName={CaptureSectionName}/>
        </div>
    </div>
  );
};

export default VisualisationHeader;
