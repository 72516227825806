import { useSelector } from "react-redux";

import { HeaderClass } from "../../../../layout/table/Header";
import TableGrid from "../../../../layout/table/Table";
import { CellClass, RowCellClass } from "../../../../layout/table/Cell";

const ContributingTable = () => {
  const { contributingTransactions, selectedAlertDetails } =
    useSelector((state) => state.allAlerts);

  const headers = [
    new HeaderClass("Transaction ID", "transaction_id", true, false, {
      minWidth: 300,
      maxWidth: 300,
      width: 300,
    }),
    new HeaderClass("Transaction Date", "txn_date", true, true, {
      minWidth: 140,
      maxWidth: 140,
      width: 140,
    }),
    new HeaderClass("Account ID", "account_id", true, true, {
      minWidth: 300,
      maxWidth: 300,
      width: 300,
    }),
    new HeaderClass("Debit/Credit", "credit_or_debit", true, true, {
      minWidth: 140,
      maxWidth: 140,
      width: 140,
    }),
    new HeaderClass("Base Amount", "amount_currency", true, true, {
      minWidth: 140,
    }),
    new HeaderClass("Transaction Type", "transaction_type", true, true, {
      minWidth: 140,
    }),
  ];

  const rows = contributingTransactions.map((rowData) => {
    const allertId = rowData.id;
    const cells = headers.map((header) => {
      const value = rowData[header.field];
      if (header.field == "txn_date") {
        if (value) {
          const dateOnly = value.split("T")[0];
          return new CellClass(dateOnly, header.field);
        }
      }
      return new CellClass(value, header.field);
    });

    return new RowCellClass(allertId, cells);
  });

  return (
    <TableGrid
      title={`Contributing transactions of ${selectedAlertDetails.alert_code}`}
      headers={headers}
      rows={rows}
      cellStyle={{ minHeight: 50 }}
      noDataPlaceholder={"No contributing transactions"}
    />
  );
};

export default ContributingTable;
