// HeaderSection.js
import React from 'react';
import {addToReport} from   "../../features/atl_tool/atlToolSlice";
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useRef } from 'react';
import * as XLSX from 'xlsx';
const ExportButton = ({data, componentName}) => {

    const dispatch = useDispatch();
    //   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    //   const [IsActive, setIsActive] = useState(false);
    //   const [isOptionHovered, setIsOptionHovered] = useState(false);
    //   const dropdownRef = useRef(null); // Creamos una referencia al dropdown
    const buttonRef = useRef(null); // Creamos referencia al botón

    const handleButtonClick = () => {
    // setIsDropdownOpen(!isDropdownOpen); // Alternar el menú desplegable
    // setIsActive(true);
    // console.log("data",data);
    console.log("componentName",componentName);
    console.log("data",data[0]);

    if (componentName === "ParameterOptimisation") {
      const { headers, columns } = exportParameterOptimisation(data);
      exportToExcel(headers, columns);
    }else if (componentName === "Supressed Alerts") {
      const { headers, columns } = exportAlerts(data);
      exportToExcel(headers, columns);
    }else if (componentName === "All Alerts") {
      const { headers, columns } = exportAlerts(data);
      exportToExcel(headers, columns);
    }
    // Call this inside exportParameterOptimisation after headers and columns are created
    };

      // Función dinámica para exportar los "Supressed Alerts"
  const exportAlerts = (data) => {
    // Obtener las cabeceras dinámicas de las claves de los datos de la alerta
    const headers = Object.keys(data[0]); // Obtén las cabeceras dinámicamente de las claves del primer objeto

    // Crear las filas de datos con base en las columnas seleccionadas
    const columns = data.map(row => 
      headers.map(header => row[header] !== null ? row[header] : '') // Maneja los valores null
    );

    // Logging para asegurarte de que los datos sean correctos
    console.log("Headers: ", headers);
    console.log("Columns: ", columns);

    return { headers, columns };
  };


    const exportParameterOptimisation = (data) => {
        // Step 1: Dynamically define the headers based on the number of scenario parameters
        const headers = [
            "Optimisation Type",
            ...data.scenario_parameters, // Spread all the parameter names dynamically
            "Non Relevant",
            "Relevant",
            "SAR",
            "Efficiency"
        ];
    
        // Step 2: Dynamically build the columns based on the parameters
        const optimisationData = data.table[0]; // Assuming only one row for now
        const parameterValues = data.scenario_parameters.map(param => optimisationData.parameters[param]);
    
        const columns = [
            [
                optimisationData.optimisation_type, // Optimisation Type
                ...parameterValues, // Spread the values of the parameters dynamically
                optimisationData.non_relevant_alerts, // Non Relevant
                optimisationData.relevant_alerts, // Relevant
                optimisationData.sar_alerts, // SAR
                ((optimisationData.relevant_alerts / (optimisationData.relevant_alerts + optimisationData.non_relevant_alerts)) * 100).toFixed(1) + "%" // Efficiency
            ]
        ];
    
        // Logging to check if the structure is correct
        console.log("Headers: ", headers);
        console.log("Columns: ", columns);
        return { headers, columns };
    };

 const exportToExcel = (headers, columns) => {
    // Step 1: Create the data for the worksheet
    const worksheetData = [headers, ...columns];

    // Step 2: Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(worksheetData);

    // Step 3: Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Optimisation Data");

    // Step 4: Use XLSX to create the file and trigger the download
    XLSX.writeFile(wb, "optimisation_data.xlsx");  // This automatically triggers a download
};
    


//   const handleClickOutside = (event) => {
//     if (
//       buttonRef.current && 
//       !buttonRef.current.contains(event.target)
//     ) {
//       setIsDropdownOpen(false); // Cierra el menú si se hace clic fuera
//     }
//   };
//   useEffect(() => {
//     // Agregar listener cuando se abre el menú
//     if (isDropdownOpen) {
//       document.addEventListener('mousedown', handleClickOutside);
//     } else {
//       document.removeEventListener('mousedown', handleClickOutside);
//     }
//     // Limpiar el listener cuando el componente se desmonta
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [isDropdownOpen]);
  
  return (
    <div style={{position:'relative' }}>
       <div style={{cursor: 'pointer'}}  
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={(e) => { 
              e.stopPropagation(); 
              handleButtonClick(); 
            }}
            ref={buttonRef} // Referencia al botón
            >
           
           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><g clipPath="url(#clip0_4655_651594)"><path d="M6.66649 14.1686L9.99982 17.5019M9.99982 17.5019L13.3332 14.1686M9.99982 17.5019V10.0019M17.3998 15.0769C18.1243 14.5674 18.6676 13.8404 18.9509 13.0012C19.2342 12.162 19.2427 11.2544 18.9752 10.4101C18.7076 9.56576 18.178 8.82864 17.4632 8.3057C16.7484 7.78276 15.8855 7.5012 14.9998 7.5019H13.9498C13.6992 6.52512 13.2302 5.61794 12.5783 4.84863C11.9263 4.07932 11.1083 3.46795 10.1858 3.06053C9.26338 2.6531 8.26053 2.46025 7.25276 2.49649C6.245 2.53274 5.25858 2.79712 4.36778 3.26975C3.47698 3.74238 2.70501 4.41094 2.10998 5.22509C1.51495 6.03924 1.11237 6.97777 0.93254 7.97002C0.752714 8.96228 0.800331 9.98239 1.07181 10.9536C1.34328 11.9248 1.83154 12.8217 2.49982 13.5769" stroke="#7A5AF8" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path></g><defs><clipPath id="clip0_4655_651594"><rect width="20" height="20" fill="white"></rect></clipPath></defs></svg>

          </div>
    </div>
  );
};

export default ExportButton;
