import styles from "./Buttons.module.css";

export function FilterButton({ disabled = false, onClick = () => {} }) {
  return (
    <div
      className={`${styles.iconButton} ${disabled && styles.disabled}`}
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled) {
          onClick();
        }
      }}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Filters lines">
          <path
            id="Icon"
            d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
            stroke="#905EE2"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </div>
  );
}

export function SortButton({
  disabled = false,
  descending = true,
  active = false,
  onClick = () => {},
}) {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled) {
          onClick();
        }
      }}
      className={`${styles.iconButton} ${disabled && styles.disabled} ${
        !active && styles.inactive
      } ${!descending && styles.ascending}`}
    >
      {descending ? (
        <svg
          width="11"
          height="12"
          viewBox="0 0 11 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.66667 1.50391V10.8372M5.66667 10.8372L10.3333 6.17057M5.66667 10.8372L1 6.17057"
            stroke="#905EE2"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          width="11"
          height="12"
          viewBox="0 0 11 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.66667 10.4961V1.16275M5.66667 1.16275L1 5.82941M5.66667 1.16275L10.3333 5.82941"
            stroke="#905EE2"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </div>
  );
}

export function ExportButton({ disabled = false, onClick = () => {} }) {
  return (
    <div
      className={`${styles.iconButton} ${disabled && styles.disabled}`}
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled) {
          onClick();
        }
      }}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="download-cloud" clipPath="url(#clip0_7483_194196)">
          <path
            id="Icon"
            d="M6.66698 14.3365L10.0003 17.6699M10.0003 17.6699L13.3336 14.3365M10.0003 17.6699V10.1699M17.4003 15.2449C18.1248 14.7354 18.6681 14.0083 18.9514 13.1692C19.2347 12.33 19.2432 11.4224 18.9756 10.5781C18.7081 9.73373 18.1785 8.99661 17.4637 8.47367C16.7489 7.95073 15.886 7.66917 15.0003 7.66987H13.9503C13.6997 6.69309 13.2307 5.78591 12.5787 5.0166C11.9268 4.24729 11.1088 3.63592 10.1863 3.22849C9.26387 2.82107 8.26101 2.62822 7.25325 2.66446C6.24548 2.7007 5.25907 2.96509 4.36827 3.43772C3.47747 3.91035 2.7055 4.57891 2.11047 5.39306C1.51544 6.20721 1.11285 7.14574 0.933028 8.13799C0.753202 9.13024 0.800819 10.1504 1.07229 11.1216C1.34377 12.0927 1.83203 12.9897 2.50031 13.7449"
            stroke="#905EE2"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_7483_194196">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(0 0.167969)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export function IconButton({
  icon = null,
  disabled = false,
  onClick = () => {},
}) {
  return (
    <div
      className={`${styles.iconButton} ${disabled && styles.disabled}`}
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled) {
          onClick();
        }
      }}
    >
      {icon}
    </div>
  );
}
