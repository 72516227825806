// components/atl_tool/scenario_selection/ScenarioSelection.js

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "../AtlTool.module.css";
import ScenarioSelector from "./scenario_selector/ScenarioSelector";
import SegmentSelection from "./segment_selection/SegmentSelection";
import HeaderSection from "../../../layout/HeaderSection";
import { useLocation, useNavigate } from "react-router-dom";

const ScenarioSelection = ({ showSegments, navBack }) => {
  const { selectedScenario } = useSelector((state) => state.atlTool);

  const navigate = useNavigate();

  return (
    <div className={styles.section}>
      <HeaderSection
        SectionName="Scenario Performance"
        CanAddToReport={true}
        Type="performance_table"
        returnFunction={
          navBack
            ? () => {
                navigate("/scenario-overview", { state: navBack });
              }
            : undefined
        }
        AdditionalText={selectedScenario && selectedScenario.name}
      />
      <div className={styles.scenarioSelectorHolder}>
        <ScenarioSelector />
        {showSegments && <SegmentSelection />}
      </div>
    </div>
  );
};

export default ScenarioSelection;
