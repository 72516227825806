import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../axiosConfig";
import { convertData } from "../Functions";
import qs from "qs";

export const keyMappingAlerts = {
  id: "id",
  alert_code: "alert_code",
  "user.username": "user",
  "scenario.name": "scenario",
  "customer_segment.name": "customer_segment",
  event_date: "event_date",
  alert_status: "status",
  alert_type: "alert_type",
  risk_score: "risk_score",
  alert_relevancy: "alert_relevancy",
  masked_data: "masked_data",
  alert_comment: "comment",
  alert_origin: "alert_origin",
  alert_close_reason: "alert_close_reason",
  alert_closure_date: "alert_closure_date",
  masked_data: "masked_data",
};

export const keyMappingAuditLog = {
  "user.username": "user",
  id: "id",
  comment: "comment",
  update_date: "date",
};

// filter functionality
function getAlertFilterParams(state, withOrder = true) {
  let filters = { ...state.alertsFilters };
  if (state.showMyAlerts) {
    filters["user"] = [localStorage.getItem("userName")];
  }
  if (withOrder) {
    filters["order_by"] = state.alertsOrder[0];
    filters["order_direction"] = state.alertsOrder[1];
  }
  if (state.alertsSearch.length > 0) {
    filters["search_term"] = state.alertsSearch;
  }
  filters.alert_origin = state.alertsOrigin;
  return filters;
}

export const getAlertsFilterValues = createAsyncThunk(
  "allAlerts/getAlertsFilterValues",
  async ({ propertyName, field }) => {
    try {
      // Make sure propertyName is being passed as a query parameter
      const response = await apiClient.get(`alerts/distinct`, {
        params: {
          property_name: propertyName, // Correct way to pass query parameters
        },
      });
      const result = response.data;
      return result;
    } catch (error) {
      console.error("Error fetching filter values:", error);
      throw error;
    }
  }
);

export const closeAlerts = createAsyncThunk(
  "allAlerts/closeAlerts",
  async ({ alertIds, selectedAll }, { getState }) => {
    const state = getState().allAlerts;
    const filters = getAlertFilterParams(state, false);

    const alertBatch = {
      ids: alertIds,
      all_selected: selectedAll,
    };

    try {
      const response = await apiClient.put(`alerts/close-batch`, alertBatch, {
        params: filters,
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: "repeat" }),
      });

      return response.data;
    } catch (error) {
      console.error("Failed to close one or more alerts:", error);
      throw error;
    }
  }
);

export const fetchAlert = createAsyncThunk(
  "allAlerts/fetchAlert",
  async ({ alertId }) => {
    const response = await apiClient.get(`alerts/${alertId}`);
    const result = convertData(response.data, keyMappingAlerts);
    return result;
  }
);

export const getAlerts = createAsyncThunk(
  "allAlerts/fetchAlerts",
  async ({ skip = 0, limit = 10 }, { getState }) => {
    const state = getState().allAlerts;
    const params = getAlertFilterParams(state);
    if (limit == "inf") {
      params.skip = skip;
    } else {
      params.skip = Math.max(0, (skip - 1) * limit);
    }
    params.limit = limit;
    try {
      const response = await apiClient.get(`alerts/filters`, {
        params,
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: "repeat" }),
      });
      const data = response.data;
      const total = data.total || 0;
      const alerts = data.alerts || [];
      const tranformedAlerts = alerts.map((alert) =>
        convertData(alert, keyMappingAlerts)
      );
      const result = { alerts: tranformedAlerts, total };
      return result;
    } catch (error) {
      console.error("Error fetching filter values:", error);
      throw error;
    }
  }
);

export const fetchFilteredAlerts = createAsyncThunk(
  "allAlerts/fetchFilteredAlerts",
  async ({ pageNr = 1, pageSize = 10 }, { dispatch }) => {
    const result = await dispatch(getAlerts({ skip: pageNr, limit: pageSize }));
    return result.payload;
  }
);

export const fetchAlerts = createAsyncThunk(
  "allAlerts/fetchAlerts",
  async (params) => {
    try {
      const response = await apiClient.get(`alerts/filters`, {
        params,
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: "repeat" }),
      });
      const data = response.data;
      const total = data.total || 0;
      const alerts = data.alerts || [];
      const tranformedAlerts = alerts.map((alert) =>
        convertData(alert, keyMappingAlerts)
      );
      const result = { alerts: tranformedAlerts, total };
      return result;
    } catch (error) {
      console.error("Error fetching filter values:", error);
      throw error;
    }
  }
);

export const fetchAuditLogs = createAsyncThunk(
  "allAlerts/fetchAuditLogs",
  async ({ alertId }) => {
    const response = await apiClient.get(`/alerts-audit-log/alerts/${alertId}`);
    const data = response.data || [];
    const result = data.map((auditLog) =>
      convertData(auditLog, keyMappingAuditLog)
    );
    return result;
  }
);

export const fetchOverlapAlerts = createAsyncThunk(
  "allAlerts/fetchOverlapAlerts",
  async ({ alertId }) => {
    const response = await apiClient.get(
      `/overlap-analysis-alerts/alerts/${alertId}`
    );
    const alerts = response?.data?.overlapping_alerts || [];
    const result = alerts.map((alert) => convertData(alert, keyMappingAlerts));
    return result;
  }
);

export const addComment = createAsyncThunk(
  "allAlerts/addComment",
  async ({ alertId, comment }, { rejectWithValue }) => {
    const payload = {
      alert_id: alertId,
      user_id: localStorage.getItem("userId"),
      comment: comment,
    };
    try {
      const response = await apiClient.post("/alerts-audit-log", payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const fetchContributingTransactions = createAsyncThunk(
  "alerts/fetchContributingTransactions",
  async ({ alertId, skip = 0, limit = 10 }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `/alerts/contributing-transactions/${alertId}`,
        {
          params: { skip, limit },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch data");
    }
  }
);

const allAlertsSlice = createSlice({
  name: "allAlerts",
  initialState: {
    alerts: [],
    alertsLoading: false,
    alertsFilters: {},
    alertsSearch: "",
    alertsOrder: [],
    alertsOrigin: "Production",
    showMyAlerts: false,
    totalAlerts: 0,
    currentPage: 0,
    pageSize: 10,
    columnsFiltersValues: [],

    selectedAlert: null,
    selectedAlertDetails: {},

    auditLogs: [],
    overlapAlerts: [],
    contributingTransactions: [],
  },
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setPageSize(state, action) {
      state.pageSize = action.payload;
    },
    setAlertsFilters(state, action) {
      state.alertsFilters = action.payload;
    },
    setAlertsSearch(state, action) {
      state.alertsSearch = action.payload;
    },
    setAlertsOrder(state, action) {
      state.alertsOrder = action.payload;
    },
    setSelectedAlertDetails(state, action) {
      state.selectedAlertDetails = action.payload;
    },
    setTotalAlerts(state, action) {
      state.totalAlerts = action.payload;
    },
    setAlertsOrigin(state, action) {
      state.alertsOrigin = action.payload;
    },
    toggleShowMyAlerts(state) {
      state.showMyAlerts = !state.showMyAlerts;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilteredAlerts.pending, (state) => {
        state.alertsLoading = true;
      })
      .addCase(fetchFilteredAlerts.fulfilled, (state, action) => {
        state.alerts = action.payload.alerts;
        state.totalAlerts = action.payload.total;
        state.alertsLoading = false;
      })
      .addCase(fetchFilteredAlerts.rejected, (state, action) => {
        state.alertsLoading = false;
      })
      .addCase(fetchAlert.fulfilled, (state, action) => {
        state.selectedAlertDetails = action.payload;
      })
      .addCase(getAlertsFilterValues.fulfilled, (state, action) => {
        const requested_column = action.meta.arg?.field;
        const filters = action.payload;
        if (requested_column) {
          if (!state.columnsFiltersValues) {
            state.columnsFiltersValues = {};
          }
          state.columnsFiltersValues = {
            ...state.columnsFiltersValues,
            [requested_column]: [...filters],
          };
        } else {
          console.warn(
            "Requested column (propertyName) is undefined:",
            action.meta.arg
          );
        }
        console.log("Filters received:", filters);
        console.log("Action details:", action);
      })
      .addCase(fetchContributingTransactions.fulfilled, (state, action) => {
        state.contributingTransactions = action.payload;
      })
      .addCase(fetchAuditLogs.fulfilled, (state, action) => {
        state.auditLogs = action.payload;
      })
      .addCase(fetchOverlapAlerts.fulfilled, (state, action) => {
        state.overlapAlerts = action.payload;
      });
  },
});

export const {
  setAlertsFilters,
  setAlertsSearch,
  setAlertsOrder,
  setSelectedAlertDetails,
  setTotalAlerts,
  setAlertsOrigin,
  toggleShowMyAlerts,
  setCurrentPage,
  setPageSize,
} = allAlertsSlice.actions;

export default allAlertsSlice.reducer;
