import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../Profiles.module.css';
import LabelList from '../../LabelList';
import RegularInput from '../../inputs/RegularInput';
import {
    setSelectedProfileId,
    fetchProfileDetails,
    setSelectedProfileDetails,
    setShowEdit,
} from '../../../../features/configuration/configurationSlice'

const Overview = ({configType}) => {

    const dispatch = useDispatch();


    
    const editActive = configType == 'Production' ? false : true;

    // all the profiles
    const profiles = useSelector((state) => state.configuration.profiles)

    // TODO: when endpoint made
    const setProfiles = () => {
        return true;
    }

    // id of selected profile
    const selectedProfileId = useSelector((state) => state.configuration.selectedProfileId)

    // if the id is valid, fetch profile details
    useEffect(() => {
        if (selectedProfileId > -1){
            dispatch(fetchProfileDetails({profileId: selectedProfileId}));
        }
    }, [selectedProfileId])

    // by default, set the selected profile to the first in the list
    useEffect(() => {
        if (profiles.length > 0){
            dispatch(setSelectedProfileId(profiles[0].id));
        }
    }, [])

    // the details of the selected prrofile
    const selectedProfileDetails = useSelector((state) => state.configuration.selectedProfileDetails);

    // properties for the name badge
    const nameBadgeProps = [
        selectedProfileDetails ? {
          type: "badge",
          text: `${selectedProfileDetails ? selectedProfileDetails.name : ''}`,
          color: "#026AA2",
          bgColor: "rgba(2, 106, 162, 0.20)",
          hasBullet: false,
        } : {
            type: "text",
          text: "",
          placeholder: "",
        }
    ];

    // properties for the profile type badge
    const profileTypeProps = [
        selectedProfileDetails ? {
          type: "badge",
          text: `${selectedProfileDetails ? selectedProfileDetails.type : ''}`,
          color: "#B54708",
          bgColor: "#FFFAEB",



       
          hasBullet: false,
        } : {
            type: "text",
            text: "",
            placeholder: "",
        }
    ];

    // properties for the time base badge
    const timeBaseProps = [
        selectedProfileDetails ? {
          type: "text",
          text: `${selectedProfileDetails.frequency}`,
          placeholder: "time base"
        } : {
          type: "text",
          text: "",
          placeholder: "",
        }
    ];

    // properties for the time range amount badge
    const timeRangeAmountProps = [
        selectedProfileDetails ? {
          type: 'text',
          text: `${selectedProfileDetails.frequency_amount}`,
          placeholder: "time base amount"
        } : {
          type: "text",
          text: "",
          placeholder: "",
        }
    ];
    
    // parse to usefull data
    // const generateDataLabelConditionProps = (inputString) => {
    //     // Remove '(' and ')'
    //     let input = inputString;
    //     if (!input) {
    //         return ; 
    //     }

        
    //     console.log("dataLabelConditionPropsVar: ", inputString);
        
    //     input = input.replace('(','');
    //     input = input.replace(')','');

    //     // Split the input string by spaces
    //     const tokens = input.split(' ');
        
    //     // Initialize an array to hold the resulting objects
    //     const dataLabelConditionPropsVar = [];
        
    //     tokens.forEach((token) => {
    //         if (["AND", "OR"].includes(token)) {
    //             // If the token is a logical operator, add it as a text type
    //             dataLabelConditionPropsVar.push({
    //               type: 'text',
    //               text: ` ${token.toLowerCase()} `,
    //         });
    //     } else if (token !== "(" && token !== ")") {
    //         // If the token is a word, add it as a badge type
    //         dataLabelConditionPropsVar.push({
    //           type: 'badge',
    //           text: token,
    //           color: "#595ae6",
    //           bgColor: "#eee0ff",
    //           hasBullet: false,


              
    //         });
    //     }  
    //     });

    //     console.log("dataLabelConditionPropsVar: ", dataLabelConditionPropsVar)

    //     return dataLabelConditionPropsVar
    // };


    const generateDataLabelConditionProps = (dataLabels) => {
        if (!dataLabels || dataLabels.length === 0) {
            return [{
                type: 'text',
                text: '',
            }];
        } else {
            var result = [];
            dataLabels.forEach((label, index) => {
                result.push({
                    type: 'badge',
                    text: label.name,
                    color: "#595ae6",
                    bgColor: "#eee0ff",
                    hasBullet: false,
                });

                if (index < dataLabels.length - 1) {
                    result.push({
                        type: 'text',
                        text: "and",
                    });
                }
            });

            return result;
        }
    };
    
    const dataLabelConditionProps = selectedProfileDetails ? generateDataLabelConditionProps(selectedProfileDetails.datalabels) : [];

    useEffect(() => {
        if (selectedProfileDetails){
            console.log("selectedProfileDetails: ", selectedProfileDetails)
        }
    }, [selectedProfileDetails])

    // when pressed on a label (passed to LabelList)
    const handleLabelClick = (label) => {
        dispatch(setSelectedProfileId(label.id));
    };

    // when pressed on the + (creation of a new profiel)
    const handlePlusClick = () => {
        dispatch(setSelectedProfileId(-1));
        dispatch(setSelectedProfileDetails(null));
        dispatch(setShowEdit(true));
    };

    // when pressed on the edit
    const handleEditClick = (() => {
        dispatch(setShowEdit(true));
    })

    // properties of the label list
    const palette = {
        borderColor: '#026AA2',
        backgroundColor: '#F0F9FF',
        textColor: '#026AA2',
        selectedBackgroundColor: 'rgba(2, 106, 162, 0.20)'
    };


    return (
        <div className={styles.container}>

            <div className={styles.contentWrapper}>

                <div style={{ width: '33%', minWidth: '300px', maxWidth: '400px' }}>
                    <LabelList
                        selectedId={selectedProfileId}
                        labels={profiles} 
                        setLabels={() => setProfiles()}
                        title={"Profiles List"} 
                        onPlusClick={() => handlePlusClick()}
                        handleLabelClick={(label) => handleLabelClick(label)}

                        editActive={editActive}
                        palette={palette}
                    />
                </div>

                <div style={{ width: '67%'}}>
                    <div className={styles.rightContainer}>
                        <RegularInput   title={"Name"}    withBackground={true}   content={nameBadgeProps} />
                        <RegularInput   title={"Profile Type"}    withBackground={false}   content={profileTypeProps} />
                        <RegularInput   title={"Time Base"}    withBackground={false}   content={timeBaseProps} />
                        <RegularInput   title={"Time Range Amount"}    withBackground={false} isEditable={false}   content={timeRangeAmountProps} />
                        <RegularInput   title={"Data Label Condition"}    withBackground={true} isEditable={false}  content={dataLabelConditionProps} />
                    </div>
                </div>

            </div>

            <div className={styles.footer}>

                {/* <div className={`${styles.buttonBox} ${styles.whiteButton}`}>
                    <div className={styles.textCancel}>
                        Cancel
                    </div>
                </div> */}

                {editActive && (
                <div className={`${styles.buttonBox} ${styles.purpleButton}`} onClick={() => handleEditClick()}>
                    <div className={styles.textEdit}>
                        Edit
                    </div>
                </div>)}


            </div>

        </div>
    );
};

export default Overview;