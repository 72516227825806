import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchReportById,
  updateReport,
  cleanReport,
  deleteReports,
} from "../../../features/report_builder/reportBuilderSlice";
import styles from "./ReportBuilder.module.css";
import view_style from "../../../layout/Layout.module.css";
// import HeaderSection from "../../../layout/HeaderSection";
import html2pdf from "html2pdf.js";
import { useNavigate } from "react-router-dom";
import ReportElements from "./ReportElements";
import {
  notifySuccess,
  notifyError,
} from "../../../layout/ToastNotifications.jsx";
import EditableTitle from "./EditableTitle";
import Actions from "./ActionButtons.js";
import HeaderSection from "../../../layout/HeaderSection";

const ReportBuilder = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const reportId = parseInt(id, 10);
  const { reports } = useSelector((state) => state.reportBuilder);
  const selectedReport = reports.find((report) => report.id === reportId);

  const navigate = useNavigate();
  const [localReportJson, setLocalReportJson] = useState(null);
  const [localReportName, setLocalReportName] = useState(""); // Añadido
  const titleRef = useRef(null);

  useEffect(() => {
    if (!reportId) {
      navigate("/reports");
    } else if (!selectedReport) {
      dispatch(fetchReportById(reportId));
    } else if (selectedReport) {
      setLocalReportJson(selectedReport.report_json);
      setLocalReportName(selectedReport.name); // Añadido
    }

    dispatch(cleanReport());
  }, [reportId, dispatch, navigate]);

  const handleUpdateReportJson = (updatedJson) => {
    setLocalReportJson(updatedJson);
  };

  const handleSave = () => {
    if (!selectedReport) return;

    const currentTitle = titleRef.current
      ? titleRef.current.getTitle()
      : localReportName;

    dispatch(
      updateReport({
        reportId: selectedReport.id,
        reportData: {
          ...selectedReport,
          report_json: localReportJson,
          name: currentTitle,
        },
        scenarioIds: selectedReport.report_scenarios.map(
          (scenario) => scenario.scenario_id
        ),
      })
    )
      .unwrap()
      .then(() => {
        notifySuccess("Report saved successfully.");
      })
      .catch((error) => {
        notifyError("Failed to save report.");
      });
  };

  const handleExportToPDF = () => {
    document.querySelectorAll(".no-print").forEach((element) => {
      element.style.display = "none";
    });

    const element = document.getElementById("reportContent");
    const opt = {
      margin: [5, 5, 5, 5],
      filename: `Report_${new Date().toISOString().split("T")[0]}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2.5, useCORS: true },
      jsPDF: { unit: "mm", format: "a3", orientation: "landscape" },
      pagebreak: {
        mode: ["avoid-all", "css", "legacy"],
        before: ".page-break",
        avoid: ".avoid-page-break",
      },
    };

    // Crear el PDF y agregar paginación centrada en el pie de página
    html2pdf()
      .set(opt)
      .from(element)
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);

          const pageWidth = pdf.internal.pageSize.getWidth();
          const text = `Page ${i} of ${totalPages}`;
          const textWidth = pdf.getTextWidth(text);

          // Centrar el texto horizontalmente
          pdf.text(
            text,
            (pageWidth - textWidth) / 2,
            pdf.internal.pageSize.getHeight() - 10
          );
        }
      })
      .save()
      .then(() => {
        document.querySelectorAll(".no-print").forEach((element) => {
          element.style.display = "";
        });
      });
  };

  const handleDelete = async () => {
    await dispatch(deleteReports([selectedReport.id]));
    navigate("/reports");
  };

  if (!selectedReport) {
    return <div>Loading report...</div>;
  }
  return (
    <div className={view_style.viewWrapper}>
      <div className={view_style.sectionWrapper} style={{ display: "block" }}>
        <HeaderSection
          SectionName="Report Builder"
          AdditionalText={selectedReport.name}
          returnFunction={() => {
            navigate(-1);
          }}
        />
        <Actions
          handleSave={handleSave}
          handleExport={handleExportToPDF}
          handleDelete={handleDelete}
        />
        <div id="reportContent">
          <EditableTitle
            ref={titleRef}
            initialTitle={localReportName}
            report={selectedReport}
          />
          {localReportJson && (
            <ReportElements
              reportJson={localReportJson}
              isEditable={true}
              onUpdate={handleUpdateReportJson}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportBuilder;
