import React, { useState, Fragment, useEffect, useRef } from "react";
import styles from "./DataManagement.module.css";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../layout/buttons/NormalButton";
import HeaderSection from "../../../layout/HeaderSection";
import InputComponent from "../../../layout/input/InputComponent";
import ModalPopUp from "../../../layout/modal/Modal";
import Selector from "../../atl_tool/visualisation/selector/Selector";
import { HeaderClass } from "../../../layout/table/Header";
import { useNavigate, useLocation } from "react-router-dom";
import TableGrid from "../../../layout/table/Table";
import DropDownButton from "../../../layout/buttons/DropDownButton";
import { ExportButton, IconButton } from "../../../layout/buttons/IconButton";
import { CellClass, RowCellClass } from "../../../layout/table/Cell";
import {
  createListColumn,
  getListById,
  setColumnCreationStatus,
  setRowCreationStatus,
  createListRow,
} from "../../../features/shared_list/sharedListSlice";

import { useDispatch, useSelector } from "react-redux";
import {
  notifySuccess,
  notifyError,
} from "../../../layout/ToastNotifications.jsx";

const headers = [
  new HeaderClass("Name", "id", true, false, { minWidth: 100, maxWidth: 100 }),
  new HeaderClass("Type", "user", true, false, {
    minWidth: 100,
    maxWidth: 100,
    width: 100,
  }),
  new HeaderClass("Description", "description", true, false, {
    minWidth: 400,
    maxWidth: 400,
    width: 400,
  }),
];

const listSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M15 2H6C5.47 2 4.96 2.21 4.59 2.59C4.21 2.96 4 3.47 4 4V20C4 20.53 4.21 21.04 4.59 21.41C4.96 21.79 5.47 22 6 22H18C18.53 22 19.04 21.79 19.41 21.41C19.79 21.04 20 20.53 20 20V7L15 2Z"
      stroke="#905EE2"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 2V6C14 6.53 14.21 7.04 14.59 7.41C14.96 7.79 15.47 8 16 8H20"
      stroke="#905EE2"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 9H8"
      stroke="#905EE2"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 13H8"
      stroke="#905EE2"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 17H8"
      stroke="#905EE2"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

function ColumnIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 3V20c0 .27-.1.52-.29.71-.19.19-.45.29-.71.29H5c-.53 0-1.04-.21-1.41-.59C3.21 20.04 3 19.53 3 19V5c0-.53.21-1.04.59-1.41C4.04 3.21 4.53 3 5 3h14c.53 0 1.04.21 1.41.59.38.37.59.88.59 1.41v6"
        stroke="#D9D6FE"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 19h6"
        stroke="#D9D6FE"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 22v-6"
        stroke="#D9D6FE"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function RowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M13 16h6"
        stroke="#D9D6FE"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 19v-6"
        stroke="#D9D6FE"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 10h17c.265 0 .52-.105.707-.293C18.895 9.52 19 9.265 19 9V3c0-.531-.21-1.04-.586-1.414C18.039 1.211 17.53 1 17 1H3c-.531 0-1.04.21-1.414.586C1.211 1.96 1 2.47 1 3v14c0 .531.21 1.04.586 1.414C1.96 18.79 2.47 19 3 19h6"
        stroke="#D9D6FE"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function ImportIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10 10.834V17.5"
        stroke="#E9D7FE"
        strokeWidth="1.392"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.333 12.415a7.996 7.996 0 0 1-1.366-5.848c.117-.878.431-1.718.92-2.456a7.332 7.332 0 0 1 6.693-3.19 7.348 7.348 0 0 1 4.153 1.866 6.96 6.96 0 0 1 1.429 2.279h1.491a3.837 3.837 0 0 1 3.18 6.007"
        stroke="#E9D7FE"
        strokeWidth="1.392"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.667 14.167 10 10.834l3.333 3.333"
        stroke="#E9D7FE"
        strokeWidth="1.392"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const DataManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");

  const location = useLocation();
  const [rows, setRows] = useState([]);

  const addRowRef = useRef();

  const [isAddingRow, setIsAddingRow] = useState(false); // Track add row mode

  const makeRowFromCells = (cells) => {
    let row = [];
    cells.forEach((cell) => {
      row.push(new CellClass(cell.value, cell.field, null, cell.data_type));
    });

    return new RowCellClass(`row-${rows.length}`, row);
  };

  const makeSaveRowData = (row) => {
    let pairs = [];

    row.forEach((cell) => {
      let pair = {};
      pair.col_id = cell.field;
      pair.value = cell.value;
      pairs.push(pair);
    });

    return pairs;
  };

  const [newRowIds, setNewRowIds] = useState();
  const [alwaysFirstId, setAlwaysFirstId] = useState();

  const handleNewRow = (newRow) => {
    if (!newRow) {
      console.error("Invalid new row data:", newRow);
      return;
    }

    const newRowId = `row-${rows.length}`;

    setNewRowIds(newRowId);
    setAlwaysFirstId(newRowId);

    dispatch(
      createListRow({
        listId: location.state.id,
        data: makeSaveRowData(newRow),
      })
    );

    setRows((prevRows) => [...prevRows, makeRowFromCells(newRow)]);
    setTimeout(() => {
      setNewRowIds(null);
    }, 2000);
  };

  const data = location.state;

  const [createNewColumnModalIsOpen, setCreateNewColumnModalIsOpen] =
    useState(false);
  const [createColumnName, setCreateColumnName] = useState("");
  const [createColumnDescription, setCreateColumnDescription] = useState("");
  const [selectedCreateColumnType, setSelectedCreateColumnType] =
    useState("TEXT");

  const { creationColumnStatus, creationRowStatus } = useSelector(
    (state) => state.sharedList
  );

  const selectedListObject = location.state; // Access selected object

  useEffect(() => {
    if (creationColumnStatus === "succeeded") {
      notifySuccess("A new column has been created");
      dispatch(setColumnCreationStatus("idle"));
    } else if (creationColumnStatus === "failed") {
      notifyError("New  Column could not be created");
      dispatch(setColumnCreationStatus("idle"));
    }
  }, [creationColumnStatus]);

  useEffect(() => {
    if (creationRowStatus === "succeeded") {
      notifySuccess("A new row has been created");
      dispatch(setRowCreationStatus("idle"));
    } else if (creationRowStatus === "failed") {
      notifyError("New row could not be created");
      dispatch(setRowCreationStatus("idle"));
    }
  }, [creationRowStatus]);

  const closeCreateNewColumnModal = () => {
    setCreateNewColumnModalIsOpen(false);
  };

  const resetModal = () => {
    setCreateColumnName("");
    setCreateColumnDescription("");
    setSelectedCreateColumnType("TEXT");
    closeCreateNewColumnModal();
  };

  const createNewColumn = () => {
    const listId = data.id;
    dispatch(
      createListColumn({
        listId: listId,
        name: createColumnName,
        type: selectedCreateColumnType,
        description: createColumnDescription,
      })
    );
    resetModal();
  };

  const openCreateNewColumnModal = () => {
    setCreateNewColumnModalIsOpen(true);
  };

  const [headersManageData, setHeadersManageData] = useState([
    new HeaderClass("Name", "id", true, false, {
      minWidth: 100,
      maxWidth: 100,
    }),
    new HeaderClass("Type", "user", true, false, {
      minWidth: 100,
      maxWidth: 100,
      width: 100,
    }),
    new HeaderClass("Description", "description", true, false, {
      minWidth: 400,
      maxWidth: 400,
      width: 400,
    }),
  ]);

  const makeHeadersManageData = (listObject) => {
    let headers = [];
    listObject.list_columns.forEach((column) => {
      headers.push(
        new HeaderClass(
          column.name,
          column.id,
          true,
          false,
          {
            minWidth: 100,
            maxWidth: 100,
          },
          column.data_type
        )
      );
    });
    return headers;
  };

  const makeRowsManageData = (listObject) => {
    let rows = [];

    if (!listObject || !listObject.list_columns) {
      console.error("Invalid list object structure", listObject);
      return rows;
    }

    const columnIds = listObject.list_columns.map((column) => column.id);

    const numberOfRows = Math.max(
      ...listObject.list_columns.map((column) => column.list_values.length)
    );

    for (let rowIndex = 0; rowIndex < numberOfRows; rowIndex++) {
      let cells = [];

      listObject.list_columns.forEach((column) => {
        const value = column.list_values[rowIndex]?.value || "";
        cells.push(new CellClass(value, column.id, null, column.data_type));
      });

      rows.push(new RowCellClass(`row-${rowIndex}`, cells));
    }

    return rows;
  };

  useEffect(() => {
    if (creationColumnStatus === "succeeded" && data) {
      dispatch(getListById({ listId: data.id, type: data.type }))
        .unwrap()
        .then((updatedList) => {
          setHeadersManageData(makeHeadersManageData(updatedList));
          setRows(makeRowsManageData(updatedList));
        })
        .catch((error) => {
          console.error("Failed to refresh list data:", error);
        });
    }
  }, [creationColumnStatus, data, dispatch]);

  useEffect(() => {
    if (data) {
      setHeadersManageData(makeHeadersManageData(data));
      setRows(makeRowsManageData(data));
    }
  }, [data]);

  const navigateBack = () => {
    navigate("/shared-lists", {
      state: selectedListObject, // Pass the selected object back
    });
  };

  const addNewColumn = () => {
    openCreateNewColumnModal();
  };

  const actions = [
    {
      name: "New Column",
      action: () => addNewColumn(),
      icon: <ColumnIcon />,
    },
    {
      name: "New Row",
      action: () => addRowRef.current(),
      icon: <RowIcon />,
      disabled: isAddingRow,
    },
  ];

  const farRightActions = [
    <IconButton icon={<ColumnIcon />} action={() => {}} />,
    <IconButton icon={<ImportIcon />} action={() => {}} />,
    <ExportButton action={() => {}} />,
  ];
  return (
    <div className={styles.container}>
      <ModalPopUp
        handleClose={closeCreateNewColumnModal}
        isOpen={createNewColumnModalIsOpen}
        inlineTitle={true}
        title={"Create New Column"}
        buttonsAlign={"center"}
        content={
          <>
            <div className={styles.inputWrapper}>
              <InputComponent
                type="normal"
                label="Name"
                value={createColumnName}
                onChange={(e) => setCreateColumnName(e.target.value)}
                content="New Column Name"
              />
              <Selector
                label="Value Type"
                options={["TEXT", "BOOLEAN", "DATE", "DATETIME", "NUMBER"]}
                selectedOption={selectedCreateColumnType}
                onOptionSelect={setSelectedCreateColumnType}
              />
            </div>
            <InputComponent
              type="comment"
              label="Description"
              value={createColumnDescription}
              onChange={(e) => setCreateColumnDescription(e.target.value)}
              content="Add text here Add text here Add text here Add text here Add text here Add text here"
            />
          </>
        }
        actionButtons={[
          <SecondaryButton
            style={{ flex: 1 }}
            content="Cancel"
            action={closeCreateNewColumnModal}
          />,
          <PrimaryButton
            style={{ flex: 1 }}
            disabled={
              createColumnName.trim() != "" &&
              createColumnDescription.trim() != ""
                ? false
                : true
            }
            content="Create"
            action={createNewColumn}
          />,
        ]}
        icon={listSvg}
      />

      <div className={styles.contentWrapper}>
        <div className={styles.rightContainer}>
          <HeaderSection
            SectionName="Manage Data"
            AdditionalText={"Transaction Type List"}
            returnFunction={navigateBack}
          />
          <div className={styles.inputWrapper}>
            <TableGrid
              title={`Transaction Type List`}
              headers={headersManageData}
              actionButtonsLeft={[
                <DropDownButton
                  title={"Create"}
                  actions={actions}
                  disabled={false}
                />,
              ]}
              rows={rows}
              onAddRow={(addRowFunction) => {
                handleNewRow(addRowFunction.cells); // Add the new row to the state
                addRowRef.current = addRowFunction; // Assign the addRow function to the ref
              }}
              isAddingRow={isAddingRow}
              setIsAddingRow={setIsAddingRow}
              canSearch={true}
              actionButtonsFarRight={farRightActions}
              noDataPlaceholder={"There are no transaction types in this list."}
              pinnedId={alwaysFirstId}
              newIds={[newRowIds]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataManagement;