import stylesheet from "../atl_tool/AtlTool.module.css";
import styles from "./Btl.module.css";
import { useSelector } from "react-redux";

import HeaderSection from "../../layout/HeaderSection";
import Selector from "./selection/Selector";
import BtlPlot from "./selection/Plot";
import BtlTable from "./selection/BtlTable";
import AlertsTable from "./alerts_table/AlertsTable";

function BtlTool() {
  const { selectedScenario, selectedSubScenario, selectedSegment } =
    useSelector((state) => state.btlTool);
  return (
    <div className={stylesheet.atlTool}>
      <div className={stylesheet.sectionWrapper}>
        <div className={stylesheet.section}>
          <HeaderSection
            SectionName="BTL"
            AdditionalText={`${
              selectedScenario?.name ? `${selectedScenario.name}` : ""
            }${
              selectedSubScenario?.name ? ` - ${selectedSubScenario.name}` : ""
            }${selectedSegment?.name ? ` - ${selectedSegment.name}` : ""}`}
          />
          <div className={styles.contentContainer}>
            <Selector />
            <BtlPlot />
          </div>
          <BtlTable />
        </div>
      </div>
      <AlertsTable />
    </div>
  );
}

export default BtlTool;
