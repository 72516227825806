import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "../report_builder/ReportBuilder.module.css";
import view_style from "../../../layout/Layout.module.css";
import HeaderSection from "../../../layout/HeaderSection";
import TableGrid from "../../../layout/table/Table.js";
import { HeaderClass } from "../../../layout/table/Header.js";
import { CellClass, RowCellClass } from "../../../layout/table/Cell.js";
import { exportToExcel } from "../../../layout/table/TableTools.js";
import { useNavigate } from "react-router-dom";
import { RadionButton } from "../../../layout/buttons/RadioButton.js";
import NewReportModal from "./NewReportModal.js";
import { SelectActionButton } from "../../../layout/table/TableTools.js";
import {
  notifyError,
  notifySuccess,
} from "../../../layout/ToastNotifications.jsx";
import NameBadge from "../../../layout/labels/name_badge/NameBadge.js";

import {
  fetchReports,
  deleteReports,
  setCurrentReportDB,
} from "../../../features/report_builder/reportBuilderSlice";
import { PrimaryButton } from "../../../layout/buttons/NormalButton.js";

function CurrentReportButton({ currentReportId, id, setNewCurrentId }) {
  function handleChange() {
    if (currentReportId == id) {
      setNewCurrentId(null);
    } else {
      setNewCurrentId(id);
    }
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "end",
        width: "100%",
        marginRight: 10,
      }}>
      <RadionButton
        checked={currentReportId == id}
        onChange={handleChange}
        canUndo={true}
      />
    </div>
  );
}

function TrashIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      width="24"
      height="24"
      stroke="currentColor">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"></path>
    </svg>
  );
}

const Reports = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reports, currentReportId } = useSelector(
    (state) => state.reportBuilder
  );
  const reportsCount = reports?.length;
  const timeoutRef = useRef(null);

  useEffect(() => {
    dispatch(fetchReports());
  }, [dispatch]);

  const [resetPage, setResetPage] = useState(1);
  const [recentlyChangedCurrent, setRecentlyChangedCurrent] = useState([]);
  const [newReportModalOpen, setNewReportModalOpen] = useState(false);

  useEffect(() => {
    setResetPage(resetPage + 1);
  }, [currentReportId]);

  async function setNewCurrentId(id) {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    await dispatch(setCurrentReportDB({ reportId: id }));
    if (id) {
      setRecentlyChangedCurrent([id]);
      timeoutRef.current = setTimeout(() => {
        setRecentlyChangedCurrent([]);
        timeoutRef.current = null;
      }, 2000);
    } else {
      setRecentlyChangedCurrent([]);
    }
  }

  const headers = [
    new HeaderClass("Name", "name", true, true, { minWidth: 200 }),
    new HeaderClass("Status", "status", true, true, { minWidth: 150 }),
    new HeaderClass("User", "user", true, true, { minWidth: 200 }),
    new HeaderClass("Creation Date", "creation_date", true, true, {
      minWidth: 200,
      maxWidth: 270,
      width: 270,
    }),
    new HeaderClass("Updated At", "updated_at", true, true, {
      minWidth: 200,
      maxWidth: 270,
      width: 270,
    }),
    new HeaderClass("Current", "set_current", false, false, {
      minWidth: 100,
      maxWidth: 100,
      width: 100,
    }),
  ];

  const rows = reports.map((report, index) => {
    const cells = headers.map((header) => {
      const value = report[header.field];
      if (header.field == "set_current") {
        return new CellClass("CurrentReport", "current_report", () => (
          <CurrentReportButton
            currentReportId={currentReportId}
            id={report.id}
            setNewCurrentId={setNewCurrentId}
          />
        ));
      }
      if (header?.field === "creation_date" || header?.field === "updated_at") {
        if (value) {
          const dateOnly = value.split("T")[0];
          return new CellClass(dateOnly, header.field);
        }
      }
      if (header?.field === "user") {
        const name = value["username"];
        return new CellClass(name, header.field, () => (
          <NameBadge name={name !== "" ? name : "Unassigned"} />
        ));
      }
      return new CellClass(value, header.field);
    });
    return new RowCellClass(report.id, cells);
  });

  async function onExport() {
    const headerLabels = headers.map((header) => header.label);
    const columns = reports.map((report) => {
      return headerLabels.map((label) => report[label.toLowerCase()]);
    });
    exportToExcel(headerLabels, columns, "Reports");
  }

  function onRowClick(rowData) {
    const id = rowData.id;
    navigate(`/reports/${id}`);
  }

  const buttonNewReport = (
    <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
      <div className={styles.plusIcon}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none">
          <path
            d="M3.7998 8.00098H12.1998"
            stroke="#8174FE"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.99902 3.80078V12.2008"
            stroke="#8174FE"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div className={styles.text}>New Report</div>
    </div>
  );

  async function deleteSelectedReports(ids, onSuccess) {
    try {
      const result = await dispatch(deleteReports(ids)).unwrap();
      dispatch(fetchReports());
      onSuccess();
      if (result.length == 0) {
        notifyError("Could not delete reports");
      } else {
        notifySuccess(`Deleted ${result.length} reports`);
      }
    } catch (error) {
      notifyError("Could not delete reports");
    }
  }

  const actionButtons = [
    new SelectActionButton(
      "delete",
      "report",
      ({ selectedIds, selectAllMode, onSuccess }) =>
        deleteSelectedReports(selectedIds, onSuccess),
      <TrashIcon />,
      true
    ),
  ];

  return (
    <div className={view_style.viewWrapper}>
      <div className={view_style.sectionWrapper} style={{ display: "block" }}>
        <HeaderSection SectionName="Reports" Count={reportsCount} />
        <TableGrid
          title={"Reports"}
          headers={headers}
          rows={rows}
          onSelectActionButtons={actionButtons}
          canExport={true}
          onRowClick={onRowClick}
          onExport={onExport}
          actionButtonsRight={[
            <PrimaryButton
              content={buttonNewReport}
              action={() => setNewReportModalOpen(true)}
            />,
          ]}
          resetPagination={resetPage}
          pinnedId={currentReportId}
          clickedIds={[currentReportId]}
          newIds={recentlyChangedCurrent}
        />
        <NewReportModal
          setModalOpen={setNewReportModalOpen}
          isOpen={newReportModalOpen}
          setRecentlyChangedCurrent={setRecentlyChangedCurrent}
        />
      </div>
    </div>
  );
};

export default Reports;
