import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../axiosConfig";
import { transformPeriodToDateRange } from "../Functions";
import { convertData } from "../Functions";
import { keyMappingAlerts } from "../all_alerts/allAlertsSlice";

function transformBtlTable(data) {
  const table = data.map((item) => {
    const name = Object.keys(item)[0];
    const rowData = item[name];

    const thresholds = rowData.thresholds.reduce((acc, threshold) => {
      acc[threshold.threshold_name] = threshold.threshold_value;
      return acc;
    }, {});

    return {
      id: rowData.optimisation_row_id,
      name: name,
      ...thresholds,
      efficiency: rowData.efficiency,
    };
  });
  return table;
}

export const fetchThresholds = createAsyncThunk(
  "btlTool/fetchThresholds",
  async ({ selectedScenario, selectedSubScenario }) => {
    const scenarioId = selectedScenario.id;
    const subScenarioId = selectedSubScenario?.id || 0;
    const response = await apiClient.get(
      `/thresholds/scenarios/${scenarioId}/${subScenarioId}`
    );
    return response.data;
  }
);

export const fetchSegments = createAsyncThunk(
  "btlTool/fetchSegments",
  async ({ scenarioId, subScenarioId, period }) => {
    const periodString = new URLSearchParams(
      transformPeriodToDateRange(period)
    ).toString();
    const response = await apiClient.get(
      `segment-relevancy-count/${scenarioId}/${subScenarioId}?${periodString}`
    );

    return response.data;
  }
);

export const fetchRiskScores = createAsyncThunk(
  "btlTool/fetchRiskScores",
  async ({ scenarioId, subScenarioId, segmentId, period }) => {
    const periodString = new URLSearchParams(
      transformPeriodToDateRange(period)
    ).toString();
    const response = await apiClient.get(
      `scenarios/${scenarioId}/${subScenarioId}/segments/${segmentId}/risk_scores?${periodString}`
    );
    return response.data;
  }
);

export const fetchBoxPlotData = createAsyncThunk(
  "btlTool/fetchBoxPlotData",
  async (
    { scenarioId, subScenarioId, segmentId, riskScore, period },
    { requestId }
  ) => {
    const periodString = new URLSearchParams(
      transformPeriodToDateRange(period)
    ).toString();
    const response = await apiClient.get(
      `/scenarios/${scenarioId}/${subScenarioId}/segments/${segmentId}/risk-scores/${riskScore.name.toLowerCase()}/btl-box-plot?${periodString}`
    );
    return { data: response.data, requestId };
  }
);

export const fetchBtlTable = createAsyncThunk(
  "btlTool/fetchBtlTable",
  async ({ scenarioId, subScenarioId, segmentId, riskScore, period }) => {
    const periodString = new URLSearchParams(
      transformPeriodToDateRange(period)
    ).toString();
    const response = await apiClient.get(
      `scenarios/${scenarioId}/${subScenarioId}/segments/${segmentId}/risk-scores/${riskScore.name.toLowerCase()}/btl-table?${periodString}`
    );
    const transformedData = transformBtlTable(response.data);
    return transformedData;
  }
);

export const performBtl = createAsyncThunk(
  "btlTool/performBtl",
  async ({
    scenarioId,
    subScenarioId,
    segmentId,
    riskScore,
    optimisationThresholds,
    period,
  }) => {
    const transformedPeriod = transformPeriodToDateRange(period);
    const payload = {
      scenario_id: scenarioId,
      subscenario_id: subScenarioId,
      customer_segment_id: segmentId,
      risk_score: riskScore?.name?.toLowerCase() || "",
      optimisation_type: "BTL",
      period: transformedPeriod,
      to_change_threshold_ids_amount_type_amount: optimisationThresholds,
    };
    const response = await apiClient.post("/perform-BTL", payload);
    return response.data;
  }
);

export const fetchGeneratedAlerts = createAsyncThunk(
  "btlTool/fetchGeneratedAlerts",
  async ({
    scenarioId,
    subScenarioId,
    segmentId,
    riskScore,
    rowId,
    period,
  }) => {
    const periodString = new URLSearchParams(
      transformPeriodToDateRange(period)
    ).toString();
    const response = await apiClient.get(
      `/scenarios/${scenarioId}/${subScenarioId}/segments/${segmentId}/risk-scores/${riskScore.name.toLowerCase()}/btl-table/${rowId}/generated-alerts?${periodString}`
    );
    const data = response.data;
    const alerts = data.map((alert) => convertData(alert, keyMappingAlerts));
    return alerts;
  }
);

export const fetchSampledIds = createAsyncThunk(
  "btlTool/fetchSampledIds",
  async ({ sampleAmount, sampleType, ids }) => {
    const response = await apiClient.post(
      `/random-sample-generated-alerts?sample_amount=${sampleAmount}&sample_type=${sampleType}`,
      { ids }
    );

    return response.data;
  }
);

const btlToolSlice = createSlice({
  name: "btlTool",
  initialState: {
    selectedScenario: null,
    selectedSubScenario: null,
    selectedSegment: null,
    selectedRiskScore: null,
    selectedThreshold: null,
    selectedOptimisation: null,
    loadingThresholds: false,
    thresholds: [],
    loadingSegments: false,
    segments: [],
    loadingRiskScores: false,
    riskScores: [],
    loadingBoxPlotData: false,
    boxPlotData: [],
    boxPlotDataRequestId: null,
    btlTable: [],
    loadingBtlTable: false,
    generatedAlerts: [],
    loadingGeneratedAlerts: false,
    selectedPeriod: {
      [new Date().getFullYear()]: Array.from(
        { length: new Date().getMonth() + 1 }, // Generate months up to the current month (inclusive)
        (_, index) => index
      ),
    },
    alertSelectionType: "All Alerts",
    sampledAlerts: [],
  },
  reducers: {
    setSelectedPeriod(state, action) {
      state.selectedPeriod = action.payload;
    },
    setSelectedScenario(state, action) {
      state.selectedScenario = action.payload;
    },
    setSelectedSubScenario(state, action) {
      state.selectedSubScenario = action.payload;
    },
    setSelectedSegment(state, action) {
      state.selectedSegment = action.payload;
    },
    setSelectedOptimisation(state, action) {
      state.selectedOptimisation = action.payload;
    },
    resetSegments(state) {
      state.segments = [];
      state.selectedSegment = null;
      state.riskScores = []
    },
    setSelectedRiskScore(state, action) {
      state.selectedRiskScore = action.payload;
    },
    setSelectedThreshold(state, action) {
      state.selectedThreshold = action.payload;
    },
    setAlertSelectionType(state, action) {
      state.alertSelectionType = action.payload;
    },
    resetSampledAlerts(state, action) {
      state.sampledAlerts = [];
    },
    resetRiskScores(state) {
      state.riskScores = [];
      state.selectedRiskScore = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSegments.pending, (state) => {
        state.loadingSegments = true;
      })
      .addCase(fetchSegments.fulfilled, (state, action) => {
        state.segments = action.payload.segments;
        const selectedSegmentExists = state.segments.some(
          (segment) => segment.id === state.selectedSegment?.id
        );
        if (!selectedSegmentExists) {
          state.selectedSegment = null;
          state.selectedRiskScore = null;
          state.riskScores = []
        }
        state.loadingSegments = false;
      })
      .addCase(fetchSegments.rejected, (state) => {
        state.segments = [];
        state.loadingSegments = false;
      })
      .addCase(fetchRiskScores.pending, (state) => {
        state.loadingRiskScores = true;
      })
      .addCase(fetchRiskScores.fulfilled, (state, action) => {
        state.riskScores = action.payload.risk_scores;
        const matchingRiskScore = state.riskScores.find(
          (riskScore) => riskScore.id === state.selectedRiskScore?.id
        );
        if (matchingRiskScore) {
          state.selectedRiskScore = matchingRiskScore;
        } else {
          state.selectedRiskScore = null;
        }

        state.loadingRiskScores = false;
      })
      .addCase(fetchRiskScores.rejected, (state) => {
        state.riskScores = [];
        state.loadingRiskScores = false;
      })
      .addCase(fetchThresholds.pending, (state) => {
        state.loadingRiskScores = true;
        state.loadingThresholds = true;
      })
      .addCase(fetchThresholds.fulfilled, (state, action) => {
        if (action.payload) {
          state.thresholds = action.payload.thresholds;
          const selectedThresholdExists = state.thresholds.some(
            (threshold) =>
              threshold.threshold_id === state.selectedThreshold?.threshold_id
          );
          if (!selectedThresholdExists) {
            state.selectedThreshold = state.thresholds[0];
          }
        }
        state.loadingThresholds = false;
      })
      .addCase(fetchThresholds.rejected, (state, action) => {
        state.thresholds = [];
        state.loadingThresholds = false;
      })
      .addCase(fetchBoxPlotData.pending, (state, action) => {
        state.boxPlotData = [];
        state.loadingBoxPlotData = true;
        state.currentRequestId = action.meta.requestId;
      })
      .addCase(fetchBoxPlotData.fulfilled, (state, action) => {
        if (state.currentRequestId === action.meta.requestId) {
          state.boxPlotData = action.payload.data;
          state.loadingBoxPlotData = false;
          state.currentRequestId = null;
        }
      })
      .addCase(fetchBoxPlotData.rejected, (state, action) => {
        if (state.currentRequestId === action.meta.requestId) {
          state.boxPlotData = [];
          state.loadingBoxPlotData = false;
          state.currentRequestId = null;
        }
      })
      .addCase(fetchBtlTable.pending, (state) => {
        state.loadingBtlTable = true;
      })
      .addCase(fetchBtlTable.fulfilled, (state, action) => {
        state.btlTable = action.payload;
        const selectedOptimisationExists = state.btlTable.some(
          (optimistation) => optimistation.id === state.selectedOptimisation?.id
        );
        if (!selectedOptimisationExists) {
          state.selectedOptimisation = null;
        }
        state.loadingBtlTable = false;
      })
      .addCase(fetchBtlTable.rejected, (state, action) => {
        state.btlTable = [];
        state.selectedOptimisation = null;
        state.loadingBtlTable = false;
      })
      .addCase(fetchGeneratedAlerts.pending, (state) => {
        state.loadingGeneratedAlerts = true;
      })
      .addCase(fetchGeneratedAlerts.fulfilled, (state, action) => {
        state.generatedAlerts = action.payload;
        state.loadingGeneratedAlerts = false;
      })
      .addCase(fetchGeneratedAlerts.rejected, (state, action) => {
        state.generatedAlerts = [];
        state.loadingGeneratedAlerts = false;
      })
      .addCase(fetchSampledIds.fulfilled, (state, action) => {
        state.sampledAlerts = action.payload?.sampled_alerts || [];
      })
      .addCase(fetchSampledIds.rejected, (state, action) => {
        state.sampledAlerts = [];
      });
  },
});

export const {
  setSelectedScenario,
  setSelectedSubScenario,
  setSelectedPeriod,
  setSelectedSegment,
  setSelectedRiskScore,
  setSelectedThreshold,
  setSelectedOptimisation,
  setAlertSelectionType,
  resetSampledAlerts,
  resetSegments,
  resetRiskScores,
} = btlToolSlice.actions;

export default btlToolSlice.reducer;
