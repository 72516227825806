import styles from "./Input.module.css";
import { useState, useEffect } from "react";

const NumberInput = ({
  value,
  setValue,
  label,
  allowNegative = false,
  setValid = () => {},
  disabled = false,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const validateInput = (value) => {
    if (value === null || value === undefined || value === "") {
      return "Input cannot be empty";
    }
    if (value < 0 && !allowNegative) {
      return "Negative values are not allowed";
    }
    if (/^\d+,\d+$/.test(value)) {
      return "Input must use a decimal point (.) instead of a comma (,)";
    }
    if (!/^(0|[1-9]\d*|0\.\d+|[1-9]\d*\.\d+)$/.test(value)) {
      return "Invalid input format";
    }
    if (isNaN(value)) {
      return "Input must be a valid number";
    }
    return "";
  };

  function handleChange(newValue) {
    const regexAllowed = /^-?\d*[.,]?\d*$/;
    if (regexAllowed.test(newValue)) {
      setValue(newValue);
    }
  }

  function checkValue() {
    const error = validateInput(value);
    setErrorMessage(error);
    if (error) {
      setValid(false);
    } else {
      setValid(true);
    }
  }

  useEffect(() => {
    checkValue();
  }, [value, disabled]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 6 }}>
      <div className={styles.label}>{label}</div>
      <div className={`${styles.boxWrapper}`} style={{ width: "60px" }}>
        <div
          className={`${styles.box} ${
            errorMessage ? styles.errorInputContainer : ""
          } ${isFocused ? styles.focusedInput : ""} ${
            disabled ? styles.disabled : ""
          }`}>
          <input
            style={{ direction: "ltr", textAlign: "left" }}
            type="text"
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            className={styles.input}
            readOnly={disabled}
            disabled={disabled}
          />
        </div>
        {errorMessage && !disabled && (
          <div className={styles.errorInputMessageContainer}>
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default NumberInput;
