import React, { useState, useEffect, useRef } from 'react';
import styles from './Selector.module.css';
import Badge from '../badges/Badge';
import RegularInput from '../inputs/RegularInput';

import Scrollbar from '../../../layout/scrollbar/Scrollbar';

const Selector = ({ color, bgColor, options, hasBullet, selectedOption, onOptionSelect, label, textSelector=false, width, height }) => {

    const [currentSelectedOption, setCurrentSelectedOption] = useState(null);
    const [optionsBadgeList, setOptionsBadgeList] = useState([]);
    const [scrollHeight, setScrollHeight] = useState('auto');

    useEffect(() => {
        const elementHeight = 51;
        setScrollHeight(`${elementHeight * optionsBadgeList.length}px`);

    }, [optionsBadgeList])

    function capitalizeWords(text) {
        return text
            .split(' ')               // Split the text into words
            .map(word =>              // Transform each word
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(' ');              // Join the transformed words back into a string
    }
    

    // remove "and"/"or" from selected option (used for multiselect)
    useEffect(() => {
        if (selectedOption.length > 0 && selectedOption[0].text === ''){
            setCurrentSelectedOption(
                [{
                    type: "badge",
                    text: `Not Selected`,
                    color: "#3B3B3B",
                    bgColor: "#C5C5C5",
                    hasBullet:  hasBullet,
                }]
            )
        }
        else {

            console.log("selectedOption: ", selectedOption);
             selectedOption.map((option) => console.log(option.type))
            setCurrentSelectedOption(selectedOption.filter((option) => option.type === 'badge'));
        }
    }, [selectedOption])

   

    useEffect(() => {
        if (textSelector) {
            var newOptionsBadgeList = [];
            options.map((item) => {
                newOptionsBadgeList.push(
                    {
                        id: item.id,
                        type: "text",
                        text: `${item.name}`,
                        hasBullet: item.hasBullet,
                    },
                )
            });
            setOptionsBadgeList(newOptionsBadgeList);
        }
        else {
            var newOptionsBadgeList = [];
            options.map((item) => {
                newOptionsBadgeList.push(
                    {
                        id: item.id,
                        type: "badge",
                        text: `${item.name}`,
                        color: `${color}`,
                        bgColor: `${bgColor}`,
                        hasBullet: hasBullet,
                    },
                )
            });
            setOptionsBadgeList(newOptionsBadgeList);
        }        
    }, [options])

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const selectOption = (option) => {
        onOptionSelect(options.find((opt) => opt.id === option.id));
        setIsOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const getRandomNumber = () => {
        return Math.floor(Math.random() * 1000000);
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={styles.inputWithLabel} ref={dropdownRef} style={{ width:width, marginRight:width?"0px":"" }} >

            <div className={styles.label}>{label}</div>

            <div className={styles.input} onClick={toggleDropdown} style={{ width:width, minHeight:height, marginTop:height?"3px":""}} >
                <div className={styles.title}>
                    <div className={styles.content}>
                        {currentSelectedOption && !textSelector && (currentSelectedOption.length === 1) && (
                            <RegularInput withBackground={false} content={currentSelectedOption}/>
                        )}
                        {currentSelectedOption && !textSelector && (currentSelectedOption.length > 1) && (
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '7px'}}>
                                <RegularInput withBackground={false} content={[currentSelectedOption[0]]}/>
                                <div style={{ backgroundColor:bgColor ,  height:"20px",  width:"20px" , borderRadius:"50%", padding:"7px", alignSelf:"center", marginTop:"6px" }}>


                       
                                      +{currentSelectedOption.length - 1}
                                </div>
                            </div>
                        )}
                        {currentSelectedOption && textSelector && (
                            <div className={styles.text}>
                                {currentSelectedOption[0].text}
                            </div>
                        )}
                    </div>
                </div>
                <img className={styles.dropdownIcon} alt="" src={`${process.env.PUBLIC_URL}/Dropdown Icon.svg`}  />
            </div>

            {optionsBadgeList.length > 0 && options && textSelector && (
                <div style={{top: (currentSelectedOption ? 77 : 61)}} className={`${styles.dropdownContent} ${isOpen ? styles.show : ''}`}>

                <Scrollbar
                    style={{ transition: 'min-height 0.3s ease', maxHeight: '200px', height: scrollHeight}} horizontalScroll={false}> 
                    {optionsBadgeList.map((option) => {
                        
                        console.log("optiondd: ", currentSelectedOption);
                        const isSelected = currentSelectedOption?.some(selected => selected.text === option.text);

                   
                        return(
                            <button
                                key={option.text}
                                className={`${styles.dropdownItem} ${isSelected ? styles.selected : ''}`}
                                onClick={() => selectOption(option)}
                            >
                                <div className={styles.text} title={option.text}>
                                    {option.text}
                                </div>
                            </button>
                        )
                    })}
                    </Scrollbar>
                </div>
            )}

            {optionsBadgeList.length > 0 && options && !textSelector && (
                <div style={{top: (currentSelectedOption ? 77 : 61)}} className={`${styles.dropdownContent} ${isOpen ? styles.show : ''}`}>
                    
                    <Scrollbar
                    style={{ transition: 'min-height 0.3s ease', maxHeight: '200px', height: scrollHeight}} horizontalScroll={false}> 
                    {optionsBadgeList.map((option) => {
                        
                        
                        const isSelected = currentSelectedOption?.some(selected => selected.text === option.text);


                        return(
                        <button
                        title={option.text}
                            key={option.text + getRandomNumber()}
                            className={`${styles.dropdownItem} ${isSelected ? styles.selected : ''}`}
                            onClick={() => selectOption(option)}
                        >
                            <Badge 
                                text={option.text}
                                color={option.color}
                                bgColor={option.bgColor}
                                hasBullet={option.hasBullet}
                            />
                        </button>
                     )
                    })}
                    </Scrollbar>
                </div>
            )}
        </div>
    );
};

export default Selector;