import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import view_style from "../../../layout/Layout.module.css";
import NameBadge from "../../../layout/labels/name_badge/NameBadge.js";
import StatusBadge from "../../../layout/labels/status_badge/StatusBadge.js";
import TableGrid from "../../../layout/table/Table.js";
import { HeaderClass } from "../../../layout/table/Header.js";
import { CellClass, RowCellClass } from "../../../layout/table/Cell.js";
import { SelectActionButton } from "../../../layout/table/TableTools.js";
import { exportToExcel } from "../../../layout/table/TableTools.js";
import ToggleButton from "../../../layout/buttons/ToggleButton.js";
import SliderButton from "../../../layout/buttons/SliderButton.js";
import {
  notifySuccess,
  notifyError,
} from "../../../layout/ToastNotifications.jsx";
import HeaderSection from "../../../layout/HeaderSection.js";

import {
  getAlerts,
  fetchFilteredAlerts,
  getAlertsFilterValues,
  setAlertsFilters,
  setAlertsSearch,
  setAlertsOrder,
  setAlertsOrigin,
  toggleShowMyAlerts,
  setSelectedAlertDetails,
  setPageSize,
  setCurrentPage,
  closeAlerts,
} from "../../../features/all_alerts/allAlertsSlice";

function LookUpIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M14 2V6C14 6.53043 14.2107 7.03914 14.5858 7.41421C14.9609 7.78929 15.4696 8 16 8H20"
        stroke="#9B8AFB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.268 21C4.44311 21.3033 4.69479 21.5553 4.99786 21.7308C5.30094 21.9063 5.64478 21.9991 5.995 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7L15 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V7"
        stroke="#9B8AFB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 18L7.5 16.5"
        stroke="#9B8AFB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 17C6.65685 17 8 15.6569 8 14C8 12.3431 6.65685 11 5 11C3.34315 11 2 12.3431 2 14C2 15.6569 3.34315 17 5 17Z"
        stroke="#9B8AFB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function CloseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M5 22H19"
        stroke="#9B8AFB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.27 13.73C19.0374 13.498 18.7614 13.3141 18.4577 13.1888C18.1539 13.0636 17.8285 12.9994 17.5 13H6.5C5.83696 13 5.20107 13.2634 4.73223 13.7322C4.26339 14.2011 4 14.837 4 15.5V17C4 17.2652 4.10536 17.5196 4.29289 17.7071C4.48043 17.8946 4.73478 18 5 18H19C19.2652 18 19.5196 17.8946 19.7071 17.7071C19.8946 17.5196 20 17.2652 20 17V15.5C20 14.84 19.74 14.2 19.27 13.73Z"
        stroke="#9B8AFB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 13V8.5C14 7 15 7 15 5C15 4.20435 14.6839 3.44129 14.1213 2.87868C13.5587 2.31607 12.7956 2 12 2C10.34 2 9 3 9 5C9 7 10 7 10 8.5V13"
        stroke="#9B8AFB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function MyAlertsToggle({ showMyAlerts, handleClick }) {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <ToggleButton checked={showMyAlerts} onChange={() => handleClick()} />
      <div>Show Only My Alerts</div>
    </div>
  );
}

function AlertsTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    alerts,
    alertsLoading,
    totalAlerts,
    columnsFiltersValues,
    alertsFilters,
    alertsSearch,
    alertsOrder,
    alertsOrigin,
    showMyAlerts,
    pageSize,
    currentPage,
  } = useSelector((state) => state.allAlerts);

  const retriggerVariables = [alertsOrigin, showMyAlerts];
  const [pagination, setPagination] = useState(1);

  const headers = [
    new HeaderClass("Alert ID", "alert_code", true, false, { minWidth: 210 }),
    new HeaderClass("Assigned To", "user", true, true, {
      minWidth: 200,
      maxWidth: 200,
      width: 200,
    }),
    new HeaderClass("Scenario", "scenario", true, true, {
      minWidth: 250,
      maxWidth: 250,
      width: 250,
    }),
    new HeaderClass("Customer Segment", "customer_segment", true, true, {
      minWidth: 210,
    }),
    new HeaderClass(
      "Creation Date",
      "event_date",
      true,
      true,
      {
        minWidth: 140,
      },
      "creation_date"
    ),
    new HeaderClass("Status", "status", true, true, {
      minWidth: 150,
    }),
  ];

  useEffect(() => {
    setPagination(pagination + 1);
  }, [alertsOrigin]);

  useEffect(() => {
    const fetchFilterOptions = async () => {
      headers
        .filter((header) => header.filterable)
        .map((header) =>
          dispatch(
            getAlertsFilterValues({
              propertyName: header.searchField,
              field: header.field,
            })
          )
        );
    };
    fetchFilterOptions();
  }, []);

  const rows = alerts.map((rowData) => {
    const allertId = rowData.id;
    const cells = headers.map((header) => {
      const value = rowData[header.field];

      if (header.field === "user") {
        return new CellClass(value, header.field, () => (
          <NameBadge name={value !== "" ? value : "Unassigned"} />
        ));
      }
      if (header.field === "status") {
        return new CellClass(value, header.field, () => (
          <div style={{ textAlign: "center" }}>
            <StatusBadge status={value} />
          </div>
        ));
      }

      return new CellClass(value, header.field);
    });

    return new RowCellClass(allertId, cells, rowData);
  });

  function fetchData(pageSize, pageNr, filters, sortOn, searchTerm) {
    dispatch(setPageSize(pageSize));
    dispatch(setCurrentPage(pageNr));
    dispatch(setAlertsFilters(filters));
    dispatch(setAlertsSearch(searchTerm));
    dispatch(setAlertsOrder(sortOn));
    dispatch(fetchFilteredAlerts({ pageNr, pageSize }));
  }

  const handleCloseAlertsConfirm = async (
    selectedIds,
    selectAllMode,
    onSuccess
  ) => {
    try {
      const result = await dispatch(
        closeAlerts({
          alertIds: selectedIds,
          selectedAll: selectAllMode,
        })
      ).unwrap();
      onSuccess();
      dispatch(
        fetchFilteredAlerts({ pageNr: currentPage, pageSize: pageSize })
      );
      if (result.length === 0) {
        notifyError("Could not close alerts");
      } else {
        notifySuccess(`Closed ${result.length} alerts`);
      }
    } catch (error) {
      notifyError("Could not close alerts");
    }
  };

  const actionButtons = [
    new SelectActionButton(
      "close",
      "alert",
      ({ selectedIds, selectAllMode, onSuccess }) => {
        handleCloseAlertsConfirm(selectedIds, selectAllMode, onSuccess);
      },
      <CloseIcon />,
      true
    ),
  ];

  async function onExport() {
    try {
      const allAlerts = await dispatch(
        getAlerts({ limit: "inf" })
      ).unwrap();

      const headerFields = headers.map((header) => header.field);
      const headerLabels = headers.map((header) => header.label);
      const columns = allAlerts.alerts.map((alert) => {
        return headerFields.map((field) => {
          return alert[field];
        });
      });

      exportToExcel(headerLabels, columns, "Alerts");
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  }

  function onRowClick(row) {
    const { id, ...alertDetails } = row;
    dispatch(setSelectedAlertDetails(alertDetails));
    navigate(`/alerts/${id}`);
  }

  let adjustedHeaders = headers;
  adjustedHeaders = useMemo(() => {
    return headers.map((header) => {
      if (header.label === "Assigned To") {
        return new HeaderClass(
          header.label,
          header.field,
          !showMyAlerts && header.sortable, // Disable sorting if showMyAlerts is true
          !showMyAlerts && header.filterable, // Disable filtering if showMyAlerts is true
          header.style
        );
      }
      return header;
    });
  }, [showMyAlerts]);

  const handleClickMyAlerts = async () => {
    dispatch(toggleShowMyAlerts());
  };

  return (
    <div className={view_style.viewWrapper}>
      <div className={view_style.sectionWrapper} style={{ display: "block" }}>
        <HeaderSection SectionName="Alerts" Count={totalAlerts} />
        <TableGrid
          title={"Alerts"}
          headers={adjustedHeaders}
          rows={rows}
          filterOptions={columnsFiltersValues}
          onRowClick={onRowClick}
          onSelectActionButtons={actionButtons}
          canExport={true}
          onExport={onExport}
          fetchData={{
            fetchFunction: fetchData,
            retriggers: retriggerVariables,
          }}
          rowsAmount={totalAlerts}
          initFilters={alertsFilters}
          initSort={alertsOrder}
          initSearchTerm={alertsSearch}
          noDataPlaceholder={"No results found"}
          resetPagination={pagination}
          actionButtonsLeft={[
            <SliderButton
              options={["Production", "Simulation"]}
              activeOption={alertsOrigin}
              onClick={(origin) => dispatch(setAlertsOrigin(origin))}
            />,
            <MyAlertsToggle
              showMyAlerts={showMyAlerts}
              handleClick={handleClickMyAlerts}
            />,
          ]}
          isLoading={alertsLoading}
        />
      </div>
    </div>
  );
}

export default AlertsTable;
