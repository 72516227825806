import React, { useEffect, useState } from 'react';
import styles from '../Profiles.module.css';
import RegularInput from '../../inputs/RegularInput';
import Selector from '../../selector/Selector';
import {
    setSelectedProfileDetails,
    setSelectedProfileId,
    setProfileData,
    setShowEdit,
    linkProfile,
    fetchProfiles,
    fetchConfigurationProfiles, 
    createProfile,
    updateProfile, 
} from '../../../../features/configuration/configurationSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchProfileDetails,
} from '../../../../features/configuration/configurationSlice';
import BadgeEditor from '../../inputs/BadgeEditor';
import { config } from '@react-spring/web';

const Edit = ({configId}) => {

    const dispatch = useDispatch();

    // ID of the selected profile
    const selectedProfileId = useSelector((state) => state.configuration.selectedProfileId);

    // Fetch profile details if the ID is valid
    useEffect(() => {
        if (selectedProfileId > -1) {
            dispatch(fetchProfileDetails({ profileId: selectedProfileId }));
        }
    }, [selectedProfileId, dispatch]);

    // Details of the selected profile
    const selectedProfileDetails = useSelector((state) => state.configuration.selectedProfileDetails);

    const timeBaseOptions = useSelector((state) => state.configuration.timeBaseOptions).map((option, index) => ({ id: index, name: option }));

    // Possible data labels
    const dataLabelsOptions = useSelector((state) => state.configuration.dataLabels);

    const dataSources = useSelector((state) => state.configuration.dataSources);

    const all_profiles = useSelector((state) => state.configuration.profiles);

    const profileToLink = useSelector((state) => state.configuration.profileToLink);

    // States to keep track of the selected options
    const [currentProfileType, setCurrentProfileType] = useState(null);
    const [currentDataLabels, setCurrentDataLabels] = useState([]);
    const [currentTimeBase, setCurrentTimeBase] = useState(null);
    const [currentTimeRangeAmount, setCurrentTimeRangeAmount] = useState(null);

    const [currentDataFields, setCurrentDataFields] = useState([]);

    const [currentDataSource, setCurrentDataSource] = useState(null);

    const [dataFieldProps, setDataFieldProps] = useState([]);

    // State to manage the name input
    const [currentName, setCurrentName] = useState('');

    // Handle name input change
    const handleNameChange = (newValue) => {
        setCurrentName(newValue);
    };

    // Initialize currentName when selectedProfileDetails changes
    useEffect(() => {
        if (selectedProfileDetails) {
            setCurrentName(selectedProfileDetails.name || '');
        } else {
            setCurrentName('');
        }
    }, [selectedProfileDetails]);


    const resetView = () => {
        if(configId)
            {
                dispatch(fetchConfigurationProfiles(configId));
            }
            else
            {
                dispatch(fetchProfiles());
            }
            dispatch(setShowEdit(false));
        };

    useEffect(() => {
        if (profileToLink) {
            
            dispatch(linkProfile({ profile: profileToLink , config: configId}));
          resetView();

           
        }

    }, [profileToLink]);

    // Properties for the name badge
    const nameBadgeProps = [
        {
            type: "text",
            text: currentName,
            color: "#5925DC",
            bgColor: "rgba(181, 120, 255, 0.20)",
            hasBullet: false,
            onChange: handleNameChange,
        }
    ];

    // Profile type options
    const profileTypeOptions = [
        {
            id: 1,
            name: "Counter",
        },
        {
            id: 2,
            name: "Accumulator",
        },
    ];

    // Properties for the profile type badge
    const profileTypeProps = [currentProfileType ?
        {
            type: "badge",
            text: `${currentProfileType}`,
            color: "#B54708",
            bgColor: "#FFFAEB",
            hasBullet: false,
        } : {
            type: "text",
            text: ""
        }
    ];

    // Properties of the data labels
    const [dataLabelConditionProps, setDataLabelConditionProps] = useState([]);

    // Parse the data labels to useful data
    const generateDataLabelConditionProps = (dataLabels) => {
        if (!dataLabels || dataLabels.length === 0) {
            return [{
                type: 'text',
                text: '',
            }];
        } else {
            var result = [];
            dataLabels.forEach((label, index) => {
                result.push({
                    type: 'badge',
                    text: label.name,
                    color: "#595ae6",
                    bgColor: "#eee0ff",
                    hasBullet: false,
                });

                if (index < dataLabels.length - 1) {
                    result.push({
                        type: 'text',
                        text: "and",
                    });
                }
            });

            return result;
        }
    };

    useEffect(() => {
        var newDataLabels = generateDataLabelConditionProps(currentDataLabels);
        setDataLabelConditionProps(newDataLabels);
        dispatch(setProfileData(newDataLabels));
    }, [currentDataLabels, dispatch]);

    // Properties for the time base badge
    const timeBaseProps = [currentTimeBase ?
        {
            type: "badge",
            text: `${currentTimeBase}`,
            color: "#B42318",
            bgColor: "#FEF3F2",
            hasBullet: false,
        } : {
            type: "text",
            text: ""
        }
    ];

    // Properties for the time range amount badge
    const timeRangeAmountProps = [
        {
            type: 'badge',
            text: `${currentTimeRangeAmount}`,
            color: "#B42318",
            bgColor: "#FEF3F2",
            hasBullet: false,
        }
    ];

    const dataSourceOptions = dataSources;

    const findCurrentDataFields = (currentDataFields) => {
        if (!selectedProfileDetails) return [];

        var selectedProfileDataFieldId = selectedProfileDetails.data_field_id;

        var selectedProfileDataField = currentDataFields ? currentDataFields.find((dataField) => dataField.id === selectedProfileDataFieldId) : [];

        var result = [];
        if (selectedProfileDataField) {
            result.push({
                type: "badge",
                text: `${selectedProfileDataField.name}`,
                color: "#027A48",
                bgColor: "#ECFDF3",
                hasBullet: false,
            });
        }

        return result;
    };

    // Set the states to the details of the selected profile
    useEffect(() => {
        if (selectedProfileDetails) {
            setCurrentProfileType(selectedProfileDetails.type);
            setCurrentTimeBase(selectedProfileDetails.frequency);
            setCurrentDataLabels(selectedProfileDetails.datalabels);
            setCurrentTimeRangeAmount(selectedProfileDetails.frequency_amount);

            if (selectedProfileDetails.datasource) {
                setCurrentDataFields(selectedProfileDetails.datasource.datafields);
                setDataFieldProps(findCurrentDataFields(selectedProfileDetails.datasource.datafields));
            } else {
                setCurrentDataFields([]);
                setDataFieldProps([]);
            }

            setCurrentDataSource(selectedProfileDetails.datasource);

        } else {
            setCurrentProfileType(null);
            setCurrentDataLabels([]);
            setCurrentTimeBase(null);
            setCurrentTimeRangeAmount(null);
            setCurrentDataSource(null);

            setDataFieldProps([]);
            setCurrentDataFields([]);
        }
    }, [selectedProfileDetails]);

    // Handle the selection of an option
    const handleOptionSelect = ((selector, option) => {
        let updatedProfileDetails = selectedProfileDetails ? { ...selectedProfileDetails } : {
            data_field_id: null,
            datalabels: [],
            datasource: null,
            frequency: null,
            frequency_amount: 0,
            id: null,
            label_condition: null,
            lists: [],
            name: currentName || "No name", // Use currentName
            type: null,
        };

        if (selector === "Data Source") {
            updatedProfileDetails.datasource = option;
            setCurrentDataSource(option);
            if (option && option.datafields) {
                setCurrentDataFields(option.datafields);
            } else {
                setCurrentDataFields([]);
            }
        } else if (selector === "Data Fields") {
            updatedProfileDetails.data_field_id = option.id;
        } else if (selector === "Profile Type") {
            updatedProfileDetails.type = option.name;
            setCurrentProfileType(option.name);
        } else if (selector === "Time Base") {
            updatedProfileDetails.frequency = option.name;
            setCurrentTimeBase(option.name);
        } else if (selector === "Data Label") {
            var newDataLabels = [...currentDataLabels];
            if (newDataLabels.find((item => item.id === option.id))) {
                newDataLabels = newDataLabels.filter((item) => item.id !== option.id);
            } else {
                newDataLabels.push(option);
            }
            updatedProfileDetails.datalabels = newDataLabels;
            setCurrentDataLabels(newDataLabels);
        } else if (selector === "Time Base Amount") {
            setCurrentTimeRangeAmount(option);
        }

        dispatch(setSelectedProfileDetails(updatedProfileDetails));
    });

    // Save the created/edited data
    const handleSaveClick = (() => {
        dispatch(setShowEdit(false));
        dispatch(setSelectedProfileId(-1));

        const data = {
            profile: {
                type: currentProfileType,
                data_source_id: currentDataSource?.id,
                data_field_id: currentDataFields[0]?.id,
                frequency: currentTimeBase,
                frequency_amount: currentTimeRangeAmount,
                label_condition: dataLabelConditionProps[0]?.text,
                name: currentName || "No name",
            },
            datalabel_ids: currentDataLabels.map((item) => item.id),
        };

       
        if (!selectedProfileDetails.id) {

            console.log("create profile : ", data, selectedProfileDetails.id);
        dispatch(createProfile({ profile: data }));
        resetView();
        }
        else
        {
            const data = {
                type: currentProfileType,
                data_source_id: currentDataSource?.id,
                data_field_id: currentDataFields[0]?.id,
                frequency: currentTimeBase,
                frequency_amount: currentTimeRangeAmount,
                label_condition: dataLabelConditionProps[0]?.text,
                name: currentName || "No name",
         
            datalabel_ids: currentDataLabels.map((item) => item.id),
        };

        console.log("update create profile : ", data, selectedProfileDetails.id);
            dispatch(updateProfile({ profile: data, id: selectedProfileDetails.id }));
            resetView();
        }


    });

    const handleCancel = (() => {
        dispatch(setShowEdit(false));
        dispatch(setSelectedProfileId(-1));
    });

    // Properties for the data source badge
    const dataSourceProps = [
        currentDataSource
            ? {
                type: "badge",
                text: `${currentDataSource.name}`,
                color: "#027A48",
                bgColor: "#ECFDF3",
                hasBullet: currentDataSource,
            }
            : {
                type: "text",
                text: "",
            }
    ];

    const isDisabled = false; // Adjust based on validation logic

    return (
        <div className={styles.container}>

            <div className={styles.contentWrapper}>

                {(profileTypeProps[0].text.toLowerCase() === "" || profileTypeProps[0].text.toLowerCase() === "counter") && (

                    <div className={styles.leftContainer}>
                        <div className={styles.text}>
                            Create new profile
                        </div>

                        <Selector
                            color={"#B54708"}
                            bgColor={"#FFFAEB"}
                            options={profileTypeOptions}
                            selectedOption={profileTypeProps ? profileTypeProps : ''}
                            onOptionSelect={(option) => handleOptionSelect("Profile Type", option)}
                            label={"Profile Type"} />

                        <Selector
                            color={"#027A48"}
                            bgColor={"#ECFDF3"}
                            options={dataSourceOptions}
                            selectedOption={dataSourceProps ? dataSourceProps : ''}
                            hasBullet={true}
                            onOptionSelect={(option) => handleOptionSelect("Data Source", option)}
                            label={"Data Source"}
                        />

                        <Selector
                            color={"#027A48"}
                            bgColor={"#ECFDF3"}
                            options={currentDataFields}
                            selectedOption={dataFieldProps ? dataFieldProps : ''}
                            hasBullet={false}
                            onOptionSelect={(option) => handleOptionSelect("Data Fields", option)}
                            label={"Data Fields"}
                        />

                        <Selector
                            color={"#595ae6"}
                            bgColor={"#eee0ff"}
                            options={dataLabelsOptions}
                            selectedOption={dataLabelConditionProps}
                            onOptionSelect={(option) => handleOptionSelect("Data Label", option)}
                            label={"Data Labels"} />

                        <Selector
                            options={timeBaseOptions}
                            selectedOption={timeBaseProps ? timeBaseProps : ''}
                            onOptionSelect={(option) => handleOptionSelect("Time Base", option)}
                            label={"Time base"}
                            textSelector={true} />

                    </div>
                )}

                {profileTypeProps.length > 0 && profileTypeProps[0].text.toLowerCase() === "accumulator" && (

                    <div className={styles.leftContainer}>
                        <div className={styles.text}>
                            Create new profile
                        </div>

                        <Selector

                            color={"#B54708"}
                            bgColor={"#FFFAEB"}
                            options={profileTypeOptions}
                            selectedOption={profileTypeProps ? profileTypeProps : ''}
                            onOptionSelect={(option) => handleOptionSelect("Profile Type", option)}
                            label={"Profile Type"} />

                        <Selector
                            color={"#027A48"}
                            bgColor={"#ECFDF3"}
                            options={dataSourceOptions}
                            selectedOption={dataSourceProps ? dataSourceProps : ''}
                            hasBullet={true}
                            onOptionSelect={(option) => handleOptionSelect("Data Source", option)}
                            label={"Data Source"}
                        />

                        <Selector
                            color={"#027A48"}
                            bgColor={"#ECFDF3"}
                            options={currentDataFields}
                            selectedOption={dataFieldProps ? dataFieldProps : ''}
                            hasBullet={false}
                            onOptionSelect={(option) => handleOptionSelect("Data Fields", option)}
                            label={"Data Fields"}
                        />

                        <Selector
                            color={"#595ae6"}
                            bgColor={"#eee0ff"}

                            options={dataLabelsOptions}
                            selectedOption={dataLabelConditionProps}
                            onOptionSelect={(option) => handleOptionSelect("Data Label", option)}
                            label={"Data Labels"} />

                        <Selector
                            options={timeBaseOptions}
                            selectedOption={timeBaseProps ? timeBaseProps : ''}
                            onOptionSelect={(option) => handleOptionSelect("Time Base", option)}
                            label={"Time base"}
                            textSelector={true} />

                        <div className={styles.frequencyAmountWrapper}>
                            <div className={styles.title}>
                                Time range amount
                            </div>
                            <div className={styles.frequencyAmount} contentEditable={true} onChange={(content) => handleOptionSelect("Time Base Amount", content)}>
                                {timeRangeAmountProps[0].text}
                            </div>
                        </div>
                    </div>
                )}

                <div className={styles.rightContainer}>

                    <RegularInput title={"Name"} withBackground={true} isBadgeEditor={false} isEditable={true} content={nameBadgeProps} />

                    <RegularInput title={"Data Labels"} isEditable={true} withBackground={true} isBadgeEditor={true} mode={"profile"} content={dataLabelConditionProps} />

                </div>

            </div>

            <div className={styles.footer}>

                <div className={`${styles.buttonBox} ${styles.whiteButton}`} onClick={() => handleCancel()}>
                    <div className={styles.textCancel}>
                        Cancel
                    </div>
                </div>

                <div className={`${styles.buttonBox} ${styles.purpleButton} ${isDisabled ? styles.disabledButton : ''}`}
                    onClick={isDisabled ? null : handleSaveClick}>

                    <div className={styles.textEdit}>
                        {selectedProfileDetails ? "Edit" : "Create"}
                    </div>
                </div>

            </div>

        </div>
    );
};

export default Edit;
