import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { HeaderClass } from "../../../../layout/table/Header";
import TableGrid from "../../../../layout/table/Table";
import { CellClass, RowCellClass } from "../../../../layout/table/Cell";
import NameBadge from "../../../../layout/labels/name_badge/NameBadge";
import StatusBadge from "../../../../layout/labels/status_badge/StatusBadge";

import { setSelectedAlertDetails } from "../../../../features/all_alerts/allAlertsSlice";

function AlertsOverlap({setShownContainer}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { overlapAlerts, selectedAlertDetails } = useSelector(
    (state) => state.allAlerts
  );

  const headers = [
    new HeaderClass("Alert ID", "alert_code", true, false, { minWidth: 210 }),
    new HeaderClass("Assigned To", "user", true, true, {
      minWidth: 200,
      maxWidth: 200,
      width: 200,
    }),
    new HeaderClass("Scenario", "scenario", true, true, {
      minWidth: 200,
      maxWidth: 200,
      width: 200,
    }),
    new HeaderClass("Customer Segment", "customer_segment", true, true, {
      minWidth: 210,
    }),
    new HeaderClass("Creation Date", "event_date", true, true, {
      minWidth: 140,
    }),
    new HeaderClass("Status", "status", true, true, {
      minWidth: 150,
    }),
  ];

  function onRowClick(row) {
    const { id, ...alertDetails } = row;
    dispatch(setSelectedAlertDetails(alertDetails));
    navigate(`/alerts/${id}`);
    setShownContainer(1)
  }

  const rows = overlapAlerts.map((rowData) => {
    const alertId = rowData.id;
    const cells = headers.map((header) => {
      const value = rowData[header.field];
      if (header.field === "user") {
        return new CellClass(value, header.field, () => (
          <NameBadge name={value !== "" ? value : "Unassigned"} />
        ));
      }
      if (header.field === "status") {
        return new CellClass(value, header.field, () => (
          <div style={{ textAlign: "center" }}>
            <StatusBadge status={value} />
          </div>
        ));
      }

      return new CellClass(value, header.field);
    });

    return new RowCellClass(alertId, cells, rowData);
  });

  return (
    <TableGrid
      title={`Overlap Alerts of ${selectedAlertDetails.alert_code}`}
      headers={headers}
      rows={rows}
      onRowClick={onRowClick}
      noDataPlaceholder={"There are no overlapping alerts"}
    />
  );
}

export default AlertsOverlap;
