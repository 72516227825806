import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  VictoryChart,
  VictoryBoxPlot,
  VictoryAxis,
  VictoryTheme,
  VictoryLine,
  VictoryLabel,
  VictoryZoomContainer,
  VictoryScatter,
} from "victory";
import styles from "./BoxPlot.module.css";

const ALERT_TYPES = {
  non_relevant_alerts: {
    name: "Non relevant Alerts",
    lightColor: "var(--light-green)",
    darkColor: "var(--dark-green)",
    fill: "url(#colorGroup1)",
  },
  relevant_alerts: {
    name: "Relevant Alerts",
    lightColor: "var(--light-yellow)",
    darkColor: "var(--dark-yellow)",
    fill: "url(#colorGroup2)",
  },
  sar_alerts: {
    name: "SAR Alerts",
    lightColor: "var(--light-red)",
    darkColor: "var(--dark-red)",
    fill: "url(#colorGroup3)",
  },
};

function Gradients() {
  return (
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient id="colorGroup1" x1="0%" y1="100%" x2="0%" y2="0%">
          <stop
            offset="0%"
            style={{ stopColor: "var(--light-green)", stopOpacity: 1 }}
          />
          <stop
            offset="100%"
            style={{ stopColor: "var(--dark-green)", stopOpacity: 1 }}
          />
        </linearGradient>
        <linearGradient id="colorGroup2" x1="0%" y1="100%" x2="0%" y2="0%">
          <stop
            offset="7.4%"
            style={{ stopColor: "var(--light-yellow)", stopOpacity: 1 }}
          />
          <stop
            offset="89.37%"
            style={{ stopColor: "var(--dark-yellow)", stopOpacity: 1 }}
          />
        </linearGradient>
        <linearGradient id="colorGroup3" x1="0%" y1="100%" x2="0%" y2="0%">
          <stop
            offset="6.2%"
            style={{ stopColor: "var(--light-red)", stopOpacity: 1 }}
          />
          <stop
            offset="88.6%"
            style={{ stopColor: "var(--dark-red)", stopOpacity: 1 }}
          />
        </linearGradient>
      </defs>
    </svg>
  );
}

function BoxPlotLegend({ boxPlotData, riskScore }) {
  const legendData = Object.keys(ALERT_TYPES)
    .map((alertType, index) => {
      const alertData = boxPlotData[alertType];
      if (alertData) {
        let legendData = ALERT_TYPES[alertType];
        legendData["amount"] = riskScore[alertType];
        return legendData;
      }
      return null;
    })
    .filter(Boolean);
  return (
    <Fragment>
      {legendData.map((legend, index) => (
        <div
          key={index}
          style={{
            display: "flex",
          }}>
          <div
            className={styles.legendCircle}
            style={{
              background: `linear-gradient(90deg, ${legend.lightColor} 0%, ${legend.darkColor} 100%)`,
            }}></div>
          <div
            className={styles.ScatterAlertsLabel}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}>
            <span>{legend.name}</span>
            <span>
              <strong>{legend?.amount}</strong> Alerts{" "}
              {legend?.ratio && `${legend?.ratio}%`}
            </span>
          </div>
        </div>
      ))}
    </Fragment>
  );
}

function Chart({ boxPlotData, showOutliers, yAxisLegend }) {
  const thresholdName = boxPlotData?.threshold_name;

  // Hooks for dimensions and zoom
  const chartRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [zoomDomain, setZoomDomain] = useState(null);

  // Update dimensions on resize
  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        setDimensions({
          width: chartRef.current.offsetWidth,
          height: chartRef.current.offsetHeight - 150,
        });
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Reset zoom when showOutliers changes
  useEffect(() => {
    setZoomDomain(null);
  }, [showOutliers, boxPlotData]);

  const data = Object.keys(ALERT_TYPES)
    .map((alertType, index) => {
      const alertData = boxPlotData[alertType];
      const alert_style_info = ALERT_TYPES[alertType];
      if (alertData) {
        return {
          x: alertType
            .replace(/_/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase()),
          y: index + 1,
          ...alertData,
          style: {
            q1: {
              fill: alert_style_info.fill,
            },
            q3: {
              fill: alert_style_info.fill,
            },
            min: { stroke: alert_style_info.darkColor },
            max: { stroke: alert_style_info.darkColor },
          },
        };
      }
      return null;
    })
    .filter(Boolean);

  // Data preparation
  const iqr_list = Object.keys(ALERT_TYPES)
    .map((alertType) => {
      const q1 = boxPlotData[alertType]?.q1;
      const q3 = boxPlotData[alertType]?.q3;
      return q1 !== undefined && q3 !== undefined ? Math.abs(q3 - q1) : null;
    })
    .filter((value) => value > 0);

  const outliersData = Object.keys(ALERT_TYPES).flatMap((alertTypeKey) => {
    const alertTypeData = boxPlotData[alertTypeKey];
    if (alertTypeData?.outliers) {
      return alertTypeData.outliers.map((y) => ({
        x: ALERT_TYPES[alertTypeKey].name,
        y,
        style: { fill: ALERT_TYPES[alertTypeKey].fill },
      }));
    }
    return [];
  });

  const smallest_zoom = iqr_list.length > 0 ? Math.min(...iqr_list) : null;

  const threshold = boxPlotData.threshold_value?.value;

  return (
    <div style={{ width: "100%", height: "100%" }} ref={chartRef}>
      <Gradients />
      <VictoryChart
        horizontal
        theme={VictoryTheme.material}
        domainPadding={{ x: 80, y: 20 }}
        padding={{ top: -30, bottom: 43, left: 80, right: 69 }}
        width={dimensions.width}
        height={350}
        containerComponent={
          <VictoryZoomContainer
            zoomDomain={zoomDomain}
            onZoomDomainChange={(domain) => setZoomDomain(domain)}
            minimumZoom={{ x: 1, y: smallest_zoom }}
            zoomDimension="y"
          />
        }>
        <VictoryAxis
          dependentAxis
          style={{
            axis: { stroke: "#000", strokeWidth: 0.2 },
            tickLabels: {
              fill: "var(--Primary-Typo-Light)",
              fontSize: 9.109,
              fontFamily: "Poppins",
            },
            grid: {
              stroke: "var(--Blue-gray-200)",
              strokeDasharray: "none",
            },
          }}
        />
        <VictoryAxis
          style={{
            axis: { stroke: "#000", strokeWidth: 0.2 },
            ticks: { stroke: "transparent" },
            tickLabels: {
              fontSize: 10,
              padding: 5,
              fill: "transparent",
            },
            grid: { stroke: "none" },
          }}
        />
        <VictoryBoxPlot
          horizontal
          boxWidth={60}
          whiskerWidth={15}
          animate={{
            duration: 100,
            onLoad: { duration: 100 },
            onEnter: { duration: 100 },
          }}
          data={data}
          style={{
            min: {
              stroke: ({ datum }) => datum.style?.min?.stroke,
              strokeWidth: 2,
            },
            max: {
              stroke: ({ datum }) => datum.style?.max?.stroke,
              strokeWidth: 2,
            },
            q1: {
              fill: ({ datum }) => datum.style?.q1?.fill,
              stroke: "none",
              strokeWidth: 1,
            },
            q3: {
              fill: ({ datum }) => datum.style?.q3?.fill,
              stroke: "none",
              strokeWidth: 1,
            },
            median: { stroke: "white", strokeWidth: 2 },
          }}
        />
        {showOutliers && (
          <VictoryScatter
            data={outliersData}
            animate={{ duration: 100, onLoad: { duration: 100 } }}
            style={{
              data: {
                fill: ({ datum }) => datum.style.fill,
                strokeWidth: 2,
              },
            }}
          />
        )}
        {threshold && (
          <VictoryLine
            style={{
              data: {
                stroke: "var(--Primary-Typo-Header)",
                strokeWidth: "2.33px",
                strokeDasharray: "5,5",
              },
            }}
            data={[
              { x: 0, y: threshold },
              { x: dimensions.width, y: threshold },
            ]}
            labels={threshold}
            labelComponent={
              <VictoryLabel
                renderInPortal
                textAnchor="middle"
                dx={-5}
                text={threshold}
                y={0}
                dy={-45}
                style={{
                  fontFamily: "Rubik",
                  fontSize: 10,
                  fontWeight: 500,
                  fill: "var(--Primary-Typo-Unactive)",
                }}
              />
            }
          />
        )}
        <VictoryLabel
          x={dimensions.width / 2}
          y={342}
          textAnchor="middle"
          text={thresholdName}
          style={{
            fontFamily: "Inter",
            fontSize: 13,
            fontWeight: 500,
            fill: "var(--Primary-Typo-Unactive)",
          }}
          className={styles.ScatterAxisLegend}
        />
        <VictoryLabel
          x={20}
          y={dimensions.height / 2}
          textAnchor="middle"
          text={yAxisLegend}
          style={{
            fontFamily: "Inter",
            fontSize: 13,
            fontWeight: 500,
            fill: "var(--Primary-Typo-Unactive)",
          }}
          className={styles.ScatterAxisLegend}
          angle={-90}
        />
      </VictoryChart>
    </div>
  );
}

function BoxPlotChart({
  riskScore,
  boxPlotData,
  showOutliers = false,
  yAxisLegend,
  isLoading = false,
}) {
  const [plotData, setPlotData] = useState(boxPlotData); // To display the data
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    let timer;
    if (isLoading) {
      timer = setTimeout(() => {
        setShowLoading(true);
        setPlotData(boxPlotData);
      }, 150); // 0.1 seconds delay
    } else {
      clearTimeout(timer);
      setShowLoading(false);
      setPlotData(boxPlotData); // Update the plot data immediately
    }
    return () => clearTimeout(timer);
  }, [isLoading, boxPlotData]);

  if (!plotData && !showLoading) {
    return (
      <div style={{ color: "var(--Primary-Typo-Light)" }}>
        No data available for the selected alert types.
      </div>
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "75px",
          gap: "20px",
          alignItems: "center",
        }}>
        {!showLoading && (
          <BoxPlotLegend boxPlotData={plotData} riskScore={riskScore} />
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "399px",
        }}>
        {showLoading ? (
          <div>Loading...</div>
        ) : (
          <Chart
            boxPlotData={plotData}
            showOutliers={showOutliers}
            yAxisLegend={yAxisLegend}
          />
        )}
      </div>
    </div>
  );
}

export default BoxPlotChart;
