import styles from "./Selector.module.css";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import { InputSelector, OptionClass } from "../../../layout/selector/Selector";
import { fetchScenarios } from "../../../features/atl_tool/atlToolSlice";
import {
  fetchThresholds,
  fetchSegments,
  fetchRiskScores,
} from "../../../features/btl_tool/btlToolSlice";
import {
  setSelectedScenario,
  setSelectedSubScenario,
  setSelectedPeriod,
  setSelectedSegment,
  setSelectedRiskScore,
  resetSegments,
} from "../../../features/btl_tool/btlToolSlice";
import MonthSelector from "../../../layout/selector/MonthSelector";
import useDebouncedAction from "../../../features/DebounceHook";
import RiskBadge from "../../../layout/labels/risk_badge/RiskBadge";

function RiskOption({ name, sarAlerts = 0, selected, onClick }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <div
      style={{
        flexDirection: "column",
        display: "flex",
        alignItems: "start",
        gap: "5px",
      }}>
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <RiskBadge
          value={name}
          selected={selected && sarAlerts > 0}
          disabled={sarAlerts === 0}
          style={{ opacity: isHovered || selected ? "1" : "0.5" }}
          onClick={onClick}
        />
      </div>
      <div
        style={{
          marginLeft: "5px",
          display: "flex",
          gap: "5px",
          alignItems: "center",
        }}
        className={`${styles.sarText} ${selected && styles.selected}`}>
        <div className={styles.sarCircle}></div>
        <div style={{ display: "flex", gap: "5px" }}>
          <div className={styles.int}>{sarAlerts}</div>
          <div>Alert{sarAlerts > 1 ? "s" : ""}</div>
        </div>
      </div>
    </div>
  );
}

function Selector() {
  const dispatch = useDispatch();
  const { scenarios } = useSelector((state) => state.atlTool);
  const {
    selectedScenario,
    selectedSubScenario,
    selectedPeriod,
    selectedSegment,
    loadingSegments,
    selectedRiskScore,
    segments,
    riskScores,
  } = useSelector((state) => state.btlTool);

  const noSelectedPeriod =
    !selectedPeriod || Object.keys(selectedPeriod).length === 0;
  const showSegments = selectedScenario && !noSelectedPeriod;
  const showRiskScores = showSegments && selectedSegment;

  useEffect(() => {
    dispatch(fetchScenarios());
  }, []);

  useEffect(() => {
    if (
      !selectedScenario?.subscenarios.find(
        (subscenario) => subscenario.id === selectedSubScenario?.id
      )
    ) {
      dispatch(setSelectedSubScenario(selectedScenario?.subscenarios[0]));
    }
  }, [selectedScenario]);

  useDebouncedAction({
    action: () =>
      dispatch(fetchThresholds({ selectedScenario, selectedSubScenario })),
    dependencies: [selectedSubScenario, selectedScenario],
  });

  useDebouncedAction({
    action: () =>
      dispatch(
        fetchSegments({
          scenarioId: selectedScenario.id,
          subScenarioId: selectedSubScenario?.id || 0,
          period: selectedPeriod,
        })
      ),
    dependencies: [
      showSegments,
      selectedScenario,
      selectedSubScenario,
      selectedPeriod,
    ],
    condition: showSegments,
    elseAction: () => dispatch(resetSegments()),
  });

  useDebouncedAction({
    action: () =>
      dispatch(
        fetchRiskScores({
          scenarioId: selectedScenario?.id,
          segmentId: selectedSegment.id,
          subScenarioId: selectedSubScenario?.id || 0,
          period: selectedPeriod,
        })
      ),
    dependencies: [
      selectedSegment,
      selectedScenario,
      selectedSubScenario,
      selectedPeriod,
      loadingSegments,
    ],
    condition: selectedSegment && showSegments && !loadingSegments,
  });

  const scenarioOptions = scenarios.map(
    (scenario) => new OptionClass(scenario.id, scenario.name, scenario)
  );

  let subscenarioOptions = [];
  if (selectedScenario?.subscenarios?.length > 0) {
    subscenarioOptions = selectedScenario.subscenarios;
  }
  subscenarioOptions = subscenarioOptions.map(
    (scenario) => new OptionClass(scenario.id, scenario.name, scenario)
  );

  let segmentOptions = [];
  if (segments.length > 0) {
    segmentOptions = segments.map(
      (segment) => new OptionClass(segment.id, segment.name, segment)
    );
  }

  let riskScoreOptions = [];
  if (riskScores.length > 0) {
    riskScoreOptions = riskScores.map(
      (risk, i) =>
        new OptionClass(
          risk.id,
          `${risk.name}${risk.sar_alerts >= 1 ? ` (${risk.sar_alerts})` : ""}`,
          risk,
          (selected) => (
            <RiskOption
              name={risk.name}
              selected={selected}
              sarAlerts={risk.sar_alerts}
            />
          )
        )
    );
  }

  function handleScenarioSelect(scenario) {
    dispatch(setSelectedScenario(scenario));
  }
  function handleSubScenarioSelect(scenario) {
    dispatch(setSelectedSubScenario(scenario));
  }
  function handlePeriodSelect(period) {
    dispatch(setSelectedPeriod(period));
  }
  function handleSegmentSelect(segment) {
    dispatch(setSelectedSegment(segment));
  }
  function handleRiskScoreSelect(riskScore) {
    dispatch(setSelectedRiskScore(riskScore));
  }

  return (
    <div className={styles.selectorsContainer}>
      <div className={styles.selectorsContainerRow}>
        <InputSelector
          options={scenarioOptions}
          label={"Choose a scenario"}
          selectedId={selectedScenario?.id}
          search={true}
          setState={handleScenarioSelect}
        />
        <InputSelector
          options={subscenarioOptions}
          label={"Choose a subscenario"}
          selectedId={selectedSubScenario?.id}
          setState={handleSubScenarioSelect}
          show={subscenarioOptions.length > 0 && selectedScenario}
        />
        <MonthSelector
          setState={handlePeriodSelect}
          label={"Choose a period"}
          state={selectedPeriod}
        />
        <InputSelector
          options={segmentOptions}
          label={"Choose a KYC segment"}
          selectedId={selectedSegment?.id}
          setState={handleSegmentSelect}
        />
      </div>
      {showRiskScores && (
        <div className={styles.selectorsContainerRow}>
          {riskScores.map((risk) => (
            <RiskOption
              name={risk.name}
              sarAlerts={risk?.sar_alerts || 0}
              selected={selectedRiskScore?.name === risk.name}
              onClick={() => handleRiskScoreSelect(risk)}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default Selector;
