import React, { useState, useEffect, useRef } from "react";
import styles from "./ReportBuilder.module.css";
import buttonStyles from "../../../layout/buttons/Buttons.module.css";

const ReportElementWrapper = ({
  element,
  index,
  isEditable,
  handleInsertElement,
  handleDeleteElement,
  handleMoveElementUp,
  handleMoveElementDown,
  renderElement,
  totalElements,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [showAddOptions, setShowAddOptions] = useState(false);
  const reportActionsRef = useRef(null);

  useEffect(() => {
    if (isHovered && reportActionsRef.current) {
      // Forzar un reflow
      void reportActionsRef.current.offsetHeight;
    }
  }, [isHovered]);

  const handleAddElement = () => {
    setShowAddOptions(true);
  };

  const handleSelectElementType = (type) => {
    const newElement = createNewElement(type);
    handleInsertElement(index, newElement);
    setShowAddOptions(false);
  };

  const createNewElement = (type) => {
    switch (type) {
      case "title":
        return { type: "title", content: "New Title" };
      case "text":
        return { type: "text", content: "New Text" };
      case "table":
        return {
          type: "table",
          title: "Nueva Tabla",
          content: { headers: [], rows: [] },
        };
      case "image":
        return { type: "image", content: { url: "" } };
      default:
        return null;
    }
  };

  const isMoveUpDisabled = index === 0;
  const isMoveDownDisabled = index === totalElements - 1;

  return (
    <div
      style={{ position: "relative", width: "100%", textAlign: "center" }}
      ref={reportActionsRef}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false);
        setShowAddOptions(false);
      }}
    >
      {renderElement(element, index)}

      {isHovered && isEditable && !showAddOptions && (
        <div
          className={`${styles.reportActions} ${isHovered ? styles.show : ""}`}
        >
          <button
            className={`${styles.reportButton} `}
            onClick={handleAddElement}
            title="Add"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              class="lucide lucide-plus"
            >
              <path stroke="#E9D7FE" d="M5 12h14" />
              <path stroke="#E9D7FE" d="M12 5v14" />
            </svg>
          </button>

          <button
            className={`${styles.reportButton}   ${
              isMoveUpDisabled ? buttonStyles.disabled : ""
            }`}
            onClick={() => handleMoveElementUp(index)}
            disabled={isMoveUpDisabled}
            title="Move Up"
          >
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.4989 15.4949L15.4989 6.49487L24.4989 15.4949"
                stroke="#E9D7FE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.4989 24.4949V6.49487"
                stroke="#E9D7FE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>

          <button
            className={`${styles.reportButton} ${
              isMoveDownDisabled ? buttonStyles.disabled : ""
            }`}
            onClick={() => handleMoveElementDown(index)}
            disabled={isMoveDownDisabled}
            title="Move Down"
          >
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.4989 6.49487V24.4949M15.4989 24.4949L24.4989 15.4949M15.4989 24.4949L6.4989 15.4949"
                stroke="#E9D7FE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <button
            className={`${styles.reportButton} `}
            onClick={() => handleDeleteElement(index)}
            title="Remove"
          >
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.92749 7.78076H27.0703"
                stroke="#E9D7FE"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M24.4994 7.78076V25.7808C24.4994 27.0665 23.2137 28.3522 21.928 28.3522H9.07082C7.7851 28.3522 6.49939 27.0665 6.49939 25.7808V7.78076"
                stroke="#E9D7FE"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.3566 7.78275V5.21132C10.3566 3.92561 11.6423 2.63989 12.928 2.63989H18.0709C19.3566 2.63989 20.6423 3.92561 20.6423 5.21132V7.78275"
                stroke="#E9D7FE"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      )}

      {showAddOptions && (
        <div className={styles.reportActions}>
          <p>Select element Type</p>
          <button
            className={` ${styles.reportButton}`}
            onClick={() => handleSelectElementType("title")}
          >
            Title
          </button>
          <button
            className={`${styles.reportButton}`}
            onClick={() => handleSelectElementType("text")}
          >
            Text
          </button>
          <button
            className={` ${styles.reportButton}`}
            onClick={() => setShowAddOptions(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              class="lucide lucide-circle-x"
            >
              <circle stroke="#E9D7FE" cx="12" cy="12" r="10" />
              <path stroke="#E9D7FE" d="m15 9-6 6" />
              <path stroke="#E9D7FE" d="m9 9 6 6" />
            </svg>{" "}
          </button>
        </div>
      )}
    </div>
  );
};

export default ReportElementWrapper;
