import { Fragment, useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { HeaderClass } from "../../../layout/table/Header.js";
import { CellClass, RowCellClass } from "../../../layout/table/Cell.js";
import TableGrid from "../../../layout/table/Table.js";
import styles from "../parameter_optimisation/optimisation_table/OptimisationTable.module.css";
import HeaderSection from "../../../layout/HeaderSection.js";
import { setParameterOptimisationIsWaiting } from "../../../features/atl_tool/atlToolSlice.js";
import Checkbox from "../../../layout/buttons/Checkbox.js";
import RiskBadge from "../../../layout/labels/risk_badge/RiskBadge.js";
import StatusBadge from "../../../layout/labels/status_badge/StatusBadge.js";
import RelevancyBadge from "../../../layout/labels/relevancy_badge/RelevancyBadge.js";

function AddColumnButton({ showColumnSelector, setShowColumnSelector }) {
  return (
    <button
      className={`${styles.addColumnButton} ${
        showColumnSelector ? styles.rotate : ""
      }`}
      onClick={(e) => {
        e.stopPropagation();
        setShowColumnSelector((prevState) => !prevState);
      }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none">
        <path
          d="M9.99935 5.05664V16.7233M4.16602 10.89H15.8327"
          stroke="white"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {showColumnSelector ? "Close" : "Add Column"}
    </button>
  );
}

function AlertsTable({}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedSegment, suppressedAlerts } = useSelector(
    (state) => state.atlTool
  );

  const [showColumnSelector, setShowColumnSelector] = useState(false);
  const [allHeaders, setAllHeaders] = useState([]);
  const columnSelectorRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    dispatch(setParameterOptimisationIsWaiting(false));
  }, [suppressedAlerts, dispatch]);

  const headers = [
    new HeaderClass("Alert ID", "alert_code", true, false, {
      minWidth: 210,
    }),
    new HeaderClass("Closure Date", "alert_closure_date", true, false, {
      minWidth: 140,
    }),
    new HeaderClass("Alert Relevancy", "alert_relevancy", true, false, {
      minWidth: 150,
    }),
    new HeaderClass("Risk Score", "risk_score", true, false, {
      minWidth: 150,
    }),
    new HeaderClass("Status", "status", true, false, {
      minWidth: 150,
    }),
  ];

  const [selectedHeaders, setSelectedHeaders] = useState(headers);

  const handleColumnChange = (header) => {
    setSelectedHeaders((prevSelectedHeaders) => {
      const isHeaderSelected = prevSelectedHeaders.some(
        (selectedHeader) => selectedHeader.field === header.field
      );

      if (isHeaderSelected) {
        return prevSelectedHeaders.filter(
          (selectedHeader) => selectedHeader.field !== header.field
        );
      } else {
        return [...prevSelectedHeaders, header];
      }
    });
  };

  const handleClickOutside = useCallback((event) => {
    if (
      columnSelectorRef.current &&
      buttonRef.current &&
      !columnSelectorRef.current.contains(event.target) &&
      !buttonRef.current.contains(event.target)
    ) {
      setTimeout(() => {
        setShowColumnSelector(false);
      }, 100);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (suppressedAlerts.length > 0) {
      const allHeaders = Object.keys(suppressedAlerts[0])
        .filter(
          (key) => key !== "alert_creation_date" && key !== "alert_comment"
        )
        .map(
          (key) =>
            new HeaderClass(
              key
                .replace(/_/g, " ")
                .replace(/\b\w/g, (char) => char.toUpperCase()),
              key,
              true,
              false,
              { minWidth: 150 }
            )
        );
      setAllHeaders(allHeaders);
    }
  }, [suppressedAlerts]);

  const rows = suppressedAlerts?.map((rowData) => {
    const alertId = rowData.id;
    const cells = selectedHeaders.map((header) => {
      const value = rowData[header.field];
      if (header.field === "alert_relevancy") {
        return new CellClass(value, header.field, () => (
          <RelevancyBadge value={value} />
        ));
      }
      if (header.field === "risk_score") {
        return new CellClass(value, header.field, () => (
          <RiskBadge value={value} />
        ));
      }
      if (header.field === "status") {
        return new CellClass(value, header.field, () => (
          <div style={{ textAlign: "center" }}>
            <StatusBadge status={value} />
          </div>
        ));
      }

      return new CellClass(value, header.field);
    });

    return new RowCellClass(alertId, cells, rowData);
  });

  function onRowClick(row) {
    navigate(`/alerts/${row.id}`);
  }

  return (
    <Fragment>
      <HeaderSection
        SectionName="Supressed Alerts"
        Count={suppressedAlerts?.length}
        AdditionalText={selectedSegment.name}
      />
      <div className={styles.optimisationTable}>
        <div
          ref={columnSelectorRef}
          className={`${styles.columnSelector} ${
            showColumnSelector ? styles.show : ""
          }`}>
          {allHeaders.length > 0 &&
            allHeaders.map((header) => (
              <div className={styles.columnSelectorItem}>
                <Checkbox
                  checked={selectedHeaders.some(
                    (selectedHeader) => selectedHeader.field === header.field
                  )}
                  onChange={() => handleColumnChange(header)}
                  label={header.label}
                />
              </div>
            ))}
        </div>
        <TableGrid
          title={`Supressed Alerts ${selectedSegment.name} `}
          headers={selectedHeaders}
          pageSize={5}
          rows={rows}
          noDataPlaceholder={"No Suppressed Alerts"}
          canExport={true}
          onRowClick={onRowClick}
          actionButtonsLeft={[
            <div ref={buttonRef} className={styles.controls}>
              <AddColumnButton
                showColumnSelector={showColumnSelector}
                setShowColumnSelector={setShowColumnSelector}
              />
            </div>,
          ]}
        />
      </div>
    </Fragment>
  );
}
export default AlertsTable;
