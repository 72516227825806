import React, { useState, useRef, useEffect } from "react";
import styles from "./Filter.module.css";
import Scrollbar from "../scrollbar/Scrollbar";
import Checkbox from "../buttons/Checkbox";

const FilterSelector = ({
  options,
  title,
  selectedOptions = [],
  setSelectedOptions,
  isFilterOpen,
  closeFilter,
  outlineRight = false,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [optionsHeight, setOptionsHeight] = useState("fit-content");
  const dropdownRef = useRef(null);
  const optionsRef = useRef(null);
  const maxOptionsHeight = 150;

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const isString = (obj) => {
    return Object.prototype.toString.call(obj) === "[object String]";
  };

  const filteredOptions = options.filter((opt_type) => {
    if (opt_type && isString(opt_type)) {
      return opt_type.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return true;
  });

  const selectAll = () => {
    setSelectedOptions([...options]);
  };

  const clearAll = () => {
    setSelectedOptions([]);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeFilter();
    }
    event.stopPropagation();
  };

  const handleCheck = (option, checked) => {
    let updatedSelectedOptions = [...selectedOptions];
    if (checked) {
      updatedSelectedOptions.push(option);
    } else {
      updatedSelectedOptions = updatedSelectedOptions.filter(
        (opt) => opt !== option
      );
    }
    setSelectedOptions(updatedSelectedOptions);
  };

  useEffect(() => {
    if (optionsRef.current) {
      const contentHeight = optionsRef.current.offsetHeight;
      setOptionsHeight(Math.min(contentHeight, maxOptionsHeight));
    }
  }, [options]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={styles.dropDownWrapper}
      style={{
        width: "260px",
        border: "none",
        boxShadow: "none",
        backgroundColor: "transparent",
        left: outlineRight && 0,
        right: !outlineRight && -34,
      }}
      ref={dropdownRef}
      onMouseDown={(e) => e.stopPropagation()} // Prevent clicks inside from triggering click outside
    >
      <div
        className={`${styles.dropdownContent} ${
          isFilterOpen ? styles.show : ""
        }`}>
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search..."
          className={styles.searchBar}
          style={{ marginBottom: 11 }}
        />
        <div className={styles.dropdownActions} style={{ marginBottom: 15 }}>
          <div className={styles.selectionActionHolder}>
            <div
              onClick={(e) => {
                e.stopPropagation();
                selectAll();
              }}
              className={styles.actionButton}>
              Select All
            </div>
            <div>|</div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                clearAll();
              }}
              className={styles.actionButton}>
              Clear All
            </div>
          </div>
        </div>
        <div className={styles.dropDownLabel} style={{ marginBottom: 22 }}>
          {title}
        </div>
        <Scrollbar
          style={{
            height: optionsHeight,
          }}
          trackYProps={{
            style: {
              zIndex: "100",
              bottom: "0px",
              right: "0px",
              width: "5px",
              backgroundColor: "#E9D7FE",
            },
          }}>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            ref={optionsRef}>
            {filteredOptions.map((option, i) => (
              <div key={i} className={styles.dropdownItem}>
                <Checkbox
                  checked={selectedOptions.includes(option)}
                  onChange={(e) => handleCheck(option, e.target.checked)}
                  label={option}
                />
              </div>
            ))}
            {filteredOptions.length === 0 && (
              <div className={styles.noResults}>No results found</div>
            )}
          </div>
        </Scrollbar>
      </div>
    </div>
  );
};

export default FilterSelector;
