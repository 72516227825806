import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchOverlapAnalysisMatrix,
  setDsId,
  setSelectedYearMonthPairs,
} from "./../../../features/overlap_analysis/overlapAnalysisSlice";
import view_style from "../../../layout/Layout.module.css";
import HeaderSection from "../../../layout/HeaderSection";
import SearchSelector from "./selectors/SearchSelector";
import style from "./MatrixOverlap.module.css";
import Heatmap from "./Heatmap";
import Scrollbar from "../../../layout/scrollbar/Scrollbar";
import { useNavigate } from "react-router-dom";
import ToggleButton from "../../../layout/buttons/ToggleButton";

const ToggleSwitch = ({ labelLeft, labelRight, isChecked, onToggle }) => (
  <div style={{ display: "flex", alignItems: "center", width: "235px" }}>
    <span
      style={{
        marginRight: "10px",
        color: isChecked ? "#B0AFD9" : "#7A5AF8",
        width: "120px",
        fontWeight: 500,
        textAlign: "left",
      }}>
      {labelLeft}
    </span>
    <ToggleButton checked={isChecked} onChange={onToggle} />
    <span
      style={{
        marginLeft: "10px",
        color: isChecked ? "#7A5AF8" : "#B0AFD9",
      }}>
      {labelRight}
    </span>
  </div>
);

const MatrixOverlap = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const overlapData = useSelector((state) => state.overlapAnalysis.matrixData);
  const status = useSelector((state) => state.overlapAnalysis.status);
  const error = useSelector((state) => state.overlapAnalysis.error);

  const [filteredOverlapData, setFilteredOverlapData] = useState([]);
  const [visibleCategories, setVisibleCategories] = useState({});
  const [isAbsoluteValue, setIsAbsoluteValue] = useState(false);
  const [isCustomerId, setIsCustomerId] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // NEW STATE FOR SEARCH QUERY

  const [scrollHeight, setScrollHeight] = useState();
  const tableRef = useRef(null);

  const calculateHeightRow = (row) => {
    if (visibleCategories[row.category_name]) {
      return 49 + row.data.length * 70;
    }
    return 49;
  };

  useEffect(() => {
    if (tableRef.current) {
      let totalHeight = 70;
      filteredOverlapData.forEach(
        (key) => (totalHeight += calculateHeightRow(key))
      );
      setScrollHeight(totalHeight);
    }
  }, [tableRef, overlapData, visibleCategories, filteredOverlapData]);

  const getRandomNumber = () => {
    return Math.floor(Math.random() * 10000000);
  };

  const toggleCategoryVisibility = (category) => {
    setVisibleCategories((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  const handleHeatmapClick = (params) => {
    dispatch(setDsId({ number: 1, id: params.data.row_id }));
    dispatch(setDsId({ number: 2, id: params.data.col_id }));
    navigate(`/scenarios-overlap/${params.data.row_id}/${params.data.col_id}`);
  };

  const keys =
    overlapData.length > 0
      ? overlapData[0].data[0].data.map((item) => item.x)
      : [];

  const [selectedDsFilter, setSelectedDsFilter] = useState([]);

  const selectedYearMonthPairs = useSelector(
    (state) => state.overlapAnalysis.selectedYearMonthPairs
  );

  const [selectedYearMonthPairsState, setSelectedYearMonthPairsState] =
    useState(selectedYearMonthPairs || []);

  // useEffect(() => {
  //   dispatch(fetchOverlapAnalysisMatrix({ year_month_pairs: selectedYearMonthPairs }));
  // }, [ selectedYearMonthPairs]);

  useEffect(() => {
    applyFilters();
  }, [overlapData, selectedDsFilter, searchQuery]);

  const applyFilters = () => {
    let result = overlapData;

    // Apply DS filter if selected
    if (selectedDsFilter.length !== 0) {
      result = result.map((cat) => {
        const dataResult = cat.data.filter((data) =>
          selectedDsFilter.includes(data.name)
        );
        return {
          ...cat,
          data: dataResult,
        };
      });
    }

    // Apply search filter
    if (searchQuery.trim() !== "") {
      const lowerCaseQuery = searchQuery.trim().toLowerCase();

      result = result
        .map((cat) => {
          // Check if the category name includes the search query
          const categoryMatches = cat.category_name
            .toLowerCase()
            .includes(lowerCaseQuery);

          let dataResult;

          // if (categoryMatches) {
          //   // If the category matches, include all data
          //   dataResult = cat.data;
          // } else {
          // Filter data items within the category
          dataResult = cat.data.filter((data) => {
            // Check if the data name includes the search query
            const dataNameMatches = data.name
              .toLowerCase()
              .includes(lowerCaseQuery);

            // Check if any of the cell values include the search query
            const cellValueMatches = data.data.some((cell) => {
              let cellValue;
              console.log("cell: ", cell);
              if (isAbsoluteValue == false) {
                // Absolute value, rounded up
                cellValue = cell.abs;
              } else {
                // Percentage, multiplied by 100
                cellValue = cell.percentage * 100;
              }
              // Convert cellValue to string and check if it includes the search query
              return cellValue
                .toString()
                .toLowerCase()
                .includes(lowerCaseQuery);
            });

            // Return true if dataNameMatches or cellValueMatches
            return dataNameMatches || cellValueMatches;
          });
          // }

          // Include the category if dataResult is not empty
          if (dataResult.length > 0) {
            return {
              ...cat,
              data: dataResult,
            };
          } else {
            // Exclude categories with no matching data
            return null;
          }
        })
        .filter((cat) => cat != null); // Remove categories that are null
    }

    setFilteredOverlapData(result);
  };

  const generateYearMonthPairs = (startYear, startMonth, endYear, endMonth) => {
    const yearMonthPairs = [];
    let year = startYear;
    let month = startMonth;

    while (year < endYear || (year === endYear && month <= endMonth)) {
      const formattedMonth = month < 10 ? `0${month}` : `${month}`;
      yearMonthPairs.push(`${year}-${formattedMonth}`);
      month++;
      if (month > 12) {
        month = 1;
        year++;
      }
    }
    return yearMonthPairs;
  };

  const minTableWidth = 270 + 200 * keys.length;

  const handleApplyMonthYearFilter = (year) => {
    dispatch(setSelectedYearMonthPairs(year));
    dispatch(fetchOverlapAnalysisMatrix({}));
  };

  useEffect(() => {
    dispatch(fetchOverlapAnalysisMatrix({}));
  }, []);

  return (
    <div className={view_style.viewWrapper}>
      <div className={view_style.sectionWrapper}>
        <HeaderSection
          SectionName="Matrix Overlap Analysis"
          AdditionalText=""
        />

        <div className={style.searcherBox}>
          <div className={style.searchInputBox} style={{ width: "100%" }}>
            <div style={{ minWidth: "20px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none">
                <path
                  d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                  stroke="#BDB4FE"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={style.searchInput}
            />
          </div>
        </div>
        <div className={style.gridContainer}>
          <SearchSelector
            key={1}
            all_options={keys}
            title={"DS"}
            selected_options={selectedDsFilter}
            setSelectedOptions={setSelectedDsFilter}
            applyFunction={() => {}} // Remove local apply function
            localFilter={true}
          />
          <SearchSelector
            key={2}
            all_options={generateYearMonthPairs(2022, 1, 2024, 8)}
            title={"Month/Year"}
            selected_options={selectedYearMonthPairsState}
            setSelectedOptions={setSelectedYearMonthPairsState}
            applyFunction={handleApplyMonthYearFilter}
            localFilter={true}
          />
          {/* NEW SEARCH BAR */}
        </div>

        <Scrollbar
          style={{ height: scrollHeight, transition: "height 0.8s ease" }}
          verticalScroll={false}>
          <div ref={tableRef} style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                minWidth: `${keys.length * 200}px`,
                borderBottom: "1px solid var(--Brand-200, #E9D7FE)",
                fontSize: "12px",
              }}>
              <div
                style={{
                  height: "70px",
                  minWidth: "270px",
                  background: "white",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <div style={{ marginBottom: "3px" }}>
                  <ToggleSwitch
                    labelLeft="ABSOLUTE VALUE"
                    labelRight="%"
                    isChecked={isAbsoluteValue}
                    onToggle={() => setIsAbsoluteValue(!isAbsoluteValue)}
                  />
                </div>
                <ToggleSwitch
                  labelLeft="DS"
                  labelRight="Scenarios"
                  isChecked={isCustomerId}
                  onToggle={() => setIsCustomerId(!isCustomerId)}
                />
              </div>
              {keys.map((key, index) => (
                <div
                  key={index}
                  className={style.headerText}
                  style={{ minWidth: "180px", textAlign: "center" }}>
                  {key}
                </div>
              ))}
            </div>
            {/* DISPLAY MESSAGE IF NO ROWS MATCH */}
            {filteredOverlapData.length === 0 ? (
              <div
                style={{
                  padding: "20px",
                  textAlign: "center",
                  color: "#7A5AF8",
                  fontWeight: "bold",
                }}>
                No rows match your search.
              </div>
            ) : (
              filteredOverlapData.map((categoryData) => (
                <div
                  key={categoryData.category_name + getRandomNumber()}
                  style={{
                    overflowY: "hidden",
                    overflowX: "hidden",
                    fontWeight: 500,
                    height: `${calculateHeightRow(categoryData)}px`,
                    minWidth: `${minTableWidth}px`,
                    width: `100%`,
                    transition: "height 0.8s ease",
                  }}>
                  <div
                    className={style.categorySeparator}
                    style={{ minWidth: `${minTableWidth}px`, width: `100%` }}
                    onClick={() =>
                      toggleCategoryVisibility(categoryData.category_name)
                    }>
                    <div className={style.icon}>
                      {visibleCategories[categoryData.category_name] ? (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none">
                          <g id="chevron-down">
                            <path
                              id="Icon"
                              d="M6 9L12 15L18 9"
                              stroke="#9B8AFB"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                        </svg>
                      ) : (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none">
                          <g id="Chevron right">
                            <path
                              id="Icon"
                              d="M9 18L15 12L9 6"
                              stroke="#7A5AF8"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                        </svg>
                      )}
                    </div>
                    <div className={style.categoryText}>
                      {categoryData.category_name}
                    </div>
                  </div>
                  {visibleCategories[categoryData.category_name] &&
                    categoryData.data.length > 0 && (
                      <Heatmap
                        data={categoryData.data}
                        keys={keys}
                        isPercentage={isAbsoluteValue}
                        handleClick={handleHeatmapClick}
                      />
                    )}
                </div>
              ))
            )}
          </div>
        </Scrollbar>
      </div>
    </div>
  );
};

export default MatrixOverlap;
