import React, { useEffect, useState } from "react";
import styles from "./SharedLists.module.css";
import TitleWithCount from "../../layout/titles/TitleWithCount/TitleWithCount";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../layout/buttons/NormalButton";
import SearchBar from "../../layout/searchBar/SearchBar";
import Dropdown from "../../layout/dropdown/Dropdown";
import HeaderSection from "../../layout/HeaderSection";
import InputComponent from "../../layout/input/InputComponent.js";
import ModalPopUp from "../../layout/modal/Modal";
import Selector from "../atl_tool/visualisation/selector/Selector";
import { HeaderClass } from "../../layout/table/Header";
import { useDispatch, useSelector } from "react-redux";
import TableGrid from "../../layout/table/Table";
import {
  getListByType,
  createListByType,
} from "../../features/shared_list/sharedListSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { CellClass, RowCellClass } from "../../layout/table/Cell.js";
import {
  notifySuccess,
  notifyError,
} from "../../layout/ToastNotifications.jsx";

const SharedLists = () => {
  const location = useLocation(); // Get location for restoring state

  const dispatch = useDispatch();
  //comune selection for the dropdowns
  const [selectedGlobalItem, setSelectedGlobalItem] = useState(null);
  const [selectedListObject, setSelectedListObject] = useState(
    location.state // Restore selected object from state
  );

  useEffect(() => {
    console.log("location ddd ", location.state);
  }, [location.state]);

  const { blackLists, infoLists, exemptLists, creationStatus } = useSelector(
    (state) => state.sharedList
  );

  const navigate = useNavigate();

  const [createName, setCreateName] = useState("");
  const [createDescription, setCreateDescription] = useState("");

  const [headers, setHeaders] = useState([
    new HeaderClass("Name", "id", true, false, {
      minWidth: 100,
      maxWidth: 100,
    }),
    new HeaderClass("Type", "user", true, false, {
      minWidth: 100,
      maxWidth: 100,
      width: 100,
    }),
    new HeaderClass("Description", "sescription", true, false, {
      minWidth: 400,
      maxWidth: 400,
      width: 400,
    }),
  ]);
  const [rows, setRows] = useState([]);

  // Internal state for list data
  const [lists, setLists] = useState([
    {
      title: "Info Lists in AML Suspicious Activity",
      items: [
        { name: "Country Flag List" },
        { name: "Transaction Type List" },
        { name: "Second Party IBAN List" },
        { name: "Special Country List" },
      ],
    },
    {
      title: "Monitoring Lists in AML Suspicious Activity",
      items: [
        { name: "Customer Monitoring List" },
        { name: "Account Monitoring List" },
      ],
    },
    {
      title: "Customer Exempt Lists",
      items: [
        { name: "Cash Exempt List" },
        { name: "Titular" },
        { name: "New Exempt List" },
      ],
    },
  ]);

  useEffect(() => {
    if (creationStatus === "failed") {
      notifyError("New list could not be created");
    } else if (creationStatus === "succeeded") {
      notifySuccess("A new list has been created");
    }
  }, [creationStatus]);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(getListByType({ type: "Blacklist" }));
    dispatch(getListByType({ type: "Info list" }));
    dispatch(getListByType({ type: "Exempt list" }));
  }, []);

  useEffect(() => {
    setLists([
      {
        title: "Info Lists in AML Suspicious Activity",
        items: infoLists,
      },
      {
        title: "Monitoring Lists in AML Suspicious Activity",
        items: blackLists,
      },
      {
        title: "Customer Exempt Lists",
        items: exemptLists,
      },
    ]);
  }, [blackLists, infoLists, exemptLists]);

  const [filteredLists, setFilteredLists] = useState(lists);

  useEffect(() => {
    // Filter the lists based on the search term
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const newFilteredLists = lists
      .map((category) => ({
        ...category,
        items: category.items.filter((item) =>
          item.name.toLowerCase().includes(lowerCaseSearchTerm)
        ),
      }))
      .filter((category) => category.items.length > 0); // Remove empty categories

    setFilteredLists(newFilteredLists);
  }, [searchTerm, lists]);

  const makeRows = (listObject) => {
    let rows = [];
    listObject.list_columns.forEach((data) => {
      let cells = [];
      cells.push(new CellClass(data.name, "name"));
      cells.push(new CellClass(data.data_type, "type"));
      cells.push(new CellClass(data.description, "description"));
      rows.push(new RowCellClass(data.id, cells));
    });
    return rows;
  };

  useEffect(() => {
    lists.forEach((category) => {
      category.items.forEach((listItem) => {
        if (listItem.name === selectedGlobalItem) {
          setSelectedListObject(listItem);
          setRows(makeRows(listItem));
          navigate(location.pathname, { state: listItem }); // makes sure the selected option is in the location.state for page refresh
        }
      });
    });
  }, [selectedGlobalItem]);

  const createList = () => {
    openCreateModal();
  };

  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);

  const closeCreateModal = () => {
    setCreateModalIsOpen(false);
  };

  const openCreateModal = () => {
    setCreateModalIsOpen(true);
  };

  const resetModals = () => {
    closeCreateModal();
    closeCreateExemptListModal();
    closeCreateDefaultListModal();
    closeCreateBlackListModal();
    setCreateName("");
    setCreateDescription("");
  };

  useEffect(() => {
    console.log("selectedListObject: ", selectedListObject);
    if (selectedListObject) {
      setSelectedGlobalItem(selectedListObject.name);
    }
  }, [selectedListObject]);

  const createDefaultList = () => {
    dispatch(
      createListByType({
        type: "Info list",
        name: createName,
        description: createDescription,
      })
    )
      .unwrap()
      .then(({ name }) => setSelectedGlobalItem(name));
    resetModals();
  };

  const createExemptList = () => {
    dispatch(
      createListByType({
        type: "Exempt list",
        name: createName,
        description: createDescription,
      })
    )
      .unwrap()
      .then(({ name }) => setSelectedGlobalItem(name));
    resetModals();
  };

  const createBlackList = () => {
    dispatch(
      createListByType({
        type: "Blacklist",
        name: createName,
        description: createDescription,
      })
    )
      .unwrap()
      .then(({ name }) => setSelectedGlobalItem(name));
    resetModals();
  };

  const [createExemptListModalIsOpen, setCreateExemptListModalIsOpen] =
    useState(false);

  const closeCreateExemptListModal = () => {
    setCreateExemptListModalIsOpen(false);
  };

  const openCreateExemptListModal = () => {
    closeCreateModal();
    setCreateExemptListModalIsOpen(true);
  };

  const [createDefaultListModalIsOpen, setCreateDefaultListModalIsOpen] =
    useState(false);

  const closeCreateDefaultListModal = () => {
    setCreateDefaultListModalIsOpen(false);
  };

  const openCreateDefaultListModal = () => {
    closeCreateModal();
    setCreateDefaultListModalIsOpen(true);
  };

  const goToDataManagement = () => {
    navigate("/data-management", { state: selectedListObject });
  };

  const [createBlackListModalIsOpen, setCreateBlackListModalIsOpen] =
    useState(false);

  const closeCreateBlackListModal = () => {
    setCreateBlackListModalIsOpen(false);
  };

  const openCreateBlackListModal = () => {
    closeCreateModal();
    setCreateBlackListModalIsOpen(true);
  };

  const listSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15 2H6C5.47 2 4.96 2.21 4.59 2.59C4.21 2.96 4 3.47 4 4V20C4 20.53 4.21 21.04 4.59 21.41C4.96 21.79 5.47 22 6 22H18C18.53 22 19.04 21.79 19.41 21.41C19.79 21.04 20 20.53 20 20V7L15 2Z"
        stroke="#905EE2"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 2V6C14 6.53 14.21 7.04 14.59 7.41C14.96 7.79 15.47 8 16 8H20"
        stroke="#905EE2"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 9H8"
        stroke="#905EE2"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 13H8"
        stroke="#905EE2"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 17H8"
        stroke="#905EE2"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const [selectedEntity, setSelectedEntity] = useState("All");

  return (
    <div className={styles.container}>
      <ModalPopUp
        handleClose={closeCreateModal}
        isOpen={createModalIsOpen}
        inlineTitle={true}
        title={"Create New List"}
        buttonsAlign={"stretch"}
        content={
          <span className={styles.text}>
            What type of list do you want to create?
          </span>
        }
        actionButtons={[
          <SecondaryButton
            style={{ flex: 1, paddingLeft: 0, paddingRight: 0 }}
            borderRadius={"24px"}
            content="Default List"
            action={openCreateDefaultListModal}
          />,
          <SecondaryButton
            style={{ flex: 1, paddingLeft: 0, paddingRight: 0 }}
            borderRadius={"24px"}
            content="Exempt List"
            action={openCreateExemptListModal}
          />,
          <SecondaryButton
            style={{ flex: 1, paddingLeft: 0, paddingRight: 0 }}
            borderRadius={"24px"}
            content="Black List"
            action={openCreateBlackListModal}
          />,
        ]}
        icon={listSvg}
      />

      <ModalPopUp
        handleClose={closeCreateExemptListModal}
        isOpen={createExemptListModalIsOpen}
        inlineTitle={true}
        title={"Create Exempt List"}
        buttonsAlign={"center"}
        content={
          <>
            <div className={styles.inputWrapper}>
              <InputComponent
                type="normal"
                label="Name"
                value={createName}
                onChange={(e) => setCreateName(e.target.value)}
                content="New Exempt List"
              />
              {/* <Selector label="Entity" options={['All','Customer', 'Other']}  selectedOption={selectedEntity} onOptionSelect={setSelectedEntity} /> */}
            </div>
            <InputComponent
              type="comment"
              label="Description"
              value={createDescription}
              onChange={(e) => setCreateDescription(e.target.value)}
              content="Add text here Add text here Add text here Add text here Add text here Add text here"
            />
            <span className={styles.text}>
              {" "}
              Warning: In the current production model, all the entries in the
              exempt list will be exempted for all the scenarios.
              <br />
              If this is not desired, leave the exempt list empty and first
              create and activate a new detection model in which you change the
              activation settings of this exempt list.
            </span>
          </>
        }
        actionButtons={[
          <SecondaryButton
            style={{ flex: 1 }}
            content="Cancel"
            action={closeCreateExemptListModal}
          />,
          <PrimaryButton
            style={{ flex: 1 }}
            disabled={
              createName.trim() != "" && createDescription.trim() != ""
                ? false
                : true
            }
            content="Create"
            action={createExemptList}
          />,
        ]}
        icon={listSvg}
      />

      <ModalPopUp
        handleClose={closeCreateDefaultListModal}
        isOpen={createDefaultListModalIsOpen}
        inlineTitle={true}
        title={"Create Default List"}
        buttonsAlign={"center"}
        content={
          <>
            <div className={styles.inputWrapper}>
              <InputComponent
                type="normal"
                value={createName}
                onChange={(e) => setCreateName(e.target.value)}
                label="Name"
                content="New Default List"
              />
              {/* <Selector label="Entity" options={['All','Customer', 'Other']}  selectedOption={selectedEntity} onOptionSelect={setSelectedEntity} /> */}
            </div>
            <InputComponent
              type="comment"
              label="Description"
              value={createDescription}
              onChange={(e) => setCreateDescription(e.target.value)}
              content="Add text here Add text here Add text here Add text here Add text here Add text here"
            />
            <span className={styles.text}>
              {" "}
              Warning: In the current production model, all the entries in the
              exempt list will be exempted for all the scenarios.
              <br />
              If this is not desired, leave the exempt list empty and first
              create and activate a new detection model in which you change the
              activation settings of this exempt list.
            </span>
          </>
        }
        actionButtons={[
          <SecondaryButton
            style={{ flex: 1 }}
            content="Cancel"
            action={closeCreateDefaultListModal}
          />,
          <PrimaryButton
            style={{ flex: 1 }}
            disabled={
              createName.trim() != "" && createDescription.trim() != ""
                ? false
                : true
            }
            content="Create"
            action={createDefaultList}
          />,
        ]}
        icon={listSvg}
      />

      <ModalPopUp
        handleClose={closeCreateBlackListModal}
        isOpen={createBlackListModalIsOpen}
        inlineTitle={true}
        title={"Create Black List"}
        buttonsAlign={"center"}
        content={
          <>
            <div className={styles.inputWrapper}>
              <InputComponent
                type="normal"
                value={createName}
                onChange={(e) => setCreateName(e.target.value)}
                label="Name"
                content="New Black List"
              />
              {/* <Selector label="Entity" options={['All','Customer', 'Other']}  selectedOption={selectedEntity} onOptionSelect={setSelectedEntity} /> */}
            </div>
            <InputComponent
              type="comment"
              value={createDescription}
              onChange={(e) => setCreateDescription(e.target.value)}
              label="Description"
              content="Add text here Add text here Add text here Add text here Add text here Add text here"
            />
            <span className={styles.text}>
              {" "}
              Warning: In the current production model, all the entries in the
              exempt list will be exempted for all the scenarios.
              <br />
              If this is not desired, leave the exempt list empty and first
              create and activate a new detection model in which you change the
              activation settings of this exempt list.
            </span>
          </>
        }
        actionButtons={[
          <SecondaryButton
            style={{ flex: 1 }}
            disabled={
              createName.trim() != "" && createDescription.trim() != ""
                ? false
                : true
            }
            content="Cancel"
            action={closeCreateBlackListModal}
          />,
          <PrimaryButton
            style={{ flex: 1 }}
            content="Create"
            action={createBlackList}
          />,
        ]}
        icon={listSvg}
      />

      <div className={styles.contentWrapper}>
        <div className={styles.leftContainer}>
          <TitleWithCount
            title="Shared Lists"
            labels={filteredLists.flatMap((list) =>
              list.items.map((item) => item.name)
            )}
            palette={{
              borderColor: "#F8F2FF",
              backgroundColor: "#F8F2FF",
              textColor: "#9E77ED",
            }}
          />

          <div className={styles.searchWrapper}>
            <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

            <div className={styles.buttonWrapper}>
              <PrimaryButton
                content="Create List"
                action={() => createList()}
              />
            </div>
          </div>
          <div className={styles.listWrapper}>
            {filteredLists.map((category) => (
              <>
                <Dropdown
                  key={category.title}
                  title={category.title}
                  items={category.items}
                  selectedGlobalItem={selectedGlobalItem}
                  setSelectedGlobalItem={setSelectedGlobalItem}
                />

                {lists.indexOf(category) !== lists.length - 1 && (
                  <div className={styles.line}></div>
                )}
              </>
            ))}

            {filteredLists.length === 0 && (
              <>
                <div className={styles.noDataPlaceholder}>
                  {searchTerm.length === 0
                    ? "There are no lists available."
                    : "No lists found with the search term."}
                </div>
              </>
            )}
          </div>
        </div>

        <div className={styles.rightContainer}>
          <HeaderSection
            SectionName="Details"
            AdditionalText={"Transaction Type List"}
          />
          <div className={styles.inputWrapper}>
            <div className={styles.nameInputWrapper}>
              <InputComponent
                type="normal"
                label="List Name"
                value={selectedListObject ? selectedListObject.name : ""}
                content="Transaction Type List"
                isEditable={false}
              />

              <div className={styles.buttonWrapper}>
                <SecondaryButton
                  content="Manage Data"
                  style={{ height: "50%", bottom: 0 }}
                  action={goToDataManagement}
                  disabled={!selectedListObject}
                />
              </div>
            </div>
            <div className={styles.commentwrapper}>
              <InputComponent
                type="comment"
                value={selectedListObject ? selectedListObject.description : ""}
                label="Description"
                content="List of transaction types"
                isEditable={false}
              />
            </div>

            <TableGrid
              title={`Transaction Type List`}
              headers={headers}
              rows={rows}
              canSearch={false}
              noDataPlaceholder={"There are no transaction types in this list."}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharedLists;
