import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../axiosConfig";

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({ username, password }, { dispatch, rejectWithValue }) => {
    try {
      const params = new URLSearchParams();
      params.append("username", username);
      params.append("password", password);

      const response = await apiClient.post("/token", params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      const { access_token, refresh_token } = response.data;
      console.log("Received tokens:", { access_token, refresh_token });

      localStorage.setItem("token", access_token);
      localStorage.setItem("refresh_token", refresh_token);

      dispatch(fetchUserId());
      return { access_token, refresh_token };
    } catch (error) {
      // console.error('Error during login:', error);

      // Verifica si hay una respuesta del servidor
      if (error.response) {
        const { status, data } = error.response;

        // Maneja el error basado en el código de estado HTTP
        if (status === 404) {
          return rejectWithValue(data.detail || "User not found");
        } else if (status === 401) {
          return rejectWithValue(data.detail || "Incorrect password");
        } else {
          return rejectWithValue(data.detail || "An unexpected error occurred");
        }
      } else if (error.request) {
        // Si se realizó la solicitud pero no se recibió respuesta
        return rejectWithValue("No response from server");
      } else {
        // Otro tipo de error
        return rejectWithValue("Request setup error");
      }
    }
  }
);

const fetchUserId = createAsyncThunk("allAlerts/fetchUserId", async () => {
  console.log("Fetching user details from API...");
  const response = await apiClient.get(`/users/me`);
  const result = response.data;
  return result;
});

export const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (_, { rejectWithValue }) => {
    const refreshToken = localStorage.getItem('refresh_token');
    if (!refreshToken) {
      return rejectWithValue('No refresh token available');
    }
    try {
      const response = await apiClient.post('/refresh-token', {
        refresh_token: refreshToken,
      });
      const { access_token } = response.data;
      localStorage.setItem('token', access_token);
      return access_token;
    } catch (error) {
      // If the refresh token is invalid, reject with appropriate message
      if (error.response && error.response.status === 401) {
        return rejectWithValue('Refresh token expired');
      }
      return rejectWithValue('Failed to refresh token');
    }
  }
);

export const logoutUser = createAsyncThunk(
  "auth/logoutUser",
  async (_, { rejectWithValue }) => {
    try {
      await apiClient.post("/logout"); // Call the logout endpoint
      return true; // Indicate success
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data.detail || "Logout failed");
      }
      return rejectWithValue("An unexpected error occurred");
    }
  }
);




export const fetchUser = createAsyncThunk(
  'auth/fetchUser',
  async () => {
    console.log('Fetching user details from API...');
    const response = await apiClient.get(`/users/me`);
    console.log('User details response:', response.data);
    const result = response.data;
    return result;
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: localStorage.getItem("token") || null,
    refresh_token: localStorage.getItem("refresh_token") || null,
    isAuthenticated: !!localStorage.getItem("token"),
    loading: false,
    user: null,
    error: null,
  },
  reducers: {
    logout: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("userId");
      localStorage.removeItem("userName");
      state.token = null;
      state.refresh_token = null;
      state.isAuthenticated = false;
      state.user = null; 
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(logoutUser.pending, (state) => {
      state.loading = true;
    })
    .addCase(logoutUser.fulfilled, (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("userId");
      localStorage.removeItem("userName");
      state.token = null;
      state.refresh_token = null;
      state.isAuthenticated = false;
      state.user = null;
      state.error = null;
      state.loading = false;
    })
    .addCase(logoutUser.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })

      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        console.log('User details:', action.payload);
      })
      .addCase(fetchUser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.token = action.payload.access_token;
        state.refresh_token = action.payload.refresh_token;
        state.isAuthenticated = true;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.error = action.payload; // Almacena el mensaje de error
      })
      .addCase(fetchUserId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserId.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userId = action.payload.id;
        state.userName = action.payload.username;
        localStorage.setItem("userId", action.payload.id);
        localStorage.setItem("userName", action.payload.username);
        console.log("User data: ", action.payload);
      })
      .addCase(fetchUserId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        console.log("ERROR: ", state.error);
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.token = action.payload;
        state.isAuthenticated = true;

        state.error = null;
      })
      .addCase(refreshToken.rejected, (state) => {
        state.token = null;
        state.isAuthenticated = false;
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
