import styles from "./Selector.module.css";
import { useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  performBtl,
  fetchBtlTable,
} from "../../../features/btl_tool/btlToolSlice";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../layout/buttons/NormalButton";
import ModalPopUp from "../../../layout/modal/Modal";
import Checkbox from "../../../layout/buttons/Checkbox";
import RadioButtonsContainer from "../../../layout/buttons/RadioButton";
import NumberInput from "../../../layout/input/ValueInput";
import { notifyError, notifySuccess } from "../../../layout/ToastNotifications";

const OPTIMISATION_AMOUNT_TYPES = {
  ratio: "Percentage",
  abs: "Absolute Number",
};

function BtlIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Property 1=Dark Mode">
        <path
          id="Vector"
          d="M15 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7L15 2Z"
          stroke="#905EE2"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_2"
          d="M14 2V6C14 6.53043 14.2107 7.03914 14.5858 7.41421C14.9609 7.78929 15.4696 8 16 8H20"
          stroke="#905EE2"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_3"
          d="M8 18V16"
          stroke="#905EE2"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_4"
          d="M12 18V14"
          stroke="#905EE2"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_5"
          d="M16 18V12"
          stroke="#905EE2"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}

function BtlModal({ modalOpen, setModalOpen }) {
  const dispatch = useDispatch();
  const {
    selectedScenario,
    selectedSubScenario,
    selectedPeriod,
    selectedSegment,
    selectedRiskScore,
    thresholds,
  } = useSelector((state) => state.btlTool);
  const amountTypeDict = {};
  const amountDict = {};
  const validDict = {};
  thresholds.forEach((threshold) => {
    amountTypeDict[threshold.threshold_id] = "ratio";
    amountDict[threshold.threshold_id] = 0;
    validDict[threshold.threshold_id] = true;
  });

  const [selectedThresholdsIds, setSelectedThresholdsIds] = useState([]);
  const [amountType, setAmountType] = useState(amountTypeDict);
  const [amount, setAmount] = useState(amountDict);
  const [validAmount, setValidAmount] = useState(validDict);
  const valid = selectedThresholdsIds.every(
    (thresholdId) => validAmount[thresholdId]
  );
  const selected = selectedThresholdsIds.length > 0;

  const generateThresholdList = selectedThresholdsIds.map((id) => [
    id,
    amountType[id],
    amount[id],
  ]);

  function resetModal() {
    setSelectedThresholdsIds([]);
    setAmountType(amountTypeDict);
    setAmount(amountDict);
    setValidAmount(validDict);
  }

  function clickThreshold(id) {
    setSelectedThresholdsIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((thresholdId) => thresholdId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  }

  function toggleAll() {
    if (selectedThresholdsIds.length === thresholds.length) {
      setSelectedThresholdsIds([]);
    } else {
      setSelectedThresholdsIds(
        thresholds.map((threshold) => threshold.threshold_id)
      );
    }
  }

  async function startBtl() {
    const result = await dispatch(
      performBtl({
        scenarioId: selectedScenario.id,
        subScenarioId: selectedSubScenario?.id || 0,
        segmentId: selectedSegment.id,
        riskScore: selectedRiskScore,
        period: selectedPeriod,
        optimisationThresholds: generateThresholdList,
      })
    ).unwrap();
    if (result.job_id) {
      notifySuccess(`Started optimisation`);
    } else {
      notifyError(`Failed to start optimisation`);
    }
    dispatch(
      fetchBtlTable({
        scenarioId: selectedScenario?.id,
        segmentId: selectedSegment.id,
        subScenarioId: selectedSubScenario?.id || 0,
        riskScore: selectedRiskScore,
        period: selectedPeriod,
      })
    );
    setModalOpen(false);
    resetModal();
  }

  const content = (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        {thresholds.map((threshold, index) => {
          const thresholdId = threshold.threshold_id;
          const enabled = selectedThresholdsIds.includes(thresholdId);
          function setThresholdAmount(amount) {
            setAmount((prevAmount) => ({
              ...prevAmount,
              [thresholdId]: amount,
            }));
          }
          function setThresholdAmountType(amountType) {
            setAmountType((prevAmountType) => ({
              ...prevAmountType,
              [thresholdId]: amountType,
            }));
          }
          function setThresholdValidAmount(valid) {
            setValidAmount((prevValid) => ({
              ...prevValid,
              [thresholdId]: valid,
            }));
          }
          return (
            <div
              key={index}
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Checkbox
                checked={selectedThresholdsIds.includes(thresholdId)}
                label={threshold.threshold_name}
                onChange={() => clickThreshold(thresholdId)}
              />
              <div style={{ display: "flex", gap: "12px", alignItems: "end" }}>
                <NumberInput
                  value={amount[thresholdId]}
                  setValue={setThresholdAmount}
                  label={"Value"}
                  setValid={setThresholdValidAmount}
                  disabled={!enabled}
                />
                <div
                  style={{
                    display: "flex",
                    gap: "24px",
                    alignItems: "center",
                  }}>
                  <RadioButtonsContainer
                    options={OPTIMISATION_AMOUNT_TYPES}
                    currentKey={amountType[thresholdId]}
                    setKey={setThresholdAmountType}
                    disabled={!enabled}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.applyAll} onClick={toggleAll}>
        Apply to all
      </div>
    </div>
  );

  const actionButtons = [
    <SecondaryButton
      content={"Cancel"}
      action={() => {
        resetModal();
        setModalOpen(false);
      }}
      style={{ flex: 1 }}
    />,
    <PrimaryButton
      content={"Continue"}
      action={startBtl}
      style={{ flex: 1 }}
      disabled={!selected || !valid}
    />,
  ];

  return (
    <ModalPopUp
      isOpen={modalOpen}
      handleClose={() => setModalOpen(false)}
      title={"Perform BTL - Lower the Threshold"}
      content={content}
      description={
        "Set your preferences and click 'Continue' to Perform the BTL, or 'Cancel' to exit."
      }
      icon={<BtlIcon />}
      actionButtons={actionButtons}
    />
  );
}

export default BtlModal;
