import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../axiosConfig";

export const getListByType = createAsyncThunk(
  "sharedList/getListByType",
  async ({ type }) => {
    const response = await apiClient.get(`/lists-by-type?list_type=${type}`);
    return { type, lists: response.data };
  }
);

export const createListByType = createAsyncThunk(
  "sharedList/createListByType",
  async ({ type, name, description }, thunkAPI) => {
    const response = await apiClient.post(`/lists`, {
      type: type,
      name: name,
      description: description,
    });
    const { dispatch } = thunkAPI;

    await dispatch(getListByType({ type }));

    return response.data;
  }
);

export const createListColumn = createAsyncThunk(
  "sharedList/createListColumn",
  async ({ listId, name, type, description }) => {
    const response = await apiClient.post(`/create-list-column`, {
      list_id: listId,
      name: name,
      data_type: type,
      description: description,
    });
    return response.data;
  }
);

export const createListRow = createAsyncThunk(
  "sharedList/createListRow",
  async ({ listId, data }) => {
    const response = await apiClient.post(`/create-list-rows`, {
      col_id_value_pairs: data,
      list_id: listId,
    });
    return response.data;
  }
);

export const getListById = createAsyncThunk(
  "sharedList/getListById",
  async ({ listId, type }, thunkAPI) => {
    try {
      // Fetch all lists of the given type
      const { dispatch } = thunkAPI;
      const result = await dispatch(getListByType({ type })).unwrap();

      // Find the specific list by its ID
      const specificList = result.lists.find((list) => list.id === listId);

      if (!specificList) {
        throw new Error(`List with ID ${listId} not found in type ${type}`);
      }

      return specificList;
    } catch (error) {
      console.error("Error fetching list by ID:", error.message);
      throw error;
    }
  }
);

const sharedListSlice = createSlice({
  name: "sharedList",
  initialState: {
    blackLists: [],
    infoLists: [],
    exemptLists: [],

    creationStatus: "idle",
    creationColumnStatus: "idle",
    creationRowStatus: "idle",
  },
  reducers: {
    setRowCreationStatus(state, action) {
      state.creationRowStatus = action.payload;
    },

    setColumnCreationStatus(state, action) {
      state.creationColumnStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListByType.fulfilled, (state, action) => {
        const { type } = action.meta.arg;
        console.log(`type: ${type}`, action.payload);
        if (type === "Blacklist") {
          state.blackLists = action.payload.lists;
        } else if (type === "Info list") {
          state.infoLists = action.payload.lists;
        } else if (type === "Exempt list") {
          state.exemptLists = action.payload.lists;
        }
      })
      .addCase(getListByType.rejected, (state, action) => {
        console.log(action.error.message);
      })
      .addCase(getListByType.pending, (state, action) => {
        console.log("fetching lists...");
      })
      .addCase(createListByType.pending, (state, action) => {
        state.creationStatus = "loading";
      })
      .addCase(createListByType.fulfilled, (state, action) => {
        state.creationStatus = "succeeded";
      })
      .addCase(createListByType.rejected, (state, action) => {
        state.creationStatus = "failed";
      })
      .addCase(createListColumn.pending, (state, action) => {
        state.creationColumnStatus = "loading";
      })
      .addCase(createListColumn.fulfilled, (state, action) => {
        state.creationColumnStatus = "succeeded";
      })
      .addCase(createListColumn.rejected, (state, action) => {
        state.creationColumnStatus = "failed";
      })
      .addCase(getListById.fulfilled, (state, action) => {
        console.log(action.payload);
      })
      .addCase(getListById.rejected, (state, action) => {
        console.log(action.error.message);
      })
      .addCase(getListById.pending, (state, action) => {
        console.log("fetching list...");
      })
      .addCase(createListRow.pending, (state, action) => {
        console.log("creating row...");
        state.creationRowStatus = "loading";
      })
      .addCase(createListRow.fulfilled, (state, action) => {
        console.log("row created");
        state.creationRowStatus = "succeeded";
      })
      .addCase(createListRow.rejected, (state, action) => {
        console.log("row creation failed");
        state.creationRowStatus = "failed";
      });
  },
});

export const { setRowCreationStatus, setColumnCreationStatus } =
  sharedListSlice.actions;

export default sharedListSlice.reducer;