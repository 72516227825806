import React, { useEffect, useState } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import styles from "./Dropdown.module.css";
import TitleWithCount from "../titles/TitleWithCount/TitleWithCount";

const Dropdown = ({
  title,
  items,
  selectedGlobalItem,
  setSelectedGlobalItem,
  svg,
  color,
  hasCount,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleSelectItem = (item) => {
    setSelectedGlobalItem(item.name);
  };

  useEffect(() => {
    console.log("items", items);
  }, [items]);

  const palette = {
    borderColor: "transparent",
    backgroundColor: "#fff0f3",
    textColor: "#F63D68",
  };

  // Default SVG if not provided
  if (!svg) {
    svg = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M15 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7L15 2Z"
          stroke="#D9D9E8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 2V6C14 6.53043 14.2107 7.03914 14.5858 7.41421C14.9609 7.78929 15.4696 8 16 8H20"
          stroke="#D9D9E8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 9H8"
          stroke="#D9D9E8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 13H8"
          stroke="#D9D9E8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 17H8"
          stroke="#D9D9E8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <div className={styles.dropdown}>
      <div className={styles.header} onClick={toggleDropdown}>
        <span className={`${styles.icon} ${isOpen ? styles.rotateIcon : ""}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M6 9L12 15L18 9"
              stroke="#905EE2"
              strokeWidth="2.95385"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span className={styles.title}>{title}</span>

        {hasCount && !items[0].items && (
          <TitleWithCount palette={palette} labels={items} isCircle={true} />
        )}
      </div>
      <div className={styles.listWrapper}>
        <TransitionGroup component="ul" className={styles.list}>
          {isOpen &&
            items.map((item, index) => {
              const reverseIndex = items.length - 1 - index;

              return (
                <CSSTransition
                  key={item.name + index}
                  timeout={{
                    enter: 500 + reverseIndex * 100,
                    exit: 500 + reverseIndex * 100,
                  }}
                  classNames={{
                    enter: styles.fadeEnter,
                    enterActive: styles.fadeEnterActive,
                    exit: styles.fadeExit,
                    exitActive: styles.fadeExitActive,
                  }}
                >
                  <li
                    className={`${styles.listItem} ${
                      selectedGlobalItem === item.name ? styles.selected : ""
                    }`}
                    style={{ "--animation-delay": `${index * 100}ms` }}
                  >
                    {/* Render nested Dropdowns if items exist */}
                    {item.items && item.items.length > 0 ? (
                      <Dropdown
                        title={item.name}
                        items={item.items}
                        selectedGlobalItem={selectedGlobalItem}
                        setSelectedGlobalItem={setSelectedGlobalItem}
                        color={color}
                        svg={svg}
                        hasCount={hasCount}
                      />
                    ) : (
                      <div onClick={() => handleSelectItem(item)}>
                        <span className={styles.icon2}>{svg}</span>
                        <span className={styles.itemName} style={{ color }}>
                          {item.name}
                        </span>
                      </div>
                    )}
                  </li>
                </CSSTransition>
              );
            })}
        </TransitionGroup>
      </div>
    </div>
  );
};

export default Dropdown;
