import { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./Plot.module.css";

import {
  fetchBoxPlotData,
  setSelectedThreshold,
} from "../../../features/btl_tool/btlToolSlice";
import SliderButton from "../../../layout/buttons/SliderButton";
import useDebouncedAction from "../../../features/DebounceHook";
import BoxPlotChart from "../../../layout/plot/BoxPlot";

function BtlPlot() {
  const dispatch = useDispatch();
  const {
    selectedScenario,
    selectedSubScenario,
    selectedPeriod,
    selectedSegment,
    loadingSegments,
    selectedRiskScore,
    loadingRiskScores,
    selectedThreshold,
    thresholds,
    boxPlotData,
    loadingBoxPlotData,
  } = useSelector((state) => state.btlTool);
  const noSelectedPeriod =
    !selectedPeriod || Object.keys(selectedPeriod).length === 0;
  const showPlot =
    selectedScenario &&
    selectedSegment &&
    selectedRiskScore &&
    !noSelectedPeriod &&
    selectedThreshold;

  useDebouncedAction({
    action: () =>
      dispatch(
        fetchBoxPlotData({
          scenarioId: selectedScenario?.id,
          segmentId: selectedSegment.id,
          subScenarioId: selectedSubScenario?.id || 0,
          riskScore: selectedRiskScore,
          period: selectedPeriod,
        })
      ),
    dependencies: [
      selectedScenario,
      selectedSubScenario,
      selectedRiskScore,
      selectedSegment,
      selectedPeriod,
      loadingSegments,
      loadingRiskScores,
    ],
    condition: showPlot && !loadingRiskScores && !loadingSegments,
  });

  if (!showPlot || selectedRiskScore.sar_alerts == 0) {
    return <Fragment></Fragment>;
  }

  function handleThresholdSelect(threshold_name) {
    const matchingThreshold = thresholds.find(
      (threshold) => threshold.threshold_name === threshold_name
    );

    if (matchingThreshold) {
      dispatch(setSelectedThreshold(matchingThreshold));
    }
  }

  const thresholdOptions = thresholds.map(
    (threshold) => threshold.threshold_name
  );
  const boxPlotDataSelected = boxPlotData.find(
    (data) => data.threshold_name === selectedThreshold.threshold_name
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 24 }}>
      <SliderButton
        options={thresholdOptions}
        activeOption={selectedThreshold.threshold_name}
        onClick={handleThresholdSelect}
        widthSlider={200}
      />
      <div className={styles.graphHolder}>
        <BoxPlotChart
          isLoading={loadingBoxPlotData}
          riskScore={selectedRiskScore}
          boxPlotData={boxPlotDataSelected}
          showOutliers={false}
          yAxisLegend={`${
            selectedSegment.name
          }, ${selectedRiskScore.name.toLowerCase()}`}
          alertTypes={["sar_alerts"]}
        />
      </div>
    </div>
  );
}

export default BtlPlot;
