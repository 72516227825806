import { useState, useEffect, Fragment, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import layout_styles from "../../../layout/Layout.module.css";
import styles from "./ConfigurationTable.module.css";
import HeaderSection from "../../../layout/HeaderSection";
import {
  loadConfigurations,
  updateConfiguration,
  uploadFiles,
  setUploadStatus,
  resetUploadDetails,
  setSelectedConfigurationDetails,
  setConfigActiveTab,
} from "../../../features/configuration/configurationSlice";
import TableGrid from "../../../layout/table/Table";
import { CellClass, RowCellClass } from "../../../layout/table/Cell";
import { HeaderClass } from "../../../layout/table/Header";
import SliderButton from "../../../layout/buttons/SliderButton";
import FileUploadModal from "../modals/FileUploadModal";
import {
  SecondaryButton,
  PrimaryDropDownButton,
  PrimaryButton,
} from "../../../layout/buttons/NormalButton";
import Checkbox from "../../../layout/buttons/Checkbox";
import { InputSelector } from "../../../layout/selector/Selector";
import ToggleButton from "../../../layout/buttons/ToggleButton";

import { IconButton } from "../../../layout/buttons/IconButton";
import Tooltip from "../../../layout/tooltip/Tooltip";
import ModalPopUp from "../../../layout/modal/Modal";
import DateSelector from "../../../layout/selector/DateSelector";
import RoundedButton from "../../../layout/buttons/RoundedButton";

import { SelectActionButton } from "../../../layout/table/TableTools.js";
import { Modal } from "@mui/material";
import Dropdown from "../../../layout/dropdown/Dropdown.js";

const configurationErrors = [
  {
    category: "Labels",
    items: [
      {
        name: "Label name",
        items: [
          { name: "This is the description of an error that has to be fixed." },
          { name: "This is the description of an error that has to be fixed." },
          { name: "This is the description of an error that has to be fixed." },
        ],
      },
      {
        name: "Label name",
        items: [{ name: "Another error description." }],
      },
    ],
  },
  {
    category: "Profiles",
    items: [
      {
        name: "Profile name",
        items: [
          { name: "Error specific to this profile." },
          { name: "Another profile-related error." },
        ],
      },
    ],
  },
  {
    category: "Parameters",
    items: [
      {
        name: "Parameter name",
        items: [
          { name: "Parameter-related error." },
          { name: "Another parameter error." },
        ],
      },
    ],
  },
  {
    category: "Scenarios",
    items: [
      {
        name: "Scenario name",
        items: [
          { name: "Scenario-related error." },
          { name: "Another scenario error." },
        ],
      },
    ],
  },
];

function CustomizeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21.1739 6.81238C21.7026 6.2838 21.9997 5.56685 21.9998 4.81923C21.9999 4.07162 21.703 3.35459 21.1744 2.82588C20.6459 2.29717 19.9289 2.00009 19.1813 2C18.4337 1.99991 17.7166 2.2968 17.1879 2.82538L3.84193 16.1744C3.60975 16.4059 3.43805 16.6909 3.34193 17.0044L2.02093 21.3564C1.99509 21.4429 1.99314 21.5347 2.01529 21.6222C2.03743 21.7097 2.08285 21.7896 2.14673 21.8534C2.21061 21.9172 2.29055 21.9624 2.37809 21.9845C2.46563 22.0065 2.55749 22.0044 2.64393 21.9784L6.99693 20.6584C7.3101 20.5631 7.59511 20.3925 7.82693 20.1614L21.1739 6.81238Z"
        stroke="#D9D6FE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 5L19 9"
        stroke="#D9D6FE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function ImportIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 13V21"
        stroke="#D9D6FE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.00034 14.8993C3.25738 14.1402 2.69691 13.222 2.36137 12.2142C2.02584 11.2064 1.92405 10.1355 2.0637 9.08256C2.20335 8.02962 2.5808 7.02226 3.16743 6.1368C3.75407 5.25133 4.53452 4.51098 5.44967 3.97182C6.36482 3.43265 7.39067 3.10882 8.44951 3.02484C9.50835 2.94087 10.5724 3.09895 11.5611 3.48712C12.5498 3.8753 13.4372 4.48337 14.1561 5.2653C14.8749 6.04723 15.4065 6.9825 15.7103 8.00026H17.5003C18.4659 8.00015 19.4058 8.31059 20.1813 8.8857C20.9569 9.46082 21.5269 10.2701 21.8071 11.1941C22.0874 12.118 22.063 13.1076 21.7377 14.0166C21.4123 14.9257 20.8032 15.706 20.0003 16.2423"
        stroke="#D9D6FE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 17L12 13L16 17"
        stroke="#D9D6FE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function WrenchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M14.7003 6.29974C14.5171 6.48666 14.4145 6.73798 14.4145 6.99974C14.4145 7.26149 14.5171 7.51281 14.7003 7.69974L16.3003 9.29974C16.4873 9.48296 16.7386 9.58559 17.0003 9.58559C17.2621 9.58559 17.5134 9.48296 17.7003 9.29974L21.4703 5.52974C21.9732 6.64092 22.1254 7.87897 21.9068 9.07888C21.6882 10.2788 21.1091 11.3836 20.2466 12.246C19.3842 13.1084 18.2794 13.6876 17.0795 13.9062C15.8796 14.1248 14.6415 13.9726 13.5303 13.4697L6.62034 20.3797C6.22252 20.7776 5.68295 21.0011 5.12034 21.0011C4.55773 21.0011 4.01817 20.7776 3.62034 20.3797C3.22252 19.9819 2.99902 19.4423 2.99902 18.8797C2.99902 18.3171 3.22252 17.7776 3.62034 17.3797L10.5303 10.4697C10.0275 9.35855 9.87526 8.1205 10.0939 6.92059C10.3125 5.72068 10.8916 4.61589 11.7541 3.75346C12.6165 2.89102 13.7213 2.3119 14.9212 2.09328C16.1211 1.87465 17.3592 2.0269 18.4703 2.52974L14.7103 6.28974L14.7003 6.29974Z"
        stroke="#E9D7FE"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function CrossIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M15 5L5 15"
        stroke="#F63D68"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 5L15 15"
        stroke="#F63D68"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function MagnifierIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M8 11L10 13L14 9"
        stroke="#E9D7FE"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
        stroke="#E9D7FE"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0002 21.0002L16.7002 16.7002"
        stroke="#E9D7FE"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function CloseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M5 22H19"
        stroke="#9B8AFB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.27 13.73C19.0374 13.498 18.7614 13.3141 18.4577 13.1888C18.1539 13.0636 17.8285 12.9994 17.5 13H6.5C5.83696 13 5.20107 13.2634 4.73223 13.7322C4.26339 14.2011 4 14.837 4 15.5V17C4 17.2652 4.10536 17.5196 4.29289 17.7071C4.48043 17.8946 4.73478 18 5 18H19C19.2652 18 19.5196 17.8946 19.7071 17.7071C19.8946 17.5196 20 17.2652 20 17V15.5C20 14.84 19.74 14.2 19.27 13.73Z"
        stroke="#9B8AFB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 13V8.5C14 7 15 7 15 5C15 4.20435 14.6839 3.44129 14.1213 2.87868C13.5587 2.31607 12.7956 2 12 2C10.34 2 9 3 9 5C9 7 10 7 10 8.5V13"
        stroke="#9B8AFB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function ClockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.0003 18.3337C14.6027 18.3337 18.3337 14.6027 18.3337 10.0003C18.3337 5.39795 14.6027 1.66699 10.0003 1.66699C5.39795 1.66699 1.66699 5.39795 1.66699 10.0003C1.66699 14.6027 5.39795 18.3337 10.0003 18.3337Z"
        stroke="#E9D7FE"
        strokeWidth="1.39167"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 5V10L13.3333 11.6667"
        stroke="#E9D7FE"
        strokeWidth="1.39167"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function RocketIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4.5 16.4993C3 17.7593 2.5 21.4993 2.5 21.4993C2.5 21.4993 6.24 20.9993 7.5 19.4993C8.21 18.6593 8.2 17.3693 7.41 16.5893C7.02131 16.2184 6.50929 16.004 5.97223 15.9874C5.43516 15.9708 4.91088 16.1531 4.5 16.4993Z"
        stroke="#E9D7FE"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15.0002L9 12.0002C9.53214 10.6197 10.2022 9.29631 11 8.05025C12.1652 6.18723 13.7876 4.6533 15.713 3.59434C17.6384 2.53538 19.8027 1.98662 22 2.00025C22 4.72025 21.22 9.50025 16 13.0002C14.7369 13.799 13.3968 14.469 12 15.0002Z"
        stroke="#E9D7FE"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 11.9995H4C4 11.9995 4.55 8.96953 6 7.99953C7.62 6.91953 11 7.99953 11 7.99953"
        stroke="#E9D7FE"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15V20C12 20 15.03 19.45 16 18C17.08 16.38 16 13 16 13"
        stroke="#E9D7FE"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function AddConfigButton({ setUploadVisible }) {
  const content = (
    <div style={{ display: "flex", gap: 8, padding: 8, alignItems: "center" }}>
      <div className={styles.plusIcon}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M3.7998 8.00098H12.1998"
            stroke="#8174FE"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.99902 3.80078V12.2008"
            stroke="#8174FE"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div className={styles.text}>New Configuration</div>
    </div>
  );

  return (
    <PrimaryDropDownButton
      content={content}
      action={[
        {
          name: "Customize",
          action: () => {},
          icon: <CustomizeIcon />,
        },
        {
          name: "Import",
          action: () => setUploadVisible(true),
          icon: <ImportIcon />,
        },
      ]}
    />
  );
}

function StatusLabel({ status, dispatch, configActiveTab, configId }) {
  async function updateConfig() {
    const statusToSet =
      status === "In production" ? "Previously in production" : "In production";
    let newData = { status: statusToSet };
    if (status == "In production") {
      newData["left_production"] = new Date().toISOString();
    } else {
      newData["entered_production"] = new Date().toISOString();
    }
    const resultAction = await dispatch(
      updateConfiguration({ configId, newData })
    );
    if (updateConfiguration.fulfilled.match(resultAction)) {
      if (status === "In production") {
        dispatch(setConfigActiveTab("Development"));
      } else {
        dispatch(setConfigActiveTab("Production"));
      }
      dispatch(loadConfigurations());
    } else {
      console.error("Update configuration failed:", resultAction.payload);
    }
  }

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <div
          className={`${styles.bullet} ${
            status == "In production"
              ? styles.green
              : status == "Previously In Production"
              ? styles.yellow
              : styles.red
          }`}
        />
        <div style={{ whiteSpace: "nowrap" }}>{status}</div>
      </div>
      <SecondaryButton
        content={
          configActiveTab === "Development" ||
          configActiveTab === "Previously in production"
            ? "run"
            : "stop"
        }
        style={{ padding: "7px 14px", fontSize: 12 }}
        action={updateConfig}
      />
    </div>
  );
}

function ConfigurationTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { configurations, uploadStatus, uploadDetails, configActiveTab } =
    useSelector((state) => state.configuration);
  const [uploadVisible, setUploadVisible] = useState(false);
  useEffect(() => {
    dispatch(loadConfigurations());
  }, []);
  const [selectedConfigurations, setSelectedConfigurations] = useState([]);
  const [activeConfigurations, setActiveConfigurations] = useState([]);

  const [selectedIds, setSelectedIds] = useState([]);

  const headers = [
    new HeaderClass("ID", "id", false, false, {
      width: 140,
    }),
    new HeaderClass("Name", "name", true, true, {
      minWidth: 250,
      maxWidth: 250,
      width: 250,
    }),
    new HeaderClass("Status", "status", true, true, {
      minWidth: 280,
      maxWidth: 280,
      width: 280,
    }),
    new HeaderClass("Domain", "domain", true, true, {
      minWidth: 140,
    }),
    new HeaderClass("Modification Date", "modification_date", true, false, {
      minWidth: 150,
    }),
    new HeaderClass("Entered Production", "entered_production", true, false, {
      minWidth: 150,
    }),
    new HeaderClass("Left Production", "left_production", true, false, {
      minWidth: 150,
    }),
    new HeaderClass("Active", "active", false, false, {
      minWidth: 120,
    }),
  ];

  const checkIfDateIsFuture = (stringDate) => {
    const date = new Date(stringDate);
    const currentDate = new Date();
    return date > currentDate;
  };

  const filteredConfigurations = configurations.filter((row) => {
    if (configActiveTab === "Production") {
      return (
        (row.status && row.status == "In production") ||
        row.status == "Entering Production" ||
        row.status == "Leaving Production"
      );
    } else {
      return (
        row.status &&
        row.status !== "In production" &&
        row.status !== "Entering Production" &&
        row.status !== "Leaving Production"
      );
    }
  });

  const handleCheckboxChange = (configId) => {
    setSelectedConfigurations((prev) => {
      if (prev.includes(configId)) {
        return prev.filter((id) => id !== configId);
      } else {
        return [...prev, configId];
      }
    });
  };

  const handleToggleChange = async (configId) => {
    // New data is the toggle switched
    let newData = {
      active: !rows.find((row) => row.rowData.id === configId).rowData.active,
    };

    // Update the configuration with the new active status
    const res = await dispatch(updateConfiguration({ configId, newData }));

    // Reload configurations after the changes
    if (res) {
      dispatch(loadConfigurations());
    } else {
      // Handle the update configuration failure
      console.error("Update configuration failed:", res.payload);
    }
  };

  // All possible options for the status selector
  const statusOptions = [
    {
      id: 1,
      name: "In production",
      data: 1,
      render: () => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="6"
            viewBox="0 0 7 6"
            fill="none"
          >
            <circle cx="3.40625" cy="3" r="3" fill="#12B76A" />
          </svg>
          <div className={styles.statusText}>In production</div>
        </div>
      ),
    },
    {
      id: 2,
      name: "Entering Production",
      data: 2,
      render: () => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="6"
            viewBox="0 0 7 6"
            fill="none"
          >
            <circle cx="3.40625" cy="3" r="3" fill="#B9E5CB" />
          </svg>
          <div className={styles.statusText}>Entering Production</div>
        </div>
      ),
    },
    {
      id: 3,
      name: "Leaving Production",
      data: 3,
      render: () => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="6"
            viewBox="0 0 7 6"
            fill="none"
          >
            <circle cx="3.40625" cy="3" r="3" fill="#FFE79F" />
          </svg>
          <div className={styles.statusText}>Leaving Production</div>
        </div>
      ),
    },
    {
      id: 4,
      name: "Validation Error",
      data: 4,
      render: () => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="6"
            viewBox="0 0 7 6"
            fill="none"
          >
            <circle cx="3.40625" cy="3" r="3" fill="#F63D68" />
          </svg>
          <div className={styles.statusText}>Validation Error</div>
        </div>
      ),
    },
    {
      id: 5,
      name: "No Status",
      data: 5,
      render: () => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="6"
            viewBox="0 0 7 6"
            fill="none"
          >
            <circle cx="3.40625" cy="3" r="3" fill="#D0D5DD" />
          </svg>
          <div className={styles.statusText}>No Status</div>
        </div>
      ),
    },
    {
      id: 6,
      name: "Previously In Production",
      data: 6,
      render: () => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="6"
            viewBox="0 0 7 6"
            fill="none"
          >
            <circle cx="3.40625" cy="3" r="3" fill="#667085" />
          </svg>

          <div className={styles.statusText}>Previously In Production</div>
        </div>
      ),
    },
    {
      id: 7,
      name: "In Development",
      data: 7,
      render: () => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="6"
            viewBox="0 0 7 6"
            fill="none"
          >
            <circle cx="3.40625" cy="3" r="3" fill="#D9D6FE" />
          </svg>

          <div className={styles.statusText}>In Development</div>
        </div>
      ),
    },

    {
      id: 8,
      name: "Ready for Production",
      data: 8,
      render: () => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="6"
            viewBox="0 0 7 6"
            fill="none"
          >
            <circle cx="3.40625" cy="3" r="3" fill="#B9E5CB" />
          </svg>

          <div className={styles.statusText}>Ready for Production</div>
        </div>
      ),
    },
  ];

  const hideModal = () => {
    setUploadVisible(false);
    dispatch(resetUploadDetails());
  };

  const daysUntil = (dateString) => {
    const parseDate = (dateStr) => {
      const [datePart, timePart] = dateStr.split(" - ");
      const [day, month, year] = datePart.split("/").map(Number);
      const [hours, minutes] = timePart.split(":").map(Number);
      return new Date(year, month - 1, day, hours, minutes);
    };

    const targetDate = parseDate(dateString);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date to midnight

    const differenceInDays = Math.ceil(
      (targetDate - today) / (1000 * 60 * 60 * 24)
    );

    if (differenceInDays === 0) {
      return "today";
    } else if (differenceInDays > 0) {
      return `in ${differenceInDays} day${differenceInDays > 1 ? "s" : ""}`;
    } else {
      return `${Math.abs(differenceInDays)} day${
        Math.abs(differenceInDays) > 1 ? "s" : ""
      } ago`;
    }
  };

  const updateConfigurationStatus = async ({ configId, status }) => {
    let newData = { status: status };

    // If selected status is "In production"
    if (status.toLowerCase() === "in production") {
      // If going into production, set entered_production to current date
      if (
        rows
          .find((row) => row.rowData.id === configId)
          .rowData.status.toLowerCase() !== "in production"
      ) {
        newData["entered_production"] = new Date().toISOString();
        newData["left_production"] = null;
      }
    } else {
      // If going to development from production, set left_production to current date
      if (
        rows
          .find((row) => row.rowData.id === configId)
          .rowData.status.toLowerCase() === "in production"
      ) {
        newData["left_production"] = new Date().toISOString();
        newData["entered_production"] = null;
      }
    }

    // Update the configuration with the new status
    const resultAction = await dispatch(
      updateConfiguration({ configId, newData })
    );

    // Switch to the correct tab after updating the configuration
    if (updateConfiguration.fulfilled.match(resultAction)) {
      if (status.toLowerCase() === "in production") {
        dispatch(setConfigActiveTab("Production"));
      } else {
        dispatch(setConfigActiveTab("Development"));
      }
      dispatch(loadConfigurations()); // Reload configurations after the changes
    } else {
      // Handle the update configuration failure
      console.error("Update configuration failed:", resultAction.payload);
    }
  };

  // Handler to update selected option for a specific selector
  const handleSelectorChange = (option, configId) => {
    let status = statusOptions.find((status) => status.id === option).name;
    updateConfigurationStatus({ configId, status });
  };

  const rows = filteredConfigurations.map((rowData) => {
    const configId = rowData.id;
    const cells = headers.map((header) => {
      const value = rowData[header.field];
      if (header.field === "status") {
        return new CellClass(
          value,
          header.field,
          () => {
            const selectedStatus = statusOptions.find(
              (option) =>
                option.name.toLowerCase() === rowData.status.toLowerCase()
            );
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                {selectedStatus?.render
                  ? selectedStatus.render()
                  : rowData.status}

                <div style={{ display: "flex", alignSelf: "flex-end" }}>
                  {configActiveTab === "Development" && (
                    <>
                      {rowData.status === "No Status" ? (
                        <onClick
                          icon={<RocketIcon />}
                          action={() => {
                            alert("Rocket Clicked");
                          }}
                        />
                      ) : rowData.status === "Validation Error" ? (
                        <Tooltip
                          text="Check Errors"
                          position="bottom-right" // Available options: 'top', 'bottom', 'left', 'right'
                          arrowVisible={true}
                        >
                          <IconButton
                            icon={<WrenchIcon />}
                            onClick={() => {
                              setIsModalErrorOpen(true);
                            }}
                          />
                        </Tooltip>
                      ) : rowData.status === "Ready for Production" ? (
                        <Tooltip
                          text="Enter in Production."
                          position="bottom-right" // Available options: 'top', 'bottom', 'left', 'right'
                          arrowVisible={true}
                        >
                          <IconButton
                            icon={<RocketIcon />}
                            onClick={() => {
                              {
                                setIsScheduleValidationModalOpen(true);
                              }
                            }}
                          />
                        </Tooltip>
                      ) : rowData.status === "In Development" ? (
                        <Tooltip
                          text="Validation Check"
                          position="bottom-right" // Available options: 'top', 'bottom', 'left', 'right'
                          arrowVisible={true}
                        >
                          <IconButton
                            icon={<MagnifierIcon />}
                            onClick={() => {
                              setIsValidationCheckModalIsOpen(true);
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
              </div>
            );
          },
          "100%"
        );
      }
      if (
        header?.field === "modification_date" ||
        header?.field === "left_production"
      ) {
        if (value) {
          const dateOnly = value.split("T")[0];
          const timeOnly = value.split("T")[1].split(".")[0];
          const [datePart, timePart] = [
            dateOnly.split("-").reverse().join("/"),
            timeOnly,
          ];
          return new CellClass(
            `${datePart} - ${timePart.slice(0, 5)}`,
            header.field
          );
        }
      }
      if (header?.field === "entered_production") {
        if (value) {
          const dateOnly = value.split("T")[0];
          const timeOnly = value.split("T")[1].split(".")[0];
          const [datePart, timePart] = [
            dateOnly.split("-").reverse().join("/"),
            timeOnly,
          ];

          const dateIsFuture = checkIfDateIsFuture(value);

          if (dateIsFuture == true) {
            return new CellClass(
              `${datePart} - ${timePart.slice(0, 5)}`,
              header.field,
              () => {
                return (
                  <Tooltip
                    text="Reschedule"
                    position="right" // Available options: 'top', 'bottom', 'left', 'right' setSelectedDate(datePart)
                    arrowVisible={true}
                  >
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 8 }}
                    >
                      {daysUntil(`${datePart} - ${timePart.slice(0, 5)}`)}
                      <IconButton
                        icon={<ClockIcon />}
                        onClick={() => {
                          setIsScheduleModalOpen(true);
                          setSelectedDate(
                            convertToDate(
                              `${datePart} - ${timePart.slice(0, 5)}`
                            )
                          );
                        }}
                      />
                    </div>
                  </Tooltip>
                );
              }
            );
          } else {
            return new CellClass(
              `${datePart} - ${timePart.slice(0, 5)}`,
              header.field
            );
          }
        }
      }
      if (header?.field === "id") {
        return new CellClass(value, header.field);
      }
      if (header?.field === "active") {
        return new CellClass(value, header.field, () => (
          <ToggleButton
            checked={
              rows.find((row) => row.rowData.id === configId).rowData.active
            }
            onChange={() => handleToggleChange(configId)}
            disabled={false}
          />
        ));
      }
      return new CellClass(value, header.field);
    });

    return new RowCellClass(configId, cells, rowData);
  });

  useEffect(() => {
    console.log("selectedConfigurations", rows);
  }, [rows]);

  // Possible configuration tabs
  const CONFIGURATION_TABS = ["Production", "Development"];

  const tabsRef = useRef([]);

  // Decides the position of the underline under the active tab
  const [underlineStyle, setUnderlineStyle] = useState({});

  // Update the position of the underline under the active tab
  const updateUnderlinePosition = (index) => {
    const currentTab = tabsRef.current[index];
    if (currentTab) {
      setUnderlineStyle({
        left: currentTab.offsetLeft,
        width: currentTab.clientWidth,
      });
    }
  };

  // Handle a change in the active configuration tab
  useEffect(() => {
    updateUnderlinePosition(configActiveTab === "Production" ? 0 : 1);
  }, [configActiveTab]);

  let onRowClick = undefined;
  // if (configActiveTab === "Development"){
  onRowClick = (row, event) => {
    console.log("evnent event ", event);
    if (event.target.closest(`.${styles.iconButton}`)) {
      // Ignore row click if the event originated from an IconButton
      return;
    }

    navigate("/configuration/" + row.id, {
      state: { config: configActiveTab },
    });
    setSelectedConfigurationDetails(row);
  };
  // }

  const listIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="lucide lucide-list-tree"
    >
      <path d="M21 12h-8" />
      <path d="M21 6H8" />
      <path d="M21 18h-8" />
      <path d="M3 6v4c0 1.1.9 2 2 2h3" />
      <path d="M3 10v6c0 1.1.9 2 2 2h3" />
    </svg>
  );

  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    console.log("slected date ", selectedDate);
  }, [selectedDate]);

  const today = new Date(); // Current date and time
  const tomorrow = new Date(today); // Clone today's date
  tomorrow.setDate(today.getDate() + 1); // Increment day by 1

  const in7Days = new Date(today); // Clone today's date
  in7Days.setDate(today.getDate() + 7); // Increment day by 7

  const selectorOptions = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "12px",
        padding: "12px",
      }}
    >
      <div style={{ width: "80%" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <RoundedButton
            radius={"16px"}
            color={"#6C3DE0"}
            bgColor={"transparent"}
            content={"Today"}
            shadow={false}
            onClick={() => {
              setSelectedDate(today);
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              marginTop: "12px",
            }}
          >
            <RoundedButton
              radius={"16px"}
              color={"#6C3DE0"}
              bgColor={"transparent"}
              content={"Tomorrow"}
              shadow={false}
              onClick={() => {
                setSelectedDate(tomorrow);
              }}
            />
            <RoundedButton
              radius={"16px"}
              color={"#6C3DE0"}
              bgColor={"transparent"}
              content={"In 7 days"}
              shadow={false}
              onClick={() => {
                setSelectedDate(in7Days);
              }}
            />
          </div>
        </div>
      </div>
      <div style={{ width: "20%" }}>
        <PrimaryButton
          content={"OK"}
          style={{ padding: "6px 12px" }}
          onClick={() => {}}
        />
      </div>
    </div>
  );
  const dateModalContent = (
    <div>
      <DateSelector
        label="Join Production on:"
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        options={selectorOptions}
      />
    </div>
  );

  const convertToDate = (dateString) => {
    const [datePart, timePart] = dateString.split(" - ");
    const [day, month, year] = datePart.split("/").map(Number);
    const [hours, minutes] = timePart.split(":").map(Number);
    // Create a Date object
    return new Date(year, month - 1, day, hours, minutes);
  };

  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);

  const modalsButtons = [
    <SecondaryButton
      content="Cancel"
      action={() => setIsScheduleModalOpen(false)}
    />,
    <PrimaryButton content="Continue" action={() => {}} />,
  ];

  const handleConfirmClear = () => {
    // Clear the selectedIds and selectAllMode
  };

  const actionButtons = [
    new SelectActionButton(
      "unselect",
      "configuration",
      ({ selectedIds, selectAllMode, onSuccess }) => {
        handleConfirmClear();
      },
      <CloseIcon />,
      true
    ),
  ];

  const [isScheduleValidationModalOpen, setIsScheduleValidationModalOpen] =
    useState(false);
  const modalButtonsJoinProduction = [
    <SecondaryButton
      content="Cancel"
      action={() => setIsScheduleValidationModalOpen(false)}
    />,
    <PrimaryButton
      content="Continue"
      action={() => {
        setIsScheduleValidationModalOpen(false);
        setIsScheduleModalOpen(true);
      }}
    />,
  ];

  const [isValidationCheckModalIsOpen, setIsValidationCheckModalIsOpen] =
    useState(false);
  const modalButtonsValidationCheck = [
    <SecondaryButton
      content="Cancel"
      action={() => setIsValidationCheckModalIsOpen(false)}
    />,
    <PrimaryButton
      content="Continue"
      action={() => {
        setIsValidationCheckModalIsOpen(false);
      }}
    />,
  ];

  const [selectedGlobalItem, setSelectedGlobalItem] = useState(null);

  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const modalButtonsError = [
    <SecondaryButton
      content="Cancel"
      action={() => setIsModalErrorOpen(false)}
    />,
    <PrimaryButton content="Continue" action={() => {}} />,
  ];

  const errorModalContent = configurationErrors.map((category) => (
    <>
      <Dropdown
        key={category.category}
        title={category.category}
        items={category.items}
        selectedGlobalItem={selectedGlobalItem}
        setSelectedGlobalItem={setSelectedGlobalItem}
        color={"red"}
        svg={<CrossIcon />}
        hasCount={true}
      />
    </>
  ));

  return (
    <div className={styles.atlTool}>
      <ModalPopUp
        icon={<WrenchIcon />}
        content={errorModalContent}
        actionButtons={modalButtonsError}
        description={" errors have been detected in this configuration "}
        buttonsAlign={"stretch"}
        title={"Check Configuration Errors"}
        isOpen={isModalErrorOpen}
        handleClose={() => setIsModalErrorOpen(false)}
      />

      <ModalPopUp
        icon={<MagnifierIcon />}
        actionButtons={modalButtonsValidationCheck}
        description={
          "Would you like to perform a validation check on this configuration?"
        }
        buttonsAlign={"stretch"}
        title={"Validation Check"}
        isOpen={isValidationCheckModalIsOpen}
        handleClose={() => setIsValidationCheckModalIsOpen(false)}
      />

      <ModalPopUp
        icon={<RocketIcon />}
        actionButtons={modalButtonsJoinProduction}
        description={"Would you like this configuration to join Production?"}
        buttonsAlign={"stretch"}
        title={"Join Production"}
        isOpen={isScheduleValidationModalOpen}
        handleClose={() => setIsScheduleModalOpen(false)}
      />
      <ModalPopUp
        icon={<RocketIcon />}
        actionButtons={modalsButtons}
        buttonsAlign={"stretch"}
        title={"Reschedule Configuration Production"}
        isOpen={isScheduleModalOpen}
        handleClose={() => setIsScheduleModalOpen(false)}
        content={dateModalContent}
      />

      <div
        className={layout_styles.sectionWrapper}
        style={{ minHeight: "976px" }}
      >
        <HeaderSection
          SectionName="Configuration"
          AdditionalText="Configuration Overview"
        />
        {uploadVisible && (
          <FileUploadModal
            hideModalFunction={hideModal}
            dispatchUploadFunction={uploadFiles}
            uploadStatus={uploadStatus}
            dispatchUploadStatus={setUploadStatus}
            uploadDetails={uploadDetails}
          />
        )}
        <PrimaryButton
          svgIcon={listIcon}
          content={"Shared Lists"}
          action={() => navigate("/shared-lists")}
          style={{
            width: "120px",
            marginLeft: "24px",
            marginTop: "12px",
            display: "flex",
          }}
        />

        {/* The possible tabs */}
        <div className={styles.tabWrapper}>
          <div className={styles.tabContainer}>
            {CONFIGURATION_TABS.map((tab, index) => (
              <button
                key={tab}
                ref={(el) => (tabsRef.current[index] = el)}
                className={`${styles.tabButton} ${
                  configActiveTab === tab ? styles.active : ""
                }`}
                onClick={() => {
                  dispatch(setConfigActiveTab(tab));
                  updateUnderlinePosition(index);
                }}
              >
                {tab.toUpperCase()}
              </button>
            ))}
            <div className={styles.underline} style={underlineStyle} />
          </div>
        </div>

        <TableGrid
          title={"Configurations"}
          headers={headers}
          rows={rows}
          canExport={false}
          onRowClick={onRowClick}
          onSelectActionButtons={actionButtons}
          noDataPlaceholder={
            configActiveTab == "Production"
              ? "No configuration in production found"
              : "No configuration in development found"
          }
          actionButtonsLeft={[
            <AddConfigButton setUploadVisible={setUploadVisible} />,
          ]}
          firstRowWidth={
            headers.find((header) => header.field === "id").style.width
          }
          fixedHeight={1065} // 1065px holds 10 items in the table
        />
      </div>
    </div>
  );
}

export default ConfigurationTable;
