

import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import styles from "./RegularInput.module.css";
import Badge from '../badges/Badge';

import BadgeEditor from './BadgeEditor';
import ProfileBadgeEditor from './ProfileBadgeEditor';

import ScenarioDefinition from './ScenarioDefinition'; // Asegúrate de importar ScenarioEditor


const RegularInput = ({
  title,
  withBackground,
  content = [],
  isEditable,
  isBadgeEditor,
  isScenario,
  onChange, 
  mode,
  definitionCallback,
  badgesSelection
}) => {

  const dispatch = useDispatch();


  const AutoExpand = ({ selector }) => {
    useEffect(() => {
      const autoExpand = (selector) => {
        document.querySelectorAll(selector).forEach((textarea) => {
          const resizeHeight = () => {

              // Styling the textarea
              textarea.style.color = '#4E5BA6';
              textarea.style.background = 'transparent';
              textarea.style.fontFamily = 'Inter';
              textarea.style.fontSize = '16px';
              textarea.style.fontStyle = 'normal';
              textarea.style.fontWeight = '400';
              textarea.style.border = 'none';
              textarea.style.outline = 'none';
              textarea.style.padding = '0';

            textarea.style.width = '100%'; // Ensure it takes the full width
            textarea.style.height = 'auto'; // Reset height to calculate proper scrollHeight
            textarea.style.resize = 'none'; // Disable manual resizing
            textarea.style.overflow = 'visible'; // Ensure scroll is hidden
            // Get the computed line height
            const computedStyle = window.getComputedStyle(textarea);
            const lineHeight = parseFloat(computedStyle.lineHeight);

            // Calculate the number of lines based on scrollHeight and lineHeight
            const lines = Math.floor(textarea.scrollHeight / lineHeight);

            // Set the height based on scrollHeight
            if (lines < 2) {
              textarea.style.height = '18px';
            } else {
              textarea.style.height = `${textarea.scrollHeight + 1 }px`; // Add 1px to prevent scroll	ressulting from floor rounding
            }


          };

          // Initialize height adjustment on mount
          resizeHeight();

          // Add event listener to handle height resize on input change
          textarea.addEventListener('input', resizeHeight);

          // Cleanup event listener when component unmounts
          return () => {
            textarea.removeEventListener('input', resizeHeight);
          };
        });
      };

      autoExpand(selector);
    }, [selector]);

    return null;
  };


  

  return (
    <div className={styles.container}>
      <div className={styles.label}>{title}</div>
      <div className={withBackground ? styles.inputWrapper : styles.inputWrapperNoBackground}>

        <div style={{ display: !isEditable ? "inline-flex" : "block", flexWrap:"wrap" }} className={isEditable ? styles.editableInputWrapper : ""}>
          {isBadgeEditor ? (
            isScenario ? (
              <ScenarioDefinition isEdit={isEditable} onChange={onChange}  badgesSelection={badgesSelection} />  // Si ambos son verdaderos, renderiza ScenarioEditor

              // <ScenarioDefinition isEdit={isEditable} onChange={onChange} />  // Si ambos son verdaderos, renderiza ScenarioEditor
              // <SlateEditor content={content}  />

            ) : (
              content && content.length > 0 ? (
                mode === 'profile' ? (<ProfileBadgeEditor badgesSelection={badgesSelection} isEdit={isEditable} onChange={onChange} mode={mode}/>) :(
               
                <BadgeEditor badgesSelection={badgesSelection} definitionCallback={definitionCallback} isEdit={isEditable} onChange={onChange} mode={mode}/> //Si solo isBadgeEditor es verdadero, renderiza BadgeEditor
                 ) 
              ) : (
                <p>No content available</p>
              )
            )
          ) : (
            content.map((badgeProps, index) => {
              if (badgeProps.type === "newline") {
                return <br key={index} />;
              } else if (badgeProps.type === "badge") {
                const { text, color, bgColor, hasBullet } = badgeProps;
                return <Badge key={index} text={text} color={color} bgColor={bgColor} hasBullet={hasBullet} />;
              } else if (badgeProps.type === "text") {
                return isEditable ? (

                  <input

                    key={index}
                    type="text"
                    value={badgeProps.text}
                    className={styles.input}

                    onChange={(e) => {
                      // Actualiza el contenido según sea necesario
                      if (badgeProps.onChange) badgeProps.onChange(e.target.value);
                    }}

                  />

                ) : (
                  <div key={index} className={styles.text}>{badgeProps.text}</div>
                );
              }
              return null;
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default RegularInput;
